import { Route, Routes, Navigate } from "react-router-dom";
import React from 'react'

import Layout from "../layouts/layout"
import RequireAuth from "../auth/requireAuth";
import { ADMIN_ROLES } from "../config/constants"

//Pages
//open
import Login from '../features/login/index'
import ForgotPassword from '../features/forgotPassword/index'

//EveryOne loggedIn can access
import Home from '../features/homePage/index'
import CreateNewPassword from '../features/resetPassword/index'
import ChangeAvatar from '../features/changeAvatar';

//Admin
import AdminViewProfile from '../features/viewProfile/index'
import AddUser from '../features/addUsers/index'
import DeleteUser from '../features/deleteUser/index'
import AccessManagement from '../features/accessManagement/index'
import Legal from '../features/legal';
import History from '../features/adminProfile/history'

//Client
import ClientCreateProfile from '../features/masterClient/viewClient'
import Dashboard from '../features/masterClient/dashboard';
import DataSummary from '../features/masterClient/dataSummary'
import Coupons from '../features/masterClient/coupons/index'
import ViewRequests from '../features/masterClient/clientSignUp/viewRequests';
import SignUpRequest from '../features/masterClient/clientSignUp/signUpRequest';

//CUSTOMER
import CustomerProfile from '../features/customer/customerProfile/index'
import PurchasesAndWarranty from '../features/customer/purchasesAndWarranty/index'
import Rewards from '../features/customer/rewards/index'
import ProductDetails from '../features/customer/productDetails/index'
import WarrantyTC from '../features/customer/warrantyTC';

//QR
import GenerateQRCode from "../features/generateQrCode/generateQRCode/index";
import DownloadQRCode from "../features/generateQrCode/downloadQRCode/index"
import DownloadQRAlreadyGeneratedCodes from '../features/downloadQRCode/index'
import BatchActions from '../features/batchActions/index'
import BatchAppend from '../features/batchActions/batchAppend/index'
import BatchEdit from '../features/batchActions/batchEdit/index'
import Sku from '../features/skuDetails/index'

//FInance
import Finance from '../features/finance/index'
import MiscInvoice from '../features/finance/miscInvoice/index'
import FinanceDashboard from '../features/finance/dashboard';

//Open - Something wrong
import MissingPage from "../components/404Page"                         //Page is missing
import UnAuthorized from "../components/unauthorized"                   //Importing UnAuthorized Component

//Login manager
import PersistLogin from "../features/login/components/persistentLogin"

function AppRoutes() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Layout />}>

                    {/* Public Routes */}
                    <Route path="/login" element={<Login />} />
                    <Route path="/unauthorized" element={<UnAuthorized />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />

                    {/* Protected Routes */}
                    <Route element={<PersistLogin />}>
                        {/* HOME */}
                        <Route element={<RequireAuth allowedRoles={Object.keys(ADMIN_ROLES).map(key => { return ADMIN_ROLES[`${key}`]})} />}>
                            <Route path="/" element={<Navigate to='/home' />} />
                            <Route path="/home" element={<Home />} />
                            <Route path="/reset-password" element={<CreateNewPassword />} />
                            <Route path="/change-avatar" element={<ChangeAvatar />} />
                        </Route>

                        {/* ADMIN */}
                        <Route element={<RequireAuth allowedRoles={[ADMIN_ROLES.SUPER_ADMIN, ADMIN_ROLES.ADMIN]} />}>
                            <Route path="/admin-view-profile" element={<AdminViewProfile />} />
                            <Route path="/add-user" element={<AddUser />} />
                            <Route path="/delete-user" element={<DeleteUser />} />
                            <Route path="/access-management" element={<AccessManagement />} />
                            <Route path="/legal" element={<Legal />} />
                            <Route path="/history" element={<History />} />
                        </Route>

                        {/* CLIENT */}
                        <Route element={<RequireAuth allowedRoles={[ADMIN_ROLES.SUPER_ADMIN, ADMIN_ROLES.ADMIN, ADMIN_ROLES.CLIENT]} />}>
                            <Route path="/view-client-profile" element={<ClientCreateProfile />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/data-summary" element={<DataSummary />} />
                            <Route path="/coupons" element={<Coupons />} />
                            <Route path="/view-requests" element={<ViewRequests />} />
                            <Route path="/signup-request/:srId" element={<SignUpRequest />} />
                        </Route>

                        {/* CUSTOMER */}
                        <Route element={<RequireAuth allowedRoles={[ADMIN_ROLES.SUPER_ADMIN, ADMIN_ROLES.ADMIN, ADMIN_ROLES.CUSTOMER]} />}>
                            <Route path="/customer-profile" element={<CustomerProfile />} />
                            <Route path="/purchases-and-warranty/:customerId" element={<PurchasesAndWarranty />} />
                            <Route path="/product-details" element={<ProductDetails />} />
                            <Route path="/rewards/:customerId" element={<Rewards />} />
                            <Route path="/warranty-terms-and-conditions" element={<WarrantyTC />} />
                        </Route>

                        {/* QR */}
                        <Route element={<RequireAuth allowedRoles={[ADMIN_ROLES.SUPER_ADMIN, ADMIN_ROLES.ADMIN, ADMIN_ROLES.QR]} />}>
                            <Route path='/new-batch-details' element={<GenerateQRCode />} />
                            <Route path='/download-qr-code' element={<DownloadQRAlreadyGeneratedCodes />} />
                            <Route path="/generated-qr-codes" element={<DownloadQRCode />} /> 
                            <Route path="/batch-actions" element={<BatchActions />} />
                            <Route path='/batch-actions/append/:clientid/:batchno' element={<BatchAppend />} />
                            <Route path='/batch-actions/edit/:clientid/:batchno' element={<BatchEdit />} />
                            <Route path="/sku" element={<Sku />} />
                        </Route>
                        
                        {/* FINANCE */}
                        <Route element={<RequireAuth allowedRoles={[ADMIN_ROLES.SUPER_ADMIN, ADMIN_ROLES.ADMIN, ADMIN_ROLES.FINANCE]} />}>
                            <Route path='/finance' element={<Finance />} />
                            <Route path='/misc-invoice' element={<MiscInvoice />} />
                            <Route path='/finance-dashboard' element={<FinanceDashboard />} />
                        </Route>
                    </Route>
                    {/* Catch All */}
                    <Route path="*" element={<MissingPage />} />
                </Route>
            </Routes>
        </div>
    )
}

export default AppRoutes