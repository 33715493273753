import { handleError } from '../../../../../utils/handles/errorHandle';
async function getServiceRequestData(srId,axiosPrivate) {
    try{
        const response = await axiosPrivate.get(`/api/client/sr-form/fetch-service-request/${srId}`);
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function updateServiceRequest(srId, formData, axiosPrivate) {
    try{
        const response = await axiosPrivate.post(`/api/client/sr-form/update-sr-request/${srId}/SR_FORM_DRAFT`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response;
    }catch(error){
        handleError(error);
    }
}

async function createMasterClient(organizationName,srId,masterClientDetails,axiosPrivate){
    try{
        const response = await axiosPrivate.post(`/api/client/create/master-client/${organizationName}/${srId}`,masterClientDetails);
        return response?.data;
    }catch(error){
        handleError(error)
    }
}

export {
    getServiceRequestData,
    updateServiceRequest,
    createMasterClient
}

const submit ={
    "useVeotsSubdomain": true,
    "domain": 'https://test.veots.in',
    "billingDate": 2,
    "organizationDetails": {
        "organizationName": 'Mahindra',
        "addressLine1": 's639, 12th cross, 2nd line',
        "addressLine2": 'barath nagar',
        "country": 'India',
        "state": 'Bihar',
        "city": 'Patna',
        "postalCode": 76205,
        "entityType": 'Companies Limited by Shares',
        "categoryOfOrganization": 'Fashion Industry',
        "taxId": 'GSTIN1237687',
        "businessId": 'KLS3184',
        "monthlyProductionQuantity": 12000,
        "numberOfSKUs": 123,
        "serviceType": [ 'Authentication', 'Warranty' ],
        "dunsNumber": 'IMF1343',
        "companyWebsite": 'https://veots.com/',
        "companyDocOptions": 'Memorandum of Association',
        "companyDoc":"https://test-veots-client-docs.s3.amazonaws.com/SR-Draft-Files%2FClient-Company-Docs%2FSRxiTvIP-20240410T075842612Z-companyDoc.pdf"
    },
    "authPersonInfo": {
        "firstName": 'Anna',
        "lastName": 'Delvey',
        "designation": 'CEO',
        "emailId": 'yadlapalliranganadh@gmail.com',
        "mobileNoDetails": {
            "countryCode": 'IN',
            "countryCallingCode": '+91',
            "mobileNo": '1234567890',
            "intelMobileNo": '+911234567890',
            "formattedMobileNo": '+91 1234567890'
        },
        "auth_Proof_Of_Identity_Doc_Options": 'Aadhar Card/e-Aadhar card downloaded from UIDAI website',
        "auth_Proof_Of_Identity_Doc":"https://test-veots-client-docs.s3.amazonaws.com/SR-Draft-Files%2FAuth-Proof-Of-Identity-Doc%2FSRxiTvIP-20240410T075842613Z-auth_Proof_Of_Identity_Doc.pdf",
        "auth_Authorization_Doc":"https://test-veots-client-docs.s3.amazonaws.com/SR-Draft-Files%2FAuth-Authorization-Doc%2FSRxiTvIP-20240410T075842613Z-auth_Authorization_Doc.pdf"
    },
    "paymentContactInfo": {
        "firstName": 'Anna',
        "lastName": 'Delvey',
        "designation": 'CEO',
        "emailId": 'yadlapalliranganadh@gmail.com',
        "mobileNoDetails": {
            "countryCode": 'IN',
            "countryCallingCode": '+91',
            "mobileNo": '1234567890',
            "intelMobileNo": '+911234567890',
            "formattedMobileNo": '+91 1234567890'
        },
        "payment_Proof_Of_Identity_Doc_Options": 'Aadhar Card/e-Aadhar card downloaded from UIDAI website',
        "payment_Proof_Of_Identity_Doc":"https://test-veots-client-docs.s3.amazonaws.com/SR-Draft-Files%2FPayment-Proof-Of-Identity-Doc%2FSRxiTvIP-20240410T075842613Z-payment_Proof_Of_Identity_Doc.pdf",
        "payment_Authorization_Doc":"https://test-veots-client-docs.s3.amazonaws.com/SR-Draft-Files%2FPayment-Authorization-Doc%2FSRxiTvIP-20240410T075842614Z-payment_Authorization_Doc.pdf"
    },
    "adminAccessSubUsers": {
        "userName1": 'Racheal',
        "emailId1": 'racheal@apple.in',
        "userName2": 'ross geller',
        "emailId2": 'rossg@apple.in'
    },
    "regularAccessSubUsers": {
        "userName1": 'kabir',
        "emailId1": 'kabir@apple.in',
        "userName2": 'aishwarya',
        "emailId2": 'aishwarya@apple.in'
    },
    "uiDesignCriteria": {
        "primaryButtonColor":"#53AAF5",
        "secondaryButtonColor":"#9639A8",
        "backgroundColor":"#000000",
        "modeOfService":"Web",
        "comments":"Nothing",
        "isfooterIncluded":true,
        "qrScanAccessLevel":["parent_brand","veots"],
        "clientLogo":"https://test-veots-client-docs.s3.amazonaws.com/SR-Draft-Files%2FClient-Logos%2FSRxiTvIP-20240410T075842613Z-clientLogo.png"
    },
    "solutionCostsDetails": {
        "infoBaseCost": 1,
        "covertBaseCost": 2,
        "covertWarrantyCost": 3,
        "covertLoyaltyCost": 4,
        "overtBaseCost": 5,
        "overtWarrantyCost": 6,
        "overtLoyaltyCost": 7,
        "trackingBaseCost": 8
    },
    "servicesCostDetails": {
        "costPerCouponCode": 1,
        "cashbackTransactionCost": 1,
        "dataCostPerQr": 1,
        "miscellaneous": [ [{ header: 'hello', fixedCost: 2 }] ],
        "maintainencePerMonth": 1000,
        "maintainenceComments": 'nothing'
    },
}