const initialValues_form = {
    'clientId':'',
    'batchNo':'',
    'fromDate':'',
    'toDate':''
}

const manuInitialValues_update = {
    'type':'',
    'batchNo':'',
    'clientId':'',
    'Pimage': '',
    'QROnprod': '',
    'expiryImage': '',
    'brand': '',
    'prodName': '',
    'mrp': '',
    'serialNo': '',
    'shelfLife': '',
    'expiryDate': '',
    'warrantyApp': '',
    'warrantyPeriod': '',
    'manuLicenseNo': '',
    'manuAdd': '',
    'addDetails': '',
    'additionalImageDetails': '',
    'extraImage1': '',
    'extraImage2': '',
    'extraVideo1': '',
    'manuWebsiteLink': ''
}

const retaInitialValues_update = {
    'type':'',
    'batchNo':'',
    'Pimage': '',
    'QROnprod': '',
    'prodName': '',
    'manuLicenseNo': '',
    'manuAdd': '',
    'addDetails': '',
    'additionalImageDetails': '',
    'extraImage1': '',
    'extraImage2': '',
    'extraVideo1': '',
    'manuWebsiteLink': ''
}


export {
    initialValues_form,
    manuInitialValues_update as initialValues_update,
    retaInitialValues_update
}