import React from 'react';

import Input from '../../../../../../components/form2/Input';

const SubUsers = ({ formik }) => {
    return (
        <div className='my-10 mb-5 mx-16'>
            <p className='text-[#182AB4] text-5xl mb-10 font-semibold'>Sub - users' details with admin access</p>
            <div className='flex gap-16'>
                <div className='w-1/2 flex flex-col'>
                    <Input 
                        label='Name 1'
                        formik={formik}
                        name={'user_userName1'}
                        placeholder=''
                        marginClass='mb-5'
                    />
                    <Input 
                        label='Name 2'
                        formik={formik}
                        name={'user_userName2'}
                        placeholder=''
                        marginClass='mb-5'
                    />
                </div>
                <div className='w-1/2 flex flex-col'>
                    <Input 
                        label='Official Email Id'
                        formik={formik}
                        name={'user_emailId1'}
                        placeholder=''
                        marginClass='mb-5'
                    />
                    <Input 
                        label='Official Email Id'
                        formik={formik}
                        name={'user_emailId2'}
                        placeholder=''
                        marginClass='mb-5'
                    />
                </div>
            </div>
        </div>
    );
}

export default SubUsers;
