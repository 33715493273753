import React, { useState, useMemo, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {Header} from '../../../layouts/header'
import Navbar from '../../../layouts/navbar'
import SidebarComponent from '../generateQRCode/components/sideBarComponent'
import { SubmitButton } from '../../../components/ui/buttons/submitButton'
import TextPopup from "../../../components/popups/textPopup"

import warning from '../../../assets/images/warning.png';

function Index() {

    const navigate = useNavigate();
    const state = useLocation().state
    useEffect(() => {
        if(!state || !state.qrCodeLinks) {
            navigate('/new-batch-details')
        }
    }, [])

    const batchData = useMemo(() => {
        return state ? state.batchData || {} : {};
    }, [state]);
    const downloadLinks = useMemo(() => {
        return state ? state.qrCodeLinks || {} : {};
    }, [state]);

    const [showTextPopup, setShowTextPopup] = useState(false)
    const [alertText, setAlertText] = useState("");
    const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false);

    const blurredBackgroundStyles = isBackgroundBlurred
        ? {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(100, 100, 100, 0.5)",
            backdropFilter: "blur(1.8px)",
            zIndex: 1,
        }
        : {};

    const handleDownload = async (url) => {
        try {
            const link = document.createElement('a');
            link.href = url;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); 
        } catch (error) {
            setAlertText(`Unable to download file, ${error.message}`);
            setShowTextPopup(true);
            setIsBackgroundBlurred(true);
        }
    }

    return (
        <div className='h-[100%]'>
            {isBackgroundBlurred && <div style={blurredBackgroundStyles} />}
            <div className='bg-bgcolor/50'>
                <Header />
            </div>
            <div className='flex h-[90vh]'>
                <div className='w-3/12 '>
                    <SidebarComponent />
                </div>
                <div className='w-full bg-bgLightBlue'>
                    <div className='h-10  flex items-center ml-10 mr-10'>
                        <Navbar />
                    </div>
                    <div className=' mr-10 ml-10 h-[90%]'>
                        <div className='bg-bgLightBlue  flex flex-row justify-between items-center h-16 border-solid border-navBorder rounded-t-xl'>
                            {
                                state && <h6 className=' font-medium pl-10'>Code list for the product with batch number: {batchData.batchid || batchData.batchNo}</h6>
                            }
                            <h6 className=' font-medium pr-10'>For queries: call on 9632123543</h6>
                        </div>
                        {showTextPopup && (
                            <TextPopup
                                heading="Alert"
                                description={alertText}
                                onClose={() => {
                                    setShowTextPopup(false);
                                    setIsBackgroundBlurred(false);
                                    navigate('/new-batch-details');
                                }}
                            />
                        )}
                        {
                            downloadLinks && <div className='h-[80%] bg-bgWhite flex flex-col justify-center items-center'>
                                <h6 className='font-bold h-11 text-3xl'>Your File is Ready!!</h6>
                                <h6 className='text-sideBarBtnText h-11'>Download the file to see the QR list</h6>
                                <div className='w-[90%] flex flex-col gap-5'>
                                    {downloadLinks.prodQrs && <SubmitButton btnWidth={'[100%]'} label={"Download Product Qrs"} onClick={() => handleDownload(downloadLinks.prodQrs)} />}
                                    {downloadLinks.quatQrs && <SubmitButton btnWidth={'[100%]'} label={"Download Quaternary Qrs"} onClick={() => handleDownload(downloadLinks.quatQrs)} />}
                                    {downloadLinks.tertQrs && <SubmitButton btnWidth={'[100%]'} label={"Download Tertiary Qrs"} onClick={() => handleDownload(downloadLinks.tertQrs)} />}
                                </div>

                                <div className='border-yellow-500 border bg-yellow-100 p-10 rounded-xl mt-20 flex justify-center items-center'>
                                    <img src={warning} alt="" className='h-[2rem] w-[2rem] mr-2' />
                                    <p className='text-2xl'><span className='font-semibold'>Please note:</span> These QR links will remain valid for 30 minutes. You can also download them later from the 'Download QR' section.</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index



