import React from 'react'
import { Formik, Form } from 'formik'

import CloseIcon from '../../assets/svg/popups/popUpCloseIcon.svg'
import FormikControl from '../form/formikControl'
import { SubmitButton } from '../ui/buttons/submitButton'
import RedButton from '../ui/buttons/deleteButtonRed'


function PopupOneButton(props) {
    const { heading, description, onClose, onSubmit, okButtonText, } = props
    return (
        <Formik>
            {
                formik => {
                    return (

                        <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-popupScreen flex flex-col w-[25%] z-10'>
                            <div className='bg-bgWhite flex flex-col justify-center w-[100%] z-10'>
                                <div className='flex justify-between items-center p-10 h-14 w-[100%] bg-gradient-to-r from-gradientPurple  to-gradientBlue to-90% '>
                                    <span className='font-bold text-4xl text-popupScreen'>{heading}</span>
                                    <img className='w-9 h-9 cursor-pointer' src={CloseIcon} alt='' onClick={onClose} />
                                </div>
                                <div className='px-12 py-20 flex justify-center items-center'>
                                    <span className='text-popUpTextColor text-3xl font-medium'>{description}</span>
                                </div>
                            </div>
                            <div className='flex w-[100%] pl-10 pr-10 pb-10 justify-center items-center'>
                                <div className='w-[45%]' >
                                    <SubmitButton onClick={onSubmit} type={'submit'} label={`${okButtonText || 'Ok'}`} btnWidth='full' />
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        </Formik >
    )
}

export default PopupOneButton