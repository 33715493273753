import React, { useEffect, useRef } from 'react'

export default function Comment({ formik }) {

    const commentsRef = useRef(null);
    useEffect(() => {
        if(!commentsRef.current) return;
        commentsRef.current.value = formik?.values?.comments || '';
    }, [formik?.values?.comments])

    return (
        <div className='mx-16 -mb-4'>
            <div className='mb-10'>
                <label htmlFor='comments' className='text-3xl mb-2'>Any thing else you want to specify?</label>
                <textarea 
                    ref={commentsRef}
                    className='w-full bg-[#182AB408] border p-[.65rem] rounded-md text-3xl' 
                    id="comments" 
                    name="comments" 
                    onBlur={formik?.handleBlur}
                    value={formik?.comments}
                    onChange={(e) => formik.setFieldValue('comments', e.target.value)}
                    rows={5}
                    />
                <p className='text-xl text-gray-500'>Max: 2000 Characters</p>
            </div>
        </div>
    )
}