import axios from 'axios';
import { useEffect, useState } from 'react';

function useGetCountriesAndStates() {

    const [countries, setCountries] = useState([]);
    const [countryAndStates, setCountryAndStates] = useState({});

    useEffect(() => {
        (async () => {
            let countriesAndStatesData = [];
            try {
                let response = await axios.get('https://countriesnow.space/api/v0.1/countries/states');
                countriesAndStatesData = response.data.data;
            } catch (err) {
                console.log(err);
            }
            const countriesList = [];
            const countryAndStatesList = {};

            countriesAndStatesData.forEach(countryAndStatesData => {
                countriesList.push(countryAndStatesData.name);

                const statesList = [];
                countryAndStatesData.states.map(statesData => {
                    statesList.push(statesData.name);
                })
                countryAndStatesList[countryAndStatesData.name] = statesList;
            })

            setCountries(countriesList);
            setCountryAndStates(countryAndStatesList)
        })();
    }, [])

    return {
        countries , getStates: country => {
            return countryAndStates[country]
        }
    };
}

export default useGetCountriesAndStates;