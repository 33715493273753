import {Outlet} from "react-router-dom"
import { useState,useEffect } from "react"
import useRefreshToken from "../../../hooks/useRefreshToken"
import useAuth from "../../../hooks/useAuth";
import Loader from "../../../components/ui/loader/Loader";


const PersistLogin = () => {

    const refresh = useRefreshToken()
    const {auth} = useAuth()
    const [isLoading, setisLoading] = useState(true)

    useEffect(()=>{
        const verifyRefreshToken = async () => {
            try{
                await refresh();
            }catch(error){
                console.error(error)
            }
            finally{
                setisLoading(false)
            }
        }
        !auth.accessToken ? verifyRefreshToken():setisLoading(false)
    },[])

    return (
        <>
            {
                isLoading
                ?<Loader/>
                :<Outlet/>
            }

        </>
    )
}


export default PersistLogin
