import { handleError } from "../../../utils/handles/errorHandle";

async function getAllAdminsPaginated(pageNo,pageSize,sortBy,sortOrder,axiosPrivate){
    try{
        const response = await axiosPrivate.get("/api/admin/users/paginated",{ params:{ pageNo, pageSize, sortBy, sortOrder }});
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function getAllMasterClientsPaginated (pageNo,pageSize,sortBy,sortOrder,axiosPrivate){
    try{
        const response = await axiosPrivate.get("/api/client/masterclients/paginated",{ params:{ pageNo, pageSize, sortBy, sortOrder }});
        
        console.log(response.data, "masters");
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function getAllSubClientsPaginated(masterClientId, pageNo, pageSize, axiosPrivate){
    try{
        const response = await axiosPrivate.get(`/api/client/${masterClientId}/subclients/paginated`, { params: {pageNo, pageSize} });
        return response?.data;
    }catch(error){  
        handleError(error);
    }
}

async function getAdminSuggestions(queryString, axiosPrivate) {
    try{
        const response = await axiosPrivate.get("/api/admin/userid-suggestions",{ params:{ queryString } });
        return response?.data;
    }catch(error){  
        handleError(error);
    }
}

async function getMasterClientSuggestions(queryString, axiosPrivate) {
    try{
        const response = await axiosPrivate.get("/api/client/masterclient/userid-suggestions",{ params:{ queryString } });
        return response?.data;
    }catch(error){  
        handleError(error);
    }
}

async function getAdminsByQueryString(queryString,sortBy,sortOrder,pageNo,pageSize,axiosPrivate){
    try{
        const response = await axiosPrivate.get("/api/admin/user-info", { params:{ queryString, sortBy, sortOrder, pageNo, pageSize }});
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function getMasterClientsByQueryString(queryString,sortBy,sortOrder,pageNo,pageSize,axiosPrivate){
    try{
        const response = await axiosPrivate.get("/api/client/masterclient/user-info", { params:{ queryString, sortBy, sortOrder, pageNo, pageSize }});
        
        console.log(response);
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function blockAdmin(blockDetails,axiosPrivate){
    try{
        const response = await axiosPrivate.post("/api/admin/block",blockDetails);
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function unBlockAdmin(unBlockDetails,axiosPrivate){
    try{
        const response = await axiosPrivate.post("/api/admin/unblock",unBlockDetails);
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function deleteAdmin (deletionDetails,axiosPrivate) {
    try{
        const response = await axiosPrivate.post("/api/admin/delete",deletionDetails);
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function deleteSubClient(deleteDetails,axiosPrivate){
    try{
        const response = await axiosPrivate.post("/api/client/delete/sub-client",deleteDetails);
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function blockSubClient(blockDetails,axiosPrivate){
    try{
        const response = await axiosPrivate.post("/api/client/block/sub-client",blockDetails);
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function unBlockSubClient(unBlockDetails,axiosPrivate){
    try{
        const response = await axiosPrivate.post("/api/client/unblock/sub-client",unBlockDetails);
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function blockMasterClient(blockDetails,axiosPrivate){
    try{
        const response = await axiosPrivate.post("/api/client/block/master-client",blockDetails);
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function unBlockMasterClient(unBlockDetails,axiosPrivate){
    try{
        const response = await axiosPrivate.post("/api/client/unblock/master-client",unBlockDetails);
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

export{
    getAllAdminsPaginated,
    getAllMasterClientsPaginated,
    getAllSubClientsPaginated,
    getAdminSuggestions,
    getMasterClientSuggestions,
    getAdminsByQueryString,
    getMasterClientsByQueryString,

    blockAdmin,
    unBlockAdmin,
    deleteAdmin,
    deleteSubClient,
    blockSubClient,
    unBlockSubClient,
    blockMasterClient,
    unBlockMasterClient,
}


