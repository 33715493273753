import axiosInstance from "../axios/axios";
import { handleError } from "../utils/handles/errorHandle";

async function requestOtp (axiosPrivate) {
    try{
        const response = await axiosPrivate.post('/api/admin/send-otp');

        console.log(response.data)
;        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function resendOtp (emailId) {
    try{
        const response = await axiosInstance.post(`/otp/resend`, {
            type: 'email',
            emailId
        });

        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function verifyOtp(contactNum, otp, axiosPrivate){
    try{
        const response = await axiosPrivate.post(`/otp/verify`,{
            "mobile":contactNum,
            "otp":otp,
            "type":"mobile"
        });
        return response?.data;
    }catch(error){
        console.log(error)
        if(error?.response.status!==500){
            throw new Error(error?.response?.data?.errorMessage)
        }
        else{
            throw new Error("Something went wrong")
        }
    }
}
export {
    requestOtp,
    resendOtp,
    verifyOtp
}