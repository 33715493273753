export const initialValues = {
    organizationName:'',
    addressLine1:'',
    addressLine2: '',
    country: '',
    state: '',
    city: '',
    postalCode: '',
    entityType: '',
    categoryOfOrganization: '',
    taxId: '',
    businessId: '',
    monthlyProductionQuantity: '',
    numberOfSKUs: '',
    serviceType: [],
    dunsNumber: '',
    companyWebsite: '',
    enterpriseType: '',
    billingDate: '',
    companyDocOptions: '',
    companyDoc: null,

    //Auth
    firstName: '',
    lastName: '',
    designation: '',
    emailId: '',
    mobileNoDetails: '',
    auth_Proof_Of_Identity_Doc_Options: '',
    authAuthorizationDoc: null,
    authProofDoc: null,

    //Incoice
    inv_firstName: '',
    inv_lastName: '',
    inv_designation: '',
    inv_emailId: '',
    inv_mobileNoDetails: '',
    inv_auth_Proof_Of_Identity_Doc_Options: '',
    invAuthorizationDoc: null,
    invProofDoc: null,

    //admin
    admin_userName1: '',
    admin_emailId1: '',
    admin_userName2: '',
    admin_emailId2: '',

    //users
    user_userName1: '',
    user_emailId1: '',
    user_userName2: '',
    user_emailId2: '',

    //Solution
    trackingBaseCost: '',
    infoBaseCost: '',
    overtBaseCost: '',
    overtWarrantyCost: '',
    overtLoyaltyCost: '',
    covertBaseCost: '',
    covertWarrantyCost: '',
    covertLoyaltyCost: '',

    //Service
    dataCostPerQr: '',
    costPerCouponCode: '',
    cashbackTransactionCost: '',
    emailAndSmsComission: '',
    geoLocation_Cost_Per_Conversion: '',
    miscellaneous: [{}],

    //AppDetils
    primaryColor: '#8950B8',
    secondaryColor: '#5B9EEB',
    gradientAngle: 90,
    backgroundColor: '#000000',
    clientLogo: '',
    clientFavIcon: '',
    modeOfService: '',
    useVeotsSubdomain: '',
    domainName: '',
    isfooterIncluded: '',
    qrScanAccessLevel: '[]',
    
    //Otpservice
    isOtpServiceEnabled: '',
    emailDomain: '',
    replyToMailId: '',
    senderMailId: '',
    
    //Background
    bgVerification_Doc: '',
    
    //comments
    comments: '',
}