import { useState, useEffect, useCallback } from 'react'
import { Form, Formik } from 'formik'
import { Header } from '../../layouts/header'
import Sidebar from '../../layouts/sidebar'
import Navbar from '../../layouts/navbar'
import FormikControl from '../../components/form/formikControl'
import { SubmitButton } from '../../components/ui/buttons/submitButton'

import SortingTable from '../../components/table/sortingTable';
import ClientAccessManagement from './components/clientAccessManagement'
import AdminAccessManagement from './components/adminAccessManagement'
import searchIcon from '../../assets/svg/form_icons/search_icon.svg'

import useSidebar from '../../hooks/useSidebar'
import sideBarData from './components/sidebardata'

import Loader from "../../components/ui/loader/Loader";
import PopUpManager from '../../components/popups2/popupManager'

//importing necessary hooks
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { getAllAdminsPaginated, getAllMasterClientsPaginated, getAdminSuggestions, getMasterClientSuggestions, getAdminsByQueryString, getMasterClientsByQueryString } from './services/api';
import { SORT_ORDER, USER_TYPES } from '../../config/constants'

const MasterclientsHeaderData = [{value: 'Client ID', space: '25%', key: 'masterClientId'}, {value: 'Organization Name', space: '25%', key: 'organizationName'}, {value: 'Created by', space: '25%', key: 'createdBy'}, {value: 'No. of Users', space: '25%', key: 'subClientsCount'}]
const AdminsHeaderData = [{value: 'Admin User ID', space: '30%', key: 'userId'}, {value: 'Email ID', space: '40%', key: 'emailId'}, {value: 'Created by', space: '30%', key: 'createdBy'}]

function Index() {
    const { isSidebarOpen, toggleSidebar } = useSidebar();
    const axiosPrivate = useAxiosPrivate();

    const [userType, setUserType] = useState();
    const [suggestionArray, setSuggetionArray] = useState([]);
    const [searchParam, setSearchParam] = useState('');

    const [placeholder, setPlaceholder] = useState('Search');
    const pageCount = 10;

    //Admin
    const [admins, setAdmins] = useState([]);
    const [adminsSortedKey, setAdminsSortedKey] = useState(AdminsHeaderData[0].key);
    const [adminsSortedWay, setAdminsSortedWay] = useState(SORT_ORDER.ASC);
    const [adminsCurrentPage, setAdminsCurrentPage] = useState(1);
    const [adminsMaxPage, setAdminsMaxPage] = useState(0);
    const [adminsPrevDisabled, setAdminsPrevDisabled] = useState(true);
    const [adminsNextDisabled, setADminsNextDisabled] = useState(true);

    //Master-CLient
    const [masterClients, setMasterClients] = useState([]);
    const [masterSortedKey, setMasterSortedKey] = useState(MasterclientsHeaderData[0].key);
    const [masterSortedWay, setMasterSortedWay] = useState(SORT_ORDER.ASC);
    const [masterCurrentPage, setMasterCurrentPage] = useState(1);
    const [masterMaxPage, setMasterMaxPage] = useState()
    const [masterPrevDisabled, setMasterPrevDisabled] = useState(true);
    const [masterNextDisabled, setMasterNextDisabled] = useState(true);

    const [selectedMasterClient, setSelectedMasterClient] = useState(null);
    const [isMasterClientDetailOpen, setIsMasterClientDetailOpen] = useState(false);

    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [isAdminDetailOpen, setIsAdminDetailOpen] = useState(false);
    
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [textPopupDiabled, setTextPopupDisabled] = useState(true);
    const [textPopupText, setTextPopupText] = useState('');

    //ADmin logic
    const loadAdmins = useCallback(async (adminsCurrentPage, adminsSortedKey, adminsSortedWay, searchParam) => {
        try {
            let res;
            setTableLoading(true);
            if(!searchParam)
                res = await getAllAdminsPaginated(adminsCurrentPage, pageCount, adminsSortedKey, adminsSortedWay, axiosPrivate);
            else 
                res = await getAdminsByQueryString(searchParam, adminsSortedKey, adminsSortedWay, adminsCurrentPage, pageCount, axiosPrivate);

            const pageData = res?.pageData;
            pageData.forEach(ele => {
                const date = new Date(ele.createdAt);
                ele.createdAt = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
            });
            setAdmins(res?.pageData);
            setAdminsMaxPage(Math.floor(res?.totalUsers/pageCount) + (res?.totalUsers%pageCount? 1 : 0));
        } catch (error) {
            //Popup
            setTextPopupText(error.message || `Unable to load Admins`);
            setTextPopupDisabled(false);
        } finally {
            setTableLoading(false);
        }
    }, [axiosPrivate]);

    const setAdminsSorting = (key, way) => {
        setAdminsSortedKey(key);
        setAdminsSortedWay(way);

        loadAdmins(adminsCurrentPage, key, way, searchParam);
    }

    const adminsNext = () => {
        const newPageNumber = adminsCurrentPage + 1;
        setAdminsCurrentPage(newPageNumber);
        loadAdmins(newPageNumber, adminsSortedKey, adminsSortedWay, searchParam);
    }

    const adminsPrevious = () => {
        const newPageNumber = adminsCurrentPage - 1;
        setAdminsCurrentPage(newPageNumber);
        loadAdmins(newPageNumber, adminsSortedKey, adminsSortedWay, searchParam);
    }

    useEffect(() => {
        if(adminsCurrentPage === 1) 
            setAdminsPrevDisabled(true);
        else
            setAdminsPrevDisabled(false);

        if(adminsCurrentPage === adminsMaxPage)
            setADminsNextDisabled(true);
        else
            setADminsNextDisabled(false); 
    }, [adminsCurrentPage, adminsMaxPage]);
    //

    //master logic
    const loadMasterClients = useCallback(async (masterCurrentPage, masterSortedKey, masterSortedWay, searchParam) => {
        try {
            let res;
            setTableLoading(true);
            if(!searchParam)
                res = await getAllMasterClientsPaginated(masterCurrentPage, pageCount, masterSortedKey, masterSortedWay, axiosPrivate);
            else
                res = await getMasterClientsByQueryString(searchParam, masterSortedKey, masterSortedWay, masterCurrentPage, pageCount, axiosPrivate);
            
            const pageData = res?.pageData;
            pageData.forEach(ele => {
                const date = new Date(ele.createdAt);
                ele.createdAt = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
            });
            setMasterClients(res?.pageData);
            setMasterMaxPage(Math.floor(res?.totalUsers/pageCount) + (res?.totalUsers%pageCount? 1 : 0));
        } catch (error) {
            //Popup
            setTextPopupText(error.message || `Unable to load Master clients`);
            setTextPopupDisabled(false);
        } finally {
            setTableLoading(false);
        }
    }, [axiosPrivate]);

    const setMasterSorting = (key, way) => {
        setMasterSortedKey(key);
        setMasterSortedWay(way);

        loadMasterClients(masterCurrentPage, key, way, searchParam);
    }

    const masterNext = () => {
        const newPageNum = masterCurrentPage + 1;
        setMasterCurrentPage(newPageNum);

        loadMasterClients(newPageNum, masterSortedKey, masterSortedWay, searchParam);
    }

    const masterPrevious = () => {
        const newPageNum =  masterCurrentPage - 1;
        setMasterCurrentPage(newPageNum);

        loadMasterClients(newPageNum, masterSortedKey, masterSortedWay, searchParam);
    }

    useEffect(() => {
        if(masterCurrentPage === 1) 
            setMasterPrevDisabled(true);
        else
            setMasterPrevDisabled(false);

        if(masterCurrentPage === masterMaxPage)
            setMasterNextDisabled(true);
        else
            setMasterNextDisabled(false); 
    }, [masterCurrentPage, masterMaxPage]);
    //

    const refreshTables = async type => {
        setSearchParam('');
        setSuggetionArray([]);

        if(type === 'admin') {
            setPlaceholder('Admin ID')
            setAdminsCurrentPage(1);
            setAdminsSortedKey(AdminsHeaderData[0].key);
            setAdminsSortedWay(SORT_ORDER.ASC);

            await loadAdmins(1, AdminsHeaderData[0].key, SORT_ORDER.ASC, '');
        } else if(type === 'client') {
            setPlaceholder('Master client ID')
            setMasterCurrentPage(1);
            setMasterSortedKey(MasterclientsHeaderData[0].key);
            setMasterSortedWay(SORT_ORDER.ASC);

            await loadMasterClients(1, MasterclientsHeaderData[0].key, SORT_ORDER.ASC, '');
        }
    }

    const handleUserType = async (e) => {
        setUserType(e.target.value);
        const type = e.target.value;

        //close tabs
        setIsAdminDetailOpen(false);
        setIsMasterClientDetailOpen(false);

        //reset tables
        await refreshTables(type);
    }

    //Manage suggestion Array
    const searchParamChange = async (param) => {
        setSearchParam(param);

        if(param) {
            try {
                let res;
                if(userType === 'admin')
                    res = await getAdminSuggestions(param, axiosPrivate)
                else if(userType === 'client')
                    res = await getMasterClientSuggestions(param, axiosPrivate)
                
                setSuggetionArray(res);
            } catch (error) {
                setSuggetionArray([]);
            }
        } else {
            await refreshTables(userType);
        }
    }

    const initialValues = {
        'param': ''        
    }

    const handleSubmit = async ({param}) => {
        setSearchParam(param);

        if(userType === 'admin') {
            setAdminsSortedKey(AdminsHeaderData[0].key);
            setAdminsSortedWay(SORT_ORDER.ASC);
            setAdminsCurrentPage(1);

            await loadAdmins(1, AdminsHeaderData[0].key, SORT_ORDER.ASC, param);
        }
        if(userType === 'client') {
            setMasterSortedKey(MasterclientsHeaderData[0].key);
            setMasterSortedWay(SORT_ORDER.ASC);
            setMasterCurrentPage(1);

            await loadMasterClients(1, MasterclientsHeaderData[0].key, SORT_ORDER.ASC, param);
        }
    }

    const onClientSelcted = (clientData) => {
        setSelectedMasterClient(clientData)
        setIsMasterClientDetailOpen(true);
    }

    const onAdminSelcted = (adminData) => {
        setSelectedAdmin(adminData)
        setIsAdminDetailOpen(true);
    }

    return (
        <>
        <PopUpManager 
                control={'text'}
                disable={textPopupDiabled}
                heading={'Alert'}
                description={textPopupText}
                onClose={() => {
                    setTextPopupDisabled(true);
                }}
            />
        <div className='layout-wrapper'>
            {loading && <Loader />}
            
            <div className='top-header w-full z-10 bg-#FFFFFF'>
                <Header toggleSidebar={toggleSidebar} />
            </div>
            <div className='content-wrapper flex flex-row'>
                <div className={`sidebar top-0 bg-white ${isSidebarOpen ? 'open' : ''}`}>
                    <Sidebar details={sideBarData} />
                </div>
                <div className='w-full bg-bgLightBlue page-content'>
                    <div className='px-[30px]'>
                        <div className='h-10  flex items-center '>
                            <Navbar />
                        </div>
                        {
                            <div className='radio-btn-wrapper flex items-center bg-bgLightBlue py-[15px] pl-10 rounded-tl-[12px] rounded-tr-[12px] border border-b-0 border-bgLightBlue'>
                                <div>
                                    <h5 className='text-couponsRadioHeading font-medium w-48 text-font20'>Filters: </h5>
                                </div>

                                <div className='flex items-center gap-5 text-couponsRadioOPtions font-semibold text-3xl mr-[20px]'>
                                    <input type='radio' name='addUser' value='admin' className='w-[20px] h-[20px] radio' onChange={handleUserType}></input>
                                    <label htmlFor='addUsers'>Admin</label>
                                </div>
                                <div className='flex items-center gap-5 text-couponsRadioOPtions font-semibold text-3xl mr-[20px]'>
                                    <input type='radio' name='addUser' value='client' className='w-[20px] h-[20px] radio active' onChange={handleUserType}></input>
                                    <label htmlFor='addUsers'>Client</label>
                                </div>
                            </div>
                        }
                        {
                            userType && !isMasterClientDetailOpen && !isAdminDetailOpen && 
                            <Formik
                                initialValues={initialValues}
                                validateOnChange={true}
                                validateOnBlur={false}
                                onSubmit={handleSubmit}
                            >
                                {
                                    formik => {
                                        return (
                                            <Form>
                                                <div className='bg-popupScreen py-10 flex w-[100%] items-center rounded-b-[12px]'>
                                                    <div className='pl-10 flex items-center w-[100%]'>
                                                        <div className='w-[50%]'>
                                                            <FormikControl control='input' value={searchParam} requiredSuggestions={true} formSetter={formik.setFieldValue} formSubmitter={formik.submitForm} suggestions={suggestionArray} onInputChange={searchParamChange} type='text' label={"Profile Details :"} name={'param'} placeholder={placeholder} bgcolor='selectorBg' width={'full'} placeholderIcon={searchIcon} />
                                                        </div>
                                                        <div className='w-[20%] pl-20 -mt-6'>
                                                            <SubmitButton type='submit' label='Search' btnWidth='full' disabled={!userType} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        )
                                    }
                                }
                            </Formik>
                        }

                        {
                            userType === 'admin' &&
                            (
                                isAdminDetailOpen ?
                                <AdminAccessManagement selectedAdmin={selectedAdmin} setIsAdminDetailOpen={setIsAdminDetailOpen} /> :
                                <SortingTable loading={tableLoading} onSelction={onAdminSelcted} headerData={AdminsHeaderData} tableData={admins} nextPage={adminsNext} previousPage={adminsPrevious} sortTable={setAdminsSorting} nextDisabled={adminsNextDisabled} previousDisabled={adminsPrevDisabled} selectedSortedKey={adminsSortedKey} selectedSortedOrder={adminsSortedWay} />
                            )
                        }
                        {
                            userType === 'client' &&
                            (
                                isMasterClientDetailOpen ?
                                <ClientAccessManagement selectedMasterClient={selectedMasterClient} setIsMasterClientDetailOpen={setIsMasterClientDetailOpen}/> :
                                <SortingTable loading={tableLoading} onSelction={onClientSelcted} headerData={MasterclientsHeaderData} tableData={masterClients} nextPage={masterNext} previousPage={masterPrevious} sortTable={setMasterSorting} nextDisabled={masterNextDisabled} previousDisabled={masterPrevDisabled} selectedSortedKey={masterSortedKey} selectedSortedOrder={masterSortedWay} />
                            )
                        }

                </div>
            </div>
        </div>
        
    </div>
    </>
)
}

export default Index