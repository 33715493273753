import React from 'react'
import "./loader.css"

export default function Loader({componental=false}) {
    return (
        <div className='loading-small-size' style={componental? {position: 'absolute', height: '100%', width: '100%'} : {position: 'fixed'}}>
            <div className="loading-page_loading-container"></div>
        </div>
    )
}
