import React, { useEffect, useState } from 'react';

import Input from '../../../../../../components/form2/Input';

const Services = ({ formik }) => {

    const [miscs, setMiscs] = useState(formik?.values?.miscellaneous || [{}]);
    useEffect(() => {
        setMiscs(formik?.values?.miscellaneous || [{}]);
    }, [formik?.values?.miscellaneous]);

    const handleAdd = () => {
        const lastMisc = miscs[miscs.length - 1];
        if(!lastMisc.header || (!lastMisc.variableCost && !lastMisc.fixedCost)) return;

        setMiscs([
            ...miscs, {}
        ])
        formik?.setFieldValue && formik?.setFieldValue('miscellaneous', [
            ...miscs, {}
        ]);
    }

    return (
        <div className='mx-16 mt-10'>
            <p className='text-[#182AB4] text-4xl mb-5 font-semibold'>Services</p>

            <div className='flex justify-center items-center '>
                <div className='w-[25%] flex flex-col justify-center items-start'>
                    <p className='text-4xl'>Data</p>
                    <p className='text-2xl'>(per QR code)</p>
                </div>
                <div className='w-[25%] flex justify-center items-center'>
                    <Input 
                        formik={formik}
                        name={'dataCostPerQr'}
                        placeholder=''
                        type='number'
                        marginClass='mt-5 mx-5'
                    />
                </div>
                <div className='w-[25%] flex flex-col justify-center items-start px-5'>
                    <p className='text-4xl'>Coupon</p>
                    <p className='text-2xl'>(per unit)</p>
                </div>
                <div className='w-[25%] flex justify-center items-center'>
                    <Input 
                        formik={formik}
                        name={'costPerCouponCode'}
                        placeholder=''
                        type='number'
                        marginClass='mt-5 mx-5'
                    />
                </div>
                
            </div>

            <div className='flex justify-center items-center '>
                <div className='w-[25%] flex flex-col justify-center items-start'>
                    <p className='text-4xl'>Cash Back</p>
                    <p className='text-2xl'>(per transaction)</p>
                </div>
                <div className='w-[25%] flex justify-center items-center'>
                    <Input 
                        formik={formik}
                        name={'cashbackTransactionCost'}
                        placeholder=''
                        type='number'
                        marginClass='mt-5 mx-5'
                    />
                </div>
                <div className='w-[25%] flex flex-col justify-center items-start px-5'>
                    <p className='text-4xl'>Geo-Location</p>
                    <p className='text-2xl'>(per conversion)</p>
                </div>
                <div className='w-[25%] flex justify-center items-center'>
                    <Input 
                        formik={formik}
                        name={'geoLocation_Cost_Per_Conversion'}
                        placeholder=''
                        type='number'
                        marginClass='mt-5 mx-5'
                    />
                </div>
            </div>

            <div className='flex justify-center items-center '>
                <div className='w-[25%] flex flex-col justify-center items-start'>
                    <p className='text-4xl'>OTP Charges</p>
                    <p className='text-2xl'>(per otp)</p>
                </div>
                <div className='w-[25%] flex justify-center items-center'>
                    <Input 
                        formik={formik}
                        name={'emailAndSmsComission'}
                        placeholder=''
                        type='number'
                        marginClass='mt-5 mx-5'
                    />
                </div>
                <div className='w-[25%] flex justify-center items-center'></div>
                <div className='w-[25%] flex justify-center items-center'></div>
            </div>

            {
                miscs.map((data, ind) => {
                    return <Miscellaneous 
                        key={ind} 
                        misc={data}
                        onChange={(misc) => {
                            setMiscs([
                                ...miscs.slice(0, ind),
                                misc
                            ])
                            formik?.setFieldValue && formik?.setFieldValue('miscellaneous', [
                                ...miscs.slice(0, ind),
                                misc
                            ]);
                        }}
                    />
                })
            }

            <div className='flex justify-end'>
                <button 
                    type='button'
                    className='bg-[#F1F2FA] border px-10 h-[46px] rounded-md'
                    onClick={handleAdd}
                    >
                    <p className='text-3xl'>Add +</p>
                </button>
            </div>
        </div>
    );
}

const Miscellaneous = ({ onChange, misc }) => {

    const [heading, setHeading] = useState(misc.header || '');
    const [variable, setVariable] = useState(misc.variableCost || '');
    const [fixed, setFixed] = useState(misc.fixedCost || '');

    useEffect(() => {
        setHeading(misc.header || '');
        setVariable(misc.variableCost || '');
        setFixed(misc.fixedCost || '');
    }, [misc])

    const handleHeadingChange = (val) => {
        setHeading(val);
        onChange({
            header: val,
            variableCost: variable,
            fixedCost: fixed
        })
    }

    const handleVariableChange = (val) => {
        setVariable(val);
        setFixed('');
        onChange({
            header: heading,
            variableCost: val,
            fixedCost: ''
        })
    }

    const handleFixedChange = (val) => {
        setFixed(val);
        setVariable('');
        onChange({
            header: heading,
            variableCost: '',
            fixedCost: val
        })
    }

    return (
        <div>
            <div className='flex justify-center items-center '>
                <div className='w-[25%] flex flex-col justify-center items-start'>
                    <p className='text-4xl'>Miscellaneous</p>
                    <p className='text-2xl'>(per month)</p>
                </div>
                <div className='w-[75%] flex justify-center items-center'>
                    <input 
                        value={heading} 
                        onChange={(e) => handleHeadingChange(e.target.value)} 
                        className={`bg-[#182AB408] border px-[1rem] rounded-md text-3xl h-[46px] w-full mx-5 mt-5`}
                    />
                </div>
            </div>
            <div className='flex justify-center items-center '>
                <div className='w-[25%] flex justify-center items-center'></div>
                <div className='w-[25%] flex flex-col justify-center items-start'>
                    <p className='text-4xl'>Variable cost</p>
                    <p className='text-2xl'>(based on QR's)</p>
                </div>
                <div className='w-[25%] flex justify-center items-center'>
                    <input 
                        type={'number'} 
                        value={variable} 
                        onChange={(e) => handleVariableChange(e.target.value)} 
                        className={`bg-[#182AB408] border px-[1rem] rounded-md text-3xl h-[46px] w-full mx-5 mt-5`}
                    />
                </div>
                <div className='w-[25%] flex justify-center items-center'></div>
            </div>
            <div className='flex justify-center items-center '>
                <div className='w-[25%] flex justify-center items-center'></div>
                <div className='w-[25%] flex flex-col justify-center items-start'>
                    <p className='text-4xl'>Fixed cost</p>
                </div>
                <div className='w-[25%] flex justify-center items-center'>
                    <input 
                        type={'number'} 
                        value={fixed} 
                        onChange={(e) => handleFixedChange(e.target.value)} 
                        className={`bg-[#182AB408] border px-[1rem] rounded-md text-3xl h-[46px] w-full mx-5 mt-10`}
                    />
                </div>
                <div className='w-[25%] flex justify-center items-center'></div>
            </div>
        </div>
    );
}

export default Services;
