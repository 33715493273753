import { axiosPrivate, axiosQrPrivate } from "../axios/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import { URL_TYPES } from "../config/constants";

import { adminLogout } from "../services/auth";

const useAxiosPrivate = (type = URL_TYPES.BASE)=>{

    const refresh = useRefreshToken();
    const {auth} = useAuth();
    const parsedAxiosPrivate = type == URL_TYPES.BASE ? axiosPrivate : (type === URL_TYPES.QR ? axiosQrPrivate : axiosPrivate);

    useEffect(()=>{

        const requestIntercept = parsedAxiosPrivate.interceptors.request.use(
            config => {
                if(!config.headers['Authorization']){
                    config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
                }
                return config;
            },(error)=> Promise.reject(error)
        )

        const responseIntercept = parsedAxiosPrivate.interceptors.response.use(
            response => response,
            async (error)=>{
                let prevRequest = error?.config;
                if(error?.response?.status === 401) {
                    try {
                        await adminLogout(axiosPrivate);
                        
                        window.location.reload();
                    } catch (error) {
                        console.log(error, "Unable to log out but session is expired!!");
                    }
                }
                if((error?.response?.data?.errorMessage === "jwt expired") && !prevRequest?.sent){
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return parsedAxiosPrivate(prevRequest);
                }
                return Promise.reject(error);
            }
        );


        return ()=>{
            parsedAxiosPrivate.interceptors.request.eject(requestIntercept);
            parsedAxiosPrivate.interceptors.response.eject(responseIntercept);
        }
    },[auth,refresh])

    return parsedAxiosPrivate;
}

export default useAxiosPrivate;