import {React, useState} from 'react'
import rightArrowIcon from '../../../../assets/svg/right_arrow_icon.svg'
import DownArrowIcon from '../../../../assets/svg/down_arrow_icon.svg'


function FormHeadingComponent(props) {

  const [clicked, setclicked] = useState('')
  const handleClick = (event) => {
    setclicked(!event)
  }
  return (
    <div>
        <div className='bg-bgLightBlue flex flex-row justify-start items-center border-solid py-5 border-navBorder rounded-t-[12px] cursor-pointer' onClick={() => handleClick(props.clicked)}>
            <button>
              <img className='w-10 h-5 pl-5' src={clicked?DownArrowIcon:rightArrowIcon} ></img>
            </button>
            <h5 className='text-sideBarBtnText font-bold pl-5'>{props.name}</h5>
          </div>
    </div>
  )
}

function FormSubHeadingComponent(props) {
  const [clicked, setclicked] = useState('')
  const handleClick = (event) => {
    setclicked(!event)
  }
    return(
        <div className='bg-bgWhite flex flex-row justify-start items-center py-5 border-solid border-navBorder cursor-pointer' onClick={() => handleClick(props.clicked)} >
            <button>
              <img className='w-10 h-5 pl-5' src={clicked?DownArrowIcon:rightArrowIcon}></img>
            </button>
          <h5 className='text-sideBarBtnText font-medium pl-5 w-[75%]'>{props.name}</h5>
          {props.extraText && <h6 className='text-financeSideBoxText w-[20%]'>{props.extraText}</h6>}
        </div>
    )
}
export  {FormHeadingComponent,FormSubHeadingComponent}
