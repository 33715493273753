import React, { useState, useEffect } from 'react';

import { arrow, arrowHead, authBackground, authLock, authentic, back, bentArrow, indianFlag, menu, notification, veots_app as veots, product, profile, scanner, veotsPoweredBy } from '../../../../../../assets/images/SignUp/index';

const Preview = ({
    primaryColor,
    secondaryColor,
    gradAngle,
    logoBgColor,
    logo,
    favicon,
    organizationName,
    includeFooter
    }) => {

    const [gradientBgColor, setGradientBgColor] = useState('black');        //auto
    const [gradientColor, setGradientColor] = useState('white');            //auto
    const [color, setColor] = useState('black');                            //auto
    const [scale, setScale] = useState();

    useEffect(() => {   //set preview size at begining
        const scalePreview = () => {
            const outerDiv = document.getElementById('preview-container');
            const outerWidth = outerDiv?.clientWidth;
            const innerWidth = 1000;
    
            if(outerWidth > innerWidth) return;
            const newScaleFactor = outerWidth / innerWidth;
            setScale(newScaleFactor);
    
            outerDiv.style.height = `${((innerWidth*newScaleFactor)/ 2)*(55/50)}px`
        }

        window.addEventListener('resize', scalePreview);
        scalePreview();

        return () => {
            window.removeEventListener('resize', scalePreview);
        }
    }, []);

    useEffect(() => {
        const getLuminance = (hexColor) => {
            // Convert hex to RGB
            const rgbColor = parseInt(hexColor?.slice(1), 16);
            const r = (rgbColor >> 16) & 255;
            const g = (rgbColor >> 8) & 255;
            const b = rgbColor & 255;

            // Calculate luminance using the relative luminance formula
            const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
            return luminance;
        };

        const backgroundLuminance = (getLuminance(primaryColor) + getLuminance(secondaryColor || primaryColor)) / 2;
        setColor(backgroundLuminance > 128 ? 'black' : 'white');

        // Function to convert hexadecimal color to RGB
        function hexToRgb(hex) {
            const r = parseInt(hex?.substring(1, 3), 16);
            const g = parseInt(hex?.substring(3, 5), 16);
            const b = parseInt(hex?.substring(5, 7), 16);
            return { r, g, b };
        }
        
        // Function to convert RGB to hexadecimal color
        function rgbToHex(r, g, b) {
            const red = r.toString(16).padStart(2, '0');
            const green = g.toString(16).padStart(2, '0');
            const blue = b.toString(16).padStart(2, '0');
            return '#' + red + green + blue;
        }

        function getMiddleColor(color1, color2) {
            // Convert hexadecimal color strings to RGB components
            const rgb1 = hexToRgb(color1);
            const rgb2 = hexToRgb(color2);
        
            // Calculate average RGB values
            const avgRed = Math.round((rgb1.r + rgb2.r) / 2);
            const avgGreen = Math.round((rgb1.g + rgb2.g) / 2);
            const avgBlue = Math.round((rgb1.b + rgb2.b) / 2);
        
            // Construct new color in hexadecimal format
            const middleColor = rgbToHex(avgRed, avgGreen, avgBlue);
            return middleColor;
        }
        const grad = getMiddleColor(getMiddleColor(primaryColor, secondaryColor), '#3c3e3c');
        setGradientBgColor(grad)
        setGradientColor(getLuminance(grad) > 128 ? 'black' : 'white')
            
    }, [primaryColor, secondaryColor]);

    return (
        <>
            <p className='text-5xl font-semibold text-center my-5'>Screen Preview</p>

            <BrowserHeader 
                favicon={favicon}
                brandName={organizationName}
            />

            <div
                id='preview-container'
                style={{
                    height: '550px'
                }}
                className='w-full border-2 rounded-2xl flex items-center justify-center'>
                <div 
                    style={{
                        transform: `scale(${scale})`
                    }}
                    className='h-[500px] w-[1000px] flex items-center'>
                    <div className='w-[50%] h-full'>
                        <MobileLoginScreen 
                            primaryColor={primaryColor}
                            secondaryColor={secondaryColor}
                            color={color}
                            logoBackground={logoBgColor}
                            logo={logo}
                            gradAngle={gradAngle}
                            includeFooter={includeFooter}
                        />
                    </div>
                    <div className='bg-gray-300 w-1 h-[400px]'/>
                    <div className='w-[50%] h-full'>
                        <MobileScanScreen 
                            primaryColor={primaryColor}
                            secondaryColor={secondaryColor}
                            color={color}
                            logoBackground={logoBgColor}
                            gradientBgColor={gradientBgColor}
                            gradientColor={gradientColor}
                            logo={logo}
                            gradAngle={gradAngle}
                            includeFooter={includeFooter}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

const BrowserHeader = ({ favicon, brandName }) => {
    return (
        <div className='bg-[#dee1e5] rounded-t-[10px] h-[40px] mb-5 flex items-center'>
            <div className='flex mx-4 gap-2'>
                <div className='bg-[#FD6B59] h-[15px] aspect-square rounded-full' />
                <div className='bg-[#FCBE2D] h-[15px] aspect-square rounded-full' />
                <div className='bg-[#26CB3F] h-[15px] aspect-square rounded-full' />
            </div>

            <div className='bg-[white] h-[35px] w-[250px] rounded-[10px] flex justify-between items-center px-3'>
                <div className='flex h-full items-center gap-3'>
                    <img className='h-[60%] aspect-square' src={favicon? ((favicon instanceof File)? URL.createObjectURL(favicon) : favicon) : `${process.env.PUBLIC_URL}/favicon.ico`} alt="" />
                    <p className='text-2xl' >{brandName? `${brandName}` : 'Veots Mobile App'}</p>
                </div>
                <div>
                    <p className='text-3xl'>x</p>
                </div>
            </div>
        </div>
    )
}

const MobileLoginScreen = ({ primaryColor="#50AFF8", secondaryColor="#9836A6", color='white', logoBackground="#212121", logo, gradAngle, includeFooter = true }) => {
    return (
        <div className='h-full w-full flex items-center justify-end relative'>
            
            <div className='absolute z-20 right-[240px] top-[100px] h-[10rem] w-[250px] flex items-center text-black'>
                <div className='w-[120px] flex flex-col h-full justify-center'>
                    <p className='text-2xl text-center font-medium'>Loading Screen gradient</p>
                    <p className='text-xl text-center'>(picks automatically from selected colors)</p>
                </div>
                <img src={arrow} alt="-----------------------------" />
            </div>

            <div className={`absolute z-20 right-[240px] ${includeFooter? 'top-[380px]' : 'top-[418px]'} h-[10rem] w-[250px] flex items-center text-black`}>
                <div className='w-[120px] flex flex-col h-full justify-center'>
                    <p className='text-2xl text-center font-medium'>Primary Color</p>
                    <p className='text-xl text-center'>(Major color of the application)</p>
                </div>
                <img src={arrow} alt="-----------------------------" />
            </div>

            <div className='h-[500px] w-[250px] rounded-[2.5rem] border-8 mr-[5rem] overflow-hidden z-10 flex flex-col'>
                <div className='w-full flex-1'>
                    {/* welcome thing */}
                    <div className='h-[37%] relative'>
                        <div 
                            style={{
                                backgroundImage: `linear-gradient(135deg, ${primaryColor}, ${secondaryColor || primaryColor}), url(${authBackground})`,
                                backgroundBlendMode: 'overlay',
                                backgroundSize: 'cover'
                            }}
                            className='h-full w-full object-cover'
                            />
                        <div className='absolute h-full w-full top-0 left-0 flex flex-col text-white'>
                            <div className='mx-[15px] mt-[20px] w-[7%] aspect-square' />
                            <div className='absolute h-full w-full flex-1 flex flex-col justify-center items-center'>    
                                <p className='text-center font-bold text-3xl my-1'>Hi!</p>
                                <p className='text-center font-bold text-3xl my-1'>Welcome</p>
                                <img 
                                    src={authLock} 
                                    alt="Secure" 
                                    className='m-1 h-[20%]' 
                                    />
                                <p className='text-center font-semibold px-4 text-xl'>You're just one step away from verifying your product</p>
                            </div>
                        </div>
                    </div>

                    {/* Branding */}
                    <div 
                        style={{
                            backgroundColor: logoBackground
                        }}
                        className={`w-full flex justify-center items-center h-[6%] `}
                    >
                        <img 
                            src={logo? ((logo instanceof File)? URL.createObjectURL(logo) : logo) : veots} 
                            alt="Brand" 
                            className='h-[80%]'
                            />
                    </div>

                    {/* Login */}
                    <div className='h-[57%] flex flex-col'>
                        <div className='flex-1'>
                            <div className='mt-[10%]'>
                                <div className='w-[80%] h-[35px] border border-black mx-auto rounded-xl flex'>
                                    <div className='w-[30%] h-full bg-[#D9D9D9] rounded-l-xl border-r border-black flex items-center'>
                                        <img src={indianFlag} alt="" className=' w-[30%] mx-1' />
                                        <p className='text-xl text-black'>+91</p>
                                        <img src={arrowHead} alt="" className='w-[12%] mx-1' />
                                    </div>

                                    <div className='w-[70%] h-full flex justify-start items-center'>
                                        <p className='text-xl ml-4 text-gray-500'>Phone Number</p>
                                    </div>
                                </div>
                            </div>
                            <div className='w-[80%] mx-[auto] mt-2'>
                                <p className='text-right text-lg'> Use Mail ID? </p>
                            </div>

                            <div className='flex w-[80%] mx-[auto]'>
                                <input type="checkbox" className='mr-2' disabled />
                                <p className='mt-2 text-base text-gray-500'>I have read and agree to the <span className='underline text-black'>Terms & Conditions</span> and <span className='underline text-black'>Privacy Policy</span></p>
                            </div>    
                        </div>
                        
                        <div className='mb-[20px] flex justify-center items-center'>
                            <div className='flex flex-col items-center w-full'>
                                <div 
                                    style={{
                                        color: color,
                                        backgroundImage: `linear-gradient(${gradAngle}deg, ${primaryColor}, ${secondaryColor || primaryColor})`,
                                    }}
                                    className='h-[33px] mx-auto w-[80%] flex justify-center items-center rounded'
                                >
                                    <p className='text-lg font-medium'>Next</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    includeFooter && <div className='h-[8%] flex flex-col justify-center items-center border-solid border-t-4 border-[#D9D9D9]'>
                        <p className='text-sm'>Powered By</p>
                        <img src={veotsPoweredBy} alt="Veots" className='h-[40%]' />
                    </div>
                }
            </div>
        </div>
    );
}

const MobileScanScreen = ({ primaryColor="#50AFF8", secondaryColor="#9836A6", color='white', logoBackground="#212121", gradientBgColor="black", gradientColor='white', logo, gradAngle, includeFooter = true }) => {
    return (
        <div className='h-full w-full flex items-center justify-start relative'>
            
            <div className='absolute z-20 left-[240px] top-[5px] h-[10rem] w-[250px] flex items-center'>
                <img src={arrow} alt="-----------------------------" className='-scale-100' />
                <div className='w-[120px] flex flex-col h-full justify-center'>
                    <p className='text-2xl text-center font-medium'>Brand color</p>
                    <p className='text-xl text-center'>(Background color for the brand Logo)</p>
                </div>
            </div>

            <div className='absolute z-20 left-[172px] top-[45px] h-[10rem] w-[300px] flex items-center'>
                <img src={bentArrow} alt="-----------------------------" className='-scale-y-100' />
                <div className='w-[120px] flex flex-col h-full justify-center pt-10'>
                    <p className='text-2xl text-center font-medium'>Logo</p>
                    <p className='text-xl text-center'>(Application Logo)</p>
                </div>
            </div>

            <div className={`absolute z-20 left-[240px] ${includeFooter? 'top-[280px]' : 'top-[305px]'} h-[10rem] w-[250px] flex items-center`}>
                <img src={arrow} alt="-----------------------------" className='-scale-100' />
                <div className='w-[120px] flex flex-col h-full justify-center'>
                    <p className='text-2xl text-center font-medium'>Gradient Color</p>
                    <p className='text-xl text-center'>(Will be picked automatically, depends on selected colors)</p>
                </div>
            </div>

            <div className={`absolute z-20 left-[240px] ${includeFooter? 'top-[379px]' : 'top-[415px]'} h-[10rem] w-[250px] flex items-center`}>
                <img src={arrow} alt="-----------------------------" className='-scale-100' />
                <div className='w-[120px] flex flex-col h-full justify-center'>
                    <p className='text-2xl text-center font-medium'>Secondary Color</p>
                    <p className='text-xl text-center'>(Minor color of the application)</p>
                </div>
            </div>

            <div className='h-[500px] w-[250px] rounded-[2.5rem] border-8 ml-[5rem] overflow-hidden z-10 flex flex-col'>
                <div className='w-full flex-1'>
                    {/* Header thing */}
                    <div className='h-[7%] px-3 flex items-center border-b'>
                        <img className='h-[30%] aspect-square' src={menu} alt="Profile" />

                        <header className='h-full w-full flex justify-end items-center'>
                            <span className='flex justify-center items-end'>
                                <p className='text-[.6rem] mx-[5px] text-[#5D5D5D]'>{`${'Sindhu >'}`}</p>
                            </span>
                            <img className='h-[40%] aspect-square mr-[.3rem]' src={profile} alt="Profile" />
                            <img className='h-[40%] aspect-square mx-[.3rem]' src={scanner} alt="Scan" />
                            <img className='h-[40%] aspect-square ml-[.3rem]' src={notification} alt="Bell" />
                        </header>
                    </div>

                    {/* Branding */}
                    <div 
                        style={{
                            backgroundColor: logoBackground
                        }}
                        className={`w-full flex justify-center items-center h-[6%]`}
                    >
                        <img 
                            src={logo? ((logo instanceof File)? URL.createObjectURL(logo) : logo) : veots} 
                            alt="Brand" 
                            className='h-[80%]'
                            />
                    </div>

                    {/* Detail */}
                    <div className='h-[10%] flex justify-center items-center'>
                        <div className='w-[15%] flex justify-center'>
                            <img src={back} alt="back" />
                        </div>
                        <div className='w-[70%] pt-5'>
                            <p className='text-xl text-center font-medium'>Scanned Product Details</p>
                            <p className='text-lg text-center'>Vitamin C tablets</p>
                        </div>
                        <div className='w-[15%] flex justify-center'>
                            {/* <img src={video} alt="back" /> */}
                        </div>
                    </div>

                    {/* Product */}
                    <div className='h-[30%] pb-5 flex justify-center items-center relative'>
                        <div 
                            
                            className='h-[90%] w-[80%] flex justify-center items-center border-2 border-green-500'
                        >
                            <img src={product} alt="Product" className='h-[70%]' />
                        </div>

                        <div className='absolute left-0 right-0 flex justify-center items-center bottom-2'>
                            <img style={{ width: '10rem' }} src={authentic} alt="verified" className='bg-white p-4' />
                        </div>
                    </div>

                    <div className='h-[12%]'>
                        <p className='text-xs text-center mx-5'>If you've purchased this product, scan the hidden QR code for multi-layer authentication and Product registration. To find the placement of this QR code, click on the "Hidden QR" button on the screen.</p>
                    </div>
                    

                    {/* Scan button */}
                    <div className='h-[10%] flex justify-center items-center'>
                        <div style={{backgroundColor: gradientBgColor, color: gradientColor}} className={`rounded-full w-[45%] h-[33px] my-5 shadow-md flex justify-center items-center`} >
                            {/* <img src={scanner} alt="" className='h-[50%] mr-2'/> */}
                            <p className='text-lg font-medium'>Scan Hidden QR</p>
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className='h-[25%] flex flex-col justify-end'>
                        <div className='mb-[20px] flex justify-center items-center -mr-6 px-6'>
                            <div style={{backgroundColor: gradientBgColor}} className='w-2 h-full -mr-2' />
                            <div className='flex flex-col items-center w-full'>
                                <div className='flex w-[80%] mb-5 gap-5'>
                                    <div 
                                        style={{
                                            color: color,
                                            backgroundImage: `linear-gradient(${gradAngle}deg, ${primaryColor}, ${secondaryColor || primaryColor})`,
                                        }}
                                        className='h-[33px] mx-auto w-[80%] flex justify-center items-center rounded'
                                    >
                                        <p className='text-lg font-medium'>Expiry Check</p>
                                    </div>
                                    <div 
                                        style={{
                                            color: color,
                                            backgroundImage: `linear-gradient(${gradAngle}deg, ${primaryColor}, ${secondaryColor || primaryColor})`,
                                        }}
                                        className='h-[33px] mx-auto w-[80%] flex justify-center items-center rounded'
                                    >
                                        <p className='text-lg font-medium'>Hidden QR</p>
                                    </div>
                                </div>
                                <div 
                                    style={{
                                        color: color,
                                        backgroundImage: `linear-gradient(${gradAngle}deg, ${primaryColor}, ${secondaryColor || primaryColor})`,
                                    }}
                                    className='h-[33px] mx-auto w-[80%] flex justify-center items-center rounded'
                                >
                                    <p className='text-lg font-medium'>Product Details</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    includeFooter && <div className='h-[8%] flex flex-col justify-center items-center border-solid border-t-4 border-[#D9D9D9]'>
                        <p className='text-sm'>Powered By</p>
                        <img src={veotsPoweredBy} alt="Veots" className='h-[40%]' />
                    </div>
                }
            </div>
        </div>
    );
}

export default Preview;
