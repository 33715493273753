import React, { useState, useRef, useEffect } from 'react';
import { SubmitButtonWide } from '../../../components/ui/buttons/submitButton';
import DeleteButtonRed from '../../../components/ui/buttons/deleteButtonRed';
import { Tablehead } from '../../../components/table/tablehead';

import Loader from "../../../components/ui/loader/Loader";
import PopupManager from '../../../components/popups2/popupManager';

//APis
import { requestOtp, resendOtp } from "../../../services/otpService";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { USER_TYPES, SORT_ORDER } from '../../../config/constants'
import {blockAdmin, unBlockAdmin, deleteAdmin} from "../services/api";

const tableHeaderOptions = [
    { heading: 'User ID’s', width: '35%' },
    { heading: 'Email Address', width: '35%' },
];

function DeleteAdmin({adminContactMail, admin, setIsAdminDetailOpen, updateDeletedAdmin}) {
    
    const [adminInfo, setAdminInfo] = useState(admin);

    const [loading, setLoading] = useState(false);
    const axiosPrivate = useAxiosPrivate();

    const [otpPopupDiabled, setOtpPopupDisabled] = useState(true); 
    const [otpPopupText, setOtpPopupText] = useState('');
    const otpOkHandler = useRef(null);
    const [otp, setOtp] = useState('');
    const [reason, setReason] = useState('');
    const [otpErr, setOtpErr] = useState('');

    const [twoButtonDisabled, setTwoButtonDisabled] = useState(true);
    const [twoButtonText, setTwoButtonText] = useState('');
    const twoButtonHandler = useRef(null);

    const [textPopupDiabled, setTextPopupDisabled] = useState(true);
    const [textPopupText, setTextPopupText] = useState('');
    const [isUserDeleted, setIsUserDeleted] = useState(false); //will be true when user delted 

    const [otpSentTo, setOtpSentTo] = useState('');

    useEffect(() => {
        setOtpErr('');
    }, [otpPopupDiabled])

    const  handleReqOtp = async () => {
        let success = false;
        try {
            setLoading(true)
            const data = await requestOtp(axiosPrivate);
            setOtpSentTo(data.emailId);

            success = true;
        } catch (err) {
            setTextPopupText(err.message || 'Error sending OTP');
            setTextPopupDisabled(false);

            success = false;
        } finally {
            setLoading(false)
        }
        return success;
    }

    const handleDeleteAdmin = async (otp, reason) => {
        let success = false;
        if(otp.length !== 4) {
            setOtpErr('Provide a valid otp');
            return (success = false);
        }

        try {
            setLoading(true)
            await deleteAdmin({
                userId: admin.userId,
                isMobile: false,
                emailId: adminContactMail,
                otp,
                reason,

            },axiosPrivate);
            success = true;

            //Delete
            setIsUserDeleted(true);
            setTextPopupText('Admin Deleted Successfully');
            setTextPopupDisabled(false);
        } catch(err) {
            success = false;
            if(err.message === 'Invalid OTP' && err.headers[`ratelimit-remaining`] > 0) {
                setOtpErr(`Invalid OTP, You still have ${err.headers[`ratelimit-remaining`]} attempts left`);
                setLoading(false);
                return success;
            }

            setTextPopupText(err.message || 'Error Deleteing Admin');
            setTextPopupDisabled(false);
            setOtpPopupDisabled(true);
        } finally {
            setLoading(false)
        }

        return success;
    }

    const handleUnblockAdmin = async (otp, reason) => {
        let success = false;
        if(otp.length !== 4) {
            setOtpErr('Provide a valid otp');
            return (success = false);
        }

        try {
            setLoading(true);
            await unBlockAdmin({
                userId: admin.userId,
                isMobile: false,
                emailId: adminContactMail,
                otp,
                reason,

            },axiosPrivate);
            success = true;

            setAdminInfo({...adminInfo, isBlocked: false});
            setTextPopupText('Admin Unblocked Successfully');
            setTextPopupDisabled(false);
        } catch(err) {
            success = false;
            if(err.message === 'Invalid OTP' && err.headers[`ratelimit-remaining`] > 0) {
                setOtpErr(`Invalid OTP, You still have ${err.headers[`ratelimit-remaining`]} attempts left`);
                setLoading(false);
                return success;
            }

            setTextPopupText(err.message || 'Error Unblocking Admin');
            setTextPopupDisabled(false);
            setOtpPopupDisabled(true);
        } finally {
            setLoading(false)
        }

        return success;
    }

    const handleBlockAdmin = async (otp, reason) => {
        let success = false;
        if(otp.length !== 4) {
            setOtpErr('Provide a valid otp');
            return (success = false);
        }

        try {
            setLoading(true)
            await blockAdmin({
                userId: admin.userId,
                isMobile: false,
                emailId: adminContactMail,
                otp,
                reason,

            },axiosPrivate);
            success = true;

            setAdminInfo({...adminInfo, isBlocked: true});
            setTextPopupText('Admin Blocked Successfully');
            setTextPopupDisabled(false);
        } catch(err) {
            success = false;
            if(err.message === 'Invalid OTP' && err.headers[`ratelimit-remaining`] > 0) {
                setOtpErr(`Invalid OTP, You still have ${err.headers[`ratelimit-remaining`]} attempts left`);
                setLoading(false);
                return success;
            }

            setTextPopupText(err.message || 'Error Blocking Admin');
            setTextPopupDisabled(false);
            setOtpPopupDisabled(true);
        } finally {
            setLoading(false)
        }

        return success;
    }

    const handleResendOtp = async () => {
        let success = false;
        try {
            setLoading(true)
            await resendOtp(adminContactMail);

            success = true;
        } catch (err) {
            setTextPopupText(err.message || 'Error sending OTP');
            setTextPopupDisabled(false);

            success = false;
        } finally {
            setLoading(false)
        }
        return success;
    }
    
    return (
        <>
            {loading && <Loader />}
            <PopupManager 
                control={'otp-text'}
                disable={!textPopupDiabled || otpPopupDiabled}
                heading={'Alert'}
                title={otpPopupText}
                description={`Otp will be sent to ${otpSentTo.slice(0,2)}*******${otpSentTo.slice(otpSentTo.length-10, otpSentTo.length)}`}
                okButtonText={'Confirm'}
                errorMessage={otpErr}

                onClose={() => {
                    setOtpPopupDisabled(true)
                }}
                clickedOk={async () => {
                    const success = await otpOkHandler.current(otp, reason);
                    if(success) setOtpPopupDisabled(true);
                }}
                otpSetter={setOtp}
                reasonSetter={setReason}
                resendOtp={handleResendOtp}
            />

            <PopupManager 
                control={'two-button'}
                disable={!textPopupDiabled || twoButtonDisabled}
                heading={'Alert'}
                title={twoButtonText}
                okButtonText={'Yes'}
                onClose={() => {
                    setTwoButtonDisabled(true);
                }}
                clickedOk={() => {
                    setTwoButtonDisabled(true);
                    twoButtonHandler.current && twoButtonHandler.current()
                }}
            />

            <PopupManager 
                control={'text'}
                disable={textPopupDiabled}
                heading={'Alert'}
                description={textPopupText}
                onClose={() => {
                    setTextPopupDisabled(true);
                    if(isUserDeleted) {
                        updateDeletedAdmin(admin._id);
                        setIsAdminDetailOpen(false)
                    }      
                }}
            />

            <div className='my-10'>
                <SubmitButtonWide
                    value='Back'
                    onClick={() => {
                        setIsAdminDetailOpen(false)
                    }}
                />
            </div>
            
            <div className='mt-10 h-full'>
                <table className='w-full'>
                    <Tablehead options={tableHeaderOptions} height='20' />
                </table>
                <div className='flex flex-col w-[100%]'>
                    <div className='flex flex-col w-[100%] bg-popupScreen rounded-b-[12px] py-3'>
                            <div className='bg-black-200 flex items-center'>
                                <div className='w-[70%] flex'>
                                    <div className='flex-1 mx-10 my-4 bg-selectorBg h-20 text-popupText font-medium text-font14 flex justify-center items-center'>
                                        {adminInfo?.userId}
                                    </div>
                                    <div className='flex-1 mx-10 my-4 bg-selectorBg h-20 text-popupText font-medium text-font14 flex justify-center items-center'>
                                        {adminInfo?.emailId}
                                    </div>
                                </div>
                                
                                <div className='w-[30%] flex justify-between px-5 h-[100%]' >
                                    <div className='w-[48%]'>
                                        <DeleteButtonRed value='Delete' btnWidth='full' pdY={'3'} onClick={() => {
                                            twoButtonHandler.current = async () => {
                                                const success = await handleReqOtp();
                                                if(!success) return;

                                                otpOkHandler.current = async (otp, reason) => {
                                                    const success = await handleDeleteAdmin(otp, reason);
                                                    return success;
                                                }
                                                setOtpPopupText(`Delete ${adminInfo.userId}`)
                                                setOtpPopupDisabled(false);
                                            }
                                            setTwoButtonText(`Do you want to delete ${adminInfo.userId}`);
                                            setTwoButtonDisabled(false);
                                        }} />
                                    </div>
                                    <div className='w-[48%]'>
                                        {adminInfo?.isBlocked ? (
                                            <DeleteButtonRed value='Unblock' bgColor='financeSideBoxText bg-financeSideBoxText' btnWidth='full' pdY={'3'} onClick={() => {
                                                twoButtonHandler.current = async () => {
                                                    const success = await handleReqOtp();
                                                    if(!success) return;

                                                    otpOkHandler.current = async (otp, reason) => {
                                                        const success = await handleUnblockAdmin(otp, reason);
                                                        return success;
                                                    }
                                                    setOtpPopupText(`Unblock ${adminInfo.userId}`)
                                                    setOtpPopupDisabled(false);
                                                }
                                                setTwoButtonText(`Do you want to Unblock ${adminInfo.userId}`);
                                                setTwoButtonDisabled(false);
                                            }} />
                                        ) : (
                                            <DeleteButtonRed
                                                value='Block' bgColor='financeSideBoxText bg-financeSideBoxText' btnWidth='full' pdY={'3'} onClick={() => {
                                                    twoButtonHandler.current = async () => {
                                                        const success = await handleReqOtp();
                                                        if(!success) return;

                                                        otpOkHandler.current = async (otp, reason) => {
                                                            const success = await handleBlockAdmin(otp, reason);
                                                            return success;
                                                        }
                                                        setOtpPopupText(`Block ${adminInfo.userId}`)
                                                        setOtpPopupDisabled(false);
                                                    }
                                                    setTwoButtonText(`Do you want to Block ${adminInfo.userId}`);
                                                    setTwoButtonDisabled(false);
                                                }} />
                                        )}
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteAdmin
