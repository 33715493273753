import React from 'react';
import useSidebar from '../../hooks/useSidebar'
import { Header } from '../../layouts/header'
import Navbar from '../../layouts/navbar'
import Sidebar from '../../layouts/sidebar'
import sidebardata from './components/sidebardata'
import ChangeAvatar from './components/changeAvatar';
import UnderConstruction from '../underConstruction/components/UnderConstruction';


function Index() {
    const { isSidebarOpen, toggleSidebar } = useSidebar();

    return (
        <div className='layout-wrapper'>
            <div className='top-header w-full z-10 bg-#FFFFFF'>
                <Header toggleSidebar={toggleSidebar} />
            </div>
            <div className='content-wrapper flex flex-row'>
                <div className={`sidebar top-0 bg-white ${isSidebarOpen ? 'open' : ''}`}>
                    <Sidebar details={sidebardata} />
                </div>
                <div className='w-full bg-bgLightBlue page-content'>
                    <div className='p-[30px]'>
                        <div className='h-10 flex items-center'>
                            <Navbar />
                        </div>
                        <UnderConstruction />
                        {/* <div className="w-full">
                            <ChangeAvatar />
                        </div> */}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Index