import React, { useState } from 'react'
import { Header } from '../../../layouts/header'
import Sidebar from '../../../layouts/sidebar'
import sideBarData from '../components/sidebarData'
import Navbar from '../../../layouts/navbar'
import useSidebar from '../../../hooks/useSidebar'
import ProformaInvoice from './components/ProformaInvoice/proformaInvoice'
import UnderConstruction from '../../underConstruction/components/UnderConstruction'

function Index() {
    const [userType, setuserType] = useState()
    const handleUserType = async (e) => {
        // e.preventDefault()
        await setuserType(e.target.value)
    }

    const { isSidebarOpen, toggleSidebar } = useSidebar();

    return (
        <div className='layout-wrapper bg-bgcolor/50'>
            <div className='top-header w-full z-10 bg-#FFFFFF'>
                <Header toggleSidebar={toggleSidebar} />
            </div>
            <div className='content-wrapper flex flex-row'>
                <div className={`sidebar top-0 bg-white ${isSidebarOpen ? 'open' : ''}`}>
                    <Sidebar details={sideBarData} />
                </div>
                <div className='w-full bg-bgLightBlue page-content'>
                    <div className='p-[30px]'>
                        <div className='h-10 flex items-center'>
                            <Navbar />
                        </div>
                        <UnderConstruction />
                        {/* <div className='radio-btn-wrapper flex items-center bg-bgLightBlue py-[15px] pl-10 rounded-tl-[12px] rounded-tr-[12px] border border-b-0 border-bgLightBlue'>
                            <div>
                                <h5 className='text-black font-medium w-80 text-font18'>Invoice Type : </h5>
                            </div>
                            <div className='flex items-center gap-5 text-couponsRadioOPtions font-semibold text-3xl mr-[20px]'>
                                <input type='radio' name='miscInvoice' value='ProformaInvoice' className='w-[20px] h-[20px] radio active' onChange={handleUserType}></input>
                                <label htmlFor='miscInvoices' className='text-black'>Proforma Invoice</label>
                            </div>
                            <div className='flex items-center gap-5 text-couponsRadioOPtions font-semibold text-3xl'>
                                <input type='radio' name='miscInvoice' value='TaxInvoice' className='w-[20px] h-[20px] radio' onChange={handleUserType}></input>
                                <label htmlFor='miscInvoices' className='text-black'>Tax Invoice</label>
                            </div>
                        </div>
                        {userType === 'ProformaInvoice' &&
                            <div className='w-full'>
                                <ProformaInvoice />
                            </div>
                        }
                        {userType === 'taxInvoice' &&
                            <div className=''>
                                
                            </div>
                        } */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index