import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import FormikControl from '../../../../components/form/formikControl';
import { FormHeadingComponent, FormSubHeadingComponent } from './formHeadingComponent'

import retaIntialValues from '../utils/reataInitialValues';
import retaValidationSchema from '../validations/retaValidation';

export var retaForm = {};
function RetailerForm({type, skuData}) {

    const [clickedRetailerProductDetailsHeading, setClickedRetailerProductDetailsHeading] = useState(true)
    const [clickedRetailerProductDetailsSub, setClickedRetailerProductDetailsSub] = useState(true)
    const [clickedRetailerDetailsHeading, setClickedRetailerDetailsHeading] = useState(true)
    const [clickedRetailerDetailsSub, setClickedRetailerDetailsSub] = useState(true)

    const [qrProdErr, setQrProdErr] = useState('');
    const [preSaleQRLocationErr, setPreSaleQRLocationErr] = useState('');

    const handleClickedRetailerProductDetailsHeadinag = (event) => {
        setClickedRetailerProductDetailsHeading(!event)
    }
    const handleClickedRetailerProductDetailsSub = (event) => {
        setClickedRetailerProductDetailsSub(!event)
    }
    const handleClickedRetailerDetailsHeading = (event) => {
        setClickedRetailerDetailsHeading(!event)
    }
    const handleClickedRetailerDetailsSub = (event) => {
        setClickedRetailerDetailsSub(!event)
    }

    const handleSubmit = () => {

    }

    const validateFormFields = (values) => {
        if(values.QROnprod) 
            setQrProdErr('');
        if(values.preSaleQRLocation) 
            setPreSaleQRLocationErr('');
    }

    let validateForm;

    return (
        <Formik
            initialValues={skuData || retaIntialValues}
            enableReinitialize={true}
            validationSchema={retaValidationSchema}
            validate={validateFormFields}
            onSubmit={handleSubmit}
        >
            {
                formik => {

                    validateForm = () => {
                        formik.validateForm();

                        let validateObj = {};
                        Object.keys(retaIntialValues).forEach(key => {
                            validateObj[key] = true;
                        })
                        formik.setTouched(validateObj);

                        if(type === "3" && !formik.values.QROnprod)
                            setQrProdErr('This Field is required');
                        if(type === "3" && !formik.values.preSaleQRLocation)
                            setPreSaleQRLocationErr('This Field is required');
                    }
                    retaForm = {
                        values: formik.values,
                        isFormValid: () => {
                            return Object.keys(formik.errors).length || qrProdErr ?false:true
                        },
                        valideForm: validateForm
                    }
                    
                    return (
                        <Form>
                            <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                <div onClick={() => { handleClickedRetailerProductDetailsHeadinag(clickedRetailerProductDetailsHeading) }}>
                                    <FormHeadingComponent name={"Product Details"} clicked={clickedRetailerProductDetailsHeading} />
                                </div>
                                {clickedRetailerProductDetailsHeading ? (
                                <div className='bg-bgWhite rounded-b-[12px]'>
                                    <div onClick={() => handleClickedRetailerProductDetailsSub(clickedRetailerProductDetailsSub)}>
                                        <FormSubHeadingComponent name={"Provide Product Details"} clicked={clickedRetailerProductDetailsSub} />
                                    </div>
                                    {clickedRetailerProductDetailsSub ? (
                                        <div>
                                            <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10 img-upload-wrapper'>
                                                <FormikControl
                                                    control='file'
                                                    type='file'
                                                    defaultImageUrl={formik.values.Pimage}
                                                    label={"Product/Seller Image :"}
                                                    name='Retailer-product-image'
                                                    acceptableFiles='.jpg,.png,.jpeg'
                                                    customerror={formik.touched.Pimage && formik.errors.Pimage}
                                                    onFileSelect={(url) => {
                                                        if(url)
                                                            formik.setFieldValue("Pimage", url);
                                                    }}
                                                />
                                            </div>
                                            {   
                                                type === "3" && <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10 img-upload-wrapper'>
                                                    <FormikControl
                                                        control='file'
                                                        type='file'
                                                        defaultImageUrl={formik.values.preSaleQRLocation}
                                                        label={"Outer QR on Box :"}
                                                        name={'outer-qr-on-box'}
                                                        acceptableFiles='.jpg,.png,.jpeg'
                                                        customerror={preSaleQRLocationErr}
                                                        onFileSelect={(url) => {
                                                            formik.setFieldValue("preSaleQRLocation", url);
                                                        }}
                                                    />
                                                
                                                    <FormikControl
                                                        control='file'
                                                        type='file'
                                                        defaultImageUrl={formik.values.QROnprod}
                                                        label={"Hidden QR on Product :"}
                                                        name={'hiddend-qr-on-prod'}
                                                        acceptableFiles='.jpg,.png,.jpeg'
                                                        customerror={qrProdErr}
                                                        onFileSelect={(url) => {
                                                            formik.setFieldValue("QROnprod", url);
                                                        }}
                                                    />
                                                </div>
                                            }
                                            <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10 rounded-b-[12px]'>
                                                <FormikControl control='input' type='text' label={"Sold by :"} name={'prodName'} bgcolor={'selectorBg'} />
                                            </div>
                                        </div>
                                    ) :
                                        <></>}
                                </div>
                            ) :
                                <></>}
                            </div>
                            <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                <div onClick={() => { handleClickedRetailerDetailsHeading(clickedRetailerDetailsHeading) }}>
                                    <FormHeadingComponent name={"Seller Details"} clicked={clickedRetailerDetailsHeading} />
                                </div>
                                {
                                    clickedRetailerDetailsHeading &&
                                    <div className='bg-bgWhite rounded-b-[12px]'>
                                        <div onClick={() => handleClickedRetailerDetailsSub(clickedRetailerDetailsSub)}>
                                            <FormSubHeadingComponent name={"Provide Seller Details"} clicked={clickedRetailerDetailsSub} />
                                        </div>
                                        {
                                            clickedRetailerDetailsSub &&
                                                <div className='px-[30px] pb-20 grid grid-cols-1 gap-y-10 rounded-[12px] qr-manufacturing-details'>
                                                    <FormikControl
                                                        control='input-textarea-small'
                                                        width={'full'}
                                                        type='description'
                                                        label={"License no :"}
                                                        name={'manuLicenseNo'}
                                                        placeholder={"Type your text here"} />
                                                    <FormikControl
                                                        control='input-textarea-large'
                                                        width={'full'}
                                                        type='description'
                                                        label={"Address :"}
                                                        name={'manuAdd'}
                                                        placeholder={"Type your text here"} />
                                                    <FormikControl
                                                        control='input-textarea-large'
                                                        width={'full'}
                                                        type='description'
                                                        label={"Details :"}
                                                        name={'addDetails'}
                                                        placeholder={"Type your text here"} />
                                                    <FormikControl
                                                        control='input-textarea-small'
                                                        width={'full'}
                                                        type='description'
                                                        label={"Image or Video Header :"}
                                                        name={'additionalImageDetails'}
                                                        placeholder={"image header"}
                                                    />
                                                    <div className='flex flex-wrap md:flex-nowrap mb-[25px] md:mb-0 items-center h-[100%] gap-5 generate-img'>
                                                        <label htmlFor=""></label>
                                                        <div className='generate-img-upload-wrapper'>
                                                            <div className='flex md:justify-end'>
                                                                <FormikControl
                                                                    control='file'
                                                                    type='file'
                                                                    label={""}
                                                                    name='reta-extra-Image-1'
                                                                    defaultImageUrl={formik.values.extraImage1}
                                                                    acceptableFiles='.jpg,.png,.jpeg'
                                                                    customerror={formik.touched.extraImage1 && formik.errors.extraImage1}
                                                                    onFileSelect={(url) => {
                                                                        formik.setFieldValue("extraImage1", url);
                                                                    }}
                                                                // onChange={(e) => formik.setFieldValue("extraImage1", URL.createObjectURL(e.currentTarget.files[0]))}
                                                                />
                                                                {/* <img style={{ "width": "20rem" }} src={formik.values.extraImage1} alt="" /> */}
                                                                <FormikControl
                                                                    control='file'
                                                                    type='file'
                                                                    label={""}
                                                                    name='reta-extra-Image-2'
                                                                    defaultImageUrl={formik.values.extraImage2}
                                                                    acceptableFiles='.jpg,.png,.jpeg'
                                                                    customerror={formik.touched.extraImage2 && formik.errors.extraImage2}
                                                                    onFileSelect={(url) => {
                                                                        formik.setFieldValue("extraImage2", url);
                                                                    }}
                                                                // onChange={(e) => formik.setFieldValue("extraImage2", URL.createObjectURL(e.currentTarget.files[0]))}
                                                                />
                                                                {/* <img style={{ "width": "20rem" }} src={formik.values.extraImage2} alt="" /> */}
                                                                
                                                                {/* <img style={{ "width": "20rem" }} src={formik.values.extraImage1} alt="" /> */}
                                                                <FormikControl
                                                                    control='video'
                                                                    type='file'
                                                                    label={""}
                                                                    name='reta-extra-video-1'
                                                                    defaultVideoUrl={formik.values.prodVedioLink}
                                                                    acceptableFiles='.mp4'
                                                                    customerror={formik.touched.prodVedioLink && formik.errors.prodVedioLink}
                                                                    onFileSelect={(url) => {
                                                                        formik.setFieldValue("prodVedioLink", url);
                                                                    }}
                                                                // onChange={(e) => formik.setFieldValue("extraImage2", URL.createObjectURL(e.currentTarget.files[0]))}
                                                                />
                                                            </div>
                                                        </div>
                                                                
                                                    </div>
                                                                
                                                    <FormikControl
                                                        control='input-textarea-small'
                                                        type='description'
                                                        label={"Website Link :"}
                                                        name={'manuWebsiteLink'}
                                                        placeholder={"Type your text here"} />
                                                </div>
                                        }
                                    </div>
                                }
                            </div>
                        </Form>
                    )
                }
            }
        </Formik>
    )
}

export default RetailerForm;