import React, { useState } from 'react';

import { Header } from '../../../layouts/header';
import useSidebar from '../../../hooks/useSidebar';
import Navbar from '../../../layouts/navbar';
import Sidebar from '../../../layouts/sidebar';
import sidebardata from './components/sidebardata';

import SearchClient from './components/SearchClient';
import FormManager from './components/FormManager';

const Create = () => {
    const { isSidebarOpen, toggleSidebar } = useSidebar();
    const [ clientData, setClientData ] = useState(null);

    return (
        <div className='layout-wrapper'>
            <div className='top-header w-full z-10 bg-#FFFFFF'>
                <Header toggleSidebar={toggleSidebar} />
            </div>
            <div className='content-wrapper flex flex-row'>
                <div className={`sidebar top-0 bg-white ${isSidebarOpen ? 'open' : ''}`}>
                    <Sidebar details={sidebardata} />
                </div>
                <div className='w-full bg-bgLightBlue page-content'>
                    <div className="px-[30px] h-full">
                        <div className='h-10 flex items-center'>
                            <Navbar />
                        </div>
                        <div className="profile-wrapper h-[85%]">
                            <SearchClient setClientData={setClientData} />
                            {clientData && <FormManager clientData={clientData} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Create;