import React,{useState} from 'react'
import CloseIcon from '../../assets/svg/popups/popUpCloseIcon.svg'
import { SubmitButton } from '../ui/buttons/submitButton'
import DeleteButtonRed from '../ui/buttons/deleteButtonRed'

function TwoButtonsPopUp(props) {
    const { heading, description, title, onClose, clickedOk, clickedNo, okButtonText } = props
    
    return (
        <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  flex flex-col w-[25%] h-[25vh] z-10'>
            <div className='bg-popupScreen flex flex-col w-[100%] justify-between items-center'>
                <div className='flex justify-between items-center p-10  h-14 w-[100%] bg-gradient-to-r from-gradientPurple  to-gradientBlue to-90% '>
                    <span className='font-bold text-4xl text-popupScreen'>{heading}</span>
                    <img className='w-9 h-9 cursor-pointer' src={CloseIcon} alt='' onClick={onClose}/>
                </div>
                <div className='pl-12 pr-12 pt-12 flex justify-center items-center '>
                    <span className='text-popUpTextColor text-3xl font-medium'>{title}</span>
                </div>
                <div className='pl-5 pr-5 pt-10 pb-10 flex justify-center items-center '>
                    <span className='text-deleteBtnRed text-2xl font-medium text-center'>{description}</span>
                </div>
                <div className='flex w-[100%] pl-10 pr-10 pb-5 justify-between items-center'>
                    <div className='w-[45%]' onClick={clickedOk}>
                        <SubmitButton type={'submit'} label={okButtonText || 'Ok'} btnWidth='full'/>
                    </div>
                    <div className='w-[45%]'>
                        <DeleteButtonRed pdY={'4'} type={'submit'} value='Cancel' btnWidth='full' onClick={clickedNo? clickedNo : onClose}/>
                    </div> 
                </div>
            </div>
            
        </div>
    )
}

export default TwoButtonsPopUp