import React from 'react'

function RedButton(props) {
    const {value, label, width, height, bgColor, fontSize, fontClass, type='button', onClick, ...rest} = props
    return (
    <div className='w-[100%]'>
        <button type={type} onClick={onClick} className={`bg-redButtonColor w-${width} h-${height} text-popupScreen font-medium text-${fontSize} ${fontClass} rounded-lg h-[50px]`}>
            {label}
        </button>
    </div>
    )
}

export default RedButton