import React from 'react';

import Input from '../../../../../../components/form2/Input';

const Solutions = ({ formik }) => {
    return (
        <div className='mx-16'>
            <p className='text-[#182AB4] text-4xl mb-5 font-semibold'>Solutions</p>
            <div className='flex justify-center items-center bg-[#E8EAF8]'>
                <div className='w-[25%] flex justify-center items-center'></div>
                <div className='w-[25%] flex justify-center items-center'>
                    <p className='text-3xl font-semibold my-5'>Base Cost</p>
                </div>
                <div className='w-[25%] flex justify-center items-center'>
                    <p className='text-3xl font-semibold my-5'>Warranty</p>
                </div>
                <div className='w-[25%] flex justify-center items-center'>
                    <p className='text-3xl font-semibold my-5'>Loyality</p>
                </div>
            </div>

            <div className='flex justify-center items-center '>
                <div className='w-[25%] flex flex-col justify-center items-start'>
                    <p className='text-4xl'>Tracking</p>
                    <p className='text-2xl'>(Multiple QR)</p>
                </div>
                <div className='w-[25%] flex justify-center items-center'>
                    <Input 
                        formik={formik}
                        name={'trackingBaseCost'}
                        placeholder=''
                        type='number'
                        marginClass='mt-5 mx-5'
                    />
                </div>
                <div className='w-[25%] flex justify-center items-center'></div>
                <div className='w-[25%] flex justify-center items-center'></div>
            </div>

            <div className='flex justify-center items-center '>
                <div className='w-[25%] flex flex-col justify-center items-start'>
                    <p className='text-4xl'>Info</p>
                    <p className='text-2xl'>(1 QR without claim)</p>
                </div>
                <div className='w-[25%] flex justify-center items-center'>
                    <Input 
                        formik={formik}
                        name={'infoBaseCost'}
                        placeholder=''
                        type='number'
                        marginClass='mt-5 mx-5'
                    />
                </div>
                <div className='w-[25%] flex justify-center items-center'></div>
                <div className='w-[25%] flex justify-center items-center'></div>
            </div>

            <div className='flex justify-center items-center '>
                <div className='w-[25%] flex flex-col justify-center items-start'>
                    <p className='text-4xl'>Overt</p>
                    <p className='text-2xl'>(1 QR with claim)</p>
                </div>
                <div className='w-[25%] flex justify-center items-center'>
                    <Input 
                        formik={formik}
                        name={'overtBaseCost'}
                        placeholder=''
                        type='number'
                        marginClass='mt-5 mx-5'
                    />
                </div>
                <div className='w-[25%] flex justify-center items-center'>
                    <Input 
                        formik={formik}
                        name={'overtWarrantyCost'}
                        placeholder=''
                        type='number'
                        marginClass='mt-5 mx-5'
                    />
                </div>
                <div className='w-[25%] flex justify-center items-center'>
                    <Input 
                        formik={formik}
                        name={'overtLoyaltyCost'}
                        placeholder=''
                        type='number'
                        marginClass='mt-5 mx-5'
                    />
                </div>
            </div>

            <div className='flex justify-center items-center '>
                <div className='w-[25%] flex flex-col justify-center items-start'>
                    <p className='text-4xl'>Covert</p>
                    <p className='text-2xl'>(2 QR with claim)</p>
                </div>
                <div className='w-[25%] flex justify-center items-center'>
                    <Input 
                        formik={formik}
                        name={'covertBaseCost'}
                        placeholder=''
                        type='number'
                        marginClass='mt-5 mx-5'
                    />
                </div>
                <div className='w-[25%] flex justify-center items-center'>
                    <Input 
                        formik={formik}
                        name={'covertWarrantyCost'}
                        placeholder=''
                        type='number'
                        marginClass='mt-5 mx-5'
                    />
                </div>
                <div className='w-[25%] flex justify-center items-center'>
                    <Input 
                        formik={formik}
                        name={'covertLoyaltyCost'}
                        placeholder=''
                        type='number'
                        marginClass='mt-5 mx-5'
                    />
                </div>
            </div>
        </div>
    );
}

export default Solutions;