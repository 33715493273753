import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './textError'

function radioButton(props) {
  const { label, name, options, textcolor, width='min-w-[45%]', height='9', nextLineError, onChange, disabled, startPadReq=true, ...rest } = props


  return (
    <div className={`w-full flex flex-col items-center ${nextLineError && 'flex-col'} mb-8`}>
      <div className='flex items-center mr-4 w-full'>
        {label &&
          <label htmlFor={name} className={` ${textcolor ? `text-${textcolor}` : 'text-popupText'} font-normal text-font16 ${width}`}>
            {label}
          </label>
        }
        <div className={`flex flex-row w-full ${startPadReq && ' pl-14'} justify-flex-start items-center gap-5`}>
          <Field name={name} {...rest}>
            {
              ({ field }) => {
                return options.map((option, ind) => {
                  return (
                    <React.Fragment key={option.key} >
                      <div className='flex items-center'>
                        <input 
                          className={`w-14 h-${height} mx-2 ${ind == 0 && 'ml-0'}`}
                          disabled={disabled}
                          type='radio'
                          id={option.value} {...field}
                          value={option.value}
                          checked={field.value === option.value}
                          onClick={() => {
                              onChange && onChange(option.value)
                          }}
                          />
                        <label htmlFor={option.value} className='text-sideBarBtnText text-3xl w-[auto] '>{option.key}</label>  
                      </div>
                    </React.Fragment>
                  )
                })
              }
            }
          </Field>
        </div>
      </div>
      <div className='w-[100%] relative'>
        <div className={`ml-12 ${nextLineError && 'ml-[5rem] mt-2 -mb-10'} absolute`}>
          <ErrorMessage name={name} component={TextError}/>
        </div>
      </div>
  </div>
  )
}

export default radioButton