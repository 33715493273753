import React, { useEffect, useState } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { Header } from '../../layouts/header'
import Navbar from '../../layouts/navbar'
import SideBar from '../../layouts/sidebar'
import SideBarContents from './components/sideBarContent'
import BatchForm from './components/batchForm'
import BatchTable from './components/batchTable'
import { SubmitButton } from '../../components/ui/buttons/submitButton'
import Loader from '../../components/ui/loader/Loader'
import TextPopup from '../../components/popups/textPopup'
import useSidebar from '../../hooks/useSidebar'

import { URL_TYPES } from '../../config/constants'
import { FetchBatches } from "./services/api"

function Index() {
    const { isSidebarOpen, toggleSidebar } = useSidebar();
    const axiosPrivate = useAxiosPrivate(URL_TYPES.QR);
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({})
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [dataFromApi, setDataFromApi] = useState([]);

    const [showTextPopup, setShowTextPopup] = useState(false)
    const [alertText, setAlertText] = useState("");
    const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false);
    const blurredBackgroundStyles = isBackgroundBlurred
        ? {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(100, 100, 100, 0.5)",
            backdropFilter: "blur(1.8px)",
            zIndex: 1,
        }
        : {};

    useEffect(() => {
        const handleSubmit = async (values) => {
            try {
                setLoading(true);
                const res = await FetchBatches(values, axiosPrivate);

                setDataFromApi(res?.data)
                setTotalPages(res?.totalPages)
                setCurrentPage(res?.currentPage)
            } catch (error) {
                setAlertText(error.message || 'Unable to load data');
                setShowTextPopup(true);
                setIsBackgroundBlurred(true);
            } finally {
                setLoading(false)
            }
        }
        dataFromApi.length !== 0 && handleSubmit({ ...formValues, page: currentPage })

        //eslint-disable-next-line
    }, [currentPage])

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className='layout-wrapper bg-bgcolor/50 w-[100%] h-full'>
            <div className='top-header w-full z-10 bg-#FFFFFF'>
                <Header toggleSidebar={toggleSidebar} />
            </div>
            <div className='content-wrapper flex flex-row'>
                <div className={`sidebar top-0 bg-white ${isSidebarOpen ? 'open' : ''}`}>
                    <SideBar details={SideBarContents} />
                </div>
                <div className='w-full bg-bgLightBlue page-content pb-20'>
                    <div className='px-[30px]'>
                        <div className='h-10  flex items-center'>
                            <Navbar />
                        </div>
                        <div className=''>
                            <BatchForm setProperties={{ setDataFromApi, setCurrentPage, setTotalPages, setFormValues }} setDataFromApi={setDataFromApi} />
                        </div>
                        {isBackgroundBlurred && <div style={blurredBackgroundStyles} />}
                        {showTextPopup && (
                            <TextPopup
                                heading="Alert"
                                description={alertText}
                                onClose={() => {
                                    setShowTextPopup(false);
                                    setIsBackgroundBlurred(false);
                                }}
                            />
                        )}
                        {
                            loading && <Loader />
                        }
                        {
                            dataFromApi.length !== 0 &&
                            <div className='mt-10 flex flex-col'>
                                <BatchTable data={dataFromApi} />
                                <div className='flex justify-between w-[100%] p-10'>
                                    <div className='w-[10%]'>
                                        <SubmitButton disabled={currentPage === 1} label={'Previous'} btnWidth={'full'} onClick={handlePreviousPage} />
                                    </div>
                                    <div className='w-[10%]'>
                                        <SubmitButton disabled={currentPage === totalPages} label={'Next'} btnWidth={'full'} onClick={handleNextPage} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index