import React, { useRef, useState } from 'react'
import MinimizeIcon from '../../assets/svg/minimize_icon.svg'
import MaximizeIcon from '../../assets/svg/maximize_icon.svg'

function TableRow(props) {
    const { rowData, widths, textColor, font, minimizeIcon, actions, actionWidth, minH, ...rest } = props
    const icon = props.icon == undefined;
    const image = useRef();
    let isExpanded = false;

    

    const onImageClick = () =>{
        isExpanded = !isExpanded;
        image.current.src = isExpanded ? MaximizeIcon : minimizeIcon;
    }

    return (
        <>
            <tr className={`w-[100%] flex rounded-[12px] h-[100%] min-h-${minH}`}>
                {Object.keys(rowData).map((key, index) => {
                    return (
                        <td key={key} className={`flex items-center justify-center border-b-[1px] pr-5 border-navBorderLight text-font14 ${font ? `font-${font}` : 'font-normal'} ${index === 1 && textColor ? `text-${textColor}` : 'text-batchActionsTBody'} `} style={{ width: widths[index]? widths[index] : 'auto', textAlign: 'center', paddingLeft: '0px' }}>
                            <div className='flex justify-center items-center w-[100%] max-h-[15rem] overflow-y-auto sidebar1'>
                                {
                                    Array.isArray(rowData[key]) ? 
                                    <div>
                                        {
                                            rowData[key].map((data, index) => {
                                            if(data) {
                                                return <div key={index} className='inline'>
                                                    {index%3 === 0 && <div className='h-1'></div>}
                                                    <p className='text-[1.7rem] font-semibold inline'>{data}{index !== rowData[key].length -1 && ';'} </p>
                                                </div>
                                            }})
                                        }
                                    </div> :
                                    key.startsWith('image') && icon ? <img className='w-11 h-11' src={rowData[key]} alt='' /> : <p className='text-[1.7rem] font-semibold'>{rowData[key]}</p>}
                            </div>
                        </td>
                    )
                })}
                {
                    actions && <td className={`flex items-center justify-center border-b-[1px] border-navBorderLight text-font14 ${font ? `font-${font}` : 'font-normal'}`} style={{width: actionWidth}}>
                        {actions(rowData)}
                    </td> 
                }
            </tr>
        </>
    )
}




export default TableRow


