import React, { useState } from 'react';
import './App.css';
import AppRoutes from './routes/appRoutes';

import PopUpManager from './components/popups2/popupManager';
import Loader from './components/ui/loader/Loader';

import useAuth from './hooks/useAuth';
import useUserData from './hooks/useUserData';
import useAxiosPrivate from './hooks/useAxiosPrivate';
import { useEffect } from 'react';
import { getUserData } from './services/auth';

function App() {

  const axiosPrivate = useAxiosPrivate();
  const { user, setUser } = useUserData();
  const { auth } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [errPopDisabled, setErrPopDisabled] = useState(true);

  useEffect(() => {
    if(!auth.accessToken || user || !axiosPrivate) return;
    (async () => {
      try {
        setIsLoading(true)
        const data = await getUserData(axiosPrivate);
        
        setUser({
          _id: data?._id,
          roles: data?.roles,
          userId: data?.userId,
          emailId: data?.emailId,
          userName: data?.userName,
          isBlocked: data?.isBlocked,
          isDeleted: data?.isDeleted
        })
      } catch (error) {
        setErrPopDisabled(false);
      } finally {
        setIsLoading(false)
      }
    })();
  }, [axiosPrivate, auth, user, setUser])


  if(window.top !== window.self) {
    return <div className='bg-[#ff3333] h-[100vh] w-[100vw] flex justify-center items-center'>
      <div className='border-2 border-[white] p-10 w-[50%]'>
        <h1 className='text-[white] text-3xl font-bold'>This page is not allowed to be embedded!!</h1>

        <div className='mt-5'>
          <p className='text-[white] text-lg'>Please visit official website at {new URL(window.location.href).hostname}</p>
        </div>
      </div>
    </div>
  }

  return (
      <>
        {isLoading && <Loader />}
        <PopUpManager 
          disable={errPopDisabled}
          control={'text'}
          heading={'Error'}
          description={'Unable to load user data. Please try again!'}
          onClose={() => {
            window.location.reload()
          }}
        />
        <div className="App" >
          <AppRoutes/>
        </div>
      </>
  );
}

export default App;

