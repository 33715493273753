export const composeData = (values) => {
    const composedData = {
        organizationName: values?.stage1?.organizationDetails?.organizationName || '',
        addressLine1: values?.stage1?.organizationDetails?.addressLine1 || '',
        addressLine2: values?.stage1?.organizationDetails?.addressLine2 || '',
        country: values?.stage1?.organizationDetails?.country || '',
        state: values?.stage1?.organizationDetails?.state || '',
        city: values?.stage1?.organizationDetails?.city || '',
        postalCode: values?.stage1?.organizationDetails?.postalCode || '',
        entityType: values?.stage1?.organizationDetails?.entityType || '',
        categoryOfOrganization: values?.stage1?.organizationDetails?.categoryOfOrganization || '',
        taxId: values?.stage1?.organizationDetails?.taxId || '',
        businessId: values?.stage1?.organizationDetails?.businessId || '',
        monthlyProductionQuantity: values?.stage1?.organizationDetails?.monthlyProductionQuantity || '',
        numberOfSKUs: values?.stage1?.organizationDetails?.numberOfSKUs || '',
        serviceType: values?.stage1?.organizationDetails?.serviceType || [],
        dunsNumber: values?.stage1?.organizationDetails?.dunsNumber || '',
        companyWebsite: values?.stage1?.organizationDetails?.companyWebsite || '',
        enterpriseType: values?.stage1?.organizationDetails?.enterpriseType || '',
        billingDate: values?.stage1?.organizationDetails?.billingDate || '',
        companyDocOptions: values?.stage1?.organizationDetails?.companyDocOptions || '',
        companyDocFileName: values?.stage1?.organizationDetails?.companyDocFileName || '', 
        companyDoc: values?.stage1?.organizationDetails?.companyDoc || '',

        //Auth
        firstName: values?.stage1?.authPersonInfo?.firstName || '',
        lastName: values?.stage1?.authPersonInfo?.lastName || '',
        designation: values?.stage1?.authPersonInfo?.designation || '',
        emailId: values?.stage1?.authPersonInfo?.emailId || '',
        mobileNoDetails: values?.stage1?.authPersonInfo?.mobileNoDetails || '',
        auth_Proof_Of_Identity_Doc_Options: values?.stage1?.authPersonInfo?.auth_Proof_Of_Identity_Doc_Options || '',
        auth_Authorization_Doc_Filename: values?.stage1?.authPersonInfo?.auth_Authorization_Doc_Filename,
        auth_Proof_Of_Identity_Doc_Filename: values?.stage1?.authPersonInfo?.auth_Proof_Of_Identity_Doc_Filename,
        authAuthorizationDoc: values?.stage1?.authPersonInfo?.auth_Authorization_Doc || '',
        authProofDoc: values?.stage1?.authPersonInfo?.auth_Proof_Of_Identity_Doc || '',

        //Incoice
        inv_firstName: values?.stage1?.paymentContactInfo?.firstName || '',
        inv_lastName: values?.stage1?.paymentContactInfo?.lastName || '',
        inv_designation: values?.stage1?.paymentContactInfo?.designation || '',
        inv_emailId: values?.stage1?.paymentContactInfo?.emailId || '',
        inv_mobileNoDetails: values?.stage1?.paymentContactInfo?.mobileNoDetails || '',
        inv_auth_Proof_Of_Identity_Doc_Options: values?.stage1?.paymentContactInfo?.payment_Proof_Of_Identity_Doc_Options || '',
        payment_Authorization_Doc_Filename: values?.stage1?.paymentContactInfo?.payment_Authorization_Doc_Filename || '',
        payment_Proof_Of_Identity_Doc_Filename: values?.stage1?.paymentContactInfo?.payment_Proof_Of_Identity_Doc_Filename || '',
        invAuthorizationDoc: values?.stage1?.paymentContactInfo?.payment_Authorization_Doc || '',
        invProofDoc: values?.stage1?.paymentContactInfo?.payment_Proof_Of_Identity_Doc || '',

        //admin
        admin_userName1: values?.stage1?.adminAccessSubUsers?.userName1 || '',
        admin_emailId1: values?.stage1?.adminAccessSubUsers?.emailId1 || '',
        admin_userName2: values?.stage1?.adminAccessSubUsers?.userName2 || '',
        admin_emailId2: values?.stage1?.adminAccessSubUsers?.emailId2 || '',

        //users
        user_userName1: values?.stage1?.regularAccessSubUsers?.userName1 || '',
        user_emailId1: values?.stage1?.regularAccessSubUsers?.emailId1 || '',
        user_userName2: values?.stage1?.regularAccessSubUsers?.userName2 || '',
        user_emailId2: values?.stage1?.regularAccessSubUsers?.emailId2 || '',

        //Solution
        trackingBaseCost: values?.stage1?.solutionCostsDetails?.trackingBaseCost || '0',
        infoBaseCost: values?.stage1?.solutionCostsDetails?.infoBaseCost || '0',
        overtBaseCost: values?.stage1?.solutionCostsDetails?.overtBaseCost || '0',
        overtWarrantyCost: values?.stage1?.solutionCostsDetails?.overtWarrantyCost || '0',
        overtLoyaltyCost: values?.stage1?.solutionCostsDetails?.overtLoyaltyCost || '0',
        covertBaseCost: values?.stage1?.solutionCostsDetails?.covertBaseCost || '0',
        covertWarrantyCost: values?.stage1?.solutionCostsDetails?.covertWarrantyCost || '0',
        covertLoyaltyCost: values?.stage1?.solutionCostsDetails?.covertLoyaltyCost || '0',

        //Service
        dataCostPerQr: values?.stage1?.servicesCostDetails?.dataCostPerQr || '0',
        costPerCouponCode: values?.stage1?.servicesCostDetails?.costPerCouponCode || '0',
        cashbackTransactionCost: values?.stage1?.servicesCostDetails?.cashbackTransactionCost || '0',
        emailAndSmsComission: values?.stage1?.servicesCostDetails?.emailAndSmsComission || '0',
        geoLocation_Cost_Per_Conversion: values?.stage1?.servicesCostDetails?.geoLocation_Cost_Per_Conversion || '0',
        miscellaneous: values?.stage1?.servicesCostDetails?.miscellaneous || [{}],

        //App details
        primaryColor: values?.stage2?.uiDesignCriteria?.primaryColor || '#8950B8',
        secondaryColor: values?.stage2?.uiDesignCriteria?.secondaryColor || '#5B9EEB',
        backgroundColor: values?.stage2?.uiDesignCriteria?.backgroundColor || '#000000',
        gradientAngle: values?.stage2?.uiDesignCriteria?.gradientAngle || '90',
        clientLogo: values?.stage2?.uiDesignCriteria?.clientLogo || '',
        clientFavIcon: values?.stage2?.uiDesignCriteria?.clientFavIcon || '',
        clientLogoFileName: values?.stage2?.uiDesignCriteria?.clientLogoFileName || '',
        clientFavIconFileName: values?.stage2?.uiDesignCriteria?.clientFavIconFileName || '',
        modeOfService: values?.stage2?.uiDesignCriteria?.modeOfService || '',
        useVeotsSubdomain: values?.stage2?.uiDesignCriteria?.useVeotsSubdomain === 'true' || values?.stage2?.uiDesignCriteria?.useVeotsSubdomain  === true? true : (values?.stage2?.uiDesignCriteria?.useVeotsSubdomain === 'false' || values?.stage2?.uiDesignCriteria?.useVeotsSubdomain === false? false : ''),
        isfooterIncluded: values?.stage2?.uiDesignCriteria?.isfooterIncluded === 'true' || values?.stage2?.uiDesignCriteria?.isfooterIncluded  === true? true : (values?.stage2?.uiDesignCriteria?.isfooterIncluded === 'false' || values?.stage2?.uiDesignCriteria?.isfooterIncluded === false? false : ''),
        domainName: values?.stage2?.uiDesignCriteria?.domainName || '',
        qrScanAccessLevel: JSON.stringify(values?.stage2?.uiDesignCriteria?.qrScanAccessLevel?.sort()) || '[]',
        
        //Otp Services
        isOtpServiceEnabled: values?.stage2?.otpServices?.isOtpServiceEnabled === 'true' || values?.stage2?.otpServices?.isOtpServiceEnabled  === true? true : (values?.stage2?.otpServices?.isOtpServiceEnabled === 'false' || values?.stage2?.otpServices?.isOtpServiceEnabled === false? false : ''),
        emailDomain: values?.stage2?.otpServices?.emailDomain || '',
        replyToMailId: values?.stage2?.otpServices?.replyToMailId || '',
        senderMailId: values?.stage2?.otpServices?.senderMailId || '',

        //Background Verification
        bgVerification_Doc: values?.bgVerification_Doc || '',
        bgVerification_Doc_Filename: values?.bgVerification_Doc_Filename || '',

        //comments
        comments: values?.comments || '',
    }

    return composedData;
}

export const parseDataForSaving = (values) => {
    const orgDetails = {
        organizationName: values.organizationName,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        country: values.country,
        state: values.state,
        city: values.city,
        postalCode: values.postalCode,
        entityType: values.entityType,
        categoryOfOrganization: values.categoryOfOrganization,
        taxId: values.taxId,
        businessId: values.businessId,
        monthlyProductionQuantity: values.monthlyProductionQuantity,
        numberOfSKUs: values.numberOfSKUs,
        serviceType: values.serviceType,
        dunsNumber: values.dunsNumber,
        companyWebsite: values.companyWebsite,
        enterpriseType: values.enterpriseType,
        billingDate: values.billingDate,
        companyDocFileName: values?.companyDoc?.name || values.companyDocFileName,
        companyDocOptions: values.companyDocOptions,
    };
    if(!(values.companyDoc instanceof File)) orgDetails.companyDoc = values.companyDoc;

    const authPersonInfo = {
        firstName: values.firstName,
        lastName: values.lastName,
        designation: values.designation,
        emailId: values.emailId,
        mobileNoDetails: values.mobileNoDetails,
        auth_Authorization_Doc_Filename: values?.authAuthorizationDoc?.name || values.auth_Authorization_Doc_Filename,
        auth_Proof_Of_Identity_Doc_Options: values.auth_Proof_Of_Identity_Doc_Options,
        auth_Proof_Of_Identity_Doc_Filename: values?.authProofDoc?.name || values.auth_Proof_Of_Identity_Doc_Filename
    }
    if(!(values.authAuthorizationDoc instanceof File)) authPersonInfo.auth_Authorization_Doc = values.authAuthorizationDoc;
    if(!(values.authProofDoc instanceof File)) authPersonInfo.auth_Proof_Of_Identity_Doc = values.authProofDoc;

    const paymentContactInfo = {
        firstName: values.inv_firstName,
        lastName: values.inv_lastName,
        designation: values.inv_designation,
        emailId: values.inv_emailId,
        mobileNoDetails: values.inv_mobileNoDetails,
        payment_Authorization_Doc_Filename: values?.invAuthorizationDoc?.name || values.payment_Authorization_Doc_Filename,
        payment_Proof_Of_Identity_Doc_Options: values.inv_auth_Proof_Of_Identity_Doc_Options,
        payment_Proof_Of_Identity_Doc_Filename: values?.invProofDoc?.name || values.payment_Proof_Of_Identity_Doc_Filename
    }
    if(!(values.invAuthorizationDoc instanceof File)) paymentContactInfo.payment_Authorization_Doc = values.invAuthorizationDoc;
    if(!(values.invProofDoc instanceof File)) paymentContactInfo.payment_Proof_Of_Identity_Doc = values.invProofDoc;

    const adminAccessSubUsers = {
        userName1: values.admin_userName1,
        emailId1: values.admin_emailId1,
        userName2: values.admin_userName2,
        emailId2: values.admin_emailId2
    }

    const regularAccessSubUsers = {
        userName1: values.user_userName1,
        emailId1: values.user_emailId1,
        userName2: values.user_userName2,
        emailId2: values.user_emailId2
    }

    const solutionDetails = {
        infoBaseCost: values.infoBaseCost,
        covertBaseCost: values.covertBaseCost,
        covertWarrantyCost: values.covertWarrantyCost,
        covertLoyaltyCost: values.covertLoyaltyCost,
        overtBaseCost: values.overtBaseCost,
        overtWarrantyCost: values.overtWarrantyCost,
        overtLoyaltyCost: values.overtLoyaltyCost,
        trackingBaseCost: values.trackingBaseCost
    }

    const serviceDetails = {
        dataCostPerQr: values.dataCostPerQr,
        costPerCouponCode: values.costPerCouponCode,
        cashbackTransactionCost: values.cashbackTransactionCost,
        emailAndSmsComission: values.emailAndSmsComission,
        geoLocation_Cost_Per_Conversion: values.geoLocation_Cost_Per_Conversion,
        miscellaneous: values.miscellaneous,
    }

    const uiDesignData = {
        primaryColor: values?.primaryColor,
        secondaryColor: values?.secondaryColor,
        backgroundColor: values?.backgroundColor,
        gradientAngle: values?.gradientAngle,
        modeOfService: values?.modeOfService,
        useVeotsSubdomain: values?.useVeotsSubdomain,
        domainName: values?.domainName,
        isfooterIncluded: values?.isfooterIncluded,
        qrScanAccessLevel: JSON.parse(values?.qrScanAccessLevel),
        clientLogoFileName: values?.clientLogo?.name || values.clientLogoFileName,
        clientFavIconFileName: values?.clientFavIcon?.name || values.clientFavIconFileName,
    }
    if(!(values.clientLogo instanceof File)) uiDesignData.clientLogo = values.clientLogo;
    if(!(values.clientFavIcon instanceof File)) uiDesignData.clientFavIcon = values.clientFavIcon;

    const otpServiceData = {
        isOtpServiceEnabled: values?.isOtpServiceEnabled,
        emailDomain: values?.emailDomain,
        replyToMailId: values?.replyToMailId,
        senderMailId: values?.senderMailId
    }

    const bgVerificationData = {
        bgVerification_Doc_Filename: values?.bgVerification_Doc?.name || values.bgVerification_Doc_Filename
    }
    if(!(values.bgVerification_Doc instanceof File)) bgVerificationData.bgVerification_Doc = values.bgVerification_Doc;

    const commentsData = {
        comments: values?.comments
    }

    const parsedOrgDetails = Object.fromEntries(
        Object.entries(orgDetails).filter(([_, value]) => Boolean(value))
    );

    const parsedAuthPersonInfo = Object.fromEntries(
        Object.entries(authPersonInfo).filter(([_, value]) => Boolean(value))
    );

    const parsedPaymentContactInfo = Object.fromEntries(
        Object.entries(paymentContactInfo).filter(([_, value]) => Boolean(value))
    );

    const paymentAdminAccessSubUsers = Object.fromEntries(
        Object.entries(adminAccessSubUsers).filter(([_, value]) => Boolean(value))
    );

    const paymentRegularAccessSubUsers = Object.fromEntries(
        Object.entries(regularAccessSubUsers).filter(([_, value]) => Boolean(value))
    );

    const parsedSolutionDetails = Object.fromEntries(
        Object.entries(solutionDetails).filter(([_, value]) => Boolean(value))
    );

    const parsedServiceDetails = Object.fromEntries(
        Object.entries(serviceDetails).filter(([_, value]) => Boolean(value))
    );

    const parsedUiDesignData = Object.fromEntries(
        Object.entries(uiDesignData).filter(([_, value]) => Boolean(value))
    );

    const parsedOtpServiceData = Object.fromEntries(
        Object.entries(otpServiceData).filter(([_, value]) => Boolean(value))
    );

    const parsedBgVerificationData = Object.fromEntries(
        Object.entries(bgVerificationData).filter(([_, value]) => Boolean(value))
    );

    const parsedCommentsData = Object.fromEntries(
        Object.entries(commentsData).filter(([_, value]) => Boolean(value))
    );

    const parsedData = {
        stage1: {
            organizationDetails: parsedOrgDetails,
            authPersonInfo: parsedAuthPersonInfo,
            paymentContactInfo: parsedPaymentContactInfo,
            adminAccessSubUsers: paymentAdminAccessSubUsers,
            regularAccessSubUsers: paymentRegularAccessSubUsers,
            solutionCostsDetails: parsedSolutionDetails,
            servicesCostDetails: parsedServiceDetails
        },
        stage2: {
            uiDesignCriteria: parsedUiDesignData,
            otpServices: parsedOtpServiceData,
        },
        ...parsedBgVerificationData,
        ...parsedCommentsData
    }
    return parsedData;
}

export const parseFiles = (values) => {
    const files = {
        companyDoc: (values.companyDoc instanceof File)? values.companyDoc : null,
        auth_Authorization_Doc: (values.authAuthorizationDoc instanceof File)? values.authAuthorizationDoc : null,
        auth_Proof_Of_Identity_Doc: (values.authProofDoc instanceof File)? values.authProofDoc : null,
        payment_Authorization_Doc: (values.invAuthorizationDoc instanceof File)? values.invAuthorizationDoc : null,
        payment_Proof_Of_Identity_Doc: (values.invProofDoc instanceof File)? values.invProofDoc : null,
        clientLogo: (values.clientLogo instanceof File)? values.clientLogo : null,
        clientFavIcon: (values.clientFavIcon instanceof File)? values.clientFavIcon : null,
        bgVerification_Doc: (values.bgVerification_Doc instanceof File)? values.bgVerification_Doc : null,
    }

    const parsedFiles = Object.fromEntries(
        Object.entries(files).filter(([_, value]) => Boolean(value))
    ); 

    return parsedFiles;
}

export const parseDataForSubmit = (values) => {
    const orgDetails = {
        organizationName: values.organizationName,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        country: values.country,
        state: values.state,
        city: values.city,
        postalCode: values.postalCode,
        entityType: values.entityType,
        categoryOfOrganization: values.categoryOfOrganization,
        taxId: values.taxId,
        businessId: values.businessId,
        monthlyProductionQuantity: values.monthlyProductionQuantity,
        numberOfSKUs: values.numberOfSKUs,
        serviceType: values.serviceType,
        dunsNumber: values.dunsNumber,
        companyWebsite: values.companyWebsite,
        enterpriseType: values.enterpriseType,
        companyDocOptions: values.companyDocOptions,
        companyDoc: values.companyDoc
    };

    const authPersonInfo = {
        firstName: values.firstName,
        lastName: values.lastName,
        designation: values.designation,
        emailId: values.emailId,
        mobileNoDetails: values.mobileNoDetails,
        auth_Proof_Of_Identity_Doc_Options: values.auth_Proof_Of_Identity_Doc_Options,
        auth_Proof_Of_Identity_Doc: values.authProofDoc,
        auth_Authorization_Doc: values.authAuthorizationDoc,
    }

    const paymentContactInfo = {
        firstName: values.inv_firstName,
        lastName: values.inv_lastName,
        designation: values.inv_designation,
        emailId: values.inv_emailId,
        mobileNoDetails: values.inv_mobileNoDetails,
        payment_Proof_Of_Identity_Doc_Options: values.inv_auth_Proof_Of_Identity_Doc_Options,
        payment_Proof_Of_Identity_Doc: values.invProofDoc,
        payment_Authorization_Doc: values.invAuthorizationDoc,
    }

    const adminAccessSubUsers = {
        userName1: values.admin_userName1,
        emailId1: values.admin_emailId1,
        userName2: values.admin_userName2,
        emailId2: values.admin_emailId2
    }

    const regularAccessSubUsers = {
        userName1: values.user_userName1,
        emailId1: values.user_emailId1,
        userName2: values.user_userName2,
        emailId2: values.user_emailId2
    }

    const solutionDetails = {
        infoBaseCost: values.infoBaseCost,
        covertBaseCost: values.covertBaseCost,
        covertWarrantyCost: values.covertWarrantyCost,
        covertLoyaltyCost: values.covertLoyaltyCost,
        overtBaseCost: values.overtBaseCost,
        overtWarrantyCost: values.overtWarrantyCost,
        overtLoyaltyCost: values.overtLoyaltyCost,
        trackingBaseCost: values.trackingBaseCost
    }

    const serviceDetails = {
        dataCostPerQr: values.dataCostPerQr,
        costPerCouponCode: values.costPerCouponCode,
        cashbackTransactionCost: values.cashbackTransactionCost,
        emailAndSmsComission: values.emailAndSmsComission,
        geoLocation_Cost_Per_Conversion: values.geoLocation_Cost_Per_Conversion,
        miscellaneous: values.miscellaneous,
    }

    const uiDesignData = {
        primaryColor: values?.primaryColor,
        secondaryColor: values?.secondaryColor,
        backgroundColor: values?.backgroundColor,
        gradientAngle: values?.gradientAngle,
        clientLogo: values.clientLogo,
        clientFavIcon: values.clientFavIcon,
        modeOfService: values?.modeOfService,
        isOtpServiceEnabled: values?.isOtpServiceEnabled,
        isfooterIncluded: values?.isfooterIncluded,
        qrScanAccessLevel: JSON.parse(values?.qrScanAccessLevel),
    }

    const otpServiceData = {
        isOtpServiceEnabled: values?.isOtpServiceEnabled,
        emailDomain: values?.emailDomain,
        replyToMailId: values?.replyToMailId,
        senderMailId: values?.senderMailId
    }

    const bgVerificationData = {
        bgVerification_Doc: values.bgVerification_Doc
    }

    const commentsData = {
        comments: values?.comments
    }

    const parsedOrgDetails = Object.fromEntries(
        Object.entries(orgDetails).filter(([_, value]) => Boolean(value))
    );

    const parsedAuthPersonInfo = Object.fromEntries(
        Object.entries(authPersonInfo).filter(([_, value]) => Boolean(value))
    );

    const parsedPaymentContactInfo = Object.fromEntries(
        Object.entries(paymentContactInfo).filter(([_, value]) => Boolean(value))
    );

    const paymentAdminAccessSubUsers = Object.fromEntries(
        Object.entries(adminAccessSubUsers).filter(([_, value]) => Boolean(value))
    );

    const paymentRegularAccessSubUsers = Object.fromEntries(
        Object.entries(regularAccessSubUsers).filter(([_, value]) => Boolean(value))
    );

    const parsedSolutionDetails = Object.fromEntries(
        Object.entries(solutionDetails).filter(([_, value]) => Boolean(value))
    );

    const parsedServiceDetails = Object.fromEntries(
        Object.entries(serviceDetails).filter(([_, value]) => Boolean(value))
    );

    const parsedUiDesignData = Object.fromEntries(
        Object.entries(uiDesignData).filter(([_, value]) => Boolean(value))
    );

    const parsedOtpServiceData = Object.fromEntries(
        Object.entries(otpServiceData).filter(([_, value]) => Boolean(value))
    );

    const parsedBgVerificationData = Object.fromEntries(
        Object.entries(bgVerificationData).filter(([_, value]) => Boolean(value))
    );

    const parsedCommentsData = Object.fromEntries(
        Object.entries(commentsData).filter(([_, value]) => Boolean(value))
    );

    const parsedData = {
        useVeotsSubdomain: values?.useVeotsSubdomain,
        domain: values?.domainName,
        billingDate: values.billingDate,
        organizationDetails: parsedOrgDetails,
        authPersonInfo: parsedAuthPersonInfo,
        paymentContactInfo: parsedPaymentContactInfo,
        adminAccessSubUsers: paymentAdminAccessSubUsers,
        regularAccessSubUsers: paymentRegularAccessSubUsers,
        solutionCostsDetails: parsedSolutionDetails,
        servicesCostDetails: parsedServiceDetails,
        uiDesignCriteria: parsedUiDesignData,
        otpServices: parsedOtpServiceData,
        ...parsedBgVerificationData,
        ...parsedCommentsData
    }
    return parsedData;
}