import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './textError';
import { FaRegEye, FaRegEyeSlash  } from "react-icons/fa";
import './textInput.css';

function TextInput(props) {
  const { label, name, width, bgcolor, textcolor, height, textcenter, placeholderIcon, border, type, customError, onInputChange, formSetter, formSubmitter, requiredSuggestions=false, suggestions = [], formik, ...rest } = props

  const [filedType, setFieldType] = useState(type);
  const [filedInFocus, setFieldInFocus] = useState(false);

  // useEffect(() => {
  //   onInputChange && onInputChange('');
  // }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const eyeToggler = () => {
    if(filedType === 'password')
      setFieldType('text')
    else
      setFieldType('password')
  }

  const onChnage = formSetter? {
    onChange: (e) => {
      setFieldInFocus(true); 
      formSetter && formSetter(name, e.target.value); 
      onInputChange && onInputChange(e.target.value)
    }
  } : {
    onKeyUp: (e) => {
      setFieldInFocus(true); 
      onInputChange && onInputChange(e.target.value)
    }
  }

  return (
    <div className={`md:flex w-[100%] mb-8 relative justify-between items-center ${height ? `h-${height}` : ''}`}>
      {label &&
        <label htmlFor={name} className={`${textcolor ? `text-${textcolor}` : 'text-popupText'} font-normal text-font16 min-w-[40%]`}>
          {label}
        </label>
      }
      <div className={`${width ? `w-${width}` : (label? 'w-[60%]' : 'w-[100%]')}  flex flex-wrap pt-2 relative`} onFocus={() => setFieldInFocus(true)} onBlur={() => setTimeout(()=>setFieldInFocus(false), 300)} >

        <Field {...onChnage} autoComplete={'off'} id={name} name={name} {...rest} className={`w-[100%] px-[20px] ${height ? `h-${height}` : 'h-[46px]'}  ${bgcolor ? `bg-${bgcolor}` : 'bg-bgWhite'} text-font16 rounded-[5px] ${border ? `border-2 border-dashed border-dashedBorder}` : ``} ${textcenter && 'text-center'}`} type={type === 'password'? filedType : type} />
        {
          type === 'password' ?
            (filedType === 'password'?
              <div onClick={eyeToggler} className={`aspect-square flex justify-center items-center ${height ? `h-${height}` : 'h-[46px]'} absolute left-[calc(100%-46px)]`}>
                <FaRegEyeSlash  className='h-[40%] w-[40%]' />
              </div> :
              <div onClick={eyeToggler} className={`aspect-square flex justify-center items-center ${height ? `h-${height}` : 'h-[46px]'} absolute left-[calc(100%-46px)]`}>
                <FaRegEye className='h-[40%] w-[40%]' />
              </div>)
            : null
        }
        {
          requiredSuggestions && filedInFocus && suggestions.length > 0 &&
          <div className='absolute top-[100%] bg-white w-full p-5 rounded-b-xl shadow-3xl max-h-96 z-[1] sidebar1' style={{overflowY: 'auto'}}>
            {
              suggestions.map((suggestion, i) => {
                return <div
                          key={i} 
                          className='text-3xl border-b border-gray-300 my-4 pb-2 cursor-pointer'
                          onClick={() => {
                            formSetter && formSetter(name, suggestion);
                            onInputChange && onInputChange(suggestion);
                            formSubmitter && formSubmitter();
                          }}
                        >
                          {suggestion}
                        </div>
              })
            }
          </div>
        }
        {placeholderIcon &&
          <div className='absolute inset-y-0 right-[10px] flex items-center pr-3 pointer-events-none'>
            <img className='w-9 h-9' src={placeholderIcon} alt=''></img>
          </div>
        }
        {
          !(requiredSuggestions && filedInFocus && suggestions.length > 0) && 
          <div className='relative w-full'>
            <div className='absolute'>
              {
                customError? 
                  <TextError>{customError}</TextError> : 
                  <ErrorMessage name={name} component={TextError} />
              }
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default TextInput