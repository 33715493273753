import { handleError } from "../../../utils/handles/errorHandle";

async function updateLegalDocumnet(docUserType,documentType,content,updatedContent,axiosPrivate){
    try {
        const response = await axiosPrivate.post("/api/legal/update-legal-document", { 
            docUserType: docUserType, 
            documentType: documentType, 
            content: content,
            updatedContent: updatedContent
        },
        {
            headers: {"Content-Type": "application/json"},
            withCredentials: true,
        });

        return response;
    } catch (error) {
        handleError(error);
    }
}

async function fetchDocLatestVersion(docUserType,documentType,axiosPrivate){
    try {
        const response = await axiosPrivate.get("/api/legal/fetch-legal-document-version", { 
            params:{
                docUserType: docUserType, 
                documentType: documentType
            }
        },
        {
            headers: {"Content-Type": "application/json"},
            withCredentials: true,
        });

        return response.data;
    } catch (error) {
        handleError(error);
    }

}

export{
    updateLegalDocumnet,
    fetchDocLatestVersion
}