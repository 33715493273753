import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { Formik, Form } from 'formik';

import FormikControl from '../../../components/form/formikControl';
import { SubmitButton } from '../../../components/ui/buttons/submitButton';
import RedButton from '../../../components/ui/buttons/redButton';
import Loader from '../../../components/ui/loader/Loader';
import PopUpManager from '../../../components/popups2/popupManager'

import { forgotPasswordReset } from '../services/api';

function ResetPassword({email}) {

    const navigate = useNavigate();
    const [newPassErr, setNewPassErr] = useState([]);
    const [confPassErr, setConfPassErr] = useState([]);

    const [newPassErrControl, setNewPassErrControl] = useState(false);
    const [confPassErrControl, setConfPassErrControl] = useState(false);

    const [loading, setLoading] = useState(false);
    const [oneButtonPopupControl, setOneButtonPopupControl] = useState(false);
    const [textPopupControl, setTextPopupControl] = useState(false);
    const [textPopupText, setTextPopupText] = useState('');

    const handleConfPassChange = useCallback((newPass, confpass, settingErrorList) => {
        const errs = [];
        
        errs.push({res: 'Passwords must match', control: settingErrorList? 0 :  newPass !== confpass? -1: 1});
        if(newPass !== confpass) setConfPassErrControl(true);
        else setConfPassErrControl(false);

        setConfPassErr(errs);
    }, [])

    const handleNewPassChange = useCallback((newPass, formik, settingErrorList) => {
        formik && formik.setFieldValue('confirmPassword', '');
        handleConfPassChange(newPass, '', true);
        const errs = [];
        
        errs.push({res: 'Password must contain minimum 8 characters',  control: settingErrorList? 0 :  newPass.length < 8? -1: 1});
        errs.push({res: 'Password must contain a number', control: settingErrorList? 0 :  !(/[1-9]/.test(newPass))? -1: 1})
        errs.push({res: 'Password must contain a Captial case letter', control: settingErrorList? 0 :  !(/[A-Z]/.test(newPass))? -1: 1})
        errs.push({res: 'Password must contain a small case letter', control: settingErrorList? 0 :  !(/[a-z]/.test(newPass))? -1: 1})
        errs.push({res: 'Password must contain a specail character', control: settingErrorList? 0 :  !(/[^\w\d]/.test(newPass))? -1: 1})

        if(newPass.length < 8 || !(/[1-9]/.test(newPass)) || !(/[A-Z]/.test(newPass)) || !(/[a-z]/.test(newPass)) || !(/[^\w\d]/.test(newPass))) setNewPassErrControl(true);
        else setNewPassErrControl(false)

        setNewPassErr(errs);
    }, [handleConfPassChange])

    useEffect(() =>{
        handleNewPassChange('', null, true);
        handleConfPassChange('', '', true);
    }, [handleNewPassChange, handleConfPassChange])

    const initialValues = {
        newPassword: '',
        confirmPassword: '',
    };

    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            await forgotPasswordReset(
                values.newPassword
            );
            
            setOneButtonPopupControl(true)
        } catch(error) {
            setTextPopupText(error.message || 'Unable to reset password')
            setTextPopupControl(true)
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
        {loading && <Loader />}
        <PopUpManager 
            control={'one-button'}
            disable={!oneButtonPopupControl}
            heading={'Alert'}
            description={'Password updated Succussfully'}
            onClose={() => {
                navigate('/home')
                setOneButtonPopupControl(true)
            }}
            clickedOk={() => {
                navigate('/home')
            }}
            okButtonText={'Go Back'}
        />
        <PopUpManager 
            control={'text'}
            disable={!textPopupControl}
            heading={'Alert'}
            description={textPopupText}
            onClose={() => {
                setTextPopupControl(false)
            }}
        />
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            {(formik) => {
                return (
                    <Form>
                        <div className='flex flex-col md:h-[83vh] gap-10 items-center md:items-start justify-between'>
                            <div className='w-[100%] pb-30 flex flex-col justify-start items-center'>
                                <h3 className='text-[30px] sm:text-[40px] text-popupText font-bold text-center leading-none mb-4'>
                                    Forgot password?
                                </h3>
                                <span className='text-popupText opacity-50 text-[14px] flex justify-center mt-3 text-center cursor-pointer'>
                                    Your new password must be different from the previous used password.
                                </span>
                            </div>
                            <div className='flex-1 flex  items-center justify-center md:w-[80%] w-full  mx-auto'>
                                <div className='w-[100%]'>
                                    <div className='w-[100%] mb-[20px]'>
                                        <h6 className='text-popupText text-[13px] font-medium mb-[9px]'>
                                            New Password
                                        </h6>
                                        <FormikControl
                                            control='input'
                                            type='password'
                                            label=''
                                            name={'newPassword'}
                                            width='full'
                                            bgcolor='selectorBg'
                                            onInputChange={(val) => handleNewPassChange(val, formik)}
                                        />
                                        {
                                            <div className='text-red-400'>
                                                <ul>
                                                    {newPassErr.map((err, i) => {
                                                        return <li key={i} className='text-lg font-bold' style={!err.control? {color: 'gray'}: (err.control === -1? {color: 'red'} : {color: 'green'})}> • {err.res}</li>
                                                    })}
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                    <div className='w-[100%] mb-[20px]'>
                                        <h6 className='text-popupText text-[13px] font-medium mb-[9px]'>
                                            Confirm Password
                                        </h6>
                                        <FormikControl
                                            control='input'
                                            type='password'
                                            label=''
                                            name={'confirmPassword'}
                                            width='full'
                                            bgcolor='selectorBg'
                                            onInputChange={(val) => handleConfPassChange(formik.values.newPassword, val)}
                                            disabled={newPassErrControl || !formik.touched.newPassword}
                                        />
                                        {
                                            <div className='text-red-400'>
                                                <ul>
                                                    {confPassErr.map(err => {
                                                        return err.control === -1 && <li className='text-lg font-bold' style={!err.control? {color: 'gray'}: (err.control === -1? {color: 'red'} : {color: 'green'})}> • {err.res}</li>
                                                    })}
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                    <div className='w-[100%] h-[40%] flex justify-between items-start'>
                                        <div className='w-[48%]'>
                                            <SubmitButton label='Submit' type='submit' btnWidth='full' disabled={!(formik.values.confirmPassword && formik.values.newPassword && formik.values.confirmPassword) || newPassErrControl || confPassErrControl} />
                                        </div>
                                        <div className='w-[48%]'>
                                            <RedButton
                                                label='Cancel'
                                                width='full'
                                                height='16'
                                                fontSize={'font16'}
                                                onClick={() => { 
                                                    navigate('/home')
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
        </>
    );
}

export default ResetPassword;