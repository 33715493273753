import React from 'react'
import TableRow from './tableRow'
function TableBody(props) {
    const {details,widths,textColor,font,minimizeIcon,actions, actionWidth, minH, ...rest} = props
    return (
    <tbody className='bg-bgWhite flex flex-col w-[100%] text-font14'>
        {
            details.map((rowValue,rowIndex) => {
                return(
                    <TableRow minH={minH} key={rowIndex} rowData={rowValue} widths={widths} actions={actions} actionWidth={actionWidth} textColor={textColor} font={font} minimizeIcon={minimizeIcon}/>                        
                )
            })
        }
    </tbody>
    )
}

export default TableBody