import React, { useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'

import useAxiosPrivate from '../hooks/useAxiosPrivate'
import Loader from '../components/ui/loader/Loader'
import PopUpManager from '../components/popups2/popupManager'

import telephoneIcon from '../assets/images/sidebar_icons/telephone_icon.png'
import helpIcon from '../assets/svg/sidebar_icons/help_icon.svg'
import serviceIcon from '../assets/svg/sidebar_icons/service_icon.svg'
import logoutIcon from '../assets/svg/sidebar_icons/logout_icon.svg'

import { adminLogout } from '../services/auth'

function Sidebar(props) {

    const navigate = useNavigate()
    const axiosPrivate = useAxiosPrivate();

    //popup
    const [loading, setLoading] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [textPopupDisabled, setTextPopupDisabled] = useState(true);

    const handleLogout = async () => {
        try {
            setLoading(true);
            await adminLogout(axiosPrivate);

            window.location.reload();
            navigate('/login');
        } catch (error) {
            setAlertText(error.message || 'Unable to logout');
            setTextPopupDisabled(false)
        } finally {
            setLoading(false);
        }
    }

    const phoneNumber = '9632123543';
    const emailAddresss = 'Help@veots.com'
    return (
        <>
            {loading && <Loader />}
            <PopUpManager 
                disable={textPopupDisabled}
                control={"text"}
                heading={"Alert"}
                description={alertText}
                onClose={() => setTextPopupDisabled(true)}
            />
            <div className='flex justify-between flex-col h-full px-[30px] pt-[40px] z-[5] relative'>
                <div className=''>
                    <div className='text-sideBarActions flex flex-row justify-start items-center mb-[14px]'>
                        <p className='text-3xl font-bold '>Actions</p>
                    </div>

                    {props.details.map((value, index) => (
                        <div className='flex flex-col nav-links' key={index}>
                            <NavLink to={`/${value.name}`} className={'pl-[15px]'}>
                                <div className='flex flex-row justify-start items-center h-20'>
                                    <div className='w-20 h-20 flex justify-center items-center'>
                                        <img className='w-10 h-8' src={value.img} alt='logo'></img>
                                    </div>
                                    <p className='text-sideBarBtnText text-3xl font-normal truncate'>{value.desc}</p>
                                </div>
                            </NavLink>
                        </div>
                    ))}
                    <div className='mt-[24px]'>
                        <div className='flex flex-row justify-start items-center text-sideBarActions mb-[14px]'>
                            <p className='text-3xl font-bold '>For Queries</p>
                        </div>
                        <div className='flex flex-col nav-links'> 
                            <div className={'pl-[15px] cursor-pointer'}>
                                <div className='flex flex-row justify-start items-center h-20'>
                                    <div className='w-20 h-20 flex justify-center items-center'>
                                        <img className='w-10 h-8 px-1' src={telephoneIcon} alt='phone'></img>
                                    </div>
                                    <p className='text-sideBarBtnText opacity-50 text-3xl font-normal'>{phoneNumber}</p>
                                </div>
                            </div>
                            <Link to={`mailto:${emailAddresss}`} className={'pl-[15px]'}>
                                <div className='flex flex-row justify-start items-center h-20'>
                                    <div className='w-20 h-20 flex justify-center items-center'>
                                        <img className='w-10 h-8 ' src={helpIcon} alt='help'></img>
                                    </div>
                                    <p className='text-sideBarBtnText opacity-50 text-3xl font-normal'>{emailAddresss}</p>
                                </div>
                            </Link>

                            <Link to={`https://veots.com/`} className={'pl-[15px]'}>
                                <div className='flex flex-row justify-start items-center h-20'>
                                    <div className='w-20 h-20 flex justify-center items-center'>
                                        <img className='w-10 h-8 ' src={serviceIcon} alt='service'></img>
                                    </div>
                                    <p className='text-sideBarBtnText opacity-50 text-3xl font-normal'>Our Services</p>
                                </div>
                            </Link>

                        </div>
                    </div>
                </div>
                <div className='pb-[40px]'>
                    <div className='flex items-start'>
                        <div onClick={handleLogout} className='cursor-pointer flex flex-row justify-start items-center gap-7 w-full'>
                            <div className='w-[20px] h-20 flex justify-start items-center'>
                                <img className='w-10 h-8' src={logoutIcon} alt='log out'></img>
                            </div>
                            <p className='text-sideBarBtnText text-3xl font-normal'>Logout</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar