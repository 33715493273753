import React, { useState } from 'react'

import { Header } from '../../../layouts/header'
import Sidebar from '../../../layouts/sidebar'
import Navbar from '../../../layouts/navbar'

import useSidebar from '../../../hooks/useSidebar'
import sideBarData from './utils/sideBarContent'

import CreateCampaign from './createCampaign/index'

const radioOptions = [
  { key: 'Create Campaign', value: 'create' },
  { key: 'View Campaign', value: 'view' },
  { key: 'Edit Campaign', value: 'edit' }
]

export default function Index() {

  const { isSidebarOpen, toggleSidebar } = useSidebar();
  const [actionSelected, setactionSelected] = useState()
  console.log('radio', actionSelected)

  const handleActionChange = async (e) => {
    setactionSelected(e.target.value)
  }


  return (
    <div className='layout-wrapper'>
            <div className='top-header w-full z-10 bg-#FFFFFF'>
                <Header toggleSidebar={toggleSidebar} />
            </div>
            <div className='content-wrapper flex flex-row'>
                <div className={`sidebar top-0 bg-white ${isSidebarOpen ? 'open' : ''}`}>
                    <Sidebar details={sideBarData} />
                </div>
                <div className='w-full bg-bgLightBlue page-content'>
                    <div className="px-[30px]">
                        <div className='h-10 flex items-center'>
                            <Navbar />
                        </div>
                        <div className="profile-wrapper">
                            {/* <Profile /> */}

                            <div className='radio-btn-wrapper flex items-center bg-bgLightBlue py-[15px] pl-10 rounded-tl-[12px] rounded-[12px] border border-bgLightBlue'>
                              <div>
                                <h5 className='text-couponsRadioHeading font-medium w-48 text-font20'>Action: </h5>
                              </div>

                              {
                                radioOptions.map((option, index) => {
                                  return (
                                    <div key={index} className='flex items-center gap-5 text-couponsRadioOPtions font-semibold text-3xl mr-6'>
                                      <input type='radio' name='actions' value={option.value} className='w-[20px] h-[20px] radio active' onChange={handleActionChange}></input>
                                      <label htmlFor='actions'>{option.key}</label>
                                    </div>
                                  )
                                })
                              }
                            </div>

                            {actionSelected === 'create' &&  <CreateCampaign />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}
