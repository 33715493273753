import React from 'react';
import useSidebar from '../../hooks/useSidebar';
import { Header } from '../../layouts/header';
import Sidebar from '../../layouts/sidebar';
import sidebarContents from './components/sidebarContents';
import HomeContent from './components/homeContent';

function Index() {
    const { isSidebarOpen, toggleSidebar } = useSidebar();

    return (
        <div className='layout-wrapper'>
            <div className='top-header w-full z-10 bg-#FFFFFF'>
                <Header toggleSidebar={toggleSidebar} />
            </div>
            <div className='content-wrapper flex flex-row'>
                <div className={`sidebar top-0 bg-white ${isSidebarOpen ? 'open' : ''}`}>
                    <Sidebar details={sidebarContents} />
                </div>
                <div className='w-full bg-bgLightBlue page-content'>
                    <div className='p-[30px]'>
                        <HomeContent />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Index