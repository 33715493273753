import { React, useState } from 'react';
import { Header } from '../../layouts/header';
import Sidebar from '../../layouts/sidebar';
import sideBarData from './components/sidebardata';
import Navbar from '../../layouts/navbar';
import AdminAddUser from './components/adminAddUser';
import ClientAddUser from './components/clientAddUser';
import useSidebar from '../../hooks/useSidebar';

function Index() {

    const { isSidebarOpen, toggleSidebar } = useSidebar();
    const [userType, setuserType] = useState();
    const handleUserType = async (e) => {
        setuserType(e.target.value)
    }

    return (
        <div className='layout-wrapper'>
            <div className='top-header w-full z-10 bg-#FFFFFF'>
                <Header toggleSidebar={toggleSidebar} />
            </div>
            <div className='content-wrapper flex flex-row'>
                <div className={`sidebar top-0 bg-white ${isSidebarOpen ? 'open' : ''}`}>
                    <Sidebar details={sideBarData} />
                </div>
                <div className='w-full bg-bgLightBlue page-content'>
                    <div className="px-[30px]">
                        <div className='h-10 flex items-center'>
                            <Navbar />
                        </div>
                        <div className='radio-btn-wrapper flex items-center bg-bgLightBlue py-[15px] pl-10 rounded-tl-[12px] rounded-tr-[12px] border border-b-0 border-bgLightBlue'>
                            <div>
                                <h5 className='text-couponsRadioHeading font-medium w-48 text-font20'>Filters: </h5>
                            </div>
                            <div className='flex items-center gap-5 text-couponsRadioOPtions font-semibold text-3xl mr-[20px]'>
                                <input type='radio' name='addUser' value='admin' className='w-[20px] h-[20px] radio active' onChange={handleUserType}></input>
                                <label htmlFor='addUsers'>Admin</label>
                            </div>
                            <div className='flex items-center gap-5 text-couponsRadioOPtions font-semibold text-3xl mr-[20px]'>
                                <input type='radio' name='addUser' value='client' className='w-[20px] h-[20px] radio active' onChange={handleUserType}></input>
                                <label htmlFor='addUsers'>Client</label>
                            </div>
                        </div>
                        {userType === 'admin' &&
                            <div className='mt-2'>
                                <AdminAddUser />
                            </div>
                        }
                        {userType === 'client' &&
                            <div className=''>
                                <ClientAddUser />
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>)
}

export default Index;