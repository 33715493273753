import React from 'react'
import SideBar from '../../../../layouts/sidebar'  
import sideBarData from './sideBarContent'
function sideBarComponent(props) {
  return (
    <div>
        <SideBar details={sideBarData}/>
    </div>
  )
}

export default sideBarComponent