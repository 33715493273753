import React from 'react';

const Dashboard = () => {
    return (
        <div className='w-full h-full'>
            <iframe className='w-full h-full rounded-[12px] p-5 bg-white' src={"https://app.powerbi.com/reportEmbed?reportId=f3dac276-d301-4dc4-90e8-cf057884669e&autoAuth=true&ctid=7da38a6f-e425-4b8e-88b3-28a78290ffe5"} frameborder="0"></iframe>
        </div>
    );
}

export default Dashboard;

{/* <iframe title="Admin Dashboard" width="1140" height="541.25"  frameborder="0" allowFullScreen="true"></iframe> */}