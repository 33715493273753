import React from 'react'
import { Field, ErrorMessage } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import TextError from './textError';
function contactNumber(props) {
    const { label, name, width, placeholder, bgcolor, textcolor, onChange, height, customerror, ...rest } = props
    return (
        <div className=''>
            <div className={`md:flex justify-between items-center w-full  ${height ? `h-${height}` : ''}`}>
                {label &&
                    <label htmlFor={name} className={`min-w-[45%] ${textcolor ? `text-${textcolor}` : 'text-popupText'} font-normal text-font16 w-[45%]`}>
                        {label}
                    </label>
                }
                <div className='w-full flex flex-wrap' >
                    <Field name={name} className={`w-${width}`} >
                        {
                            ({ form, field }) => {
                                const { setFieldValue } = form
                                const { value } = field
                                return (
                                    <PhoneInput
                                        // style={{ background: 'blue' }}
                                        // placeholder= "Enter phone number"
                                        // className={`bg-${bgcolor}`}
                                        name={name}
                                        className="w-[100%] number-wrapper font16"
                                        inputProps={{
                                            style: { background: 'rgba(24, 42, 180, 0.03)' }, // Set the desired color using inline styles
                                            placeholder: placeholder || "Enter phone number",
                                            width: '100%'
                                        }}
                                        {...field}{...rest}
                                        selected={value}
                                        onChange={val => {
                                            setFieldValue(name, val)
                                            onChange && onChange(val);
                                        }}
                                    />
                                )
                            }
                        }
                    </Field>
                    {
                        customerror? <TextError>{customerror}</TextError>
                            : <ErrorMessage name={name} component={TextError} />
                    }
                </div>
            </div>
        </div>
    )
}

export default contactNumber