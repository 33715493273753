import React from 'react'
import un_authorized from '../assets/svg/un_authorized.svg'

function Unauthorized() {
    return (
        <div className='w-[100vw] h-[100vh] flex flex-col justify-center items-center'>
            <div className='mx-5'>
                <div className='flex justify-start'>
                    <img src={un_authorized} alt="" className='h-[15rem]' />
                </div>
                <h1 className='w-full'>HOLD UP!</h1>
                <p className='w-full text-3xl'>You are not authorized to access.</p>
                <div className='w-full my-10'>
                    <button className='p-4 px-8 border-2 border-[#50AFF8] text-[#50AFF8] rounded-full text-3xl '>
                        Go Back Home
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Unauthorized