import React from 'react'

import Select from '../../../../../../components/form2/Select'
import Input from '../../../../../../components/form2/Input'

import { otpOptions } from './utils/options'

export default function OtpDetails({ formik }) {
    return (
        <div className='mx-16 -mb-4'>
            <p className='text-[#182AB4] text-5xl mb-10 font-semibold'>Otp Service Details</p>
            <div className='flex gap-12'>
                <div className='w-1/2'>
                    <Select 
                        label='Otp Service *'
                        name='isOtpServiceEnabled'
                        formik={formik}
                        marginClass='mb-2'
                        options={otpOptions}
                    />

                    <Input 
                        placeholder=''
                        label='Email Domain *'
                        name='emailDomain'
                        formik={formik}
                        marginClass='mb-2'
                    />
                </div>
                <div className='w-1/2'>
                    <Input 
                        placeholder=''
                        label='Reply To Mail Id *'
                        name='replyToMailId'
                        formik={formik}
                        marginClass='mb-2'
                    />
                    
                    <Input 
                        placeholder=''
                        label='From Mail Id *'
                        name='senderMailId'
                        formik={formik}
                        marginClass='mb-2'
                    />
                </div>
            </div>
        </div>
    )
}


/**
 * [11:22] Ranganadh Yadlapalli
otpServices
[11:23] Ranganadh Yadlapalli
isOtpServiceEnabled
emailDomain
replyToMailId
senderMailId
 */