import profile from '../../../../assets/svg/sidebar_icons/profile_icon.svg'
import Dashboard from '../../../../assets/svg/sidebar_icons/dashboard_icon.svg'
import couponsImage from '../../../../assets/svg/sidebar_2_icons/coupons_icon.svg'
import dataImage from '../../../../assets/svg/sidebar_icons/data_icon.svg';
import viewRequests from '../../../../assets/svg/sidebar_icons/requests_icon.svg';

const sideBarData = [
    {
        name: "view-client-profile",
        img: profile,
        desc: "Profile"
    },
    {
        name:"dashboard",
        img:Dashboard,
        desc:"Dashboard"
    },
    {
        name:"data-summary",
        img: dataImage,
        desc:"Data Summary"
    },
    {
        name: "coupons",
        img: couponsImage,
        desc: "Coupons"
    },
    {
        name: "view-requests",
        img: viewRequests,
        desc: "View Requests"
    }
]

export default sideBarData