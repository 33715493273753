import { React, useState } from 'react'

import { useFormik } from 'formik'

import Input from '../../../../components/form2/Input'
import MultipleSelect from '../../../../components/form2/MultipleSelect'
import Select from '../../../../components/form2/Select'
import Type1CouponsForm from './components/Type1CouponsForm'

function Index() {

  // const [typeSelected, settypeselected] = useState();

  const formik = useFormik({
    initialValues: {
      campaignName: '',
      campaignType: '',
    },
    onSubmit: values => {
      console.log(values)
    },
  })

  return (
    <form className=''>
      <p className='text-[#182AB4] font-bold text-4xl my-8'>Campaign Details</p>

      <div className='p-[2rem] bg-white rounded-[12px] '>

        <div className='flex gap-10'>
          <div className='w-1/2 flex justify-center items-center'>
            <label className='text-3xl w-[40%] pb-[2.5rem]'>Client ID: </label>
            <Input name='campaignName' formik={formik} placeholder='' marginClass='mb-6' />
          </div>

          <div className='w-1/2 flex justify-center items-center'>
            <label className='text-3xl w-[40%] pb-[2.5rem]'>Client Name: </label>
            <Input name='campaignName' formik={formik} placeholder='' marginClass='mb-6' />
          </div>
        </div>
        
        <div className='flex gap-10'>
          <div className='w-1/2 flex justify-center items-center'>
            <label className='text-3xl w-[40%] pb-[2.5rem]'>Duration: </label>
            <div className='w-full flex gap-5'>
              <div className="w-1/2">
                <Input name='campaignName' formik={formik} type='date' marginClass='mb-6' />
              </div>
              <div className="w-1/2">
                <Input name='campaignName' formik={formik} type='date' marginClass='mb-6' />
              </div>
            </div>
          </div>

          <div className='w-1/2 flex justify-center items-center'>
            <label className='text-3xl w-[40%] pb-[2.5rem]'>Campaign Name: </label>
            <Input name='campaignName' formik={formik} placeholder='' marginClass='mb-6' />
          </div>
        </div>

        <div className='flex gap-10'>
          <div className='w-1/2 flex justify-center items-center'>
            <label className='text-3xl w-[40%] pb-[2.5rem]'>Client ID: </label>
            <MultipleSelect name='campaignName' formik={formik} placeholder='' marginClass='mb-6' options={[{key: 'option', value: 'option'}]} />
          </div>

          <div className='w-1/2 flex justify-center items-center'>
            <label className='text-3xl w-[40%] pb-[2.5rem]'>Client Name: </label>
            <Input name='campaignName' formik={formik} placeholder='' marginClass='mb-6' />
          </div>
        </div>

      </div>
    </form>
  )
}

export default Index