import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import FormikControl from '../../../components/form/formikControl';
import { SubmitButtonWide } from '../../../components/ui/buttons/submitButton';

import PopUpManager from '../../../components/popups2/popupManager';
import Loader from "../../../components/ui/loader/Loader";

import useAuth from '../../../hooks/useAuth';
import { loginService } from "../services/api";
import validationSchema from '../validations/validationSchema';

function LoginForm() {

    const { setAuth } = useAuth();
    const navigate = useNavigate();


    const [loading, setLoading] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [textPopupDisabled, setTextPopupDisabled] = useState(true);
    
    const initialValues = {
        "login": "",
        "password": ""
    }

    const handleSubmit = async (values) => {
        try {
            setLoading(true)
            const response = await loginService(values.login, values.password);
            
            const accessToken = response?.accessToken;
            const roles = response?.roles;
            const userId = response?.userId;
            setAuth({ accessToken, roles, userId })
            navigate("/home")
        } catch (error) {
            setAlertText(error.message)
            setTextPopupDisabled(false);
        } finally {
            setLoading(false)
        }
    }

    const handleForgotPass = () => {
        navigate(`/forgot-password`)
    }

    return (
        <div className='w-[100%]'>
            {loading && <Loader />}
            <PopUpManager 
                disable={textPopupDisabled}
                control={"text"}
                heading={"Alert"}
                description={alertText}
                onClose={() => setTextPopupDisabled(true)}
            />

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={true}
            >
                {
                    formik => {
                        return (
                            <Form>
                                <div className='flex flex-col gap-10 items-center md:items-start'>
                                    <div className='w-full md:h-[25%] text-center md:text-left' >
                                        <h3 className='text-[#000] text-[30px] sm:text-[40px] font-bold'>Login</h3>
                                    </div>
                                    <div className='flex flex-col md:h-[75%] items-center md:items-start w-full'>
                                        <div className='w-[80%] md:w-[100%] lg:w-[80%] flex flex-col pb-6 mb-[18px]'>
                                            <h6 className='text-popupText font-medium mb-[9px]'>Email / User ID</h6>
                                            <FormikControl className="" control='input' type='text' label='' name={'login'} width='full' bgcolor='selectorBg' placeholder={""} />
                                        </div>
                                        <div className='w-[80%] md:w-[100%] lg:w-[80%] pb-[35px]'>
                                            <h6 className='text-popupText font-medium mb-[9px]'>Password</h6>
                                            <FormikControl control='input' type='password' label='' name={'password'} width='full' bgcolor='selectorBg' placeholder={""} />
                                            <span className='text-forgotPassword text-[13px] flex justify-end items-center leading-[50px]'><span className='cursor-pointer' onClick={handleForgotPass}>Forgot Password?</span></span>
                                        </div>
                                        <div className='w-[80%] md:w-[100%] lg:w-[80%] md:h-[40%] '>
                                            <SubmitButtonWide value='Get Started' type='submit' />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                }
            </Formik>
        </div>
    )
}

export default LoginForm