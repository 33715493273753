import React from 'react'
import {Field,ErrorMessage} from 'formik'
import TextError from './textError'
function selector(props) {
  const { label, name, options, bgcolor, width, height, radius, padX, padY, ...rest} = props

  return (
    <div className='mb-8'>
      <div className='md:flex h-full justify-between items-center w-[100%]'>
          {label && <label htmlFor={name} className='text-sideBarBtnText text-font16 font-medium min-w-[40%]'>
              {label}
          </label>}
          <Field {...rest} as='select' name={name} className={`${width? `w-${width}` : `${label? 'w-[60%]' : 'w-[100%]'}`} h-${height || '[46px]'}  bg-${bgcolor} rounded-${radius} px-${padX} py-${padY} text-sideBarBtnText text-font16 cursor-pointer`} id="dropdown" value={props.value} >
          {options && options.length && options.map(option => {
          return(
            <option className='px-2' key={option.value} value={option.value} hidden={option.value === ''?true:false}>
              {option.key}
            </option>
          )
          })}
        </Field>
      </div>
      <div className='w-full relative'>
        <div className={`absolute ${label? 'ml-[40%]' : ''}`}>
          <ErrorMessage name={name} component={TextError}/>
        </div>
      </div>
    </div>
  )
}

export default selector