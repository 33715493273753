import * as Yup from 'yup';

const manValidationSchema = Yup.object({
    skuId: Yup.string().required('This Field is Required'),
    Pimage: Yup.mixed(),
    QROnprod: Yup.mixed(),
    preSaleQRLocation: Yup.mixed(),
    prodName: Yup.string().required('This Field is Required'),
    manuLicenseNo: Yup.string(),
    manuAdd: Yup.string(),
    addDetails: Yup.string(),
    additionalImageDetails: Yup.string(),
    extraImage1: Yup.string(),
    extraImage2: Yup.string(),
    extraVideo1: Yup.string(),
    manuWebsiteLink: Yup.string().url('Please provide a valid url. example: `https://example.com`')
})

export default manValidationSchema;
