import { handleError } from "../../../utils/handles/errorHandle";

async function getAllAdminsPaginated(pageNo,pageSize,sortBy,sortOrder,axiosPrivate){
    try{
        const response = await axiosPrivate.get("/api/admin/users/paginated",{ params:{ pageNo, pageSize, sortBy, sortOrder }});
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function getAllMasterClientsPaginated (pageNo,pageSize,sortBy,sortOrder,axiosPrivate){
    try{
        const response = await axiosPrivate.get("/api/client/masterclients/paginated",{ params:{ pageNo, pageSize, sortBy, sortOrder }});
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function getAllSubClientsPaginated(pageNo,pageSize,sortBy,sortOrder,axiosPrivate){
    try{
        const response = await axiosPrivate.get("/api/client/all-subclients/paginated", { params:{ pageNo, pageSize, sortBy, sortOrder }});
        
        console.log(response.data, "subs")
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function getAdminSuggestions(queryString, axiosPrivate) {
    try{
        const response = await axiosPrivate.get("/api/admin/userid-suggestions",{ params:{ queryString } });
        return response?.data;
    }catch(error){  
        handleError(error);
    }
}

async function getMasterClientSuggestions(queryString, axiosPrivate) {
    try{
        const response = await axiosPrivate.get("/api/client/masterclient/userid-suggestions",{ params:{ queryString } });
        return response?.data;
    }catch(error){  
        handleError(error);
    }
}

async function getSubClientSuggestions(queryString, axiosPrivate) {
    try{
        const response = await axiosPrivate.get("/api/client/sub-client/userid-suggestions",{ params:{ queryString } });
        return response?.data;
    }catch(error){  
        handleError(error);
    }
}

async function getAdminsByQueryString(queryString,sortBy,sortOrder,pageNo,pageSize,axiosPrivate){
    try{
        const response = await axiosPrivate.get("/api/admin/user-info", { params:{ queryString, sortBy, sortOrder, pageNo, pageSize }});
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function getMasterClientsByQueryString(queryString,sortBy,sortOrder,pageNo,pageSize,axiosPrivate){
    try{
        const response = await axiosPrivate.get("/api/client/masterclient/user-info", { params:{ queryString, sortBy, sortOrder, pageNo, pageSize }});
        
        console.log(response);
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function getSubClientsByQueryString(queryString,sortBy,sortOrder,pageNo,pageSize,axiosPrivate){
    try{
        const response = await axiosPrivate.get("/api/client/sub-client/user-info", { params:{ queryString, sortBy, sortOrder, pageNo, pageSize }});
        return response?.data;
    }catch(error){
        handleError(error);
    }
}


export{
    getAllAdminsPaginated,
    getAllMasterClientsPaginated,
    getAllSubClientsPaginated,
    getAdminSuggestions,
    getMasterClientSuggestions,
    getSubClientSuggestions,
    getAdminsByQueryString,
    getMasterClientsByQueryString,
    getSubClientsByQueryString
}