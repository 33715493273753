import React from 'react';

import { Header } from "../../../../layouts/header"
import Sidebar from '../../../../layouts/sidebar'
import Navbar from '../../../../layouts/navbar'
import useSidebar from '../../../../hooks/useSidebar';
import sideBarData from "./components/sidebardata";
import ViewRequests from './components/ViewRequests';

const Index = () => {
    const { isSidebarOpen, toggleSidebar } = useSidebar();

    return (
        <div className='layout-wrapper'>
            <div className='top-header w-full z-10 bg-#FFFFFF'>
                <Header toggleSidebar={toggleSidebar} />
            </div>
            <div className='content-wrapper flex flex-row'>
                <div className={`sidebar top-0 bg-white ${isSidebarOpen ? 'open' : ''}`}>
                    <Sidebar details={sideBarData} />
                </div>
                <div className='w-full bg-bgLightBlue page-content'>
                    <div className="px-[30px]">
                        <div className='h-10 flex items-center'>
                            <Navbar />
                        </div>
                        <div className="profile-wrapper">
                            <ViewRequests />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index;
