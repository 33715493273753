import profile from '../../../../assets/svg/sidebar_icons/profile_icon.svg'
import Dashboard from '../../../../assets/svg/sidebar_icons/dashboard_icon.svg'


const sideBarData = (customerId) => {
    return [
        {
            name:`purchases-and-warranty/${customerId}`,
            img:profile,
            desc:"Profile"
        },
    ]
} 

export default sideBarData