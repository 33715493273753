import manage from '../../../assets/svg/sidebar_icons/profile_icon.svg'
import miscInvoice from '../../../assets/svg/sidebar_icons/misc_icon.svg'
import dashboard from '../../../assets/svg/sidebar_icons/dashboard_icon.svg'

const sideBarData = [
    {
        name:"finance",
        img:manage,
        desc:"Manage"
    },
    {
        name:"misc-invoice",
        img:miscInvoice,
        desc:"Misc Invoice"
    },
    {
        name:"finance-dashboard",
        img:dashboard,
        desc:"Dashboard"
    },
]

export default sideBarData