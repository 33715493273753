import React, { useEffect, useRef, useState } from 'react';
import Info from './components/Info';

const NAO = 'Not An Option';
const MultipleSelect = ({
    name, 
    formik,
    marginClass='mx-auto',
    tailwindClasses='',  
    label,
    message='',
    options=[],
    }) => {
    
    const optionsList = useRef([{key: 'Please select an option', value: NAO}, ...options]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const updateSelectedOptions = (options) => {
        setSelectedOptions(options);
        formik.setFieldValue(name, options);
    }

    useEffect(() => {
        setSelectedOptions(formik.values[`${name}`] || '');
    }, [formik.values[`${name}`]]);

    const handleOptionSelection = (clickedOption) => {
        if(clickedOption === NAO) return;
        if(selectedOptions.includes(clickedOption))
            updateSelectedOptions(selectedOptions.filter(option => option !== clickedOption));
        else 
            updateSelectedOptions([...selectedOptions, clickedOption]);
    }

    return (
        <div 
            className={`w-full flex flex-col ${marginClass}`}
        >
            {
                label && <div className='flex pb-2 items-center'>
                    <label htmlFor={name} className='text-3xl mr-2'>{label}</label>
                    {message && <Info message={message} />}
                </div>
            }
            <select 
                id={name}
                name={name}
                value={NAO}
                onBlur={formik?.handleBlur}
                onChange={e => handleOptionSelection(e.target.value)}
                className={`${tailwindClasses} bg-[#182AB408] border p-[.65rem] rounded-md text-3xl h-[46px]`}
            >
                {
                    optionsList.current && optionsList.current.map((option, ind) => {
                        return <option className={`${selectedOptions.includes(option?.value) && 'bg-blue-500'}`} key={ind} value={option?.value} >{option?.key}</option>
                    })
                }
            </select>
            <div className='flex mt-[.1rem]'>
                {
                    selectedOptions && Array.isArray(selectedOptions) && selectedOptions.map((option, ind) => {
                        return <div key={ind} className='mr-1 border border-black px-2 rounded-xl flex items-center justify-center'>
                            <p className='text-xl mr-1'>{option}</p>
                            <button type='button' onClick={() => updateSelectedOptions(selectedOptions.filter(opt => opt !== option))}><p className='text-xs aspect-square pl-1'>x</p></button>
                        </div>
                    })
                }
            </div>
            { formik?.touched[`${name}`] && formik?.errors[`${name}`] && <p className='text-red-500 text-2xl'>{formik?.touched[`${name}`] && formik?.errors[`${name}`]}&nbsp;</p> }
            
        </div>
    );
}

export default MultipleSelect;
