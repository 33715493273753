import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';

import Loader from '../../../../components/ui/loader/Loader';
import PopUpManager from '../../../../components/popups2/popupManager';
import FormikControl from '../../../../components/form/formikControl';
import { SubmitButtonWide } from '../../../../components/ui/buttons/submitButton';

import { viewMasterClient } from '../services/api';

const SearchClient = ({ setClientData }) => {

    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [textPopupDisabled, setTextPopupDisabled] = useState(true);

    const initialValues = {
        clientId: ''
    }

    const validationSchema = Yup.object({
        clientId: Yup.string().required()
    })

    const handleSubmit = async (values) => {
        const loadClientData = async (clientId) => {
            try {
                setLoading(true);
                const data = await viewMasterClient(clientId, axiosPrivate);

                setClientData && setClientData(data);
            } catch (error) {
                setAlertText(error.message || 'Unable to fetch client Details');
                setTextPopupDisabled(false);
            } finally {
                setLoading(false);
            }
        }
        await loadClientData(values.clientId);
    }

    return (
        <>
            { loading && <Loader /> }
            <PopUpManager 
                disable={textPopupDisabled}
                control={"text"}
                heading={"Alert"}
                description={alertText}
                onClose={() => setTextPopupDisabled(true)}
            />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {
                    formik => (
                        <Form>
                            <div className='bg-white rounded-[12px] p-10 mb-20'>
                                <div className='flex h-10 items-center '>
                                    <p className='font-normal text-font16 text-popupText mr-10'>Client Id: </p>
                                    <div className='w-[50%]'>
                                        <FormikControl 
                                            control='input' 
                                            name='clientId'
                                            bgcolor='selectorBg'
                                            formSetter={formik.setFieldValue}
                                            onInputChange={() => setClientData(null)}
                                        />
                                    </div>
                                </div>

                                <div className='w-[20%] min-w-[15rem]'>
                                    <SubmitButtonWide 
                                        value='Search'
                                        type='submit'
                                    />
                                </div>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </>
    );
}

export default SearchClient;
