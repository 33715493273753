import { React, useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';

import FormikControl from '../../../../components/form/formikControl';
import { FormHeadingComponent, FormSubHeadingComponent } from '../../components/formHeadingComponent';
import { SubmitButtonWide } from '../../../../components/ui/buttons/submitButton';

import manuIntialValues from '../utils/manuInitialvalues';
import numberToMonthName from '../utils/numberToMonth';

import { warrantyDropDown } from "../../../../utils/QR/batchDropdowns";
import {
    calculateExpiryDateChange,
    calculateShelfLifeChange,
} from "../../../../utils/QR/handleDateChanges"

import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { getSkuIdsByBatchType, getSkuData } from '../../../skuDetails/services/api';
import {updateBatchDetails} from "../../services/api"
import monthNameToNumber from '../../../generateQrCode/generateQRCode/utils/monthNameToNumber';

import { URL_TYPES } from '../../../../config/constants';
import Loader from '../../../../components/ui/loader/Loader';
import PopUpManager from '../../../../components/popups2/popupManager';
import manuValidationSchema from '../validations/manuValidations';

function ManuBatchEdit(props) {
    
    const { clientid, batchno, rawBatchData} = props;
    const axiosPrivate = useAxiosPrivate(URL_TYPES.QR);

    const navigate = useNavigate();

    const [clickedProductDetails, setclickedProductDetails] = useState(true)
    const [clickedManufacturingDetails, setclickedManufacturingDetails] = useState(true)

    const [clickedProductDetailsSub, setclickedProductDetailsSub] = useState(true)
    const [clickedManufacturingsub, setclickedManufacturingsub] = useState(true)

    const [clickedSkuDetails, setclickedSkuDetails] = useState(true)
    const [clickedSkuDetailsSub, setclickedSkuDetailsSub] = useState(true)

    const [initialData, setInitialData] = useState({});
    const [batchData, setBatchData] = useState([]);
    const [skuIds, setSkuIds] = useState([]);
    const [slectedSku_Id, setSelectedSku_Id] = useState('')

    const [shelfLife, setShelfLife] = useState('');
    const [mfgDate, setMfgDate] = useState('Unset');
    const [mfgNotAvailable, setMfgNotAvailable] = useState(false);
    const [mfDateNotSelected, setMfgDateNotSelected] = useState(true);

    const [expDate, setExpDate] = useState('Unset');
    const [expNotAvailable, setExpNotAvailable] = useState(false);
    const [expNotApplicable, setExpNotApplicable] = useState(false);

    const [mfgDateErr, setMfgDateErr] = useState('');
    const [expDateErr, setExpDateErr] = useState('');
    const [shelfErr, setShelfErr] = useState('');

    const [loading, setLoading] = useState(false);
    const [oneButtonPopupnDisbled, setOneButtonPopupDisabled] = useState(true);
    const [alertText, setAlertText] = useState("");
    const [textPopupDisabled, setTextPopupDisabled] = useState(true);

    const handleClickSkuDetailsSub = (event) => {
        setclickedSkuDetailsSub(!event)
    }
    const handleClickSkudetails = (event) => {
        setclickedSkuDetails(!event)
    }
    const handleClickProductDetails = (event) => {
        setclickedProductDetails(!event)
    }
    const handleClickManufacturingDetails = (event) => {
        setclickedManufacturingDetails(!event)
    }
    const handleClickProductDetailsSub = (event) => {
        setclickedProductDetailsSub(!event)
    }
    const handleClickManufacturingsub = (event) => {
        setclickedManufacturingsub(!event)
    }

    const handleSubmit = async (values) => {
        if(handleMfgExpErrsOnSubmit()) return;

        let batchData = Object.assign({}, values);

        batchData.clientId = clientid;
        batchData.batchNo = batchno;
        batchData.uploadType  = "EDIT_BATCH";
        batchData.type = rawBatchData.type;
        
        if(values.mfgDate !== 'notApplicable' && values.mfgDate !== 'notAvailable' && values.mfgDate != 'Unset') {
            if(values.shelfLife && values.expiryDate === 'notAvailable') {
                const mfgDate = new Date(`${values.mfgDate?.year} ${values.mfgDate?.month} ${values.mfgDate.day && values.mfgDate.day !== '' && values.mfgDate.day !== 'DD' ? values.mfgDate.day : '01'}`);
                const expiryDate = await  calculateShelfLifeChange({
                    shelfLife: values.shelfLife, mfgDate
                });

                values.expiryDate = {
                    year: expiryDate.getFullYear(), 
                    month: expiryDate.toLocaleString('default', { month: 'long' }), 
                    day: expiryDate.getDate()
                };
            }

            batchData.mfgDate = `${values.mfgDate.year}-${monthNameToNumber(values.mfgDate.month)}-${values.mfgDate.day && values.mfgDate.day !== '' && values.mfgDate.day !== 'DD' ? values.mfgDate.day : '01'}`;
        }

        if(values.expiryDate !== 'notApplicable' && values.expiryDate !== 'notAvailable' && values.mfgDate != 'Unset') {
            const lastDay = new Date(values.expiryDate.year, monthNameToNumber(values.expiryDate.month), 0);
            batchData.expiryDate = `${values.expiryDate.year}-${monthNameToNumber(values.expiryDate.month)}-${values.expiryDate.day && values.expiryDate.day !== '' && values.expiryDate.day !== 'DD' ? values.expiryDate.day: lastDay.getDate()}`;
        }
        
        if (batchData.type === 1 || batchData.warrantyApp === "false" || !batchData.warrantyApp) {
            batchData.warrantyApp = false;
            batchData.warrantyPeriod = 0;
        } else {
            batchData.warrantyApp = true;
        }

        if (batchData.warrantyApp === true && !batchData.warrantyPeriod) {
            setAlertText("Please enter the warranty period");
            setTextPopupDisabled(false);
            return;
        }

        if (rawBatchData.type !== 3) {
            batchData.QROnprod = '';
            batchData.preSaleQRLocation = '';
        }

        batchData = Object.keys(batchData).reduce((result, key) => {
            if (batchData[key] !== initialData[key]) 
                result[key] = batchData[key];
            
            return result
        }, {})
        if(slectedSku_Id) batchData.skuId = slectedSku_Id;
        
        let formData = new FormData();
        for (var key in batchData) {
            if(key !== '_id' && key !== 'skuId')
                formData.append(key, batchData[key]);
        }
        formData.append("clientId",clientid);
        formData.append("batchNo" ,batchno);
        formData.append("uploadType","EDIT_BATCH");
        formData.append("skuId", slectedSku_Id);
        // entries print
        for (const entry of formData.entries()) {
            console.log("updated", entry[0], entry[1]);
        }
        
        try {
            setLoading(true);
            await updateBatchDetails(clientid,formData, axiosPrivate);

            setOneButtonPopupDisabled(false);
        } catch (error) {
            setAlertText(error.message || "Unable to update batch");
            setTextPopupDisabled(false);
        } finally {
            setLoading(false);
        }
    }

    let formikSetter;
    useEffect(() => {
        if(expNotApplicable) {
            setShelfLife(0);
            if(formikSetter)
                formikSetter('shelfLife', 0);
        }

        //eslint-disable-next-line
    }, [expNotApplicable])

    useEffect(() => {
        if(mfgNotAvailable) { setMfgDateErr('');}
        else if(!mfgDate) { setMfgDateErr('Please provide a valid Date');}

        if(expNotAvailable || expNotApplicable) { setExpDateErr(''); }
        else if(!expDate) { setExpDateErr('Please provide a valid Date');}

        if(!expNotApplicable) {
            if(shelfLife < 0) setShelfErr('Shelf life can not be negative')
            else if(!shelfLife || shelfLife === '') setShelfErr('This field is Required!');
            else setShelfErr('')
        } else 
            setShelfErr('');
        
        //eslint-disable-next-line
    }, [mfgNotAvailable, expNotAvailable, expNotApplicable])

    let shelErrStart = useRef(false);
    useEffect(() => {
        if((shelfLife || shelfLife !== '')) shelErrStart.current = true; 
        if(!shelErrStart.current) return;

        // if(mfgNotAvailable && expNotAvailable && (!shelfLife || shelfLife === '')) 
        if(!expNotApplicable && (!shelfLife || shelfLife === '')) 
            setShelfErr('This field is Required!');
        else if(shelfLife < 0)
            setShelfErr('Shelf life can not be negative')
        else
            setShelfErr('')

        //eslint-disable-next-line
    }, [shelfLife])

    const handleMfgExpErrsOnSubmit = () => {
        let err = false;
        if(mfgDateErr || expDateErr || shelfErr) err = true;
        if(!mfgNotAvailable && (!mfgDate || mfgDate === 'Unset')) {err = true; setMfgDateErr('Please Provide a Valid Date');}
        if((!expNotAvailable && !expNotApplicable) && (!expDate || expDate === 'Unset')) {err = true; setExpDateErr('Please Provide a Valid Date');}
        if(!expNotApplicable && (!shelfLife || shelfLife === '')) {err = true; setShelfErr('Please provide a valid Shelf life time');}

        return err;
    }

    const handleManufacturingDateChange = async (formik, value) => {
        if(value.day) setMfgDateNotSelected(false)
        else setMfgDateNotSelected(true)

        if(!value.year || value.year === 'YYYY' || !value.month || value.month === 'MM') {
            setMfgDateErr('Please Provide a Valid Date');
            setMfgDate('');
            return;
        }
        else setMfgDateErr('');

        const mfgData = value;
        if(value.day === 'DD') mfgData.day = '01';
        const mfgDate = new Date(`${mfgData.year} ${mfgData.month} ${mfgData.day || '01'}`);
        setMfgDate(mfgDate)

        const shelfLife = Number(formik.values.shelfLife) || 0;
        if(formik.values.expiryDate === 'notApplicable' || formik.values.expiryDate === 'notAvailable') return;

        const expiryDate = await  calculateShelfLifeChange({
            shelfLife: shelfLife, mfgDate
        })
        if(expiryDate) setExpDateErr('');
        
        setExpDate(expiryDate);
        if (expiryDate)
            formik.setFieldValue('expiryDate', {
                year: expiryDate.getFullYear(), 
                month: expiryDate.toLocaleString('default', { month: 'long' }), 
                day: value.day? expiryDate.getDate() : 'DD'
            });
        else
            formik.setFieldValue('expiryDate', '');
    }

    const handleExpiryDateChange = async (formik, value) => {
        if(!value.year || value.year === 'YYYY' || !value.month || value.month === 'MM') {
            setExpDateErr('Please Provide a Valid Date');
            setExpDate('');
            return;
        }
        else setExpDateErr('');

        const mfgData = formik.values.mfgDate;
        const mfgDate = mfgData? new Date(`${mfgData.year} ${mfgData.month} ${mfgData.day || '01'}`) : new Date();
        const expData = value;
        if(value.day === 'DD') expData.day = '01';
        const expDate = new Date(`${expData.year} ${expData.month} ${expData.day || '01'}`);
        setExpDate(expDate)

        if(formik.values.mfgDate && formik.values.mfgDate !== 'notAvailable') 
        {
            const shelfLife = await calculateExpiryDateChange({
                mfgDate: mfgDate,
                expiryDate: expDate
            });
    
            if (shelfLife) {
                formik.setFieldValue('shelfLife', shelfLife);
                setShelfLife(shelfLife)
            }
            else 
                formik.setFieldValue('shelfLife', '');

        }
    }

    const handleShelfLifeChange = async (formik, value) => {

        for (let i = 0; i < value.length; i++) {
            let char = value[i];
            if(isNaN(char)){
                setShelfErr("Please Enter a valid shelfLife period");
                return;
            }
        }

        setShelfLife(value);
        await formik.setFieldValue('shelfLife', value);

        const mfgData = formik.values.mfgDate;
        const mfgDate = new Date(`${mfgData?.year} ${mfgData?.month} ${(!mfgData?.day || mfgData?.day === 'DD')? '01' : mfgData?.day }`);
        const expiryDate = await  calculateShelfLifeChange({
            shelfLife: value || 0, mfgDate
        })

        if(formik.values.mfgDate && formik.values.mfgDate !== 'notAvailable' && !expNotAvailable) {
            setExpDate(expiryDate)
            if(expDate) setExpDateErr('');
            
            if (expiryDate)
                formik.setFieldValue('expiryDate', {
                    year: expiryDate.getFullYear(), 
                    month: expiryDate.toLocaleString('default', { month: 'long' }), 
                    day: (mfDateNotSelected || mfgData.day === 'DD')? 'DD' : expiryDate.getDate()
                });
            else
                formik.setFieldValue('expiryDate', '');
        }

    }

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const data = await getSkuIdsByBatchType(clientid, rawBatchData.batchType, axiosPrivate);

                setSkuIds(data);
            } catch (error) {
                setAlertText(error.message || "Unable to load SkuIDs");
                setTextPopupDisabled(false);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const reqSkuData = async (formik, skuid) => {
        if (skuid) {
            try {
                setLoading(true);
                const data = await getSkuData(clientid, skuid, axiosPrivate);

                Object.keys(data[0]).forEach(key => {
                    formik.setFieldValue(key, data[0][key]);
                })
            } catch (error) {
                setAlertText(error.message || "Unable to load Sku data");
                setTextPopupDisabled(false);
            } finally {
                setLoading(false);
            }
        }
    }

    const [mfgNoDate, setMfgNoDate] = useState('');
    const [expNoDate, setexpNoDate] = useState('');

    useEffect(() => {
        const mfgDateParts = rawBatchData.mfgDate !== "notAvailable" ? rawBatchData.mfgDate.split('-'): null;
        const expDateParts = rawBatchData.expiryDate !== "notAvailable" && rawBatchData.expiryDate !== "notApplicable" ? rawBatchData.expiryDate.split('-'): null;
        
        const data = {
            ...manuIntialValues,
            ...rawBatchData,
            mfgDate: rawBatchData.mfgDate != "notAvailable" ? {year: mfgDateParts[0], month: numberToMonthName(mfgDateParts[1]), day: mfgDateParts[2] || 0} : undefined,
            expiryDate: rawBatchData.expiryDate != "notAvailable" && rawBatchData.expiryDate != 'notApplicable' ? {year: expDateParts[0], month: numberToMonthName(expDateParts[1]), day: expDateParts[2] || 0}  : undefined,
            warrantyApp: rawBatchData.warrantyApp.toString()
        }
        setShelfLife(rawBatchData.shelfLife)
        data.mfgDate && setMfgDate(data.mfgDate)
        data.expiryDate && setExpDate(data.expiryDate)

        if(rawBatchData.mfgDate == 'notAvailable') {
            setMfgNoDate('notAvailable');
            setMfgNotAvailable(true);
        }

        if(rawBatchData.expiryDate == 'notAvailable') {
            setexpNoDate('notAvailable')
            setExpNotAvailable(true)
        }
        else if(rawBatchData.expiryDate == 'notApplicable') {
            setexpNoDate('notApplicable')
            setExpNotApplicable(true)
        }
        
        setBatchData(data);
        setInitialData(data);
        setSelectedSku_Id(data.skuId);
    }, [rawBatchData]);

    return (
        <>
            { loading && <Loader /> }
            <PopUpManager 
                control={'one-button'}
                disable= {oneButtonPopupnDisbled}
                heading={`Info`}
                description={`${batchno} is succefully edited`}
                onClose={() => setOneButtonPopupDisabled(true)}
                okButtonText={"Generate Qr's"}
                clickedOk={() => navigate('/new-batch-details')}
            />

            <PopUpManager 
                disable={textPopupDisabled}
                control={"text"}
                heading={"Alert"}
                description={alertText}
                onClose={() => setTextPopupDisabled(true)}
            />

            <Formik
                initialValues={batchData || manuIntialValues}
                enableReinitialize={true}
                validationSchema={manuValidationSchema}
                onSubmit={handleSubmit}
            > 
            {
                formik => {
                    formikSetter = formik.setFieldValue;
                    return (
                        <Form>
                            {
                                skuIds && Array.isArray(skuIds) && skuIds.length > 1 && <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                    <div onClick={() => handleClickSkudetails(clickedSkuDetails)}>
                                        <FormHeadingComponent name={"SKU Details"} clicked={clickedSkuDetails} />
                                    </div>
                                    {clickedSkuDetails ? (
                                        <div className='bg-bgWhite rounded-b-[12px]'>
                                            <div onClick={() => handleClickSkuDetailsSub(clickedSkuDetailsSub)}>
                                                <FormSubHeadingComponent name={"Select the sku id from drop down"} clicked={clickedSkuDetailsSub} />
                                            </div>
                                            {clickedSkuDetailsSub ? (
                                                <div className='bg-bgWhite border-solid border-navBorder rounded-b-[12px] w-1/2 pl-14 pb-6 dropdown-icon'>
                                                    <FormikControl
                                                        control='select'
                                                        label='SKU ID'
                                                        name='skuId'
                                                        options={skuIds}
                                                        bgcolor={'selectorBg'}
                                                        onChange={(e) => {
                                                            const _skuId = e.target.value;
                                                            formik.setFieldValue('skuId', _skuId)
                                                            reqSkuData(formik, _skuId)

                                                            skuIds.forEach(sku => {
                                                                if(sku.value === _skuId) {
                                                                    console.log(sku._id)
                                                                    setSelectedSku_Id(sku._id);
                                                                }
                                                            });
                                                        }}
                                                        height={'[46px]'}
                                                        radius={'[5px]'}
                                                    />
                                                </div>
                                            ) :
                                                <></>}
                                        </div>
                                    ) :
                                        <></>
                                    }
                                </div>
                            }
                            <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                <div onClick={() => handleClickProductDetails(clickedProductDetails)}>
                                    <FormHeadingComponent name={"Product Details"} clicked={clickedProductDetails} />
                                </div>
                                {clickedProductDetails ? (
                                    <div className='w-full  '>
                                        <div onClick={() => handleClickProductDetailsSub(clickedProductDetailsSub)}>
                                            <FormSubHeadingComponent name={"Provide Product Details"} clicked={clickedProductDetailsSub} />
                                        </div>
                                        {clickedProductDetailsSub ? (
                                            <>
                                                <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10 img-upload-wrapper'>
                                                    <FormikControl
                                                        control='file'
                                                        type='file'
                                                        defaultImageUrl={formik.values.Pimage}
                                                        label={"Product Image :"}
                                                        name='Product-image'
                                                        acceptableFiles='.jpg,.png,.jpeg'
                                                        blobRequired={false}
                                                        customerror={formik.touched.Pimage && formik.errors.Pimage}
                                                        onFileSelect={(url) => {
                                                            if(url)
                                                                formik.setFieldValue("Pimage", url);
                                                        }}
                                                    />

                                                    <FormikControl
                                                        control='file'
                                                        type='file'
                                                        defaultImageUrl={formik.values.expiryImage}
                                                        label={"Expiry Product :"}
                                                        name={'expiry-prod'}
                                                        acceptableFiles='.jpg,.png,.jpeg'
                                                        blobRequired={false}
                                                        customerror={formik.touched.expiryImage && formik.errors.expiryImage}
                                                        onFileSelect={(url) => {
                                                            formik.setFieldValue("expiryImage", url);
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    batchData.type == "3" && <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10 img-upload-wrapper'>
                                                        <FormikControl
                                                            control='file'
                                                            type='file'
                                                            defaultImageUrl={formik.values.QROnprod}
                                                            label={"Hidden QR on Product :"}
                                                            name={'hiddend-qr-on-prod'}
                                                            acceptableFiles='.jpg,.png,.jpeg'
                                                            blobRequired={false}
                                                            customerror={formik.touched.QROnprod && formik.errors.QROnprod}
                                                            onFileSelect={(url) => {
                                                                formik.setFieldValue("QROnprod", url);
                                                            }}
                                                        />

                                                        <FormikControl
                                                            control='file'
                                                            type='file'
                                                            defaultImageUrl={formik.values.preSaleQRLocation}
                                                            label={"Hidden QR on Product :"}
                                                            name={'hiddend-qr-on-prod'}
                                                            acceptableFiles='.jpg,.png,.jpeg'
                                                            blobRequired={false}
                                                            customerror={formik.touched.preSaleQRLocation && formik.errors.preSaleQRLocation}
                                                            onFileSelect={(url) => {
                                                                formik.setFieldValue("preSaleQRLocation", url);
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10 rounded-b-[12px]'>
                                                    <FormikControl control='input' type='text' label={"Brand :"} name={'brand'} bgcolor={'selectorBg'} />
                                                    <FormikControl control='input' type='text' label={"Product Name :"} name={"prodName"} bgcolor={'selectorBg'} />
                                                    <FormikControl disabled={true} control='input' type='text' label={"Batch number :"} name={"batchNo"} bgcolor={'selectorBg'} />
                                                    <FormikControl defaultNoDate={mfgNoDate} formikSetter={formik.setFieldValue} customError={mfgDateErr} dateSetAuto={true} notAvailableOption={true} setNotAvailableOption={setMfgNotAvailable} onChange={(date) => handleManufacturingDateChange(formik, date)} control='datepicker2' type='date' label={"Mfg Date :"} name={"mfgDate"} fieldWidth='[100%]' bgcolor={'selectorBg'} />
                                                    <FormikControl control='input' type='number' label={"MRP. :"} name={"mrp"} bgcolor={'selectorBg'} />
                                                    <FormikControl control='input' type='text' label={"Serial No. :"} name={"serialNo"} bgcolor={'selectorBg'} />
                                                    <FormikControl disabled={expNotApplicable} customError={shelfErr} value={shelfLife} control='input' type='number' label={"Shelf Life in days:"} name={"shelfLife"} onChange={(e) => handleShelfLifeChange(formik, e.target.value)} bgcolor={'selectorBg'} />
                                                    <FormikControl defaultNoDate={expNoDate} formikSetter={formik.setFieldValue} customError={expDateErr} dateSetAuto={true} notAvailableOption={true} setNotAvailableOption={setExpNotAvailable} notApplicableOption={true} setNotApplicableOption={setExpNotApplicable} onChange={(date) => handleExpiryDateChange(formik, date)} control='datepicker2' type='date' label={"Expiry Date:"} name={"expiryDate"} fieldWidth='[100%]' bgcolor={'selectorBg'} />
                                                    {
                                                        formik.values.type != '1' && <>
                                                        <FormikControl
                                                            control='select'
                                                            className="dropdown-icon"
                                                            label='Warranty Applicable'
                                                            name='warrantyApp'
                                                            options={warrantyDropDown}
                                                            height={'[46px]'}
                                                            padX={'10'}
                                                            bgcolor={'selectorBg'}
                                                            value={formik.values.warrantyApp}
                                                            onChange={(e) => {
                                                                if (e.target.value === "false") {
                                                                    formik.setFieldValue('warrantyPeriod', '')
                                                                }
                                                                formik.setFieldValue('warrantyApp', e.target.value)
                                                            }}
                                                        />
                                                            {
                                                                formik.values.warrantyApp === "true" && <FormikControl control='input' type='number' label={"Warranty in days"} name={"warrantyPeriod"} bgcolor={'selectorBg'} disabled={formik.values.warrantyApp === "true" ? false : true} />
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        ) :
                                            <></>}
                                    </div>
                                ) :
                                    <></>}
                            </div>
                            <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                <div onClick={() => { handleClickManufacturingDetails(clickedManufacturingDetails) }}>
                                    <FormHeadingComponent name={"Manufacturing Details"} clicked={clickedManufacturingDetails} />
                                </div>
                                {clickedManufacturingDetails ? (
                                    <div className='bg-bgWhite rounded-[12px]'>
                                        <div onClick={() => handleClickManufacturingsub(clickedManufacturingsub)}>
                                            <FormSubHeadingComponent name={"Provide Manufacturing Details "} clicked={clickedManufacturingsub} />
                                        </div>
                                        {clickedManufacturingsub ? (
                                            <div className='px-[30px] pb-20 grid grid-cols-1 gap-y-10 rounded-[12px] qr-manufacturing-details'>
                                                <FormikControl
                                                    control='input-textarea-small'
                                                    width={'full'}
                                                    type='description'
                                                    label={"Manufacturing license no :"}
                                                    name={'manuLicenseNo'}
                                                    placeholder={"Type your text here"} />
                                                <FormikControl
                                                    control='input-textarea-large'
                                                    width={'full'}
                                                    type='description'
                                                    label={"Manufacturing Address :"}
                                                    name={'manuAdd'}
                                                    placeholder={"Type your text here"} />
                                                <FormikControl
                                                    control='input-textarea-large'
                                                    width={'full'}
                                                    type='description'
                                                    label={"Additional Details :"}
                                                    name={'addDetails'}
                                                    placeholder={"Type your text here"} />
                                                <FormikControl
                                                    control='input-textarea-small'
                                                    width={'full'}
                                                    type='description'
                                                    label={"Image or Video Header :"}
                                                    name={'additionalImageDetails'}
                                                    placeholder={"image header"}
                                                />
                                                <div className='flex flex-wrap md:flex-nowrap mb-[25px] md:mb-0 items-center h-[100%] gap-5 generate-img'>
                                                    <label htmlFor=""></label>
                                                    <div className='generate-img-upload-wrapper'>
                                                        <div className='flex md:justify-end'>
                                                            <FormikControl
                                                                control='file'
                                                                type='file'
                                                                label={""}
                                                                name='extra-Image-1'
                                                                defaultImageUrl={formik.values.extraImage1}
                                                                acceptableFiles='.jpg,.png,.jpeg'
                                                                blobRequired={false}
                                                                customerror={formik.touched.extraImage1 && formik.errors.extraImage1}
                                                                onFileSelect={(url) => {
                                                                    formik.setFieldValue("extraImage1", url);
                                                                }}
                                                            />
                                                            <FormikControl
                                                                control='file'
                                                                type='file'
                                                                label={""}
                                                                name='extra-Image-2'
                                                                defaultImageUrl={formik.values.extraImage2}
                                                                acceptableFiles='.jpg,.png,.jpeg'
                                                                blobRequired={false}
                                                                customerror={formik.touched.extraImage2 && formik.errors.extraImage2}
                                                                onFileSelect={(url) => {
                                                                    formik.setFieldValue("extraImage2", url);
                                                                }}
                                                            />
                                                            <FormikControl
                                                                control='video'
                                                                type='file'
                                                                label={""}
                                                                name='extra-video-1'
                                                                defaultVideoUrl={formik.values.prodVedioLink}
                                                                acceptableFiles='.mp4'
                                                                blobRequired={false}
                                                                customerror={formik.touched.prodVedioLink && formik.errors.prodVedioLink}
                                                                onFileSelect={(url) => {
                                                                    formik.setFieldValue("prodVedioLink", url);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <FormikControl
                                                    control='input-textarea-small'
                                                    type='description'
                                                    label={"Website Link :"}
                                                    name={'manuWebsiteLink'}
                                                    placeholder={"Type your text here"} />
                                            </div>
                                        ) : <></>}
                                    </div>
                                ) : <></>
                                }
                            </div>
                            <div className='h-40'>
                                <SubmitButtonWide value={"Process"} type={'submit'} />
                            </div>

                        </Form>
                    )
                }
            }

        </Formik>
            {/* <PopUpScreen header = {popupScreenView} title = {""}/> */}
        </>
    )
}

export default ManuBatchEdit;