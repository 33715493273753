const manuIntialValues = {
    'Pimage': '',
    'QROnprod': '',
    'preSaleQRLocation': '',
    'brand': '',
    'prodName': '',
    'batchid': '',
    'mfgDate': '',
    'mrp': '',
    'serialNo': '',
    'shelfLife': '',
    'expiryDate': '',
    'warrantyApp': '',
    'warrantyPeriod': null,
    'manuLicenseNo': '',
    'manuAdd': '',
    'addDetails': '',
    'additionalFileDetails': '',
    'extraImage1': '',
    'extraImage2': '',
    'prodVedioLink': '',
    'manuWebsiteLink': '',
    'totalQRGen': '',
    'trackingQr_tert': '',
    'trackingQr_quat': ''
}

export default manuIntialValues;