import React, { useEffect, useState } from 'react'
import { Header } from '../../../layouts/header'
import Navbar from '../../../layouts/navbar'
import Sidebar from '../../../layouts/sidebar'
import SideBarContents from '../components/sideBarContent'
import ManuBatchEdit from './components/manuBatchEdit'
import RetaBatchEdit from './components/retaBatchEdit'
import useSidebar from '../../../hooks/useSidebar'
import Loader from '../../../components/ui/loader/Loader';
import { useParams } from 'react-router-dom'

import PopUpManager from '../../../components/popups2/popupManager'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

import { URL_TYPES } from '../../../config/constants'
import { getBatchData } from "../services/api";

function Index() {
    const { isSidebarOpen, toggleSidebar } = useSidebar();
    const { clientid, batchno } = useParams();
    const [ rawBatchData, setRawBatchData] = useState(undefined);
    const axiosPrivate = useAxiosPrivate(URL_TYPES.QR);
    const [ loading, setLoading ] = useState(false);
    const [ textPopupDisabled, setTextPopupDisabled ] = useState(true);
    const [ textPopupDescription, setTextPopupDescription ] = useState('');

    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true);
                const data = await getBatchData(clientid, batchno,axiosPrivate);
                setRawBatchData(data)
            } catch (error) {
                setTextPopupDescription(error.message || 'Unable to load data');
                setTextPopupDisabled(false);
            } finally {
                setLoading(false);
            }
        }
        if(clientid && batchno)
            getData();
    }, [clientid, batchno]);

    return (
        <div className='layout-wrapper'>
            {
                loading && <Loader />
            }
            <PopUpManager 
                disable={textPopupDisabled}
                control='text'
                heading='Alert'
                description={textPopupDescription}
                onClose={() => setTextPopupDisabled(true)}
            />
            <div className='top-header w-full z-10 bg-#FFFFFF'>
                <Header toggleSidebar={toggleSidebar} />
            </div>
            <div className='content-wrapper flex flex-row'>
                <div className={`sidebar top-0 bg-white ${isSidebarOpen ? 'open' : ''}`}>
                    <Sidebar details={SideBarContents} />
                </div>
                <div className='w-full bg-bgLightBlue page-content pb-20'>
                    <div className='px-[30px]'>
                        <div className='h-10  flex items-center ml-10 mr-10'>
                            <Navbar />
                        </div>
                        <div className='text-generateQRheading font-bold ml-10 mr-10 h-16  flex justify-start items-center'>
                            <h5> Edit Batch </h5>
                        </div>
                        <div>
                            <div className='ml-10 mr-10'>
                                {
                                    rawBatchData?.batchType && rawBatchData.batchType == 'Manufacturer' &&
                                    <ManuBatchEdit clientid={clientid} batchno={batchno} rawBatchData={rawBatchData} />
                                }
                                {
                                    rawBatchData?.batchType && rawBatchData.batchType == 'Retailer' &&
                                    <RetaBatchEdit clientid={clientid} batchno={batchno} rawBatchData={rawBatchData} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Index