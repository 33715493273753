import profile from '../../../../assets/svg/sidebar_icons/profile_icon.svg'
import Dashboard from '../../../../assets/svg/sidebar_icons/dashboard_icon.svg'


const sideBarData = [
    {
        name:"rewards",
        img:profile,
        desc:"Profile"
    },
]

export default sideBarData