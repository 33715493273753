import * as Yup from 'yup';
import { isValidNumberForRegion  } from 'libphonenumber-js';

export const validationSchema = Yup.object({
    organizationName:Yup.string().required('This field is required'),
    addressLine1:Yup.string().required('This field is required'),
    addressLine2: Yup.string(),
    country: Yup.string().required('This field is required'),
    state: Yup.string().required('This field is required'),
    city: Yup.string().required('This field is required'),
    postalCode: Yup.number().required('This field is required'),
    entityType: Yup.string().required('This field is required'),
    categoryOfOrganization: Yup.string().required('This field is required'),
    taxId: Yup.string().required('This field is required'),
    businessId: Yup.string().required('This field is required'),
    monthlyProductionQuantity: Yup.number().required('This field is required'),
    numberOfSKUs: Yup.number().required('This field is required'),
    serviceType: Yup.array().min(1, "Atleast one option is required").required('This field is required'),
    dunsNumber: Yup.string(),
    companyWebsite: Yup.string().required('This field is required'),
    enterpriseType: Yup.string().required('This field is required'),
    billingDate: Yup.number().required('This field is required').test('valid-date', 'Please provide a valid date between 1 to 10', value => {
        return value > 0 && value <= 10;
    }),
    companyDocOptions: Yup.string().required('This field is required'),
    companyDoc: Yup.mixed().required('This field is required'),

    //AUth
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
    designation: Yup.string().required('This field is required'),
    emailId: Yup.string().email('Please provide a valid Email').required('This field is required'),
    mobileNoDetails: Yup.object().test('mobile-number-required', 'This field is required', value => {
        return value?.intelMobileNo && Boolean(value.intelMobileNo)
    }).test('valid-mobile-number', 'Please provide a valid mobile number', value => {
        return value?.intelMobileNo && value?.countryCode && isValidNumberForRegion(value.intelMobileNo, value.countryCode)
    }),
    auth_Proof_Of_Identity_Doc_Options: Yup.string().required('This field is required'),
    authAuthorizationDoc: Yup.mixed().required('This field is required'),
    authProofDoc: Yup.mixed().required('This field is required'),

    //Invoice
    inv_firstName: Yup.string().required('This field is required'),
    inv_lastName: Yup.string().required('This field is required'),
    inv_designation: Yup.string().required('This field is required'),
    inv_emailId: Yup.string().email('Please provide a valid Email').required('This field is required'),
    inv_mobileNoDetails: Yup.object().test('mobile-number-required', 'This field is required', value => {
        return value?.intelMobileNo && Boolean(value.intelMobileNo)
    }).test('valid-mobile-number', 'Please provide a valid mobile number', value => {
        return value?.intelMobileNo && value?.countryCode && isValidNumberForRegion(value.intelMobileNo, value.countryCode)
    }),
    inv_auth_Proof_Of_Identity_Doc_Options: Yup.string().required('This field is required'),
    invAuthorizationDoc: Yup.mixed().required('This field is required'),
    invProofDoc: Yup.mixed().required('This field is required'),

    //Solution
    trackingBaseCost: Yup.number().required('This field is required').test( 'Should not be negative', 'This field can not be negative', value => value >= 0),
    infoBaseCost: Yup.number().required('This field is required').test( 'Should not be negative', 'This field can not be negative', value => value >= 0),
    overtBaseCost: Yup.number().required('This field is required').test( 'Should not be negative', 'This field can not be negative', value => value >= 0),
    overtWarrantyCost: Yup.number().required('This field is required').test( 'Should not be negative', 'This field can not be negative', value => value >= 0),
    overtLoyaltyCost: Yup.number().required('This field is required').test( 'Should not be negative', 'This field can not be negative', value => value >= 0),
    covertBaseCost: Yup.number().required('This field is required').test( 'Should not be negative', 'This field can not be negative', value => value >= 0),
    covertWarrantyCost: Yup.number().required('This field is required').test( 'Should not be negative', 'This field can not be negative', value => value >= 0),
    covertLoyaltyCost: Yup.number().required('This field is required').test( 'Should not be negative', 'This field can not be negative', value => value >= 0),

    //Service
    dataCostPerQr: Yup.number().required('This field is required').test( 'Should not be negative', 'This field can not be negative', value => value >= 0),
    costPerCouponCode: Yup.number().required('This field is required').test( 'Should not be negative', 'This field can not be negative', value => value >= 0),
    cashbackTransactionCost: Yup.number().required('This field is required').test( 'Should not be negative', 'This field can not be negative', value => value >= 0),
    emailAndSmsComission: Yup.number().required('This field is required').test( 'Should not be negative', 'This field can not be negative', value => value >= 0),
    geoLocation_Cost_Per_Conversion: Yup.number().required('This field is required').test( 'Should not be negative', 'This field can not be negative', value => value >= 0),
    miscellaneous: Yup.array(),

    //App details
    primaryColor: Yup.string().required('This field is required'),
    secondaryColor: Yup.string(),
    gradientAngle: Yup.number(),
    backgroundColor: Yup.string().required('This field is required'),
    clientLogo: Yup.mixed().required('This field is required'),
    clientFavIcon: Yup.mixed().required('This field is required'),
    modeOfService: Yup.string().required('This field is required'),
    useVeotsSubdomain: Yup.boolean().required('This field is required'),
    domainName: Yup.string().url().required('This field is required'),
    isfooterIncluded: Yup.boolean().required('This field is required'), 
    qrScanAccessLevel: Yup.string().required('This field is required').test('atleast-one-option-selected', "Please select atleast one option", value => {
        const val = JSON.parse(value)
        return Array.isArray(val) && val.length > 0;
    }),

    //otp
    isOtpServiceEnabled: Yup.string().required('This field is required'),
    emailDomain: Yup.string().when('isOtpServiceEnabled', {
        is: val => val === 'true' || val === true,
        then: () => Yup.string().test('should be a emali doaim', 'Please provide a valid email domain', value => {
            const domainRegex = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
            return domainRegex.test(value);
        }).required('This field is required'),
        otherwise: () => Yup.string()
    }),
    replyToMailId: Yup.string().when('isOtpServiceEnabled', {
        is: val => val === 'true' || val === true,
        then: () => Yup.string().email('Please provide a valid email').required('This field is required'),
        otherwise: () => Yup.string().email('Please provide a valid email')
    }),
    senderMailId: Yup.string().when('isOtpServiceEnabled', {
        is: val => val === 'true' || val === true,
        then: () => Yup.string().email('Please provide a valid email').required('This field is required'),
        otherwise: () => Yup.string().email('Please provide a valid email')
    }),

    //Background Verification
    bgVerification_Doc: Yup.mixed().required('This field is required'),

    //comments
    comments: Yup.string(),
})