import axios from "../axios/axios"
import useAuth from "./useAuth"

const useRefreshToken = () => {
    const {setAuth} = useAuth();

    const refresh = async ()=>{
        const response = await axios.get(`/api/auth/admin/generate-access-token`,{
            withCredentials:true
        });
        console.log(response)
        setAuth(prev=>{
            console.log("Previous Access Token",JSON.stringify(prev));
            console.log("New Access Token",response.data.accessToken)
            return {
                ...prev,
                accessToken:response.data.accessToken,
                roles:response.data.roles
            }
        });
        return response.data.accessToken;
    }
    return refresh;
}

export default useRefreshToken