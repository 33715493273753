import React from 'react';

import Input from '../../../../../../components/form2/Input';
import FileUpload from '../../../../../../components/form2/FileUpload';
import Select from '../../../../../../components/form2/Select';
import ColorInput from '../../../../../../components/form2/ColorInput';

import { modeOfServiceOptions, subDomainOptions, footerIncludeOptions, qrScanLevelOptions } from './utils/options';

const AppDetails = ({formik, isReview=false}) => {
    return (
        <div className='mx-16 -mb-4'>
            <p className='text-[#182AB4] text-5xl mb-10 font-semibold'>App Details</p>
            <div className='flex gap-12'>
                <div className='w-1/2'>
                    <FileUpload 
                        label='Logo on App*'
                        name={'clientLogo'}
                        defaultFileName={formik?.values?.clientLogoFileName}
                        formik={formik}
                        marginClass='mb-2'
                        acceptableFilesFormats='.png, .jpg, .jpeg'
                    /> 
                    <ColorInput 
                        label='Primary Color *'
                        name={'primaryColor'}
                        formik={formik}
                        marginClass='mb-2'
                    />
                    <Input 
                        placeholder=''
                        label='Gradient Angle'
                        name='gradientAngle'
                        formik={formik}
                        type='number'
                        marginClass='mb-2'
                    />
                    <Select 
                        label='Would you want to go with Veots sub-domain? *'
                        name='useVeotsSubdomain'
                        formik={formik}
                        marginClass='mb-2'
                        options={subDomainOptions}
                        disabled={isReview}
                    />
                    <Select 
                        label='Include footer in App? *'
                        name='isfooterIncluded'
                        formik={formik}
                        marginClass='mb-2'
                        options={footerIncludeOptions}
                    />
                    <Select 
                        label='Scan Access Leevl *'
                        name='qrScanAccessLevel'
                        formik={formik}
                        marginClass='mb-2'
                        options={qrScanLevelOptions}
                    />
                </div>
                <div className='w-1/2'>
                    <FileUpload 
                        label='Favicon *'
                        name={'clientFavIcon'}
                        defaultFileName={formik?.values?.clientFavIconFileName}
                        formik={formik}
                        marginClass='mb-2'
                        acceptableFilesFormats='.ico, .png'
                    /> 
                    <ColorInput 
                        label='Secondary Color *'
                        name={'secondaryColor'}
                        formik={formik}
                        marginClass='mb-2'
                    />
                    <ColorInput 
                        label='Background Color *'
                        name={'backgroundColor'}
                        formik={formik}
                        marginClass='mb-2'
                    />
                    <Input 
                        placeholder=''
                        label='Domain Name'
                        message='Format should be like: https://www.veots.com'
                        name='domainName'
                        formik={formik}
                        marginClass='mb-2'
                        disabled={isReview}
                    />
                    <Select 
                        label='Mode of service *'
                        name='modeOfService'
                        formik={formik}
                        marginClass='mb-2'
                        options={modeOfServiceOptions}
                    />
                </div>
            </div>
        </div>
    );
}

export default AppDetails;


