import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Breadcrumbs() {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    const breadcrumbs = pathnames.map((part, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;

        return {
            name: part,
            to: routeTo,
            isLast: isLast,
        };
    });

    // Generate parent links
    const parentLinks = breadcrumbs.map((breadcrumb, index) => {
        if (!breadcrumb.isLast) {
            return (
                <span key={breadcrumb.name}>
                    <Link className='text-navGrey font-normal text-[14px]' to={breadcrumb.to}>{breadcrumb.name}</Link> <span className='px-3'>{' > '}</span>
                </span>
            );
        } else {
            return (
                <span className='text-navBlue text-[14px]' key={breadcrumb.name}>
                    {breadcrumb.name} {'  '}
                </span>
            );
        }
    });

    return (
        <div className='text-[14px]'>
            <Link className='text-navGrey font-normal text-[14px]' to="/home">Home</Link> <span className='px-[10px]'>{' >'}</span>
            {parentLinks}
        </div>
    );
}

export default Breadcrumbs;
