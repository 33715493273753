import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import FormikControl from '../../../components/form/formikControl';
import { SubmitButton, SubmitButtonWide } from '../../../components/ui/buttons/submitButton';

import Loader from "../../../components/ui/loader/Loader";
import PopUpManager from '../../../components/popups2/popupManager';
import rightArrow from '../../../assets/svg/arrow_delete_user.svg';

//importing necessary hooks
import {SUB_CLIENT_ROLES} from "../../../config/constants";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {fetchSubClientUserRoles, updateSubClientUserRoles} from "../services/api";

function ClientAccessManagement({selectedMasterClient, setIsMasterClientDetailOpen}) {

    const axiosPrivate = useAxiosPrivate();
    const [userRoleData, setuserRoleData] = useState();

    const pageSize = 10;
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [maxPageNo, setMaxPageNo] = useState(0);
    const [tableLoading, setTableLoading] = useState(false);

    const [loading, setLoading] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [textPopupDisabled, setTextPopupDisabled] = useState(true);

    const textCenter = {
        textAlign: 'center',
        color: 'black',
        fontWeight: '500'
    };

    // Initialize state for selected roles
    let selectedRoles = []

    const loadSubClients = async (masterClientId) => {
        if(!masterClientId){
            setAlertText("Please select a valid Client ID");
            setTextPopupDisabled(false);
            return;
        }
        try{
            setTableLoading(true)
            const res = await fetchSubClientUserRoles(masterClientId, currentPageNo, pageSize, axiosPrivate);
            setMaxPageNo(Math.floor(res.totalUsers / pageSize) + (res.totalUsers % pageSize ? 1 : 0));
            const userData = res.pageData;

            //transforming the api roles to readable format.
            for(let i = 0; i < userData.length; i++){
                userData[i].roles = userData[i].roles.map((value) => {
                    switch (value) {
                        case SUB_CLIENT_ROLES.ADMIN:
                            return "Admin";
                        case SUB_CLIENT_ROLES.CUSTOMER:
                            return "Customer";
                        case SUB_CLIENT_ROLES.USER:
                            return "User";
                        case SUB_CLIENT_ROLES.DASHBOARD:
                            return "Dashboard";
                        default:
                            return value;
                    }
                });
            }
            //setting the formik values with the roles
            formikSetter && formikSetter({
                ...formikValues,
                ...extractvalues(userData)
            });
            setuserRoleData(userData);
        }catch(error){
            setAlertText(error.message);
            setTextPopupDisabled(false);
        }
        finally{
            setTableLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await loadSubClients(selectedMasterClient.masterClientId);
        })();
        
        // eslint-disable-next-line
    },[selectedMasterClient, currentPageNo])

    const radioOptions = {
        'Admin': SUB_CLIENT_ROLES.ADMIN,
        'User': SUB_CLIENT_ROLES.USER,
        'Dashboard': SUB_CLIENT_ROLES.DASHBOARD,
        'Customer': SUB_CLIENT_ROLES.CUSTOMER
    }
    
    const extractvalues = (userdata) => {
        const obj = {};
        // fill obj
        for(let i = 0; i < userdata.length; i++){
            const userId = userdata[i]["userId"];

            obj[`${userId}_Admin`] = false;
            obj[`${userId}_Customer`] = false;
            obj[`${userId}_Dashboard`] = false;
            obj[`${userId}_User`] = false;
        }

        for(let i = 0; i < userdata.length; i++){
            const userId = userdata[i]["userId"];

            const roles = userdata[i]["roles"];

            for(let k = 0; k < roles.length; k++){
                obj[`${userId}_${roles[k]}`] = true;
            }
        }
        return obj;
    }

    const initialValues = {
        "masterClientId": "",
    }

    const handleSubmit = async (values, userId) => {
        selectedRoles = [];
        for (let k in values){
            if(!values[k]) continue;
            const data = k.split("_");
            if(data[0] !== userId) continue;
            selectedRoles.push(radioOptions[data[1]]);
        }
        try{
            setLoading(true);
            await updateSubClientUserRoles(userId,selectedRoles,axiosPrivate);
            
            setAlertText("Roles Updated Successfully");
            setTextPopupDisabled(false);
        }catch(error){
            setAlertText(error.message || `Unable to update roles`);
            setTextPopupDisabled(false);
        }
        finally{
            setLoading(false);
        }
    };

    let formikSetter = null;
    let formikValues = null;
    return (
        <>
        {loading && <Loader />}
        <PopUpManager 
            disable={textPopupDisabled}
            control={"text"}
            heading={"Alert"}
            description={alertText}
            onClose={() => setTextPopupDisabled(true)}
        />

        <div className='my-10'>
            <SubmitButtonWide
                value='Back'
                onClick={() => {
                    setIsMasterClientDetailOpen(false)
                }}
            />
        </div>

        <Formik
            initialValues={initialValues}
        >
            {
                formik => {
                    formikSetter = formik.setValues;
                    formikValues = formik.values;
                    return (
                        <Form>
                            <div className='bg-popupScreen mb-10 py-5 flex items-center pl-16 pr-10'>
                                <div className='w-[75%] flex items-center'>
                                    <span className='text-formBlueHeading text-3xl font-bold pr-8'>Master Client ID</span>
                                    <svg width="40" height="40" className='mr-8' >
                                        <image href={rightArrow} width="40" height="40" />
                                    </svg>
                                    <span className=' w-96 h-16 flex items-center justify-center  bg-bgDeleteUser text-popupText text-2xl font-bold'>{selectedMasterClient.masterClientId}</span>
                                </div>
                            </div>

                            <div className='relative'>
                                {tableLoading && <Loader componental={true} />}
                                {
                                    Array.isArray(userRoleData) && userRoleData.length !== 0 &&
                                    <div className='my-8 rounded-[12px] overflow-y-auto bg-white pr-5'>
                                        {
                                            userRoleData.map((user, index) => (
                                            <>
                                                <div key={index} className='flex justify-between items-center access-box border-b-4'>
                                                    <div className='px-7 py-5 w-[45rem] flex justify-between items-center left border-r border-[#182AB4] border-opacity-20'>
                                                        <div className=' flex justify-start items-center w-[15rem]'>
                                                            <span className='text-financeSideBoxText text-font16 font-semibold'>User ID {index + 1}</span>
                                                        </div>
                                                        <div className='w-[30rem] text-center -mb-4'>
                                                            <FormikControl style={textCenter} control='input' type='text' label={""} name={`${user.userId}`} placeholder={""} bgcolor='selectorBg' width={'full'} defaultValue={`${user.userId}`} disabled />
                                                        </div>
                                                    </div>
                                                    <div className='min-w-[calc(99%-45rem)]'>
                                                        <div className='px-7 py-5 flex justify-between items-center'>
                                                            <div className='flex '>
                                                                <div className="mr-10 pt-2 pb-2 flex justify-center items-center radio-btn-wrapper">
                                                                    <FormikControl control='checkbox' label={'Admin'} name={`${user.userId}_Admin`} // Use a unique name for each checkbox
                                                                        defaultChecked={formik.values[`${user.userId}_Admin`]}
                                                                    />
                                                                </div>
                                                                <div className="mr-10 pt-2 pb-2 flex justify-center items-center radio-btn-wrapper">
                                                                    <FormikControl control='checkbox' label={'User'} name={`${user.userId}_User`}
                                                                        defaultChecked={formik.values[`${user.userId}_User`]}
                                                                    />
                                                                </div>
                                                                <div className="mr-10 pt-2 pb-2 flex justify-center items-center radio-btn-wrapper">
                                                                    <FormikControl control='checkbox' label={'Customer'} name={`${user.userId}_Customer`}
                                                                        defaultChecked={formik.values[`${user.userId}_Customer`]}
                                                                    />
                                                                </div>
                                                                <div className="mr-10 pt-2 pb-2 flex justify-center items-center radio-btn-wrapper">
                                                                    <FormikControl control='checkbox' label={'Dashboard'} name={`${user.userId}_Dashboard`}
                                                                        defaultChecked={formik.values[`${user.userId}_Dashboard`]}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='min-w-[20rem] mr-10 flex justify-center items-center '>
                                                                <SubmitButton label='Save' btnWidth='full' type='button' onClick={() => handleSubmit(formik.values, user.userId)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                } 
                            </div>
                            
                            {
                                (!userRoleData || userRoleData.length === 0) && 
                                <div className='h-10 flex justify-center items-center bg-white'>
                                    <p className='text-4xl'>No Client Data Found</p>
                                </div>
                            }
                        </Form>
                    )
                }
            }
        </Formik>

        <div className='w-[100%] flex justify-between my-10'>
            <div className='w-[25rem]'>
                <SubmitButton label='Previous' btnWidth='full' disabled={(userRoleData && userRoleData.length === 0) || currentPageNo === 1} onClick={() => {if(currentPageNo > 1) setCurrentPageNo(currentPageNo - 1)}} />
            </div>
            <div className='w-[25rem]'>
                <SubmitButton label='Next' btnWidth='full' disabled={(userRoleData && userRoleData.length === 0) || currentPageNo >= maxPageNo} onClick={() => {if(currentPageNo < maxPageNo) setCurrentPageNo(currentPageNo + 1)}} />
            </div>
        </div>
        </>
    )
}
export default ClientAccessManagement