export const entityOptions = [
    {key: "Companies Limited by Shares", value: "Companies Limited by Shares" },
    {key: "Companies Limited by Guarantee", value: "Companies Limited by Guarantee" },
    {key: "Unlimited Companies", value: "Unlimited Companies" },
    {key: "One Person Companies (OPC)", value: "One Person Companies" },
    {key: "Private Companies", value: "Private Companies" },
    {key: "Public Companies", value: "Public Companies" },
    {key: "Holding and Subsidiary Companies", value: "Holding and Subsidiary Companies" },
    {key: "Associate Companies", value: "Associate Companies" },
    {key: "Companies in terms of Access to Capital", value: "Companies in terms of Access to Capital" },
    {key: "Government Companies", value: "Government Companies" },
    {key: "Foreign Companies", value: "Foreign Companies" },
    {key: "Charitable Companies", value: "Charitable Companies" },
    {key: "Dormant Companies", value: "Dormant Companies" },
    {key: "Nidhi Companies", value: "Nidhi Companies" },
    {key: "Limited Liability Partnership", value: "Limited Liability Partnership" },
    {key: "Sole proprietorship", value: "Sole proprietorship" },
    {key: "Public Financial Institutions", value: "Public Financial Institutions" }
];

export const orgCategoryOptions = [
    {key: "Fashion Industry", value: "Fashion Industry"},
    {key: "FMCG", value: "FMCG"},
    {key: "Pharmaceutical", value: "Pharmaceutical"},
    {key: "Toys", value: "Toys"},
    {key: "IT", value: "IT"},
    {key: "Alcohol & Beverages", value: "Alcohol & Beverages"},
    {key: "Agriculture", value: "Agriculture"},
    {key: "Jewelery", value: "Jewelery"},
    {key: "Printing", value: "Printing"},
    {key: "Art and Craft", value: "Art and Craft"},
    {key: "Paint", value: "Paint"},
    {key: "Baby Care", value: "Baby Care"},
    {key: "Wellness", value: "Wellness"},
    {key: "Cosmetics", value: "Cosmetics"},
    {key: "Construction industry", value: "Construction industry"},
    {key: "Chemical industry", value: "Chemical industry"},
    {key: "Petroleum industry", value: "Petroleum industry"},
    {key: "Automotive industry", value: "Automotive industry"},
    {key: "Electronic industry", value: "Electronic industry"},
    {key: "Power engineering ", value: "Power engineering"},
    {key: "Power manufacturing", value: "Power manufacturing"},
    {key: "Others", value: "Others"},
];

export const serviceTypeOptions = [
    {key: "Authentication", value: "Authentication"},
    {key: "Warranty", value: "Warranty"},
    {key: "Cashback", value: "Cashback"},
    {key: "Coupons", value: "Coupons"},
    {key: "Data Analytics", value: "Data Analytics"},
    {key: "Tracking", value: "Tracking"},
    {key: "Advertisement", value: "Advertisement"},
]

export const companyDocTypeOptions = [
    {key: "Memorandum of Association", value: "Memorandum of Association"},
    {key: "Articles of Association", value: "Articles of Association"},
    {key: "Certificate of Incorporation", value: "Certificate of Incorporation"},
    {key: "Shareholding Pattern", value: "Shareholding Pattern"},
    {key: "Financial Statements", value: "Financial Statements"},
    {key: "Income Tax Returns", value: "Income Tax Returns"},
    {key: "Bank Statements", value: "Bank Statements"},
    {key: "Tax Registration Certificates", value: "Tax Registration Certificates"},
    {key: "Tax Payment Receipts", value: "Tax Payment Receipts"},
    {key: "Statutory Registers", value: "Statutory Registers"},
    {key: "Property Documents", value: "Property Documents"},
    {key: "Intellectual Property Registration or Application Documents", value: "Intellectual Property Registration or Application Documents"},
    {key: "Utility Bills", value: "Utility Bills"},
    {key: "Trade Certificate", value: "Trade Certificate"},
    {key: "Company PAN", value: "Company PAN"},
];

export const identityDocOptions = [
    {key: "Aadhar Card/e-Aadhar card downloaded from UIDAI website", value: "Aadhar Card/e-Aadhar card downloaded from UIDAI website"},
    {key: "Address card with photo issued by Dept. of Posts, Govt of India", value: "Address card with photo issued by Dept. of Posts, Govt of India"},
    {key: "Arms License", value: "Arms License"},
    {key: "Caste and Domicile certificate with photo issued by State Govt.", value: "Caste and Domicile certificate with photo issued by State Govt."},
    {key: "Certificate of photo identity issued by Village Panchayat head or its equivalent authority (for rural areas)", value: "Certificate of photo identity issued by Village Panchayat head or its equivalent authority (for rural areas)"},
    {key: "CGHS/ECHS Card", value: "CGHS/ECHS Card"},
    {key: "Current passbook of Post Office/any scheduled bank having photo", value: "Current passbook of Post Office/any scheduled bank having photo"},
    {key: "Income Tax PAN Card", value: "Income Tax PAN Card"},
    {key: "Passport", value: "Passport"},
    {key: "Driving License", value: "Driving License"},
    {key: "Voting Card / Election Commission ID Card", value: "Voting Card / Election Commission ID Card"},
    {key: "Electricity Bill", value: "Electricity Bill"},
    {key: "Telephone Bill", value: "Telephone Bill"},
    {key: "Car Registration", value: "Car Registration"},
    {key: "Pension card having photo", value: "Pension card having photo"},
    {key: "Ration Card", value: "Ration Card"},
    {key: "Property Tax Receipt", value: "Property Tax Receipt"},
    {key: "Marriage Certificate", value: "Marriage Certificate"},
    {key: "Credit Card Statement", value: "Credit Card Statement"},
    {key: "Water Bill", value: "Water Bill"},
    {key: "Matriculation Certificate", value: "Matriculation Certificate"},
]

export const enterPriseTypeOptions = [
    {key: "Manufacturer", value: "Manufacturer"},
    {key: "Retailer", value: "Retailer"},
]