import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';

import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { SubmitButtonWide } from '../../../../../components/ui/buttons/submitButton';
import PopUpManager from '../../../../../components/popups2/popupManager';
import Loader from '../../../../../components/ui/loader/Loader';

import CompanyDetails from './CommmpanyDetails/CompanyDetails';
import InvoicePaymentContact from './CommmpanyDetails/InvoicePaymentContact';
import AuthPersonInfo from './CommmpanyDetails/AuthPersonInfo';
import SubAdmins from './CommmpanyDetails/SubAdmins';
import SubUsers from './CommmpanyDetails/SubUsers';
import OtpDetails from './OtpDetails/OtpDetails';
import Comment from './comments/Comment';

import Solutions from './CostStructureDetails/Solutions';
import Services from './CostStructureDetails/Services';

import Preview from './AppDetails/Preview';
import AppDetails from './AppDetails/AppDetails';

import BackgroundVerification from './BackgroundVerificationDetails/BackgroundVerification';

import { initialValues } from '../utils/initialValues';
import { validationSchema } from '../utils/validationSchema';
import { composeData, parseDataForSaving, parseFiles, parseDataForSubmit } from '../utils/massageData';

import { getServiceRequestData, updateServiceRequest, createMasterClient } from '../services/api';

const FormManager = () => {

    const { srId } = useParams()
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const [srData, setSrData] = useState({});
    const [canSubmit, setCanSubmit] = useState(true);

    //Popup
    const [ loading, setLoading ] = useState(false);
    const [ textPopupDisabled, setTextPopupDisabled ] = useState(true);
    const [ textPopupHead, setTextPopupHead ] = useState('');
    const [ textPopupDescription, setTextPopupDescription ] = useState('');
    const popupFun = useRef(() => {});

    const fetSrData = async () => {
        const loadSRRequestData = async () => {
            try {
                setLoading(true);
                const data = await getServiceRequestData(srId, axiosPrivate);
                return data.formData;
            } catch (error) {
                setTextPopupHead('Alert');
                setTextPopupDescription(error.message || 'Unable to load SR Details');
                setTextPopupDisabled(false);
                popupFun.current = () => setTextPopupDisabled(true);
            } finally {
                setLoading(false);
            }
        }
        try {
            setLoading(true);
            const formData = await loadSRRequestData();

            if(formData) {
                const loadedInitialValues = composeData(formData);
                setSrData(loadedInitialValues);

                Object.keys(loadedInitialValues).forEach(async key => {
                    await formik.setFieldValue(key, loadedInitialValues[`${key}`]);
                });
            }
        } catch (error) {
            setTextPopupHead('Alert');
            setTextPopupDescription(error.message || 'Unable to load SR Details');
            setTextPopupDisabled(false);
            popupFun.current = () => setTextPopupDisabled(true);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await fetSrData();
        })();
    }, []);

    const handleSave = async () => {
        const parsedData = parseDataForSaving(formik.values);
        const parsedFiles = parseFiles(formik.values);

        //creating formData
        const formData = new FormData();
        formData.append('draftData', JSON.stringify(parsedData));
        Object.keys(parsedFiles).forEach(key => {
            const file = parsedFiles[`${key}`];
            if(file) formData.append(key, file);
        });
        //

        try {
            setLoading(true);
            await updateServiceRequest(srId, formData, axiosPrivate);
            await fetSrData(); //To get links again and to load SrData

            setTextPopupHead('Success');
            setTextPopupDescription('Successfully updated client details');
            setTextPopupDisabled(false);
            popupFun.current = () => setTextPopupDisabled(true);
        } catch (error) {
            setTextPopupHead('Alert');
            setTextPopupDescription(error.message || 'Unable to save data');
            setTextPopupDisabled(false);
            popupFun.current = () => setTextPopupDisabled(true);
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = async (values) => {
        const parsedFiles = parseDataForSubmit(values);
        
        try {
            setLoading(true);
            const data = await createMasterClient(formik.values.organizationName, srId, parsedFiles, axiosPrivate);
            console.log(data);

            setTextPopupHead('Success');
            setTextPopupDescription(`Master client created successfully with id: ${data.masterClientId}`);
            setTextPopupDisabled(false);
            popupFun.current = () => navigate('/view-requests');
        } catch (error) {
            setTextPopupHead('Alert');
            setTextPopupDescription(error.message || 'Unable to create client');
            setTextPopupDisabled(false);
            popupFun.current = () => setTextPopupDisabled(true);
        } finally {
            setLoading(false);
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: handleSubmit
    });

    useEffect(() => {
        let tempCanSubmit = true;
        Object.keys(formik.values).forEach(key => {
            const formVal = formik.values[`${key}`];
            const srVal = srData[`${key}`];
            if(typeof formVal != typeof srVal) {
                tempCanSubmit = false;
            } else if(typeof formVal === 'object') {
                const formStr = JSON.stringify(Object.keys(formVal).sort().reduce((obj, key) => { 
                        obj[key] = formVal[key]; 
                        return obj;
                    },{}));
                const srStr = JSON.stringify(Object.keys(srVal).sort().reduce((obj, key) => { 
                        obj[key] = srVal[key]; 
                        return obj;
                    },{}));
                if(formStr != srStr) {
                    tempCanSubmit = false;
                }
            } else if(formVal != srVal) {
                tempCanSubmit = false;
            }
        })

        setCanSubmit(tempCanSubmit);
    }, [formik.values, srData]);

    return (
        <>
            {loading && <Loader />}
            <PopUpManager 
                disable={textPopupDisabled}
                control='text'
                heading={textPopupHead}
                description={textPopupDescription}
                onClose={popupFun.current}
            />
            <form 
                className='bg-white rounded-[12px] py-1'
                onSubmit={formik.handleSubmit} 
            >
                <CompanyDetails 
                    formik={formik} 
                />

                <hr className='mx-16 my-20' />

                <AuthPersonInfo 
                    formik={formik}
                />

                <hr className='mx-16 my-20' />

                <InvoicePaymentContact 
                        formik={formik}
                />

                <hr className='mx-16 my-20' />

                <SubAdmins 
                    formik={formik}
                />

                <hr className='mx-16' />

                <SubUsers 
                    formik={formik}
                />

                <hr className='mx-16 mb-20 mt-10' />

                <p className='text-[#182AB4] text-5xl mb-10 mx-16 font-semibold'> Cost Structure</p>
                <Solutions 
                    formik={formik}
                />
                <Services 
                    formik={formik}
                />

                <hr className='mx-16 my-20' />

                <div className='mx-[5%]'>
                    <Preview 
                        gradAngle={formik.values.gradientAngle}
                        logo={formik.values.clientLogo}
                        logoBgColor={formik.values.backgroundColor}
                        primaryColor={formik.values.primaryColor}
                        secondaryColor={formik.values.secondaryColor}
                        favicon={formik.values.clientFavIcon}
                        includeFooter={formik.values.isfooterIncluded === "" || formik.values.isfooterIncluded === "true" || formik.values.isfooterIncluded === true}
                        organizationName={formik.values.organizationName}
                    />
                </div>

                <hr className='mx-16 my-20' />

                <AppDetails 
                    formik={formik} 
                />

                <hr className='mx-16 my-20' />

                <OtpDetails
                    formik={formik}
                />

                <hr className='mx-16 my-20' />

                <Comment
                    formik={formik}
                />

                <hr className='mx-16 my-20' />

                <BackgroundVerification 
                    formik={formik}
                />

                <hr className='mx-16 my-20' />

                <div className={`flex justify-between items-center gap-20 mx-16 mb-10`}>
                    <div className='w-[35rem]'>
                        <SubmitButtonWide 
                            value='Save as Draft'
                            type='button'
                            onClick={handleSave}
                            disabled={canSubmit}
                        />
                    </div>

                    <div className='w-[35rem]'>
                        <SubmitButtonWide 
                            value='Create Master Client'
                            type='submit'
                            disabled={!canSubmit}
                            onClick={() => console.log(formik.errors, formik.touched)}
                        />
                    </div>
                </div>
            </form>
        </>
    );
}

export default FormManager;
