import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import BlueButton from '../../../../components/ui/buttons/blueButton'

function ProductDetails() {

    const navigate = useNavigate();
    const state = useLocation().state;
    const product = state?.product;

    useEffect(() => {
        if(!state || !product)
            navigate('/customer-profile');
    }, []);

    return (
        <div className='bg-white w-[100%] mx-auto border border-navBorderLight rounded-[12px] my-20'>
            <div className='p-20 flex gap-20 flex-wrap md:flex-nowrap'>
                <div className="md:w-[25%] w-full">
                    <div className="flex gap-10 mb-2">
                        <h4 className='text-sideBarActions font-semibold text-font22'>Warranty left <span className='text-black font-semibold text-font22'>364 days</span></h4>
                    </div>
                    <div className="img-wrapper flex justify-center items-center">
                        <img className="mb-4 w-auto h-full mx-auto md:mx-0" src={product?.imageProd} alt="profile-img" />
                    </div>
                </div>

                <div className="md:w-[75%] w-full">
                    {/* <div className='flex justify-center md:justify-end items-center mb-5'>
                        <div className="md:w-[30%] w-[100%]">
                            <RedButton label={'View Bill'} name={'view_bill'} type={'button'} width={'full'} fontSize={'font20'} />
                        </div>
                    </div> */}
                    <div>
                        <h4 className='text-black font-semibold text-font20'>Product Info:</h4>
                        {product?.brand && <p className='text-black font-semibold text-[15px] my-10'>Brand Name : <span className='font-normal'>{product?.brand}</span></p>}
                        {product?.name && <p className='text-black font-semibold text-[15px] my-10'>Product Name : <span className='font-normal'>{product?.name}</span></p>}
                        {product?.price && <p className='text-black font-semibold text-[15px] my-10'>MRP : <span className='font-normal'>{product?.price}</span></p>} {/* ₹ */}
                        {product?.mfgdate &&  <p className='text-black font-semibold text-[15px] my-10'>Mfg Date : <span className='font-normal'>{(product?.mfgdate === "notAvailable" || product?.mfgdate === "notApplicable") ? product?.mfgdate : product?.mfgdate?.slice(0, 10)}</span></p>}
                        {product?.shelflife && <p className='text-black font-semibold text-[15px] my-10'>Shelf Life : <span className='font-normal'>{product?.shelflife}</span></p>}
                        {product?.expiryDate && <p className='text-black font-semibold text-[15px] my-10'>Expiry Date : <span className='font-normal'>{(product?.expiryDate === "notAvailable" || product?.expiryDate === "notApplicable") ? product?.expiryDate : product?.expiryDate.slice(0, 10)}</span></p>}
                        {product?.batchNo && <p className='text-black font-semibold text-[15px] my-10'>Batch No. : <span className='font-normal'>{product?.batchNo}</span></p>}
                        {product?.serialNo && <p className='text-black font-semibold text-[15px] my-10'>Serial No : <span className='font-normal'>{product?.serialNo}</span></p>}
                        {product?.warrantyApp && product?.warrantyPeriod && <p className='text-black font-semibold text-[15px] my-10'>Warranty : <span className='font-normal'>{product?.warrantyApp ? `This product comes with ${product?.warrantyPeriod} days warranty`: 'This product has no warranty'}</span></p>}
                        {product?.manuLicenseNo && <p className='text-black font-semibold text-[15px] my-10'>Manufacturer License no : <span className='font-normal'>{product?.manuLicenseNo}</span></p>}
                        {product?.manuAddress && <p className='text-black font-semibold text-[15px] my-10'>Manufacturer Address :<br></br><span className='font-normal'>{product?.manuAddress}</span></p>}
                        {product?.addtionalDetails && <pre className='text-black font-semibold text-[15px] my-10'>Additional Details :<br></br><span style={{whiteSpace: 'pre-wrap'}} className='font-normal'>{product?.addtionalDetails}</span></pre>}
                        {(product?.additionalImageDetails || (product?.addtionalImages && product?.addtionalImages[0]?.length)) && <p className='text-black font-semibold text-[15px] my-10'>Additional Images :<br></br><span className='font-normal'>{product?.additionalImageDetails}</span></p>}
                        <div className="flex justify-center md:justify-start flex-wrap">
                            {
                                product?.addtionalImages && Array.isArray(product?.addtionalImages[0]) && product?.addtionalImages[0]?.map((addImage, i) => {
                                    return <img key={i} src={addImage.url} alt="Additional" className='h-[15rem] mr-5 mb-5' />
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="m-20">
                <div className="flex mx-auto gap-10">
                    {product?.manuWebsiteLink && <BlueButton label={'Web link'} type={'button'} height={'[46px]'} width={'full'} fontSize={'font20'} 
                        onClick={() => window.open(product?.manuWebsiteLink, "_blank")}
                    />}
                    {product?.manuWebsiteLink && <BlueButton label={'Warranty Terms and Conditions'} type={'button'} height={'[46px]'} width={'full'} fontSize={'font20'} 
                        onClick={() => {
                            navigate('/warranty-terms-and-conditions', {
                                state: {
                                    warrantyTC: product.warrantyConditions
                                }
                            })
                        }}
                    />}
                    {/* <BlueButton label={'Track'} name={'view_bill'} type={'button'} height={'[46px]'} width={'full'} fontSize={'font20'} /> */}
                </div>
            </div>
        </div>
    )
}

export default ProductDetails