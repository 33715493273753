import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import veotsLogo from '../../../assets/brand/veots_logo_new.png'
import Poppins from '../../../assets/fonts/Poppins/Poppins-Regular.ttf'
import PoppinsSemiBold from '../../../assets/fonts/Poppins/Poppins-SemiBold.ttf';
import PoppinsBold from '../../../assets/fonts/Poppins/Poppins-Bold.ttf'

import downArrowIcon from './downArrow.png';
import rightArrowIcon from './rightArrow.png';

import { useTempData } from './financeTableContent';

Font.register({
    family: 'Poppins',
    fonts: [
        { src: Poppins, fontWeight: 'normal' },
        { src: PoppinsSemiBold, fontWeight: 'semibold' },
        { src: PoppinsBold, fontWeight: 'bold' },
    ],
});

const Pdf = ({ clientId, month, year, inVoiceNum }) => {
    
    const { getData } = useTempData();
    const {
        qrgeneration,qrgenerationSubheading,qrgenerationTableContents,
        tracking,trackingSubheading,trackingTableContents,
        coupons,couponsSubheading,couponTableContents,
        biUserCost,biUserCostSubheading,biUserCostTableContents,
        misc,miscSubheading,miscTableContents,
        customerSupport,
        totalInvoiceAmtTableContents,
        amountINR
    } = getData(month);

    console.log(month, "Changed month yuup")

    return (
        <Document>
            <Page1 
                clientId={clientId} 
                month={month} 
                year={year}
                qrgeneration={qrgeneration}
                tracking={tracking}
                coupons={coupons}
                biUserCost={biUserCost}
                misc={misc}
                customerSupport={customerSupport}
                totalInvoiceAmtTableContents={totalInvoiceAmtTableContents}
                amountINR={amountINR}
                inVoiceNum={inVoiceNum}
            />
            <Page2 
                qrgeneration={qrgeneration}
                qrgenerationSubheading={qrgenerationSubheading}
                qrgenerationTableContents={qrgenerationTableContents}
                tracking={tracking}
                trackingSubheading={trackingSubheading}
                trackingTableContents={trackingTableContents}
                coupons={coupons}
                couponsSubheading={couponsSubheading}
                couponTableContents={couponTableContents}
                biUserCost={biUserCost}
                biUserCostSubheading={biUserCostSubheading}
                biUserCostTableContents={biUserCostTableContents}
                misc={misc}
                miscSubheading={miscSubheading}
                miscTableContents={miscTableContents}
                customerSupport={customerSupport}
                totalInvoiceAmtTableContents={totalInvoiceAmtTableContents}
                amountINR={amountINR}
            />
        </Document>
    )
}

const Page1 = ({ clientId, month, year, qrgeneration, tracking, coupons, biUserCost, misc, customerSupport, totalInvoiceAmtTableContents, amountINR, inVoiceNum }) => {
    
    const monthValue = month === 'January' ? '01' : month === 'February' ? '02' : month === 'March' ? '03' : month === 'April' ? '04' : '05';
    function convertNumberToWords(number) {
        number = number.split('').filter && number.split('').filter(item => item !== ',').join('');

        var ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
        var tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
        var teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];

        function convert_millions(num) {
            if (num >= 1000000) {
                return convert_millions(Math.floor(num / 1000000)) + " million " + convert_thousands(num % 1000000);
            } else {
                return convert_thousands(num);
            }
        }

        function convert_thousands(num) {
            if (num >= 1000) {
                return convert_hundreds(Math.floor(num / 1000)) + " thousand " + convert_hundreds(num % 1000);
            } else {
                return convert_hundreds(num);
            }
        }

        function convert_hundreds(num) {
            if (num > 99) {
                return ones[Math.floor(num / 100)] + " hundred " + convert_tens(num % 100);
            } else {
                return convert_tens(num);
            }
        }

        function convert_tens(num) {
            if (num < 10) return ones[num];
            else if (num >= 10 && num < 20) return teens[num - 10];
            else {
                return tens[Math.floor(num / 10)] + " " + ones[num % 10];
            }
        }

        function convert(num) {
            if (num == 0) return "zero";
            else return convert_millions(num);
        }
    
        return convert(number);
    }
    
    return (
        <Page style={styles.page}>
            <View style={styles.header}>
                <Image src={veotsLogo} style={styles.logo} />

                <View style={styles.headerDetailsContainer}>
                    <Text style={[styles.text, { textDecoration: 'underline', marginBottom: 5 }]}>log in to <Text style={styles.link}>clients.veotrs.in</Text> to view details</Text>
                    <View style={styles.amountContainer}>
                        <Text style={[styles.amountContainerText, { borderBottomWidth: 1, borderColor: '#E5E5F0' }]}>Total Invoice Amount</Text>
                        <Text style={[styles.amountContainerText, { color: 'blue' }]}>{amountINR[0]}</Text>
                    </View>
                </View>
            </View>
            {/* <View style={styles.line} /> */}

            <View style={styles.dataContainer}>
                <Text style={styles.clientId} >Master Client ID: {clientId}</Text>

                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <View style={styles.clientDetails}>
                        <Text style={[styles.headText, { marginBottom: 5 }]}>Attention: Shanmugham Arya</Text>
                        <Text style={[styles.text, { marginBottom: 10 }]}>{`Reebok Pvt Limited
                                No: 567, 13th cross, 15th A main, Vijayanagar, Bangalore
                                Karnataka PIN: 560091`}</Text>

                        <Text style={[styles.text, { display: 'flex', alignItems: 'center' }]}><Text style={styles.headText}>GSTIN/UIN: </Text> 29AACTIN87987AC</Text>
                        <Text style={[styles.text, { display: 'flex', alignItems: 'center' }]}><Text style={styles.headText}>Ph/UIN: </Text> 9886024465</Text>
                        <Text style={[styles.text, { display: 'flex', alignItems: 'center' }]}><Text style={styles.headText}>Email address: </Text> shanmugham.Arya@wipro.com</Text>
                    </View>

                    <View style={styles.billDetails}>
                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginVertical: 2 }}>
                            <Text style={styles.text}>Bill Generation date: </Text>
                            <Text style={styles.text}>29/{monthValue}/{year}</Text>
                        </View>
                        
                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginVertical: 2 }}>
                            <Text style={styles.text}>Billing Period: </Text>
                            <Text style={styles.text}>01/{monthValue}/{year} to 29/{monthValue}/{year}</Text>
                        </View>
                        
                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginVertical: 2 }}>
                            <Text style={styles.text}>Invoice date: </Text>
                            <Text style={styles.text}>01/{monthValue}/{year}</Text>
                        </View>

                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginVertical: 2 }}>
                            <Text style={styles.text}>Payable by: </Text>
                            <Text style={styles.text}>15/{monthValue}/{year}</Text>
                        </View>

                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginVertical: 2 }}>
                            <Text style={styles.text}>Invoice Number: </Text>
                            <Text style={styles.text}>{inVoiceNum}</Text>
                        </View>

                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginVertical: 2 }}>
                            <Text style={styles.text}>Place of service: </Text>
                            <Text style={styles.text}>Karnataka</Text>
                        </View>
                    </View>
                </View>

                <View style={styles.dataTable}>
                    <View style={[styles.tableHead, styles.tableRow]}>
                        <Text style={styles.headText}>SUMMARY</Text>
                        <Text style={styles.headText}>{amountINR[0]}</Text>
                    </View>

                    <View style={styles.tableRow}>
                        <Text style={styles.text}>QR Generation</Text> 
                        <Text style={styles.text}>{qrgeneration[5]}</Text>
                    </View>

                    <View style={styles.tableRow}>
                        <Text style={styles.text}>Tracking</Text>
                        <Text style={styles.text}>{tracking[5]}</Text>
                    </View>

                    <View style={styles.tableRow}>
                        <Text style={styles.text}>Coupons</Text>
                        <Text style={styles.text}>{coupons[5]}</Text>
                    </View>

                    <View style={styles.tableRow}>
                        <Text style={styles.text}>BI User Cost</Text>
                        <Text style={styles.text}>{biUserCost[5]}</Text>
                    </View>

                    <View style={styles.tableRow}>
                        <Text style={styles.text}>Miscellaneous</Text>
                        <Text style={styles.text}>{misc[5]}</Text>
                    </View>

                    <View style={[styles.tableRow, {borderWidth: 0}]}>
                        <Text style={styles.text}>Customer Support</Text>
                        <Text style={styles.text}>{customerSupport[5]}</Text>
                    </View>
                </View>

                <Text style={[styles.text, { color: '#100588', marginBottom: 25 }]}>Amount Payable (in words) : <Text style={styles.headText}>{convertNumberToWords(amountINR[0].slice(0, 10))}</Text> </Text>
                <Text style={[styles.text, { color: '#FF5E5E', textDecoration: 'underline' }]}>Note: Defaulting on due date would attract automatic invalidation of services availed from KSJ Rudh Private Limited</Text>
                <Text style={[styles.text, { color: '#00000080' }, { fontSize: 6 }]}>Please Note: </Text>
                <Text style={[styles.text, { color: '#00000080' }, { fontSize: 6 }]}>1. This is digitally printed invoice; Invoice date may vary based on the date it is printed/generated.</Text>
                <Text style={[styles.text, { color: '#00000080' }, { fontSize: 6 }]}>2. Invoice overall amount will remain the same, however the amount due might change if the invoice amount is partially paid during the month.</Text>
            </View>

            <Footer />
        </Page>
    )
}

const Page2 = ({ qrgeneration, qrgenerationSubheading, qrgenerationTableContents, tracking, trackingSubheading, trackingTableContents, coupons, couponsSubheading, couponTableContents, biUserCost, biUserCostSubheading, biUserCostTableContents, misc, miscSubheading, miscTableContents, customerSupport, totalInvoiceAmtTableContents, amountINR }) => {
    return (
        <Page style={styles.page} size={{ height: 1000, width: 595.28 }}>
            <View style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
                <Text style={[styles.text, { fontSize: 15, fontWeight: 'bold', color: '#FF0303', marginBottom: 20 }]}>Your Generation Details</Text>

                <View style={styles.dataHeadContainer}>
                    <Text style={styles.headText}>Estimated Total Amount (A+B+C+D+E+F)</Text>
                    <Text style={styles.headText}>{amountINR[0]}</Text>
                </View>

                <Text style={[styles.text, { fontSize: 13, color: '#100588', fontWeight: 'bold', marginBottom: 10 }]} >Details</Text>
                <View style={styles.dataTableHeader}>
                    <View style={{  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <View style={styles.arrowContainer} />
                        <Text style={styles.headText}>Services Availed</Text>
                    </View>
                    <View style={{ width: '40%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>HSN/SAC</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>IGST</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>CGST</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>SGST</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>Amount</Text>
                    </View>
                </View>

                {/* QR */}
                <View style={styles.genreHeader}>
                    <View style={{  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <View style={styles.arrowContainer}>
                            <Image 
                                source={rightArrowIcon}
                                style={{ height: '100%', width: '100%', padding: 5 }}
                            />
                        </View>
                        <Text style={styles.headText}>QR Generation </Text>
                    </View>

                    <View style={{ width: '40%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>403625</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>@9%</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>@9%</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>{`(A)`}</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>{qrgeneration[5]}</Text>
                    </View>
                </View>
                <View style={styles.genreHeadItem}>
                    <View style={{ width: '60%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <View style={styles.arrowContainer}>
                            <Image 
                                source={downArrowIcon}
                                style={{ height: '100%', width: '100%', padding: 5 }}
                            />
                        </View>
                        {
                            qrgenerationSubheading.map((item, ind) => {
                                return <Text key={ind} style={[styles.text, { flex: ind === 0? 2 : 1, padding: 2, fontSize: 6, textAlign: 'center' }]}>{item}</Text>
                            })
                        }
                    </View>
                </View>
                {
                    qrgenerationTableContents.map((rowData, ind) => {
                        return <View style={styles.genreBodyItem} key={ind}>
                            <View style={{ width: '60%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <View style={styles.arrowContainer}>
                                    <Text style={styles.headText} >-</Text>
                                </View>
                                {
                                    Object.keys(rowData).slice(0, 8).map((key, index) => {
                                        return <Text key={index} style={[styles.text, { flex: index === 0? 2 : 1, padding: 2, fontSize: 6, textAlign: 'center'  }]}>{rowData[key]}</Text>
                                    })
                                }
                            </View>
                            <View style={{ width: '40%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}></Text>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}>{rowData.cgst}</Text>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}>{rowData.sgst}</Text>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}></Text>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}>{rowData.amount}</Text>
                            </View>
                        </View>
                    })
                }
                
                {/* Tracking */}
                <View style={styles.genreHeader}>
                    <View style={{  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <View style={styles.arrowContainer}>
                            <Image 
                                source={rightArrowIcon}
                                style={{ height: '100%', width: '100%', padding: 5 }}
                            />
                        </View>
                        <Text style={styles.headText}>Tracking </Text>
                    </View>

                    <View style={{ width: '40%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>403625</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>@9%</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>@9%</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>{`(B)`}</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>{tracking[5]}</Text>
                    </View>
                </View>
                <View style={styles.genreHeadItem}>
                    <View style={{ width: '60%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <View style={styles.arrowContainer}>
                            <Image 
                                source={downArrowIcon}
                                style={{ height: '100%', width: '100%', padding: 5 }}
                            />
                        </View>
                        {
                            trackingSubheading.map((item, ind) => {
                                return <Text key={ind} style={[styles.text, { flex: ind === 0? 2 : 1, padding: 2, fontSize: 6, textAlign: 'center' }]}>{item}</Text>
                            })
                        }
                    </View>
                </View>
                {
                    trackingTableContents.map((rowData, ind) => {
                        return <View style={styles.genreBodyItem} key={ind}>
                            <View style={{ width: '60%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <View style={styles.arrowContainer}>
                                    <Text style={styles.headText} >-</Text>
                                </View>
                                {
                                    Object.keys(rowData).slice(0, 5).map((key, index) => {
                                        return <Text key={index} style={[styles.text, { flex: index === 0? 2 : 1, padding: 2, fontSize: 6, textAlign: 'center'  }]}>{rowData[key]}</Text>
                                    })
                                }
                            </View>
                            <View style={{ width: '40%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}></Text>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}>{rowData.cgst}</Text>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}>{rowData.sgst}</Text>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}></Text>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}>{rowData.amount}</Text>
                            </View>
                        </View>
                    })
                }

                {/* COUPONS */}
                <View style={styles.genreHeader}>
                    <View style={{  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <View style={styles.arrowContainer}>
                            <Image 
                                source={rightArrowIcon}
                                style={{ height: '100%', width: '100%', padding: 5 }}
                            />
                        </View>
                        <Text style={styles.headText}>Coupons </Text>
                    </View>

                    <View style={{ width: '40%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>403625</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>@9%</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>@9%</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>{`(C)`}</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>{coupons[5]}</Text>
                    </View>
                </View>
                <View style={styles.genreHeadItem}>
                    <View style={{ width: '60%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <View style={styles.arrowContainer}>
                            <Image 
                                source={downArrowIcon}
                                style={{ height: '100%', width: '100%', padding: 5 }}
                            />
                        </View>
                        {
                            couponsSubheading.map((item, ind) => {
                                return <Text key={ind} style={[styles.text, { flex: ind === 0? 2 : 1, padding: 2, fontSize: 6, textAlign: 'center' }]}>{item}</Text>
                            })
                        }
                    </View>
                </View>
                {
                    couponTableContents.map((rowData, ind) => {
                        return <View style={styles.genreBodyItem} key={ind}>
                            <View style={{ width: '60%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <View style={styles.arrowContainer}>
                                    <Text style={styles.headText} >-</Text>
                                </View>
                                {
                                    Object.keys(rowData).slice(0, 3).map((key, index) => {
                                        return <Text key={index} style={[styles.text, { flex: index === 0? 2 : 1, padding: 2, fontSize: 6, textAlign: 'center'  }]}>{rowData[key]}</Text>
                                    })
                                }
                            </View>
                            <View style={{ width: '40%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}></Text>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}>{rowData.cgst}</Text>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}>{rowData.sgst}</Text>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}></Text>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}>{rowData.amount}</Text>
                            </View>
                        </View>
                    })
                }

                {/* BI */}
                <View style={styles.genreHeader}>
                    <View style={{  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <View style={styles.arrowContainer}>
                            <Image 
                                source={rightArrowIcon}
                                style={{ height: '100%', width: '100%', padding: 5 }}
                            />
                        </View>
                        <Text style={styles.headText}>BI User Cost </Text>
                    </View>

                    <View style={{ width: '40%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>403625</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>@9%</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>@9%</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>{`(D)`}</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>{biUserCost[5]}</Text>
                    </View>
                </View>
                <View style={styles.genreHeadItem}>
                    <View style={{ width: '60%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <View style={styles.arrowContainer}>
                            <Image 
                                source={downArrowIcon}
                                style={{ height: '100%', width: '100%', padding: 5 }}
                            />
                        </View>
                        {
                            biUserCostSubheading.map((item, ind) => {
                                return <Text key={ind} style={[styles.text, { flex: ind === 0? 2 : 1, padding: 2, fontSize: 6, textAlign: 'center' }]}>{item}</Text>
                            })
                        }
                    </View>
                </View>
                {
                    biUserCostTableContents.map((rowData, ind) => {
                        return <View style={styles.genreBodyItem} key={ind}>
                            <View style={{ width: '60%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <View style={styles.arrowContainer}>
                                    <Text style={styles.headText} >-</Text>
                                </View>
                                {
                                    Object.keys(rowData).slice(0, 3).map((key, index) => {
                                        return <Text key={index} style={[styles.text, { flex: index === 0? 2 : 1, padding: 2, fontSize: 6, textAlign: 'center' }]}>{rowData[key]}</Text>
                                    })
                                }
                            </View>
                            <View style={{ width: '40%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}></Text>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}>{rowData.cgst}</Text>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}>{rowData.sgst}</Text>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}></Text>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}>{rowData.amount}</Text>
                            </View>
                        </View>
                    })
                }

                {/* MISC */}
                <View style={styles.genreHeader}>
                    <View style={{  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <View style={styles.arrowContainer}>
                            <Image 
                                source={rightArrowIcon}
                                style={{ height: '100%', width: '100%', padding: 5 }}
                            />
                        </View>
                        <Text style={styles.headText}>Miscellaneous </Text>
                    </View>

                    <View style={{ width: '40%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>403625</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>@9%</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>@9%</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>{`(E)`}</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>{misc[5]}</Text>
                    </View>
                </View>
                <View style={styles.genreHeadItem}>
                    <View style={{ width: '60%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <View style={styles.arrowContainer}>
                            <Image 
                                source={downArrowIcon}
                                style={{ height: '100%', width: '100%', padding: 5 }}
                            />
                        </View>
                        {
                            miscSubheading.map((item, ind) => {
                                return <Text key={ind} style={[styles.text, { flex: ind === 0? 2 : 1, padding: 2, fontSize: 6, textAlign: 'center' }]}>{item}</Text>
                            })
                        }
                    </View>
                </View>
                {
                    miscTableContents.map((rowData, ind) => {
                        return <View style={styles.genreBodyItem} key={ind}>
                            <View style={{ width: '60%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <View style={styles.arrowContainer}>
                                    <Text style={styles.headText} >-</Text>
                                </View>
                                {
                                    Object.keys(rowData).slice(0, 5).map((key, index) => {
                                        return <Text key={index} style={[styles.text, { flex: index === 0? 2 : 1, padding: 2, fontSize: 6, textAlign: 'center'  }]}>{rowData[key]}</Text>
                                    })
                                }
                            </View>
                            <View style={{ width: '40%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}></Text>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}>{rowData.cgst}</Text>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}>{rowData.sgst}</Text>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}></Text>
                                <Text style={[styles.text, { flex: 1, fontSize: 6, textAlign: 'center' }]}>{rowData.amount}</Text>
                            </View>
                        </View>
                    })
                }

                {/* Customer Support */}
                <View style={styles.genreHeader}>
                    <View style={{  display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <View style={styles.arrowContainer} />
                        <Text style={styles.headText}>Customer Support </Text>
                    </View>

                    <View style={{ width: '40%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>403625</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>@9%</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>@9%</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>{`(F)`}</Text>
                        <Text style={[styles.text, { flex: 1, textAlign: 'center' }]}>{customerSupport[5]}</Text>
                    </View>
                </View>

                <View style={{ padding: 5, marginVertical: 10, borderRadius: 5, borderWidth: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={styles.text}>{totalInvoiceAmtTableContents[0]['invoiceAmt']}</Text>
                    <View style={{ width: '40%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={[styles.text, { color: '#C90404' }]}>{totalInvoiceAmtTableContents[0]['value']}</Text>
                        <Text style={styles.text}>{totalInvoiceAmtTableContents[0]['amount']}</Text>
                    </View>
                </View>

                {/* Final Calculcation for Amount Due */}
                <View style={styles.totalAmountContainer}>
                    <View style={{ padding: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', borderBottomWidth: 1 }}>
                        <Text style={[styles.text]} >Amount in IN (₹)</Text>
                    </View>

                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>
                        <Text style={[styles.text]} >Overall Amount Due</Text>
                        <Text style={[styles.text]} >{amountINR[0]}</Text>
                    </View>

                    {/* <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>
                        <Text style={[styles.text]} >Amount Cleared (during current month)</Text>
                        <Text style={[styles.text]} >0</Text>
                    </View> */}

                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>
                        <Text style={[styles.text]} >Amount Received</Text>
                        <Text style={[styles.text]} >0</Text>
                    </View>

                    {/* <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>
                        <Text style={[styles.text]} >Discount</Text>
                        <Text style={[styles.text]} >0</Text>
                    </View> */}

                    <View style={{ padding: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', borderTopWidth: 1 }}>
                        <Text style={[styles.text]} >{amountINR[0]}</Text>
                    </View>
                </View>
            </View>
            
            <Footer />
        </Page>
    )
}

const Footer = () => {
    return (
        <View style={styles.footer}>
            <Text style={styles.text}>Registered office at:</Text>
            <Text style={[styles.text, { fontSize: 15, color: '#100588', fontWeight: 'bold' }]}>KSJ Rudh Private Limited</Text>
            <Text style={styles.text}>No: S693, 12th Cross, 15th Main, Bharathnagar 2nd stage, BEL Layout, Bangalore, Karnataka, INDIA - 560091</Text>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={[styles.text, { textAlign: 'center' ,width: '30%', borderColor: '#E5E5F0', borderRightWidth: 1 }]}>CIN: U72900KA2019PTC124408</Text>
                <Text style={[styles.text, { textAlign: 'center' ,width: '30%' }]}>GST: 29AAHCK6378H1ZY</Text>
            </View>

            <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={[styles.text, { textAlign: 'center' ,width: '40%' }]}>For Queries Write to: <Text style={{ color: '#100588', textDecoration: 'underline' }}>help@veots.com</Text></Text>
                <Text style={[styles.text, { textAlign: 'center' ,width: '40%' }]}>Contact: <Text style={{ color: '#100588', textDecoration: 'underline' }}>9632123543 / 8971347758</Text></Text>
            </View>

            <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', backgroundColor: '#FAFAFF', width: '100%', paddingTop: 10, marginTop: 10 }}>
                <Text style={[styles.text, { fontSize: 15, textAlign: 'center', color: '#100588', fontWeight: 'bold' }]}>Please make payment to our Corporate Bank Account</Text>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <Text style={[styles.text, { textAlign: 'center' ,width: '30%', borderColor: '#E5E5F0', borderRightWidth: 1 }]}>Account Number:  231705001861</Text>
                    <Text style={[styles.text, { textAlign: 'center' ,width: '30%', borderColor: '#E5E5F0', borderRightWidth: 1 }]}>Branch Code:  2317</Text>
                    <Text style={[styles.text, { textAlign: 'center' ,width: '30%' }]}>IFSC Code :  ICIC0002317</Text>
                </View>

                <View style={[styles.line, { marginTop: 5 }]} />

                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <Text style={[styles.text, { textAlign: 'center' ,width: '30%', paddingVertical: 10, color: '#265510' }]}>Think before printing , SAVE TREES</Text>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    page: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    text: {
        fontSize: 8,
        fontFamily: 'Poppins',
    },
    headText: {
        fontSize: 10,
        fontWeight: 'semibold',
        fontFamily: 'Poppins',
    },
    line: {
        borderColor: 'black',
        borderWidth: 0,
        borderTopWidth: 1,
        width: '100%',
        borderStyle: 'solid',
    },
    
    header :{
        padding: 25,
        height: '15%',
        marginBottom: 10,

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    headerDetailsContainer: {
        width: '40%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    amountContainer: {
        backgroundColor: '#FAFAFF',
        borderColor: '#E5E5F0',
        borderWidth: 1,
        borderRadius: 10,
        width: '100%',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    amountContainerText: {
        fontSize: 12,
        paddingVertical: 5,
        textAlign: 'center',
        width: '100%',
        fontFamily: 'Poppins',
        fontWeight: 'semibold',
    }, 
    link: {
        color: 'blue',
    },
    dataContainer: {
        backgroundColor: '#FAFAFF',
        paddingHorizontal: 25,
        paddingVertical: 10,
        marginVertical: 10,
    },  
    clientId: {
        fontSize: 12,
        marginBottom: 5,
        color: 'blue',
        fontFamily: 'Poppins',
        fontWeight: 'semibold',
    },
    clientDetails: {},
    billDetails: {
        backgroundColor: 'white',
        padding: 10,
        width: '40%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: '#E5E5F0',
        borderWidth: 1,
        borderRadius: 10,
    },
    dataTable: {
        marginTop: 25,
        marginBottom: 5,
        backgroundColor: 'white',
        borderColor: '#E5E5F0',
        borderWidth: 1,
        borderRadius: 10,
        overflow: 'hidden',
    },
    tableRow: {
        padding: 7,
        borderColor: '#E5E5F0',
        borderBottomWidth: 1,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    tableHead: {
        backgroundColor: '#182AB411',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    //page two
    dataHeadContainer: {
        padding: 5,
        marginBottom: 10,
        borderColor: 'black',
        borderBottomWidth: 1,
        borderStyle: 'solid',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    dataTableHeader: {
        padding: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#182AB411',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
    },
    genreHeader: {
        padding: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    genreHeadItem: {
        padding: 3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#182AB411',
    },
    genreBodyItem: {
        padding: 3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(0,0,0,0.03)',
    },
    arrowContainer: {
        height:15,
        width: 15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    totalAmountContainer: {
        backgroundColor: 'white',
        borderWidth: 1,
        borderRadius: 5,
    }
})

export default Pdf;