import React, { useEffect, useRef, useState } from 'react';

import downloadIcon from '../../assets/svg/download.svg';
import removedExifFile from '../../utils/files/exifRemover';
import Info from './components/Info';

const FileUpload = ({
    label='',
    message='',
    name, 
    defaultFileName='',
    formik,
    marginClass='mx-auto',
    tailwindClasses='',    
    acceptableFilesFormats='.png, .jpg, .jpeg, .pdf',
    disabled=false,
    fileSizeLimit=5,
    }) => {

    const inpRef = useRef(null);
    const [fileName, setFileName] = useState(defaultFileName || 'No File Chosen');
    useEffect(() => {
        setFileName(defaultFileName || 'No File Chosen')
    }, [defaultFileName]);

    const handleDownloadFile = () => {
        if(!formik?.values[`${name}`] || formik?.values[`${name}`] instanceof File) return;

        const link = document.createElement('a');
        link.href = formik?.values[`${name}`];
        link.target = '_blank';
        link.click();
    }

    const handleFIleUpload = (e) => {
        let file = e.target.files[0];
        if(!file) return;

        const MAX_FILE_SIZE = fileSizeLimit * 1024 * 1024;
        if(file.size > MAX_FILE_SIZE) {
            alert(`File size should be less than ${fileSizeLimit}MB`);
        return;
        }

        setFileName(file.name);
        // file = new File([file], `${Math.floor(Math.random()*1000000)}.${file.type.split('/')[1]}`, { type: file.type });

        if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
            removedExifFile(file).then((modifiedFile) => {
                formik?.setFieldValue(name, modifiedFile);
            });
        } else {
            formik?.setFieldValue(name, file);
        }
    }

    return (
        <div
            className={`w-full flex flex-col ${marginClass}`}
        >
            {
                label && <div className='flex pb-2 items-center'>
                    <label htmlFor={name} className='text-3xl mr-2'>{label}</label>
                    {message && <Info message={message} />}
                </div>
            }
            <input 
                type='file' 
                name={name} 
                id={name}
                // value={formik?.values[`${name}`]}
                onBlur={formik?.handleBlur}
                onChange={e => {
                    handleFIleUpload(e);
                }} 
                className={`hidden`}
                ref={inpRef}
                multiple={false}
                accept={acceptableFilesFormats}
                disabled={disabled}
            />
            <div className={`${tailwindClasses} bg-[#F1F2FA] border p-[.65rem] rounded-md text-base flex items-center justify-between h-[46px]`} >
                <div className='flex items-center'>
                    <button 
                        type='button'
                        className='bg-[#ECECFF] border rounded-md text-2xl p-1 px-5'
                        onClick={() => inpRef.current?.click()}
                    >
                        Choose File
                    </button>
                    <p className='px-5 text-3xl'>{fileName?.length > 15 ? fileName?.slice(0, 15) + '...' : fileName}</p>
                </div>
                <button
                    type='button'
                    className='bg-[#ECECFF] border rounded-md text-2xl p-1 px-5'
                    onClick={handleDownloadFile}
                >
                    <img src={downloadIcon} alt="" />
                </button>
            </div>
            <p className='text-red-500 text-base'>{formik?.touched[`${name}`] && formik?.errors[`${name}`]}&nbsp;</p>
        </div>
    );
}

export default FileUpload;
