import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { SubmitButton } from '../../../../components/ui/buttons/submitButton';
import Loader from '../../../../components/ui/loader/Loader';
import PopUpManager from '../../../../components/popups2/popupManager';
import Reward from './Reward';

import { getAllCustomerCouponsPaginated } from '../services/api';

const FILTER_OPTIONS = {
    LAST30DAYS: 'Last 30 days',
    OLDER: 'Older'
}

function Rewards() {
    const { customerId } = useParams();
    const axiosPrivate = useAxiosPrivate();

    const [ filters, setFilters ] = useState([]);
    const [ rewards, setRewards ] = useState([]);
    const [ currentPageNumber, setCurrentPageNumber ] = useState(1);
    const [ totalPages, setTotalPages ] = useState(1);
    const pageSize = useRef(15);
    const rewardsContainerRef = useRef(null);

    //Popup
    const [ loading, setLoading ] = useState(false);
    const [ textPopupDisabled, setTextPopupDisabled ] = useState(true);
    const [ textPopupDescription, setTextPopupDescription ] = useState('');

    const loadRewards = async (pageNumber, filters) => {
        try {
            setLoading(true);
            const rewardsHistory = await getAllCustomerCouponsPaginated(
                customerId, 
                pageNumber, 
                pageSize.current, 
                filters.includes(FILTER_OPTIONS.LAST30DAYS), 
                filters.filter(ele => (ele !== FILTER_OPTIONS.LAST30DAYS && ele !== FILTER_OPTIONS.OLDER)), 
                filters.includes(FILTER_OPTIONS.OLDER), 
                axiosPrivate
            )
            console.log(rewardsHistory);

            //Scroll to top
            window.scrollTo(0, 0);
            setRewards(rewardsHistory.pageData);
            setTotalPages(Math.ceil(rewardsHistory.totalRewards / pageSize.current));
        } catch (error) {
            setTextPopupDescription(error.message);
            setTextPopupDisabled(false);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        //Load rewards on Page load
        (async () => {
            await loadRewards(currentPageNumber, filters);
        })();
    }, [])

    const handleFilterChanges = async (filter) => {
        const isContain =  filters.find(ele => ele === filter);

        let updatedFilters = [];
        if(isContain) 
            updatedFilters = filters.filter(ele => ele !== filter);
        else if(filter === FILTER_OPTIONS.LAST30DAYS || filter === FILTER_OPTIONS.OLDER) //Only options
            updatedFilters = [filter];
        else {
            const filteredYears = filters.filter(ele => (ele !== FILTER_OPTIONS.LAST30DAYS && ele !== FILTER_OPTIONS.OLDER))
            updatedFilters = [...filteredYears, filter];
        }

        setFilters(updatedFilters);
        setCurrentPageNumber(1);
        await loadRewards(1, updatedFilters)
    }

    return (
        <>
            {loading && <Loader />}
            <PopUpManager 
                disable={textPopupDisabled}
                control='text'
                heading='Alert'
                description={textPopupDescription}
                onClose={() => setTextPopupDisabled(true)}
            />
            <div className='flex flex-wrap md:flex-nowrap justify-between gap-20 my-10'>
                <div className="md:w-[25rem] w-full">
                    <div className="bg-bgWhite border-[1px] border-navBorderLight rounded-3xl">
                        <div className='flex justify-start items-center px-10 py-10 border-b-[1px] border-navBorderLight mb-5'>
                            <h5 className='text-black text-opacity-50 text-font18 font-semibold '>FILTERS</h5>
                        </div>
                        <div className='flex flex-col'>
                            <div className='flex flex-col mb-5'>
                                <div className='flex mx-10 items-center my-4'>
                                    <input 
                                        className='h-7 aspect-square' 
                                        type="checkbox" 
                                        checked={filters.includes(FILTER_OPTIONS.LAST30DAYS)}
                                        onChange={() => handleFilterChanges(FILTER_OPTIONS.LAST30DAYS)}
                                    /> <p className='text-4xl mx-4'>{FILTER_OPTIONS.LAST30DAYS}</p>
                                </div>
                                
                                {
                                    Array.from({ length: 3 }, (_, index) => new Date().getFullYear() - index).map(year => {
                                        return <div key={year} className='flex mx-10 items-center my-4'>
                                            <input 
                                                className='h-7 aspect-square'
                                                type="checkbox" 
                                                checked={filters.includes(year)}
                                                onChange={() => handleFilterChanges(year)}
                                            /> <p className='text-4xl mx-4'>{year}</p>
                                        </div>
                                    })
                                }
                                <div className='flex mx-10 items-center my-4'>
                                    <input 
                                        className='h-7 aspect-square' 
                                        type="checkbox" 
                                        checked={filters.includes(FILTER_OPTIONS.OLDER)}
                                        onChange={() => handleFilterChanges(FILTER_OPTIONS.OLDER)}
                                    /> <p className='text-4xl mx-4'>{FILTER_OPTIONS.OLDER}</p>
                                </div>
                            </div>
                        </div>                                    
                    </div>
                </div>
                            
                <div className="md:w-[calc(100%-25rem)] w-full flex flex-col gap-10" ref={rewardsContainerRef} >
                    {/* //Rewards */}
                    {
                        rewards && Array.isArray(rewards) && rewards.map((reward, ind) => {
                            return <React.Fragment key={ind} >
                                <Reward rewardData={reward} />
                            </React.Fragment>
                        })
                    }
                    {
                        (!rewards || !rewards?.length) && <div className='h-10 flex justify-center items-center font-bold'>
                            <p className='text-3xl'>No rewards found</p>
                        </div>
                    }
                </div>
            </div>
            {
                rewards && Array.isArray(rewards) && rewards.length > 0 && <div className='flex justify-end'>
                    {/* pl-[4rem] md:w-[calc(100%-25rem)]*/}
                    <div className='my-10 flex justify-between w-full'>
                        <div className='flex items-center w-[20%]'>
                            <SubmitButton label={'Previous'} btnWidth={'full'} disabled={currentPageNumber == 1} onClick={async () => {
                                setCurrentPageNumber(currentPageNumber - 1);
                                await loadRewards(currentPageNumber - 1, filters);
                            }} />
                        </div>
                        
                        <div className='flex items-center w-[20%]'>
                            <SubmitButton label={'Next'} btnWidth={'full'} disabled={currentPageNumber >= totalPages} onClick={async () => {
                                setCurrentPageNumber(currentPageNumber + 1);
                                await loadRewards(currentPageNumber + 1, filters);
                            }} />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Rewards