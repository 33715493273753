import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import FormikControl from '../../../components/form/formikControl'
import { SubmitButton, SubmitButtonWide } from '../../../components/ui/buttons/submitButton'

//importing admin roles from config
import { ADMIN_ROLES} from '../../../config/constants'
import Loader from "../../../components/ui/loader/Loader";

//importing necessary hooks
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"


import {fetchAdminUserRoles, updateAdminUserRoles} from "../services/api";
import PopUpManager from '../../../components/popups2/popupManager'
function AdminAccessManagement({selectedAdmin, setIsAdminDetailOpen}) {

    const axiosPrivate = useAxiosPrivate();
    const [userRoleData, setuserRoleData] = useState();

    const [loading, setLoading] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [textPopupDisabled, setTextPopupDisabled] = useState(true);

    const textCenter = {
        textAlign: 'center',
        color: 'black',
        fontWeight: '500'
    };

    const [adminData, setadminData] = useState(false)
    // Initialize state for selected roles.
    let selectedRoles = []

    
    const loadAdminData = async(userId) => {
        if(!userId){
            setAlertText("Please select a valid Admin ID");
            setTextPopupDisabled(false);
            return;
        }

        try{
            setLoading(true)
            const data = await fetchAdminUserRoles(userId,axiosPrivate);
            
            //transforming the api roles to readable format.
            data.roles = data.roles.map((value) => {
                switch (value) {
                    case ADMIN_ROLES.ADMIN:
                        return "Admin";
                    case ADMIN_ROLES.CLIENT:
                        return "Client";
                    case ADMIN_ROLES.CUSTOMER:
                            return "Customer";
                    case ADMIN_ROLES.FINANCE:
                        return "Finance";
                    case ADMIN_ROLES.QR:
                        return "QR";
                    default:
                        return value;
                }
            });
            setuserRoleData(data);
            //setting the formik values with the roles
            formikSetter && formikSetter({
                ...formikValues,
                ...extractvalues(data)
            });
            setadminData(true);
        }catch(error){
            setAlertText(error.message);
            setTextPopupDisabled(false);
        }
        finally{
            setLoading(false);
        }
    }

    useEffect(() => {
        loadAdminData(selectedAdmin.userId)

        // eslint-disable-next-line
    }, [selectedAdmin.userId])

    const radioOptions = {
        'Admin': ADMIN_ROLES.ADMIN,
        'Client': ADMIN_ROLES.CLIENT,
        'Customer': ADMIN_ROLES.CUSTOMER,
        'Finance': ADMIN_ROLES.FINANCE,
        'QR': ADMIN_ROLES.QR,
    }
    
    const extractvalues = (userdata) => {
        const obj = {};
        // fill obj
        const userId = userdata["userId"];
        obj[`${userId}_Admin`] = false;
        obj[`${userId}_Client`] = false;
        obj[`${userId}_Customer`] = false;
        obj[`${userId}_Finance`] = false;
        obj[`${userId}_QR`] = false;

        const roles = userdata["roles"];

        for(let k = 0; k < roles.length; k++){
            obj[`${userId}_${roles[k]}`] = true;
        }
        return obj;
    }

    const initialValues = {
        "userId": "",
    }

    const handleSubmit = async (formik, values, userId) => {
        selectedRoles = [];
        for (let k in values){
            if(!values[k]) continue;
            const data = k.split("_");
            if(data[0] !== userId) continue;
            selectedRoles.push(radioOptions[data[1]]);
        }
            try{
                setLoading(true);
                await updateAdminUserRoles(userId,selectedRoles,axiosPrivate);

                setAlertText("Roles Updated Successfully");
                setTextPopupDisabled(false);
            }catch(error){
                setAlertText(error.message || `Unable to update roles`)
                setTextPopupDisabled(false);
            }
            finally{
                setLoading(false);
            }
    };

    let formikSetter = null;
    let formikValues = null;
    return (
        <>
        {loading && <Loader />}
        <PopUpManager 
            disable={textPopupDisabled}
            control={"text"}
            heading={"Alert"}
            description={alertText}
            onClose={() => setTextPopupDisabled(true)}
        />

        <div className='my-10'>
            <SubmitButtonWide
                value='Back'
                onClick={() => {
                    setIsAdminDetailOpen(false)
                }}
            />
        </div>

        <Formik
            initialValues={initialValues}>
            {
                formik => {
                    formikSetter = formik.setValues;
                    formikValues = formik.values;
                    return (
                        <Form>
                            <div className='my-8 rounded-[12px]'>
                                {adminData &&
                                    userRoleData && 
                                        <div className='flex w-[100%] justify-between items-center rounded-[12px] access-box overflow-y-auto bg-bgWhite'>
                                            <div className='w-[45rem] px-7 py-5 flex justify-between items-center left border-x border-[#182AB4] border-opacity-20'>
                                                <div className=' flex justify-start items-center w-[12rem]'>
                                                    <span className='text-financeSideBoxText text-font16 font-semibold'>User ID</span>
                                                </div>
                                                <div className='w-[30rem] text-center -mb-4'>
                                                    <FormikControl style={textCenter} control='input' type='text' label={""} name={`${userRoleData.userId}`} placeholder={""} bgcolor='selectorBg' width={'full'} defaultValue={`${userRoleData.userId}`} disabled />
                                                </div>
                                            </div>
                                            <div className='w-[calc(99%-45rem)]'>
                                                <div className='px-7 py-5 flex justify-between items-center'>
                                                    <div className='flex '>
                                                        <div className="mr-10 pt-2 pb-2 flex justify-center items-center radio-btn-wrapper">
                                                            <FormikControl control='checkbox' label={'Admin'} name={`${userRoleData.userId}_Admin`} // Use a unique name for each checkbox
                                                                defaultChecked={formik.values[`${userRoleData.userId}_Admin`]}
                                                            />
                                                        </div>
                                                        <div className="mr-10 pt-2 pb-2 flex justify-center items-center radio-btn-wrapper">
                                                            <FormikControl control='checkbox' label={'Client'} name={`${userRoleData.userId}_Client`}
                                                                defaultChecked={formik.values[`${userRoleData.userId}_Client`]}
                                                            />
                                                        </div>
                                                        <div className="mr-10 pt-2 pb-2 flex justify-center items-center radio-btn-wrapper">
                                                            <FormikControl control='checkbox' label={'Customer'} name={`${userRoleData.userId}_Customer`}
                                                                defaultChecked={formik.values[`${userRoleData.userId}_Customer`]}
                                                            />
                                                        </div>
                                                        <div className="mr-10 pt-2 pb-2 flex justify-center items-center radio-btn-wrapper">
                                                            <FormikControl control='checkbox' label={'Finance'} name={`${userRoleData.userId}_Finance`}
                                                                defaultChecked={formik.values[`${userRoleData.userId}_Finance`]}
                                                            />
                                                        </div>
                                                        <div className="mr-10 pt-2 pb-2 flex justify-center items-center radio-btn-wrapper">
                                                            <FormikControl control='checkbox' label={'QR'} name={`${userRoleData.userId}_QR`}
                                                                defaultChecked={formik.values[`${userRoleData.userId}_QR`]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='min-w-[20rem] flex justify-center items-center mr-10'>
                                                        <SubmitButton label='Save' btnWidth='full' type='button' onClick={() => handleSubmit(formik,formik.values, userRoleData.userId)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                            </div>
                        </Form>
                    )
                }
            }
        </Formik>
        </>
    )
}

export default AdminAccessManagement