import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import FormikControl from '../../../../components/form/formikControl';
import { FormHeadingComponent, FormSubHeadingComponent } from './formHeadingComponent'
import { calculateExpiryDateChange, calculateShelfLifeChange } from "../../../../utils/QR/handleDateChanges"
import { warrantyDropDown } from "../../../../utils/QR/batchDropdowns";

import manuIntialValues from '../utils/manuInitialValues';
import manuValidationSchema from '../validations/manuValidation';

export var manuForm = {};
function ManufacturerForm({type, skuData}) {

    const [clickedProductDetails, setclickedProductDetails] = useState(true)
    const [clickedManufacturingDetails, setclickedManufacturingDetails] = useState(true)

    const [clickedProductDetailsSub, setclickedProductDetailsSub] = useState(true)
    const [clickedManufacturingsub, setclickedManufacturingsub] = useState(true)

    const [shelfLife, setShelfLife] = useState('');
    const [mfgDate, setMfgDate] = useState('Unset');
    const [mfgNotAvailable, setMfgNotAvailable] = useState(false);
    const [mfDateNotSelected, setMfgDateNotSelected] = useState(true);

    const [expDate, setExpDate] = useState('Unset');
    const [expNotAvailable, setExpNotAvailable] = useState(false);
    const [expNotApplicable, setExpNotApplicable] = useState(false);

    const [mfgDateErr, setMfgDateErr] = useState('');
    const [expDateErr, setExpDateErr] = useState('');
    const [shelfErr, setShelfErr] = useState('');

    const [qrProdErr, setQrProdErr] = useState('');
    const [preSaleQRLocationErr, setPreSaleQRLocationErr] = useState('');

    const handleClickProductDetails = (event) => {
        setclickedProductDetails(!event)
    }
    const handleClickManufacturingDetails = (event) => {
        setclickedManufacturingDetails(!event)
    }
    
    const handleClickProductDetailsSub = (event) => {
        setclickedProductDetailsSub(!event)
    }
    const handleClickManufacturingsub = (event) => {
        setclickedManufacturingsub(!event)
    }

    useEffect(() => {
        // formikRester()
        setShelfLife(skuData.shelfLife);
    }, [skuData]);

    let formikSetter;
    useEffect(() => {
        if(expNotApplicable) {
            setShelfLife(0);
            if(formikSetter)
                formikSetter('shelfLife', 0);
        }

        //eslint-disable-next-line
    }, [expNotApplicable])

    useEffect(() => {
        if(mfgNotAvailable) { setMfgDateErr('');}
        else if(!mfgDate) { setMfgDateErr('Please provide a valid Date');}

        if(expNotAvailable || expNotApplicable) { setExpDateErr(''); }
        else if(!expDate) { setExpDateErr('Please provide a valid Date');}

        if(!expNotApplicable) {
            if(shelfLife < 0) setShelfErr('Shelf life can not be negative')
            else if(!shelfLife || shelfLife === '') setShelfErr('This field is Required!');
            else setShelfErr('')
        } else 
            setShelfErr('');
        
        //eslint-disable-next-line
    }, [mfgNotAvailable, expNotAvailable, expNotApplicable])

    let shelErrStart = useRef(false);
    useEffect(() => {
        if((shelfLife || shelfLife !== '')) shelErrStart.current = true; 
        if(!shelErrStart.current) return;

        // if(mfgNotAvailable && expNotAvailable && (!shelfLife || shelfLife === '')) 
        if(!expNotApplicable && (!shelfLife || shelfLife === '')) 
            setShelfErr('This field is Required!');
        else if(shelfLife < 0)
            setShelfErr('Shelf life can not be negative')
        else
            setShelfErr('')

        //eslint-disable-next-line
    }, [shelfLife])

    const handleMfgExpErrsOnSubmit = () => {
        let err = false;
        if(mfgDateErr || expDateErr || shelfErr) err = true;
        if(!mfgNotAvailable && (!mfgDate || mfgDate === 'Unset')) {err = true; setMfgDateErr('Please Provide a Valid Date');}
        if((!expNotAvailable && !expNotApplicable) && (!expDate || expDate === 'Unset')) {err = true; setExpDateErr('Please Provide a Valid Date');}
        if(!expNotApplicable && (!shelfLife || shelfLife === '')) {err = true; setShelfErr('Please provide a valid Shelf life time');}

        return err;
    }

    const handleManufacturingDateChange = async (formik, value) => {
        if(value.day) setMfgDateNotSelected(false)
        else setMfgDateNotSelected(true)

        if(!value.year || value.year === 'YYYY' || !value.month || value.month === 'MM') {
            setMfgDateErr('Please Provide a Valid Date');
            setMfgDate('');
            return;
        }
        else setMfgDateErr('');

        const mfgData = value;
        if(value.day === 'DD') mfgData.day = '01';
        const mfgDate = new Date(`${mfgData.year} ${mfgData.month} ${mfgData.day || '01'}`);
        setMfgDate(mfgDate)

        const shelfLife = Number(formik.values.shelfLife) || 0;
        if(formik.values.expiryDate === 'notApplicable' || formik.values.expiryDate === 'notAvailable') return;

        const expiryDate = await  calculateShelfLifeChange({
            shelfLife: shelfLife, mfgDate
        })
        if(expiryDate) setExpDateErr('');
        
        setExpDate(expiryDate);
        if (expiryDate)
            formik.setFieldValue('expiryDate', {
                year: expiryDate.getFullYear(), 
                month: expiryDate.toLocaleString('default', { month: 'long' }), 
                day: value.day? expiryDate.getDate() : 'DD'
            });
        else
            formik.setFieldValue('expiryDate', '');
    }

    const handleExpiryDateChange = async (formik, value) => {
        if(!value.year || value.year === 'YYYY' || !value.month || value.month === 'MM') {
            setExpDateErr('Please Provide a Valid Date');
            setExpDate('');
            return;
        }
        else setExpDateErr('');

        const mfgData = formik.values.mfgDate;
        const mfgDate = mfgData? new Date(`${mfgData.year} ${mfgData.month} ${mfgData.day || '01'}`) : new Date();
        const expData = value;
        if(value.day === 'DD') expData.day = '01';
        const expDate = new Date(`${expData.year} ${expData.month} ${expData.day || '01'}`);
        setExpDate(expDate)

        if(formik.values.mfgDate && formik.values.mfgDate !== 'notAvailable') 
        {
            const shelfLife = await calculateExpiryDateChange({
                mfgDate: mfgDate,
                expiryDate: expDate
            });
    
            if (shelfLife) {
                formik.setFieldValue('shelfLife', shelfLife);
                setShelfLife(shelfLife)
            }
            else 
                formik.setFieldValue('shelfLife', '');

        }
    }

    const handleShelfLifeChange = async (formik, value) => {

        for (let i = 0; i < value.length; i++) {
            let char = value[i];
            if(isNaN(char)){
                setShelfErr("Please Enter a valid shelfLife period");
                return;
            }
        }

        setShelfLife(value);
        await formik.setFieldValue('shelfLife', value);

        const mfgData = formik.values.mfgDate;
        const mfgDate = new Date(`${mfgData?.year} ${mfgData?.month} ${(!mfgData?.day || mfgData?.day === 'DD')? '01' : mfgData?.day }`);
        const expiryDate = await  calculateShelfLifeChange({
            shelfLife: value || 0, mfgDate
        })

        if(formik.values.mfgDate && formik.values.mfgDate !== 'notAvailable' && !expNotAvailable) {
            setExpDate(expiryDate)
            if(expDate) setExpDateErr('');
            
            if (expiryDate)
                formik.setFieldValue('expiryDate', {
                    year: expiryDate.getFullYear(), 
                    month: expiryDate.toLocaleString('default', { month: 'long' }), 
                    day: (mfDateNotSelected || mfgData.day === 'DD')? 'DD' : expiryDate.getDate()
                });
            else
                formik.setFieldValue('expiryDate', '');
        }

    }

    const handleSubmit = async (values) => {
        if(handleMfgExpErrsOnSubmit()) return;

        //SubmitForm
    }

    const validateFormFields = (values) => {
        if(values.QROnprod) 
            setQrProdErr('');
        if(values.preSaleQRLocation) 
            setPreSaleQRLocationErr('');
    }

    let validateForm;
    return (
        <Formik
            initialValues={skuData || manuIntialValues} 
            enableReinitialize={true}
            validationSchema={manuValidationSchema}
            validate={validateFormFields}
            onSubmit={handleSubmit}
        > 
            {
                formik => {
                    formikSetter = formik.setFieldValue;
                    validateForm = () => {
                        formik.validateForm();

                        let validateObj = {};
                        Object.keys(manuIntialValues).forEach(key => {
                            validateObj[key] = true;
                        })
                        formik.setTouched(validateObj);

                        handleMfgExpErrsOnSubmit()

                        if(type === "3" && !formik.values.QROnprod)
                            setQrProdErr('This Field is required');
                        if(type === "3" && !formik.values.preSaleQRLocation)
                            setPreSaleQRLocationErr('This Field is required');
                    }
                    manuForm = {
                        values: formik.values,
                        isFormValid: () => {
                            return Object.keys(formik.errors).length || qrProdErr || preSaleQRLocationErr || handleMfgExpErrsOnSubmit() ?false:true
                        },
                        valideForm: validateForm
                    }
                    
                    return (
                        <Form>
                            <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                <div onClick={() => handleClickProductDetails(clickedProductDetails)}>
                                    <FormHeadingComponent name={"Product Details"} clicked={clickedProductDetails} />
                                </div>
                                {clickedProductDetails ? (
                                    <div className='w-full  '>
                                        <div onClick={() => handleClickProductDetailsSub(clickedProductDetailsSub)}>
                                            <FormSubHeadingComponent name={"Provide Product Details"} clicked={clickedProductDetailsSub} />
                                        </div>
                                        {clickedProductDetailsSub ? (
                                            <>
                                                <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10 img-upload-wrapper'>
                                                    <FormikControl
                                                        control='file'
                                                        type='file'
                                                        defaultImageUrl={formik.values.Pimage}
                                                        label={"Product Image :"}
                                                        name='Product-image'
                                                        acceptableFiles='.jpg,.png,.jpeg'
                                                        customerror={formik.touched.Pimage && formik.errors.Pimage}
                                                        onFileSelect={(url) => {
                                                            if(url)
                                                                formik.setFieldValue("Pimage", url);
                                                        }}
                                                    />
                                                    <FormikControl
                                                        control='file'
                                                        type='file'
                                                        defaultImageUrl={formik.values.expiryImage}
                                                        label={"Expiry Product :"}
                                                        name={'expiry-prod'}
                                                        acceptableFiles='.jpg,.png,.jpeg'
                                                        customerror={formik.touched.expiryImage && formik.errors.expiryImage}
                                                        onFileSelect={(url) => {
                                                            formik.setFieldValue("expiryImage", url);
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    type === "3" && <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10 img-upload-wrapper'>
                                                        <FormikControl
                                                            control='file'
                                                            type='file'
                                                            defaultImageUrl={formik.values.QROnprod}
                                                            label={"Hidden QR on Product :"}
                                                            name={'hiddend-qr-on-prod'}
                                                            acceptableFiles='.jpg,.png,.jpeg'
                                                            customerror={qrProdErr}
                                                            onFileSelect={(url) => {
                                                                formik.setFieldValue("QROnprod", url);
                                                            }}
                                                        />

                                                        <FormikControl
                                                            control='file'
                                                            type='file'
                                                            defaultImageUrl={formik.values.preSaleQRLocation}
                                                            label={"Outer QR on Box :"}
                                                            name={'outer-qr-on-box'}
                                                            acceptableFiles='.jpg,.png,.jpeg'
                                                            customerror={preSaleQRLocationErr}
                                                            onFileSelect={(url) => {
                                                                formik.setFieldValue("preSaleQRLocation", url);
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10 rounded-b-[12px]'>
                                                    <FormikControl control='input' type='text' label={"Brand :"} name={'brand'} bgcolor={'selectorBg'} />
                                                    <FormikControl control='input' type='text' label={"Product Name :"} name={"prodName"} bgcolor={'selectorBg'} />
                                                    <FormikControl control='input' type='text' label={"Batch number :"} name={"batchid"} bgcolor={'selectorBg'} onChange={(e) => {type == 1 && formik.setFieldValue('warrantyApp', 'false'); formik.setFieldValue('batchid', e.target.value)}} />
                                                    <FormikControl formikSetter={formik.setFieldValue} customError={mfgDateErr} dateSetAuto={shelfLife && expDate && expDate !== 'Unset'} notAvailableOption={true} setNotAvailableOption={setMfgNotAvailable} onChange={(date) => handleManufacturingDateChange(formik, date)} control='datepicker2' type='date' label={"Mfg Date :"} name={"mfgDate"} fieldWidth='[100%]' bgcolor={'selectorBg'} />
                                                    <FormikControl control='input' type='number' label={"MRP. :"} name={"mrp"} bgcolor={'selectorBg'} />
                                                    <FormikControl control='input' type='text' label={"Serial No. :"} name={"serialNo"} bgcolor={'selectorBg'} />
                                                    <FormikControl customError={shelfErr} value={shelfLife} control='input' type='text' label={"Shelf Life in days:"} name={"shelfLife"} onChange={(e) => handleShelfLifeChange(formik, e.target.value)} bgcolor={'selectorBg'} />
                                                    <FormikControl formikSetter={formik.setFieldValue} customError={expDateErr} dateSetAuto={shelfLife && mfgDate && mfgDate !== 'Unset'} notAvailableOption={true} setNotAvailableOption={setExpNotAvailable} notApplicableOption={true} setNotApplicableOption={setExpNotApplicable} onChange={(date) => handleExpiryDateChange(formik, date)} control='datepicker2' type='date' label={"Expiry Date:"} name={"expiryDate"} fieldWidth='[100%]' bgcolor={'selectorBg'} />
                                                    {
                                                        type != '1' && <><FormikControl
                                                            control='select'
                                                            className="dropdown-icon"
                                                            label='Warranty Applicable'
                                                            name='warrantyApp'
                                                            options={warrantyDropDown}
                                                            height={'[46px]'}
                                                            padX={'10'}
                                                            bgcolor={'selectorBg'}
                                                            value={formik.values.warrantyApp}
                                                            onChange={(e) => {
                                                                if (e.target.value === "false") {
                                                                    formik.setFieldValue('warrantyPeriod', '')
                                                                    formik.setFieldValue('warrantyTC', '')
                                                                }
                                                                formik.setFieldValue('warrantyApp', e.target.value)
                                                            }}
                                                        />
                                                            {
                                                                formik.values.warrantyApp === "true" && <FormikControl control='input' type='number' label={"Warranty in days"} name={"warrantyPeriod"} bgcolor={'selectorBg'} disabled={formik.values.warrantyApp === "true" ? false : true} />
                                                            }
                                                            {
                                                                formik.values.warrantyApp === "true" && <div className='col-span-full md:col-span-2'>
                                                                    <FormikControl control='input-textarea-large' label={"Warranty T&C"} name={"warrantyTC"} bgcolor={'selectorBg'} />
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        ) :
                                            <></>}
                                    </div>
                                ) :
                                    <></>}
                            </div>
                            <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                <div onClick={() => { handleClickManufacturingDetails(clickedManufacturingDetails) }}>
                                    <FormHeadingComponent name={"Manufacturing Details"} clicked={clickedManufacturingDetails} />
                                </div>
                                {clickedManufacturingDetails ? (
                                    <div className='bg-bgWhite rounded-[12px]'>
                                        <div onClick={() => handleClickManufacturingsub(clickedManufacturingsub)}>
                                            <FormSubHeadingComponent name={"Provide Manufacturing Details "} clicked={clickedManufacturingsub} />
                                        </div>
                                        {clickedManufacturingsub ? (
                                            <div className='px-[30px] pb-20 grid grid-cols-1 gap-y-10 rounded-[12px] qr-manufacturing-details'>
                                                <FormikControl
                                                    control='input-textarea-small'
                                                    width={'full'}
                                                    type='description'
                                                    label={"Manufacturing license no :"}
                                                    name={'manuLicenseNo'}
                                                    placeholder={"Type your text here"} />
                                                <FormikControl
                                                    control='input-textarea-large'
                                                    width={'full'}
                                                    type='description'
                                                    label={"Manufacturing Address :"}
                                                    name={'manuAdd'}
                                                    placeholder={"Type your text here"} />
                                                <FormikControl
                                                    control='input-textarea-large'
                                                    width={'full'}
                                                    type='description'
                                                    label={"Additional Details :"}
                                                    name={'addDetails'}
                                                    placeholder={"Type your text here"} />
                                                <FormikControl
                                                    control='input-textarea-small'
                                                    width={'full'}
                                                    type='description'
                                                    label={"Image or Video Header :"}
                                                    name={'additionalImageDetails'}
                                                    placeholder={"image header"}
                                                />
                                                <div className='flex flex-wrap md:flex-nowrap mb-[25px] md:mb-0 items-center h-[100%] gap-5 generate-img'>
                                                    <label htmlFor=""></label>
                                                    <div className='generate-img-upload-wrapper'>
                                                        <div className='flex md:justify-end'>
                                                            <FormikControl
                                                                control='file'
                                                                type='file'
                                                                label={""}
                                                                name='extra-Image-1'
                                                                defaultImageUrl={formik.values.extraImage1}
                                                                acceptableFiles='.jpg,.png,.jpeg'
                                                                customerror={formik.touched.extraImage1 && formik.errors.extraImage1}
                                                                onFileSelect={(url) => {
                                                                    formik.setFieldValue("extraImage1", url);
                                                                }}
                                                            />
                                                            <FormikControl
                                                                control='file'
                                                                type='file'
                                                                label={""}
                                                                name='extra-Image-2'
                                                                defaultImageUrl={formik.values.extraImage2}
                                                                acceptableFiles='.jpg,.png,.jpeg'
                                                                customerror={formik.touched.extraImage2 && formik.errors.extraImage2}
                                                                onFileSelect={(url) => {
                                                                    formik.setFieldValue("extraImage2", url);
                                                                }}
                                                            />
                                                            <FormikControl
                                                                control='video'
                                                                type='file'
                                                                label={""}
                                                                name='extra-video-1'
                                                                defaultVideoUrl={formik.values.prodVedioLink}
                                                                acceptableFiles='.mp4'
                                                                customerror={formik.touched.prodVedioLink && formik.errors.prodVedioLink}
                                                                onFileSelect={(url) => {
                                                                    formik.setFieldValue("prodVedioLink", url);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <FormikControl
                                                    control='input-textarea-small'
                                                    type='description'
                                                    label={"Website Link :"}
                                                    name={'manuWebsiteLink'}
                                                    placeholder={"Type your text here"} />
                                            </div>
                                        ) : <></>}
                                    </div>
                                ) : <></>
                                }
                            </div>

                        </Form>
                    )
                }
            }

        </Formik>
    );
}

export default ManufacturerForm;