import React from "react"

import FileUpload from "../../../../../../components/form2/FileUpload"

const BackgroundVerification = ({ formik }) => {
    return (
        <div className="mx-16">
{/* `           <p className="text-[#182AB4] text-5xl mb-10 font-semibold">Background Verification</p> */}

            <div className="">
                <FileUpload 
                    label='Background Verification File*'
                    name={'bgVerification_Doc'}
                    defaultFileName={formik?.values?.bgVerification_Doc_Filename}
                    formik={formik}
                    marginClass='mb-2'
                    acceptableFilesFormats='.png, .jpg, .jpeg, .pdf'
                /> 
            </div>
        </div>
    )
}

export default BackgroundVerification;