import React, { useState } from 'react';
import { Formik, Form } from 'formik';

import FormikControl from '../../../components/form/formikControl';
import { SubmitButton } from '../../../components/ui/buttons/submitButton';
import Loader from "../../../components/ui/loader/Loader";
import PopUpManager from '../../../components/popups2/popupManager';

import {
    initialValues_form as initialValues
} from '../utils/initialValues';

import {
    validationSchema_form as validationSchema
} from '../validations/validations';

import { FetchBatches } from '../services/api';

import { URL_TYPES } from '../../../config/constants';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

function BatchForm(props) {

    const axiosPrivate = useAxiosPrivate(URL_TYPES.QR)
    const [loading, setLoading] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [textPopupDisabled, setTextPopupDisabled] = useState(true);

    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            const res = await FetchBatches(values,axiosPrivate);
            
            const data = res.data;
            const totalPages = res.totalPages;
            const currentPage = res.currentPage;
            if (data?.length === 0)  throw new Error('No data Found');

            console.log(data, "data")

            props.setProperties.setDataFromApi(data);
            props.setProperties.setTotalPages(totalPages)
            props.setProperties.setCurrentPage(currentPage)
            props.setProperties.setFormValues({
                batchNo: values.batchNo,
                toDate: values.toDate,
                fromDate: values.fromDate,
            })
        } catch (error) {
            setAlertText(error.message);
            setTextPopupDisabled(false);
        }  finally {
            setLoading(false);
        }
    }

    const validateForm = (values) => {
        const { batchNo, fromDate } = values;
        if (!batchNo && !fromDate) {
            return {
                fromDate: 'At least one field is required',
                batchNo: 'At least one field is required'
            };
        }
        return {};
    };

    return (
        <>
        { loading && <Loader /> }
        <PopUpManager 
            disable={textPopupDisabled}
            control={"text"}
            heading={"Alert"}
            description={alertText}
            onClose={() => setTextPopupDisabled(true)}
        />

        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validate={validateForm}
            onSubmit={handleSubmit}
        >
            {
                formik => (
                    <Form>
                        
                        <div className=' w-full'>
                            {/* <div className='h-14 bg-BorderLight opacity-10'>

                            </div> */}
                            <div className='bg-bgWhite flex flex-col p-10 h-[100%] border border-navBorderLight rounded-[12px]'>
                                <div className='w-[50%] h-[33%] mb-5'>
                                    <FormikControl control='input' label='Client Id :' name='clientId' labelColor='popupText' bgcolor={'selectorBg'} />
                                </div>
                                <div className='w-[50%] h-[33%] mb-5'>
                                    <FormikControl control='input' label='Batch Number :' name='batchNo' labelColor='popupText' bgcolor={'selectorBg'} />
                                </div>
                                <div className='w-[37%] flex justify-end items-center h-20'>
                                    <span className='text-couponsRadioHeading text-font16 font-medium'>OR</span>
                                </div>
                                <div className='flex items-center w-[100%]'>
                                    <div className='text-sideBarBtnText font-medium flex items-center mr-5 -mt-4'>
                                        <h5>Creation Period: </h5>
                                    </div>
                                    
                                    <div className='flex justify-center items-center'>
                                        <h6 className='text-couponsRadioHeading text-3xl font-medium text-center mx-5 -mt-4'>from</h6>
                                        <div className='w-[40%] '>
                                            <FormikControl control='date' name={'fromDate'} label={''} fieldWidth={'full'} bgcolor={'selectorBg'} />
                                        </div>
                                        <h6 className='mx-5 text-couponsRadioHeading text-3xl font-medium text-center -mt-4'>to</h6>
                                        <div className='w-[40%]'>
                                            <FormikControl control='date' name={'toDate'} label={''} fieldWidth={'full'} bgcolor={'selectorBg'} />
                                        </div>
                                    </div>

                                    <div className='w-[20%] flex justify-end items-center -mt-6 ml-10'>
                                        <SubmitButton label={'Search'} imageUrl={''} btnWidth='[100%]' height={'16'} type="submit" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )
            }
        </Formik>
        </>
    )
}

export default BatchForm