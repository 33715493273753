import React, { useState } from 'react'

// import { info } from '../../../assets/images'
import info from '../../../assets/svg/info.svg';

export default function Info({ message }) {

  const [isMessageInView, setIsMessageInView] = useState(false)

  return (
    <div 
      className='h-[1.5rem] w-[1.5rem] relative' 
      onMouseEnter={() => setIsMessageInView(true)}
      onMouseLeave={() => setIsMessageInView(false)}
    >
      <img src={info} alt='info' className='h-full w-full object-contain' />
      {
        isMessageInView &&<div className='absolute bottom-0 left-[2rem] min-w-[20rem] border border-[#CECEFD] rounded bg-[white] overflow-hidden'>
          <p className='text-base m-1 mx-2 text-gray-500' >{message}</p>
        </div>
      }
    </div>
  )
}
