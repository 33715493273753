import React from 'react';

import not_found from '../assets/svg/not_found.svg'

function Missing() {
    return (
        <div className='w-[100vw] h-[100vh] flex flex-col justify-center items-center'>
            <div className='mx-5'>
                <div className='flex justify-start'>
                    <img src={not_found} alt="" className='h-[15rem]' />
                </div>
                <h1 className='w-full'>PAGE NOT FOUND</h1>
                <p className='w-full text-3xl'>Are you sure the website URL is correct?</p>
                <div className='w-full my-10'>
                    <button className='p-4 px-8 border-2 border-[#50AFF8] text-[#50AFF8] rounded-full text-3xl '>
                        Go Back Home
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Missing