import React, { useRef } from 'react';
import Info from './components/Info';

const ColorInput = ({
    name, 
    formik,
    marginClass='mx-auto',
    tailwindClasses='',    
    label='',
    message='',
    disabled=false
    }) => {

    const inpRef = useRef(null);

    return (
        <div 
            className={`w-full flex flex-col ${marginClass} relative`}
            onClick={() => inpRef.current?.click()}
        >
            {
                label && <div className='flex pb-2 items-center'>
                    <label htmlFor={name} className='text-3xl mr-2'>{label}</label>
                    {message && <Info message={message} />}
                </div>
            }
            <div
                style={{
                    backgroundColor: formik?.values[`${name}`]
                }}
                className={`z-1 ${tailwindClasses} bg-[#182AB408] border p-[.65rem] rounded-md text-base h-[46px]`}
            >&nbsp;</div>
            <input 
                type='color' 
                name={name} 
                id={name}
                value={formik?.values[`${name}`]} 
                onChange={formik?.handleChange} 
                onBlur={formik?.handleBlur}
                className='z-[5] absolute top-0 left-0 h-0 w-0'
                ref={inpRef}
                disabled={disabled}
            />  
            <p className='text-red-500 text-xs'>{formik?.touched[`${name}`] && formik?.errors[`${name}`]}&nbsp;</p>
        </div>
    );
}

export default ColorInput;
