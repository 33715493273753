import { handleError } from "../../../utils/handles/errorHandle";

async function getAllAdminsPaginated(pageNo,pageSize,sortBy,sortOrder,axiosPrivate){
    try{
        const response = await axiosPrivate.get("/api/admin/users/paginated",{ params:{ pageNo, pageSize, sortBy, sortOrder }});
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function getAllMasterClientsPaginated (pageNo,pageSize,sortBy,sortOrder,axiosPrivate){
    try{
        const response = await axiosPrivate.get("/api/client/masterclients/paginated",{ params:{ pageNo, pageSize, sortBy, sortOrder }});
        
        console.log(response.data, "masters");
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function getAdminSuggestions(queryString, axiosPrivate) {
    try{
        const response = await axiosPrivate.get("/api/admin/userid-suggestions",{ params:{ queryString } });
        return response?.data;
    }catch(error){  
        handleError(error);
    }
}

async function getMasterClientSuggestions(queryString, axiosPrivate) {
    try{
        const response = await axiosPrivate.get("/api/client/masterclient/userid-suggestions",{ params:{ queryString } });
        return response?.data;
    }catch(error){  
        handleError(error);
    }
}

async function getAdminsByQueryString(queryString,sortBy,sortOrder,pageNo,pageSize,axiosPrivate){
    try{
        const response = await axiosPrivate.get("/api/admin/user-info", { params:{ queryString, sortBy, sortOrder, pageNo, pageSize }});
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function getMasterClientsByQueryString(queryString,sortBy,sortOrder,pageNo,pageSize,axiosPrivate){
    try{
        const response = await axiosPrivate.get("/api/client/masterclient/user-info", { params:{ queryString, sortBy, sortOrder, pageNo, pageSize }});
        
        console.log(response);
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

//
async function fetchAdminUserRoles(userId,axiosPrivate){
    try {
        const res = await axiosPrivate.get(`/api/admin/roles/${userId}`);
        return res.data;
    }catch(error){
        handleError(error);
    }
}

async function updateAdminUserRoles(userId,roles,axiosPrivate){
    try {
        const res = await axiosPrivate.put(`/api/admin/${userId}/roles`,{roles});
        return res;
    } catch(error) {
        handleError(error);
    }
}

async function fetchSubClientUserRoles(masterClientId,pageNo,pageSize,axiosPrivate){
    try {
        const res = await axiosPrivate.get(`/api/client/roles/${masterClientId}`, { params:{ pageNo, pageSize } });
        return res?.data;
    } catch (error) {
        handleError(error); 
    }
}

async function updateSubClientUserRoles(userId,roles,axiosPrivate){
    try {
        const res = await axiosPrivate.put(`/api/client/${userId}/roles`,{roles});
        return res;
    } catch (error) {
        handleError(error);
    }
}

async function getUserInfo(queryString,userType,sortBy,sortOrder,pageNo,pageSize,axiosPrivate){
    try{
        const response = await axiosPrivate.get("/api/admin/user-info", { params:{ queryString, userType, sortBy, sortOrder, pageNo, pageSize }});
        return response.data;
    }catch(error){
        handleError(error);
    }
}

export{
    getAllAdminsPaginated,
    getAllMasterClientsPaginated,
    getAdminSuggestions,
    getMasterClientSuggestions,
    getAdminsByQueryString,
    getMasterClientsByQueryString,

    fetchAdminUserRoles,
    updateAdminUserRoles,
    fetchSubClientUserRoles,
    updateSubClientUserRoles,
    getUserInfo,
}