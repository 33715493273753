import React, { useEffect, useRef, useState } from 'react';

/**
 * Otp Component
 * @memberof module:InputFields
 * 
 * @param {Object} props - The component props.
 * 
 * @param {number} [props.length] - Number of fields in Otp
 * @param {Function} [props.onChnage] - Response to OTP change.
 * @param {Function} [props.onErr] - Response to Err change.
 * 
 * @param {string} [props.borderColor] - Border color.
 * 
 * @param {string} [props.marginClass] - Tailwind CSS margin class.
 * @returns OTP component
 */
const Otp = ({
    length = 4,
    onChnage = () => {},
    onErr = () => {},

    //style props
    borderColor = '#7F7F7F',

    //tailwind classes
    marginClass = ''
    }) => {
    
    const inputWidth = (100 / (3*length - 1)) * 2;      //width of a input field
    const spaceWidth = (100 / (3*length - 1));          //width of a spacer
    
    /**
     * entered otp
     * @type {[string[], ([]) => void]}
     */
    const [otp, setOtp] = useState(new Array(length).fill(''));

    /**
     * otp - string
     * @type {[string, (string) => void]}
     */
    const [completeOtp, setCompleteOtp] = useState('');
    const inputRefs = useRef([]);

    useEffect(() => {
        if(inputRefs.current[0])
            inputRefs.current[0]?.focus();
    }, []);

    useEffect(() => {
        onChnage && onChnage(completeOtp)
        if(completeOtp && completeOtp.length !== length) onErr && onErr('Please provide a valid OTP');
        else onErr && onErr('');
    }, [completeOtp, length, onChnage, onErr])
    
    /**
     * function to handle otp change
     * @type {(index: number, value: string) => void}
     */
    const handleChange = (index, value) => {
        if(!(value === '' || !isNaN(parseInt(value)))) return;

        let newOtp = [...otp];
        if(!isNaN(parseInt(value))) //Allow only one input
            newOtp[index] = value.substring(value.length - 1);
        else if(value === '') //remove the number
            newOtp = [...newOtp.slice(0, index), ...new Array(length - index).fill('')];

        setOtp(newOtp);
        
        //CompleteOtp
        setCompleteOtp(newOtp.join(''));

        //move to next field if current id completed
        if(value && index < length - 1 && inputRefs.current[index + 1])
            inputRefs.current[index + 1]?.focus();
    }

    /**
     * function to handle click
     * @type {(index: number) => void}
     */
    const handleClick = (index) => {
        if(!inputRefs.current[index]) return;
        
        //keep cursor at the end
        inputRefs.current[index].setSelectionRange(1, 1);
    }

    /**
     * function to handle keyDown
     * @type {(index: number, e: React.KeyboardEvent<HTMLInputElement>) => void}
     */
    const handleKeyDown = (index, e) => {
        if(e.key === "Backspace" && !otp[index] && index > 0 && inputRefs.current[index - 1])
            inputRefs.current[index - 1]?.focus();
    }
    
    return (
        <div
            className={`flex w-[80%] m-auto ${marginClass} max-w-[500px]`}
        >
            {
                otp.map((value, index) => {
                    return <div
                        key={index}
                        style={{
                            width: `${index !== length - 1? inputWidth + spaceWidth : inputWidth}%`
                        }}
                        className='flex'
                    >
                        <input 
                            key={index}
                            ref={(input) => inputRefs.current[index] = input}
                            type='text'
                            value={value}
                            onChange={(e) => handleChange(index, e?.target?.value)}
                            onClick={() => handleClick(index)}
                            onKeyDown={(e) => handleKeyDown(index, e)}

                            style={{
                                width: `${index !== length - 1? 'calc(75% - 8%)' : '100%'}`,
                                borderColor: borderColor
                            }}
                            className={`border-solid border rounded-md aspect-square text-center`}
                        />
                        {index !== length - 1 && <div 
                            key={index + `spacer`}
                            style={{
                                width: `25%`
                            }}
                            className='flex flex-col justify-center items-center'
                        >
                            <p className='h-1 text-center'>~</p>
                            <p className='text-center'>~</p>
                        </div>}
                    </div>
                })
            }
        </div>
    );
}

export default Otp;
