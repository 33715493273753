import React from 'react'
import veotsLogo from '../assets/brand/veots_logo_new.png'
import menuIcon from '../assets/svg/sidebar_icons/menu-icon.svg'
import profilePicture from '../assets/svg/header_profile_picture.svg'

import useUserData from '../hooks/useUserData'

function Header({ toggleSidebar }) {

    const { user } = useUserData();

    return (
        <div className='h-[77px] w-full bg-#FFFFFF shadow-[0_6px_12px_0px_rgba(0,0,0,0.12)] flex flex-row justify-between z-[100]'>
            <div className='lg:w-1/5 md:w-1/3 w-1/2 flex flex-row items-center'>
                <div className="menu-icon pl-[30px] flex lg:hidden">
                    <img className='w-[35px] cursor-pointer' src={menuIcon} alt="Toggle_Icon" onClick={toggleSidebar}></img>
                </div>
                <div className='pl-[45px]'>
                    <a href="/home">
                        <img className='w-[120px]' src={veotsLogo} alt='veots_logo'></img>
                    </a>
                </div>
            </div>
            <div className='flex flex-row justify-center items-center m-10 gap-[22px]'>
                <div className='flex flex-col h-1/10 items-end justify-center gap-[4px]'>
                    <span className='text-sideBarBtnText text-[16px] font-medium leading-6'>{user?.userId}</span>
                    <span className='text-sideBarBtnText text-[12px] font-normal opacity-70 leading-6'>KSJ Rudh Pvt Ltd</span>
                </div>
                <img className='font-bold w-[46px] rounded-[5px]' src={profilePicture} alt='profile'></img>
            </div>
        </div>
    )
}

function HeaderPlain() {
    return (
        <div className="h-1/10 w-full flex items-center">
            <a href="/home" className='ml-16'>
                <img className='w-[17rem]' src={veotsLogo} alt='veots_logo'></img>
            </a>
        </div>
    )
}

export { Header, HeaderPlain }