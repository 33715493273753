import React from 'react';
import Info from './components/Info';

const Input = ({
    name, 
    formik,
    type='text',
    marginClass='mx-auto',
    tailwindClasses='',    
    placeholder='Please enter some text',
    label='',
    message='',
    disabled=false,
    onChange=() => {}
    }) => {
    return (
        <div 
            className={`w-full flex flex-col ${marginClass}`}
        >
            {
                label && <div className='flex pb-2 items-center'>
                    <label htmlFor={name} className='text-3xl mr-2'>{label}</label>
                    {message && <Info message={message} />}
                </div>
            }
            <input 
                type={type} 
                name={name} 
                id={name}
                value={formik?.values[`${name}`]} 
                onChange={(e) => {
                    onChange(e.target.value);
                    formik?.handleChange(e);
                }} 
                placeholder={placeholder}
                onBlur={formik?.handleBlur}
                className={`${tailwindClasses} bg-[#182AB408] border px-[1rem] rounded-md text-3xl h-[46px]`}
                disabled={disabled}
            />
            <p className='text-red-500 text-base'>{formik?.touched[`${name}`] && formik?.errors[`${name}`]}&nbsp;</p>
        </div>
    );
}

export default Input;
