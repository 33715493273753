import { React, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik, Form } from 'formik';

import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';

import { FormHeadingComponent, FormSubHeadingComponent } from '../../components/formHeadingComponent'
import { SubmitButtonWide } from '../../../../components/ui/buttons/submitButton';
import FormikControl from '../../../../components/form/formikControl';
import Loader from '../../../../components/ui/loader/Loader';
import PopUpManager from '../../../../components/popups2/popupManager';

import retaInitialValues from '../utils/retaInitialValues';
import { appendBatch } from '../../services/api';
import { URL_TYPES } from '../../../../config/constants';

function RetaBatchAppend(props) {

    const { clientid, batchno, rawBatchData} = props;
    const axiosPrivate = useAxiosPrivate(URL_TYPES.QR);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false); 
    const [isErrPopupOpen, setIsErrPopupOpen] = useState(false);
    const [errPopupText, setErrPopupText] = useState('');
    const [isBatchGenerating, setIsBatchGenerating] = useState(false);

    const [clickedProductDetails, setclickedProductDetails] = useState(true)
    const [clickedManufacturingDetails, setclickedManufacturingDetails] = useState(true)
    const [clickedQRDetails, setclickedQRDetails] = useState(true)
    const [clickedProductDetailsSub, setclickedProductDetailsSub] = useState(true)
    const [clickedManufacturingsub, setclickedManufacturingsub] = useState(true)
    const [clickedQRDetailsSub, setclickedQRDetailsSub] = useState(true)
    const [batchData, setBatchData] = useState({});

    const handleClickProductDetails = (event) => {
        setclickedProductDetails(!event)
    }
    const handleClickManufacturingDetails = (event) => {
        setclickedManufacturingDetails(!event)
    }
    const handleClickQRDetails = (event) => {
        setclickedQRDetails(!event)
    }
    const handleClickProductDetailsSub = (event) => {
        setclickedProductDetailsSub(!event)
    }
    const handleClickManufacturingsub = (event) => {
        setclickedManufacturingsub(!event)
    }
    const handleClickQRDetailsSub = (event) => {
        setclickedQRDetailsSub(!event)
    }

    useEffect(() => {
        if(!isBatchGenerating) return;
        
        const handlePopEvent = (event) => {
            const confirmLeave = window.confirm(`If you navigate away from this screen, you won't be able to view the generated file. However, it will be saved under the 'Download QR' option once processing is complete. Are you sure you want to continue?`);
            if(!confirmLeave) {
                navigate('/new-batch-details');
            }
        }

        const handleBeforeUnLoad = (event) => {
            event.preventDefault();
            return(event.returnValue = '');
        }


        window.addEventListener('beforeunload', handleBeforeUnLoad, { capture: true })
        window.addEventListener('popstate', handlePopEvent, { capture: true });

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnLoad, { capture: true });
            setTimeout(() => {
                window.removeEventListener('popstate', handlePopEvent, { capture: true });
            }, 100);
        };
    }, [isBatchGenerating]);

    const handleSubmit = async (values) => {
        let batchData = Object.assign({}, values);
        if (batchData.type === 1 || batchData.warrantyApp === "false") {
            batchData.warrantyApp = 'false';
            batchData.warrantyPeriod = 0;
        }
        if (batchData.type !== 3) {
            batchData.QROnprod = '';
            batchData.preSaleQRLocation = '';
        }

        batchData.verb = "add";
        batchData.clientId = clientid;
        batchData.batchNo = batchno;
        batchData["totalQRGen"] = values.totalQRGen;
        batchData["trackingQr_tert"] = values.trackingQr_tert;
        batchData["trackingQr_quat"] = values.trackingQr_quat;

        try {
            setIsLoading(true);
            setIsBatchGenerating(true);

            const qrCodeLinks = await appendBatch(batchData, axiosPrivate);
            navigate(`/generated-qr-codes`, { state: { qrCodeLinks, batchData } });
        } catch (error) {
            setErrPopupText(error.message || 'Unable to genreate QR codes');
            setIsErrPopupOpen(true);
        } finally {
            setIsLoading(false);
            setIsBatchGenerating(false);
        }
    }

    const validateForm = (values) => {
        const { totalQRGen, trackingQr_tert, trackingQr_quat } = values;
        if (!totalQRGen && !trackingQr_tert && !trackingQr_quat) {
            return {
                totalQRGen: 'At least one field is required',
                trackingQr_tert: 'At least one field is required',
                trackingQr_quat: 'At least one field is required',
            };
        }
        return {};
    };

    useEffect(() => {
        const data = {
            ...retaInitialValues,
            ...rawBatchData,
        }
        setBatchData(data)
    }, [rawBatchData]);

    return (
        <>
            {
                isLoading && <Loader />
            }
            <PopUpManager 
                control={'text'}
                disable={!isErrPopupOpen}

                heading={'Error'}
                description={errPopupText}
                onClose={() => setIsErrPopupOpen(false)}
            />
            <Formik
                initialValues={batchData || retaInitialValues}
                enableReinitialize={true}
                validate={validateForm}
                onSubmit={handleSubmit}
            >
                {
                    formik => {
                        return (
                            <Form>
                                <div>
                                    <div className='pb-20'>
                                        <div onClick={() => handleClickProductDetails(clickedProductDetails)}>
                                            <FormHeadingComponent name={"Product Details"} clicked={clickedProductDetails} />
                                        </div>
                                        {clickedProductDetails ? (
                                            <div className='w-full  '>
                                                <div onClick={() => handleClickProductDetailsSub(clickedProductDetailsSub)}>
                                                    <FormSubHeadingComponent name={"Provide Product Details"} clicked={clickedProductDetailsSub} />
                                                </div>
                                                {clickedProductDetailsSub ? (
                                                    <>
                                                    <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10 img-upload-wrapper'>
                                                        <FormikControl
                                                                control='file'
                                                                type='file'
                                                                label={"Product Image :"}
                                                                name='Product-image'
                                                                acceptableFiles='.jpg,.png,.jpeg'
                                                                defaultImageUrl={formik.values.Pimage}
                                                                disabled
                                                            />
                                                    </div>
                                                    {
                                                        formik.values.type == '3' && <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10 img-upload-wrapper'>
                                                            <FormikControl
                                                                control='file'
                                                                type='file'
                                                                label={"Hidden QR on Product :"}
                                                                name={'hiddend-qr-on-prod'}
                                                                acceptableFiles='.jpg,.png,.jpeg'
                                                                defaultImageUrl={formik.values.QROnprod}
                                                                disabled
                                                            />

<FormikControl
                                                                control='file'
                                                                type='file'
                                                                label={"Hidden QR on Product :"}
                                                                name={'hiddend-qr-on-prod'}
                                                                acceptableFiles='.jpg,.png,.jpeg'
                                                                defaultImageUrl={formik.values.preSaleQRLocation}
                                                                disabled
                                                            />
                                                        </div>
                                                    }
                                                    <div className='pb-10 grid grid-cols-2 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 rounded-b-xl'>
                                                        
                                                        <FormikControl control='input' type='text' label={"Seller Name :"} name={"prodName"} disabled bgcolor={'slate-50'} />
                                                        <FormikControl control='input' type='text' label={"Batch number :"} name={"batchNo"} disabled bgcolor={'slate-50'} />
                                                    </div>
                                                    </>
                                                ) :
                                                    <></>}
                                            </div>
                                        ) :
                                            <></>}
                                    </div>

                                    <div className=''>
                                        <div onClick={() => { handleClickManufacturingDetails(clickedManufacturingDetails) }}>
                                            <FormHeadingComponent name={"Seller Details"} clicked={clickedManufacturingDetails} />
                                        </div>
                                        {clickedManufacturingDetails ? (
                                            <div className='bg-bgWhite rounded-b-xl'>
                                                <div onClick={() => handleClickManufacturingsub(clickedManufacturingsub)}>
                                                    <FormSubHeadingComponent name={"Seller Details "} clicked={clickedManufacturingsub} />
                                                </div>
                                                {clickedManufacturingsub ? (
                                                    <div className='px-[30px] pb-20 grid grid-cols-1 gap-y-10 rounded-[12px] qr-manufacturing-details'>
                                                        <FormikControl
                                                            control='input-textarea-small'
                                                            width={'full'}
                                                            type='description'
                                                            label={"License no :"}
                                                            name={'manuLicenseNo'}
                                                            placeholder={"Type your text here"}
                                                            bgcolor='slate-50'
                                                            disabled />
                                                        <FormikControl
                                                            control='input-textarea-large'
                                                            width={'full'}
                                                            type='description'
                                                            label={"Address :"}
                                                            name={'manuAdd'}
                                                            placeholder={"Type your text here"}
                                                            bgcolor='slate-50'
                                                            disabled />
                                                        <FormikControl
                                                            control='input-textarea-large'
                                                            width={'full'}
                                                            type='description'
                                                            label={"Details :"}
                                                            name={'addDetails'}
                                                            placeholder={"Type your text here"}
                                                            bgcolor='slate-50'
                                                            disabled />
                                                        <FormikControl
                                                            control='input-textarea-small'
                                                            width={'full'}
                                                            type='description'
                                                            label={"Image or Video Header :"}
                                                            name={'additionalImageDetails'}
                                                            placeholder={"image header"}
                                                            bgcolor='slate-50'
                                                            disabled
                                                        />
                                                        <div className='flex flex-wrap md:flex-nowrap mb-[25px] md:mb-0 items-center h-[100%] gap-5 generate-img'>
                                                            <label htmlFor=""></label>
                                                            <div className='generate-img-upload-wrapper'>
                                                                <div className='flex md:justify-end'>
                                                                    <FormikControl
                                                                        control='file'
                                                                        type='file'
                                                                        label={""}
                                                                        name='extra-Image-1'
                                                                        disabled={true}
                                                                        defaultImageUrl={formik.values.extraImage1}
                                                                        acceptableFiles='.jpg,.png,.jpeg'
                                                                    />
                                                                    <FormikControl
                                                                        control='file'
                                                                        type='file'
                                                                        label={""}
                                                                        name='extra-Image-2'
                                                                        disabled={true}
                                                                        defaultImageUrl={formik.values.extraImage2}
                                                                        acceptableFiles='.jpg,.png,.jpeg'
                                                                    />
                                                                    <FormikControl
                                                                        control='video'
                                                                        type='file'
                                                                        label={""}
                                                                        name='extra-video-1'
                                                                        disabled={true}
                                                                        defaultVideoUrl={formik.values.extraVideo1}
                                                                        acceptableFiles='.mp4'
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <FormikControl
                                                            control='input-textarea-small'
                                                            width={'full'}
                                                            type='description'
                                                            label={"Website Link :"}
                                                            name={'manuWebsiteLink'}
                                                            placeholder={"Type your text here"}
                                                            bgcolor='slate-50'
                                                            disabled />
                                                    </div>
                                                ) : <></>}
                                            </div>
                                        ) : <></>
                                        }

                                    </div>

                                    <div className='mt-20 mb-20'>
                                        <div onClick={() => { handleClickQRDetails(clickedQRDetails) }}>
                                            <FormHeadingComponent name={"QR Details"} clicked={clickedQRDetails} />
                                        </div>
                                        {clickedQRDetails ? (
                                            <div className='bg-bgWhite rounded-b-xl'>
                                                <div onClick={() => handleClickQRDetailsSub(clickedQRDetailsSub)}>
                                                    <FormSubHeadingComponent name={"Provide Manufacturing Details "} clicked={clickedQRDetailsSub} extraText='No of tracking QR code:' />
                                                </div>
                                                {clickedQRDetailsSub ? (
                                                    <div className='w-[100%] grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 rounded-b-[12px] py-10 provide-manufacturing-details'>
                                                    <FormikControl control='input' type='text' label={"No of Product QR's:"} name={'totalQRGen'} bgcolor={'selectorBg'} />
                                                    <FormikControl control='input' type='text' label={"level 1 :"} name={'trackingQr_tert'} bgcolor={'selectorBg'} width='1/2' />
                                                    <div></div>
                                                    <FormikControl control='input' type='text' label={"level 2  :"} name={'trackingQr_quat'} bgcolor={'selectorBg'} width='1/2' />
                                                </div>
                                                ) :
                                                    <></>}
                                            </div>
                                        ) :
                                            <></>}

                                    </div>

                                    <div className='h-40'>
                                        <SubmitButtonWide value={"Process"} type={'submit'} />
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                }

            </Formik>
            {/* <PopUpScreen header = {popupScreenView} title = {""}/> */}
        </>
    )
}

export default RetaBatchAppend;