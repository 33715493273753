import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Select from '../../../components/form2/Select';
import Input from '../../../components/form2/Input';
import { SubmitButtonWide } from '../../../components/ui/buttons/submitButton';
import Loader from '../../../components/ui/loader/Loader';
import PopUpManager from '../../../components/popups2/popupManager';

import { initialValues } from '../utils/initialValues';
import { validationSchema } from '../utils/validationSchema';

import { fetchDocLatestVersion, updateLegalDocumnet } from '../services/api';

const ACTION_TYPES = {
    CLIENT: 'sub_client',
    CUSTOMER: 'customer'
}

const CLIENT_DOCUMENT_TYPES = [
    {key: 'Privacy Policy', value: 'Privacy Policy'},
    {key: 'Terms & Conditions', value: 'Terms & Conditions'},
    {key: 'Cookie Policy', value: 'Cookie Policy'},
    {key: 'Service Agreement', value: 'Service Agreement'},
]

const CUSTOMER_DOCUMENT_TYPES = [
    {key: 'Privacy Policy', value: 'Privacy Policy'},
    {key: 'Terms & Conditions', value: 'Terms & Conditions'},
    {key: 'Cookie Policy', value: 'Cookie Policy'},
]

const LegalInfoUpdate = () => {

    const axiosPrivate = useAxiosPrivate()
    const [userType, setUserType] = useState(null);

    //POpup
    const [ loading, setLoading ] = useState(false);
    const [ textPopupDisabled, setTextPopupDisabled ] = useState(true);
    const [ textPopupHead, setTextPopupHead ] = useState('');
    const [ textPopupDescription, setTextPopupDescription ] = useState('');

    const handleActionChnage = (e) => {
        setUserType(e.target.value)
    }

    const fetchDocumentVersion = async (userType, docType) => {
        if(!userType || !docType) return;

        try {
            setLoading(true);
            const data = await fetchDocLatestVersion(userType, docType, axiosPrivate);
            formik.setFieldValue('version', data.version);
        } catch (error) {
            setTextPopupHead('Alert');
            setTextPopupDescription(error.message || `Unable to fetch version details`);
            setTextPopupDisabled(false);
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            await updateLegalDocumnet(userType, values.documentType, values.legalText, values.changedText, axiosPrivate);

            formik.resetForm();
            setTextPopupHead('Success');
            setTextPopupDescription('Document updated succfully!');
            setTextPopupDisabled(false);
        } catch (error) {
            setTextPopupHead('Alert');
            setTextPopupDescription(error.message || `Unable to update document`);
            setTextPopupDisabled(false);
        } finally {
            setLoading(false)
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: handleSubmit
    });

    useEffect(() => {
        (async () =>{
            await fetchDocumentVersion(userType, formik.values.documentType);
        })();
    }, [formik.values.documentType, userType])

    return (
        <>
            {loading && <Loader />}
            <PopUpManager 
                disable={textPopupDisabled}
                control='text'
                heading={textPopupHead}
                description={textPopupDescription}
                onClose={() => setTextPopupDisabled(true)}
            />
            <div className='w-full h-full'>
                <div className='radio-btn-wrapper flex items-center bg-bgLightBlue py-[15px] pl-10 rounded-tl-[12px] rounded-tr-[12px] border border-b-0 border-bgLightBlue'>
                    <div>
                        <h5 className='text-couponsRadioHeading font-medium w-48 text-font20'>Filters: </h5>
                    </div>
                    <div className='flex items-center gap-5 text-couponsRadioOPtions font-semibold text-3xl mr-[20px]'>
                        <input type='radio' name='addUser' value={ACTION_TYPES.CLIENT} className='w-[20px] h-[20px] radio active' onChange={handleActionChnage}></input>
                        <label htmlFor='addUsers'>Client</label>
                    </div>
                    <div className='flex items-center gap-5 text-couponsRadioOPtions font-semibold text-3xl mr-[20px]'>
                        <input type='radio' name='addUser' value={ACTION_TYPES.CUSTOMER} className='w-[20px] h-[20px] radio active' onChange={handleActionChnage}></input>
                        <label htmlFor='addUsers'>Customer</label>
                    </div>
                </div>
                {
                    userType && <div className='bg-white p-10 rounded-b-[12px] h-full'>
                        <form
                            onSubmit={formik.handleSubmit}
                            className='h-full flex flex-col'
                        >
                            <div className='flex mb-10'>
                                <div className='flex items-center w-1/2 mr-4'>
                                    <label className='text-3xl w-[40%] relative -top-2'>Document Type: </label>
                                    <div className='w-[60%]'>
                                        <Select 
                                            formik={formik}
                                            tailwindClasses=''
                                            name={'documentType'}
                                            options={userType === ACTION_TYPES.CLIENT? CLIENT_DOCUMENT_TYPES : (userType === ACTION_TYPES.CUSTOMER? CUSTOMER_DOCUMENT_TYPES : [])}
                                        />
                                    </div>
                                </div>
                                <div className='flex items-center w-1/2'>
                                    <label className='text-3xl w-[40%] relative -top-2'>Version: </label>
                                    <Input 
                                        formik={formik}
                                        name={'version'}
                                        disabled={true}
                                        placeholder=''
                                    />
                                </div>
                            </div>

                            <div className='flex mb-10'>
                                <label className='text-3xl w-[20%] relative -top-2'>Legal Text: </label>
                                <div className='w-[calc(80%)]'>
                                    <textarea 
                                        value={formik.values.legalText}
                                        className='w-full h-[25rem] border bg-[#F8F8FD] text-3xl p-5'
                                        onFocus={() => formik.setFieldTouched('legalText', true)}
                                        onChange={e => formik.setFieldValue('legalText', e.target.value)}
                                    />
                                    <p className='text-xl text-red-500'>{formik.touched.legalText && formik.errors.legalText}</p>
                                </div>
                            </div>

                            <div className='flex mb-10 flex-1'>
                                <label className='text-3xl w-[20%] relative -top-2'>Changed Text: </label>
                                <div className="w-[calc(80%)]">
                                    <textarea 
                                        value={formik.values.changedText}
                                        className='w-full h-full border bg-[#F8F8FD] text-3xl p-5'
                                        onFocus={() => formik.setFieldTouched('changedText', true)}
                                        onChange={e => formik.setFieldValue('changedText', e.target.value)}
                                    />
                                    <p className='text-xl text-red-500'>{formik.touched.changedText && formik.errors.changedText}</p>
                                </div>
                            </div>

                            <SubmitButtonWide 
                                value='Save'
                            />
                        </form>
                    </div>
                }
            </div>
        </>
    );
}

export default LegalInfoUpdate;
