import { React, useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'

import { Header } from '../../layouts/header'
import Navbar from '../../layouts/navbar'
import Sidebar from '../../layouts/sidebar'
import sideBarData from './skuCreate/components/sideBarData'
import CreateSku from './skuCreate'
import EditSku from './skuEdit'
import FormikControl from '../../components/form/formikControl';
import TextPopup from '../../components/popups/textPopup'
import useSidebar from '../../hooks/useSidebar'

import initialValues from "./utils/initialValues";
import validationSchema from './validations/validations';

import { getClientDetails } from './services/api'
import Loader from '../../components/ui/loader/Loader'

import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { actionOptions, batchTypeOptions } from './utils/options';
import { URL_TYPES } from '../../config/constants'

function Index() {

    const axiosPrivate = useAxiosPrivate(URL_TYPES.QR)
    const [showTextPopup, setShowTextPopup] = useState(false)
    const [alertText, setAlertText] = useState("");
    const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false);

    const [loading, setLoading] = useState(false)
    const blurredBackgroundStyles = isBackgroundBlurred
        ? {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(100, 100, 100, 0.5)",
            backdropFilter: "blur(1.8px)",
            zIndex: 1,
        }
        : {};

    const [clientId, setClientId] = useState('');
    const [clientName, setClientName] = useState('');
    const [actionSelected, setactionSelected] = useState('');
    const [modeSelected, setModeSelected] = useState('');


    const setClientDetails = async (formik, clientId) => {
        formik.setFieldValue('clientId', clientId)
        if (clientId.length !== 7) {
            formik.setFieldValue('clientName', '')
            return;
        } 
        try {
            setLoading(true);
            const data = await getClientDetails(clientId,axiosPrivate);

            formik.setFieldValue('clientName', data.organizationName);
            setClientName(data.organizationName);
        } catch (error) {
            formik.setFieldValue('clientName', '')

            setAlertText(error.message);
            setShowTextPopup(true);
            setIsBackgroundBlurred(true);
        } finally {
            setLoading(false);
        }
    }

    const { isSidebarOpen, toggleSidebar } = useSidebar();
    
    return (
        <div className='layout-wrapper bg-bgcolor/50'>
            <div className='top-header w-full z-10 bg-#FFFFFF'>
                <Header toggleSidebar={toggleSidebar} />
            </div>
            <div className='content-wrapper flex flex-row'>
                <div className={`sidebar top-0 bg-white ${isSidebarOpen ? 'open' : ''}`}>
                    <Sidebar details={sideBarData} />
                </div>
                <div className='w-full bg-bgLightBlue page-content pb-20'>
                    <div className='px-[30px]'>
                        <div className='h-10 flex items-center'>
                            <Navbar />
                        </div>
                        <div className='mt-5 flex flex-col border-solid border-navBorderLight rounded-[12px] bg-popupScreen p-10'>
                            <div className='flex items-start'>
                                <div className='w-[100%]'>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                    >
                                        {
                                            formik => {
                                                return (
                                                    <Form>
                                                        {
                                                            loading && <Loader />
                                                        }
                                                        {isBackgroundBlurred && <div style={blurredBackgroundStyles} />}
                                                        {showTextPopup && (
                                                            <TextPopup
                                                                heading="Alert"
                                                                description={alertText}
                                                                onClose={() => {
                                                                    setShowTextPopup(false);
                                                                    setIsBackgroundBlurred(false);
                                                                }}
                                                            />
                                                        )}
                                                        <div>
                                                            <div className='w-[100%] flex'>

                                                                <div className='w-[50%] pr-10 '>
                                                                    <FormikControl control='input' type='text' label={"Client ID:"} name={'clientId'} bgcolor='selectorBg' textcolor='black' onChange={(e) => {
                                                                        setClientDetails(formik, e.target.value);
                                                                        setClientId(e.target.value);
                                                                    }} />
                                                                    <FormikControl control='input' type='text' label={"Client Name :"} name={"clientName"} bgcolor='selectorBg' disabled={true} />
                                                                </div>

                                                                <div className='flex flex-col w-[50%]'>
                                                                    {/* Action */}
                                                                    <div className='flex w-[100%] justify-start items-center h-20 my-3'>
                                                                        <label className={`text-right text-popupText font-normal text-font16 w-[45%]`}> Action: </label>
                                                                        
                                                                        <div className='text-couponsRadioOPtions font-semibold text-3xl radio-btn-custom'>
                                                                            <FormikControl nextLineError={true} control='radio' name="actions" options={actionOptions}
                                                                                onChange={(value) => {
                                                                                    formik.setFieldValue('actions', value)
                                                                                    setactionSelected(value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    
                                                                    {/* Batch Type */}
                                                                    <div className='flex w-[100%] justify-start items-center h-20 my-3'>
                                                                        <label className={`text-right text-popupText font-normal text-font16 w-[45%]`}> Mode: </label>
                                                                        
                                                                        <div className='text-couponsRadioOPtions font-semibold text-3xl radio-btn-custom'>
                                                                            <FormikControl nextLineError={true} control='radio' name="batchType" options={batchTypeOptions}
                                                                                onChange={(value) => {
                                                                                    formik.setFieldValue('batchType', value)
                                                                                    setModeSelected(value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )
                                            }
                                        }
                                    </Formik>
                                </div>
                            </div>
                        </div>
                        {actionSelected === 'Create' && modeSelected && clientName &&
                            <div>
                                <CreateSku clientId={clientId} batchType={modeSelected} />
                            </div>
                        }

                        {actionSelected === 'Edit' && modeSelected && clientName &&
                            <div>
                                <EditSku clientId={clientId} batchType={modeSelected} />
                            </div>
                        }
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Index