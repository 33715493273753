import React from 'react'
import { Header } from '../../../layouts/header'
import Navbar from '../../../layouts/navbar'
import SideBarComponent from './components/sideBarComponent'
import GenerateQRCodeForm from './components/generateQrCodeForm'
import useSidebar from '../../../hooks/useSidebar'

function Index() {
  const { isSidebarOpen, toggleSidebar } = useSidebar();
  return (
    <div className='layout-wrapper bg-bgcolor/50'>
      <div className='top-header w-full z-10 bg-#FFFFFF'>
        <Header toggleSidebar={toggleSidebar} />
      </div>
      <div className='content-wrapper flex flex-row '>
        <div className={`sidebar top-0 bg-white ${isSidebarOpen ? 'open' : ''}`}>
          <SideBarComponent />
        </div>
        <div className='w-full bg-bgLightBlue page-content'>
          <div className='h-10  flex items-center ml-10 mr-10'>
            <Navbar main='user' current='generate' />
          </div>
          <div className='ml-10 mr-10 pt-10 pb-10 flex justify-start items-center'>
            <h5 className='text-generateQRheading font-bold text-font24'>QR Generation</h5>
          </div>
          <div>
            <div className='ml-10 mr-10'>
              <GenerateQRCodeForm />
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Index