import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from "yup";
import {useNavigate} from 'react-router-dom'
import { Formik,Form } from 'formik'
import FormikControl from '../../../components/form/formikControl'
import searchIcon from '../../../assets/svg/form_icons/search_icon.svg'
import { SubmitButton } from '../../../components/ui/buttons/submitButton'
import SortingTable from '../../../components/table/sortingTable'
import addIcon from '../../../assets/svg/add_icon_white.svg'
import ClientAddUserForm from './clientAddUserForm'
import PopUpManager from '../../../components/popups2/popupManager'

import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import { getAllSubClientsPaginated } from "../services/api"

const SubclientsHeaderData = [{value: 'User ID', space: '30%', key: 'userId'}, {value: 'Name', space: '40%', key: 'userName'}, {value: 'Email Address', space: '50%', key: 'emailId'}, {value: 'Status', space: '20%', key: 'status'}]

function ClientAddUser() {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const pageSize = 10;
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [maxPageNo, setMaxPageNo] = useState(0);
    const [tableLoading, setTableLoading] = useState(false);

    const [addUserForm, setAddUserForm] = useState(false);
    const [subClientsData, setsubClientsData] = useState([]);
    const [masterClientId, setmasterClientId] = useState("");

    const [canCreateSubClient, setCanCreateSubClient] = useState(true);
    const [canAddSubClient, setCanAddSubClient] = useState(false);

    const [textPopupDisabled, setTextPopupDisabled] = useState(true);
    const [textPopupDescription, setTextPopupDescription] = useState('heading');

    const fetchSubClients = useCallback(async (masterClientId, currentPageNo) => {
        try{
            setTableLoading(true);
            const data = await getAllSubClientsPaginated(masterClientId, currentPageNo, pageSize, axiosPrivate);

            data?.pageData?.forEach(clientData => clientData.status = clientData.isBlocked? 'Blocked' : 'UnBlocked')
            setsubClientsData(data.pageData);
            setMaxPageNo(Math.floor(data.totalUsers / pageSize) + (data.totalUsers % pageSize ? 1 : 0));
            setAddUserForm(true);
            setTableLoading(false);
        }catch(error){
            setTextPopupDescription(error.message);
            setTextPopupDisabled(false);

            setTableLoading(false);
        }
    }, [axiosPrivate])

    useEffect(() => {
        if(masterClientId) {
            (async () => {
                await fetchSubClients(masterClientId, currentPageNo);
            })();
        }
    }, [fetchSubClients, masterClientId, currentPageNo]);


    const handleSubmit = async(values)=>{
        setmasterClientId(values.masterClientId);
    }

    // async function handleDone(){
    //     navigate ("/home")
    // }

    const onCreateSuccess = async (newSubClient) => {
        setCanCreateSubClient(false);
        setCanAddSubClient(true);

        //Show popUp
        setTextPopupDescription(`New Sub client created with id: ${newSubClient.userId}`);
        setTextPopupDisabled(false);

        //update table
        setCurrentPageNo(1);
        await fetchSubClients(masterClientId, 1);
    }

    const initialValues = {
        "masterClientId":"",
    }
    
    const MCIdInputValidation = Yup.object({
        masterClientId: Yup.string().required("User Id is required"),
    });

    return (
        <>
            <PopUpManager 
                control={'text'}
                disable={textPopupDisabled}
                heading={'Success'}
                description={textPopupDescription}
                onClose={() =>{
                    setTextPopupDisabled(true);
                }}
            />
            <Formik
                initialValues={initialValues}
                validationSchema={MCIdInputValidation}
                onSubmit={handleSubmit}
                validateOnChange={true}
                validateOnBlur={false}

            >
                {
                    formik => {
                        return (
                            <Form>
                                <div className='bg-popupScreen h-32 flex w-[100%] items-center'>
                                    <div className='pl-10 flex items-center w-[100%] mt-6'>
                                        <div className='font-normal text-font16 mr-4 -mt-6'>
                                            Master Clinet Id :
                                        </div>
                                        <div className='w-[50%]'>
                                            <FormikControl control='input' type='text' name={'masterClientId'}placeholder={"Master Client Id"} bgcolor='selectorBg' width={'full'} placeholderIcon={searchIcon} autoComplete={"off"}/>
                                        </div>
                                        <div className='w-[25%] pl-20 -mt-6'>
                                            <SubmitButton label='Search' btnWidth='full' type='submit' disabled={!(formik.isValid&&formik.dirty)}/>
                                        </div>
                                    </div>
                                </div>
                                
                            </Form>
                        )
                    }
                }
            </Formik>
            <div className='h-full'>
                {addUserForm &&
                    <div className='bg-popupScreen py-5 rounded-b-[12px]'>
                        {canCreateSubClient && <ClientAddUserForm onCreateSuccess={onCreateSuccess} masterClientId={masterClientId}/>}
                    
                        <div className='w-[20%] pl-10 flex items-center pt-10' >
                            <SubmitButton label='Add More Users' imageUrl={addIcon} btnWidth='full' disabled={!canAddSubClient} onClick={() => {
                                setCanCreateSubClient(true);
                                setCanAddSubClient(false);
                            }}/>
                        </div>
                        {/* <div className='pl-10 pr-10 mt-5 flex items-center'>
                            <SubmitButton label='Done' btnWidth='full'onClick={handleDone} />
                        </div> */}
                    </div>
                }

                {
                    addUserForm && subClientsData && subClientsData.length && 
                    <SortingTable 
                        headerData={SubclientsHeaderData} 
                        tableData={subClientsData} 
                        nextDisabled={currentPageNo >= maxPageNo} 
                        previousDisabled={currentPageNo === 1} 
                        nextPage={() => {if(currentPageNo < maxPageNo) setCurrentPageNo(currentPageNo + 1)}}
                        previousPage={() => {if(currentPageNo > 1) setCurrentPageNo(currentPageNo - 1)}}
                        loading={tableLoading}
                        />
                }

                {
                    addUserForm && (!subClientsData || !subClientsData.length) &&
                    <div className='h-10 flex justify-center items-center mt-10 bg-white'>
                        <p className='text-4xl'>No Sub clients Data Found</p>
                    </div>
                }
                
            </div>
        </>
    )
}

export default ClientAddUser