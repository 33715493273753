import qrCodeImage from '../../../../assets/svg/sidebar_2_icons/qr_code_icon.svg'
import downloadQrImage from '../../../../assets/svg/sidebar_2_icons/download_qr_icon.svg'
import batchActionsImage from '../../../../assets/svg/sidebar_2_icons/batch-actions_icon.svg'
import skuImage from '../../../../assets/svg/sidebar_2_icons/sku_icon.svg'


const sideBarData = [
    {
        name:"new-batch-details",
        img:qrCodeImage,
        desc:"Generate"
    },
    {
        name:"download-qr-code",
        img:downloadQrImage,
        desc:"Download QR"
    },
    {
        name:"batch-actions",
        img:batchActionsImage,
        desc:"Batch Actions"
    },
    {
        name:"sku",
        img:skuImage,
        desc:"SKU"
    }
]

export default sideBarData