import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './textError'
function selector(props) {
    const { label, name, options, bgcolor, width, height, radius, padX, padY, ...rest } = props
    

    return (
        <div className='w-full'>
            <div className='h-full w-[100%]'>
                <Field {...rest} as='select' name={name} className={`${width ? `w-${width}` : 'w-[100%]'} h-${height} bg-${bgcolor} rounded-${radius} px-${padX} py-${padY} text-black text-font16 cursor-pointer `} id={props.id} value={props.value} rest >
                    {options?.map((option, index) => {
                        return (
                            <option key={`${option.value}-${index}`} value={option.value} hidden={option.value === '' ? true : false}>
                                {option.label}
                            </option>
                        )
                    })}
                </Field>
            </div>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default selector