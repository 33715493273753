import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './textError'
function textInput(props) {
    const { label, name, width, placeholder, bgcolor, textcolor, height, ...rest } = props

    return (
        <div>
            <div className={`md:flex justify-between items-center ${height ? `h-${height}` : ''}`}>
                <label htmlFor={name} className={` ${textcolor ? `text-${textcolor}` : 'text-popupText'} font-normal text-font16 w-[45%]`}>
                    {label}
                </label>
                {/* {console.log(bgcolor)} */}
                <div className='flex flex-wrap'>
                    <Field id={name} name={name} {...rest} className={`w-${width} ${height ? `h-${height}` : 'h-[46px]'}  ${bgcolor ? `bg-${bgcolor}` : 'bg-bgWhite'} text-font16 rounded-xl`} type='email' placeholder={placeholder} />
                    <ErrorMessage name={name} component={TextError} />
                </div>
            </div>
        </div>
    )
}

export default textInput