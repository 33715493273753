import { handleError } from "../utils/handles/errorHandle";

async function adminLogout(axiosPrivate){
    try{
        const res = await axiosPrivate.post('/api/auth/admin/logout', {} , {
            withCredentials: true
        })

        return res.data;
    }catch(error){
        handleError(error)
    }
}

async function getUserData(axiosPrivate) {
    try{
        const res = await axiosPrivate.get('/api/admin/admin-info');
        return res.data;
    }catch(error){
        handleError(error)
    }
}

export {
    adminLogout,
    getUserData
}