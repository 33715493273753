import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import Loader from '../../../../components/ui/loader/Loader';
import PopUpManager from '../../../../components/popups2/popupManager';

import { SubmitButton } from '../../../../components/ui/buttons/submitButton'
import OfferIcon from '../../../../assets/images/profile_images/offer_icon.svg'
import TermsIcon from '../../../../assets/images/profile_images/terms_icon.svg'
import NarrowArrow from '../../../../assets/svg/narrow_arrow.svg';

import { getProductDetailsById } from '../services/api';

const Reward = ({ rewardData }) => {

    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const [isTCOpen, setIsTCOpen] = useState(false);
    const [tAc, setTaC] = useState(rewardData?.termsAndConditions)
    const [isOfferDetailsOpen, setIsOfferDetailsOpen] = useState(false);
    const [offerDetails, setOfferDetails] = useState(rewardData?.offerDetails);


    const expiredDate = new Date(rewardData?.expiryDate);
    const todayDate = new Date();
    const expired = (expiredDate - todayDate <= 0);

    //Popup
    const [ loading, setLoading ] = useState(false);
    const [ textPopupDisabled, setTextPopupDisabled ] = useState(true);
    const [ textPopupDescription, setTextPopupDescription ] = useState('');

    const handleProductDetails = async () => {
        try {
            setLoading(true);
            const productDetails = await getProductDetailsById(rewardData?.productId, axiosPrivate);
            
            navigate('/product-details', { state: { product: productDetails }});
        } catch (error) {
            setTextPopupDescription(error.message || 'Unable to fetch product details');
            setTextPopupDisabled(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading && <Loader />}
            <PopUpManager 
                disable={textPopupDisabled}
                control='text'
                heading='Alert'
                description={textPopupDescription}
                onClose={() => setTextPopupDisabled(true)}
            />
            <div className="profile-card bg-white p-[20px] rounded-[12px] border border-navBorderLight flex flex-col justify-between gap-y-5 mb-14">
                <div className="w-full flex justify-between items-start">
                    <div className="md:w-[75%]">
                        <div>
                            <h4 className="text-sideBarActions font-semibold text-[17px] my-2">{rewardData?.couponHeader}</h4>
                            <span className="text-black text-opacity-[35%] italic text-font14 my-2">{rewardData?.couponText}</span>
                            {rewardData?.deliveredAt && <p className="text-black italic text-font14 my-2">Rewarded on: {new Date(rewardData?.deliveredAt).toLocaleDateString('en-US', {month: 'long', day: 'numeric', year: 'numeric', daySuffix: 'numeric'})}</p>}
                            {rewardData?.expiryDate && <p className={`text-black italic text-font14 my-2  ${expired? 'text-textRed' : 'text-textGreen'}`}>{expired? 'Expired on: ' : 'Will be expired on: '} {new Date(rewardData?.expiryDate).toLocaleDateString('en-US', {month: 'long', day: 'numeric', year: 'numeric', daySuffix: 'numeric'})}</p>}
                        </div>
                    </div>
                    <div className="md:w-[25%]">
                        <div className="flex gap-5 md:justify-end items-center">
                            <span className="text-black text-font14">Status</span>
                            <div className={`${expired? 'bg-bgredLight ' : 'bg-bggreenLight'} flex items-center justify-center py-3 px-20 rounded-[5px] ${expired? 'text-textRed' : 'text-textGreen'} text-font14 font-medium border ${expired? 'border-textRed' : 'border-textGreen'} border-opacity-50`}>{expired? 'Expired' : (rewardData.isScratched? 'Viewed' : 'Issued')}</div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex items-center justify-between">
                    <div className="md:w-[75%] flex">
                        <button 
                            className='px-5 py-6 flex items-center rounded-lg border-2 mr-4' 
                            style={{backgroundImage: 'linear-gradient(to right, #50AFF81A, #9836A61A)'}}
                            onClick={() => {
                                setIsTCOpen(false);
                                setIsOfferDetailsOpen(!isOfferDetailsOpen)
                            }}    
                        >
                            <img className='mr-4' src={OfferIcon} alt="Offer" />
                            <p className='text-3xl mr-8'>Offer details</p>
                            <img className='' src={NarrowArrow} alt="Arrow" />
                        </button>
                        <button 
                            className='px-5 py-6 flex items-center rounded-lg border-2 mr-4' 
                            style={{backgroundImage: 'linear-gradient(to right, #50AFF81A, #9836A61A)'}}
                            onClick={() => {
                                setIsOfferDetailsOpen(false)
                                setIsTCOpen(!isTCOpen)
                            }}    
                        >
                            <img className='mr-4' src={TermsIcon} alt="Offer" />
                            <p className='text-3xl mr-8'>Terms & Conditions</p>
                            <img className='' src={NarrowArrow} alt="Arrow" />
                        </button>
                    </div>
                    <div className="flex items-center w-[25%]">
                        <SubmitButton label={'Product Details'} type="submit" btnWidth="full" onClick={() => handleProductDetails()} />
                    </div>
                </div>
                <AnimatePresence>
                    {
                        isOfferDetailsOpen && <motion.div
                            initial={{height: '0'}}
                            animate={{height: 'auto'}}
                            exit={{height: '0'}}
                            className='overflow-hidden'
                        >
                            <hr className='my-10' />
                            <pre
                                style={{whiteSpace: 'pre-wrap'}}
                                className='text-3xl'
                            >
                                {offerDetails}
                            </pre>
                        </motion.div>
                    }
                </AnimatePresence>
                <AnimatePresence>
                    {
                        isTCOpen && <motion.div
                            initial={{height: '0'}}
                            animate={{height: 'auto'}}
                            exit={{height: '0'}}
                            className='overflow-hidden'
                        >
                            <hr className='my-10' />
                            <pre
                                style={{whiteSpace: 'pre-wrap'}}
                                className='text-3xl'
                            >
                                {tAc}
                            </pre>
                        </motion.div>
                    }
                </AnimatePresence>
            </div>
        </>
    );
}

export default Reward;
