import { React, useState } from 'react'
import { TableMainHeading, TableSubHeading } from '../../../components/table/tablehead'
import TableBody from '../../../components/table/tableBody'
import minimizeIcon from '../../../assets/svg/minimize_icon.svg'

import { useTempData } from './financeTableContent'



function FinanceServicesTable({ month }) {

    const { getData } = useTempData();
    const { 
        qrgeneration, qrgenerationSubheading, qrgenerationTableContents,
        tracking, trackingSubheading, trackingTableContents,
        coupons, couponsSubheading, couponTableContents,
        biUserCost, biUserCostSubheading, biUserCostTableContents,
        misc, miscSubheading, miscTableContents,
        customerSupport,
        totalInvoiceAmtTableContents,
    } = getData(month);

    const [qrgenerationMainHeading, setqrgenerationMainHeading] = useState(true)
    const [qrGenerationSubheading, setqrgenerationSubheading] = useState(true)
    const [trackingMainHeading, settrackingMainHeading] = useState(true)
    const [trackingSubHeading, settrackingSubHeading] = useState(true)
    const [couponsMainHeading, setcouponsMainHeading] = useState(true)
    const [couponsSubHeading, setcouponsSubHeading] = useState(true)
    const [biUserMainHeading, setbiUserMainHeading] = useState(true)
    const [biUserSubHeading, setbiUserSubHeading] = useState(true)
    const [miscMainHeading, setmiscMainHeading] = useState(true)
    const [miscSubHeading, setmiscSubHeading] = useState(true)
    const [amtClearedMainHeading, setamtClearedMainHeading] = useState(true)
    const [amtClearedSubHeading, setamtClearedSubHeading] = useState(true)

    const handleQrGeneration = () => {
        setqrgenerationMainHeading(!qrgenerationMainHeading)
    }
    const QrGenerationSubheading = () => {
        setqrgenerationSubheading(!qrGenerationSubheading)
    }
    const handleTrackingMainHeading = () => {
        settrackingMainHeading(!trackingMainHeading)
    }
    const handleTrackingSubHeading = () => {
        settrackingSubHeading(!trackingSubHeading)
    }
    const handleCoupongMainHeading = () => {
        setcouponsMainHeading(!couponsMainHeading)
    }
    const handleCouponSubHeading = () => {
        setcouponsSubHeading(!couponsSubHeading)
    }
    const handleBiUserMainHeading = () => {
        setbiUserMainHeading(!biUserMainHeading)
    }
    const handleBiUserSubHeading = () => {
        setbiUserSubHeading(!biUserSubHeading)
    }
    const handleMiscMainHeading = () => {
        setmiscMainHeading(!miscMainHeading)
    }
    const handleMiscSubHeading = () => {
        setmiscSubHeading(!miscSubHeading)
    }
    const handleAmtClearedMainHeading = () => {
        setamtClearedMainHeading(!amtClearedMainHeading)
    }
    const handleAmtClearedSubHeading = () => {
        setamtClearedSubHeading(!amtClearedSubHeading)
    }
    const qrGenWidths = ['60%', '8%', '8%', '8%', '8%', '8%']
    const qrSubHeadingWidth = ['14%', '7%', '5%', '8%', '6%', '8%', '6%', '14%']
    const qrTableWidth = ['14%', '7%', '5%', '8%', '6%', '8%', '6%', '14%', '8%', '16%', '8%']
    const trackingSubWidth = ['14%', '13%', '13.5%', '13.5%', '14%']
    const trackingTableWidth = ['14%', '13%', '13.5%', '13.5%', '14%', '8%', '16%', '8%']
    const couponSubWidth = ['14%', '40%', '14%', '6%']
    const couponsTableWidth = ['14%', '40%', '14%', '8%', '16%', '8%']
    const amtClearedWidth = ['60%', '32%', '8%']
    const amtClearedTableWidth = ['14%', '40%', '38%', '8%']
    return (
        <table className='w-[100%]'>
            <div className='border-t-0 border border-navBorderLight rounded-[12px]'>
                <div onClick={handleQrGeneration}>
                    <TableMainHeading details={qrgeneration} widths={qrGenWidths} />
                </div>
                {qrgenerationMainHeading &&
                    <div onClick={QrGenerationSubheading} >
                        <TableSubHeading details={qrgenerationSubheading} widths={qrSubHeadingWidth} />
                    </div>
                }
                {qrGenerationSubheading &&
                    <TableBody details={qrgenerationTableContents} widths={qrTableWidth} minimizeIcon={minimizeIcon} />
                }

                <div onClick={handleTrackingMainHeading}>
                    <TableMainHeading details={tracking} widths={qrGenWidths} />
                </div>
                {trackingMainHeading &&
                    <div onClick={handleTrackingSubHeading} >
                        <TableSubHeading details={trackingSubheading} widths={trackingSubWidth} />
                    </div>
                }
                {trackingSubHeading && <TableBody details={trackingTableContents} widths={trackingTableWidth} />}

                <div onClick={handleCoupongMainHeading}>
                    <TableMainHeading details={coupons} widths={qrGenWidths} />
                </div>
                {couponsMainHeading &&
                    <div onClick={handleCouponSubHeading} >
                        <TableSubHeading details={couponsSubheading} widths={couponSubWidth} />
                    </div>
                }
                {couponsSubHeading && <TableBody details={couponTableContents} widths={couponsTableWidth} />}

                <div onClick={handleBiUserMainHeading}>
                    <TableMainHeading details={biUserCost} widths={qrGenWidths} />
                </div>
                {biUserMainHeading &&
                    <div onClick={handleBiUserSubHeading} >
                        <TableSubHeading details={biUserCostSubheading} widths={couponSubWidth} />
                    </div>
                }
                {biUserSubHeading && <TableBody details={biUserCostTableContents} widths={couponsTableWidth} />}

                <div onClick={handleMiscMainHeading}>
                    <TableMainHeading details={misc} widths={qrGenWidths} />
                </div>
                {miscMainHeading &&
                    <div onClick={handleMiscSubHeading} >
                        <TableSubHeading details={miscSubheading} widths={trackingSubWidth} />
                    </div>
                }
                {miscSubHeading && <TableBody details={miscTableContents} widths={trackingTableWidth} />}

                <div >
                    <TableMainHeading details={customerSupport} widths={qrGenWidths} />
                </div>

                {/* <div >
                    <TableMainHeading details={outstandingAmount} widths={qrGenWidths} />
                </div> */}

                {/* <div className='bottom-radius' onClick={handleAmtClearedMainHeading}>
                    <TableMainHeading details={amtCleared} widths={qrGenWidths} />
                </div> */}
                {/* {amtClearedMainHeading &&
                    <div onClick={handleAmtClearedSubHeading} >
                        <TableSubHeading details={amtClearedSubheading} widths={couponSubWidth} />
                    </div>
                } */}
                {/* {amtClearedSubHeading && <TableBody details={amtClearedTableContents} widths={amtClearedTableWidth} />} */}
            </div>

            <div className='mt-5 mb-5 border border-navBorderLight rounded-[12px] border-table'>
                <TableBody details={totalInvoiceAmtTableContents} widths={amtClearedWidth} textColor='redButtonColor' font='bold' />
            </div>
            {/* <div className='border border-navBorderLight rounded-[12px] border-table'>
                <TableBody details={amtDueTableContents} widths={amtClearedWidth} textColor='redButtonColor' font='bold' />
            </div> */}


        </table>
    )
}

export default FinanceServicesTable