import React, { useState } from 'react'
import { Formik, Form } from 'formik'

import CloseIcon from '../../assets/svg/popups/popUpCloseIcon.svg'
import FormikControl from '../form/formikControl'
import { SubmitButton } from '../ui/buttons/submitButton'
import RedButton from '../ui/buttons/deleteButtonRed'


function TextBoxPopup(props) {
    const { heading, description, subHeading, onClose, onSubmit, setReason, okButtonText, reasonRequired, cancelRequired=true } = props

    const [resonVal, setReasonVal] = useState();
    return (
        <Formik>
            {
                formik => {
                    return (

                        <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  flex flex-col w-[25%] h-[25vh] z-10'>
                            <div className='bg-popupScreen flex justify-around items-center flex-col w-[100%]  z-10'>
                                <div className='flex justify-between items-center p-10 h-14 w-[100%] bg-gradient-to-r from-gradientPurple  to-gradientBlue to-90% '>
                                    <span className='font-bold text-4xl text-popupScreen'>{heading}</span>
                                    <img className='w-9 h-9' src={CloseIcon} alt='' onClick={onClose} />
                                </div>
                                <div className='pl-14 pr-14 h-20 flex justify-start items-center my-5'>
                                    <p className='text-popUpTextColor text-3xl font-medium text-center'>{subHeading}</p>
                                </div>
                                <div className='text-popUpTextColor flex flex-col w-full justify-center items-center font-medium text-3xl pl-14 pr-14'>
                                    <span className='popUpTextColor w-full text-2xl text-left'>{description}</span>
                                    <div className='w-full flex justify-center' >
                                        <FormikControl
                                            control='input-textarea-large'
                                            type='description'
                                            label={""}
                                            name={'deleteDescription'}
                                            placeholderColor='popUpPlaceholder'
                                            placeholder={"2000 characters allowed"}
                                            onChange={(e)=> {
                                                setReason(e.target.value);
                                                setReasonVal(e.target.value)
                                            }}
                                            width='full'
                                            height='90' />
                                        {/* <input className='bg-selectorBg w-full h-full  opacity-50 placeholder-top-0 placeholder-left-0 text-xl border rounded-xl' type='text-area' name='' placeholder='2000 characters allowed' /> */}
                                    </div>
                                </div>
                                <div className={`flex w-[100%] pl-10 pr-10 items-center my-5`} style={cancelRequired? {justifyContent: 'space-between'} : {justifyContent: 'center'}}>
                                    <div className='w-[45%]' >
                                        <SubmitButton disabled={reasonRequired && !resonVal} onClick={onSubmit} type={'submit'} label={okButtonText || 'Submit'} btnWidth='full' />
                                    </div>
                                    {
                                        cancelRequired &&
                                        <div className='w-[45%]'>
                                            <RedButton onClick={onClose} type={'submit'} value='Cancel' width='full' height='14' />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        </Formik>
    )
}

export default TextBoxPopup