import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';

import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';

import Loader from '../../../../components/ui/loader/Loader';
import PopUpManager from '../../../../components/popups2/popupManager';
import { SubmitButtonWide } from '../../../../components/ui/buttons/submitButton';

import CompanyDetails from '../../clientSignUp/signUpRequest/components/CommmpanyDetails/CompanyDetails';
import AuthPersonInfo from '../../clientSignUp/signUpRequest/components/CommmpanyDetails/AuthPersonInfo';
import InvoicePaymentContact from '../../clientSignUp/signUpRequest/components/CommmpanyDetails/InvoicePaymentContact';
import Preview from '../../clientSignUp/signUpRequest/components/AppDetails/Preview';
import AppDetails from '../../clientSignUp/signUpRequest/components/AppDetails/AppDetails';
import Solutions from '../../clientSignUp/signUpRequest/components/CostStructureDetails/Solutions';
import Services from '../../clientSignUp/signUpRequest/components/CostStructureDetails/Services';
import OtpDetails from '../../clientSignUp/signUpRequest/components/OtpDetails/OtpDetails';
import Comment from '../../clientSignUp/signUpRequest/components/comments/Comment';

import BackgroundVerification from '../../clientSignUp/signUpRequest/components/BackgroundVerificationDetails/BackgroundVerification';

import { initialValues } from '../utils/initialValues';
import { validationSchema } from '../utils/validationSchema';
import { composeData } from '../utils/massageData';

import { parseData, parseFiles } from '../utils/massageData';
import { USER_TYPES, SORT_ORDER } from '../../../../config/constants';

import { saveEditedChanges, blackListMasterClient, whiteListMasterClient } from '../services/api';
import { requestOtp, resendOtp } from '../../../../services/otpService';
import useUserData from '../../../../hooks/useUserData';

const SERVICESTATUS_TYPES = {
    WHITELISTED: 'Whitelisted',
    BLACKLISTED: 'Blacklisted'
}

const FormManager = ({clientData}) => {

    const axiosPrivate = useAxiosPrivate();
    const { user } = useUserData();

    const [loadedValues, setLoadedValues] = useState({});
    const [canSubmit, setCanSubmit] = useState(false);

    //Popup
    const [ loading, setLoading ] = useState(false);
    const [ textPopupDisabled, setTextPopupDisabled ] = useState(true);
    const [ textPopupHead, setTextPopupHead ] = useState('');
    const [ textPopupDescription, setTextPopupDescription ] = useState('');
    const [ otpPopupDisabled, setOtpPopupDisabled ] = useState(true);
    const [ otpPopupHead, setOtpPopupHead ] = useState('');
    const otpPopupFun = useRef(() => {});
    const [ otp, setOtp ] = useState();
    const [ reason, setReason ] = useState();
    const [otpErr, setOtpErr] = useState('');

    const [otpSentTo, setOtpSentTo] = useState('');

    useEffect(() => {
        setOtpErr('');
    }, [otpPopupDisabled])

    useEffect(() => {
        if(!clientData) return;
        const loadedInitialValues = composeData(clientData);
        setLoadedValues(loadedInitialValues);

        Object.keys(loadedInitialValues).forEach(async key => {
            await formik.setFieldValue(key, loadedInitialValues[`${key}`]);
        });
    }, []);

    const handleReqOtp = async () => {
        let success = false;
        try {
            setLoading(true);
            const data = await requestOtp(axiosPrivate);
            setOtpSentTo(data.emailId);

            success = true;
        } catch (err) {
            setTextPopupHead('Alert')
            setTextPopupDescription(err.message || 'Error sending OTP');
            setTextPopupDisabled(false);

            success = false;
        } finally {
            setLoading(false)
        }
        return success;
    }

    const handleResendOtp = async () => {
        let success = false;
        try {
            setLoading(true)
            await resendOtp(user?.emailId);

            success = true;
        } catch (err) {
            setTextPopupHead('Alert')
            setTextPopupDescription(err.message || 'Error sending OTP');
            setTextPopupDisabled(false);

            success = false;
        } finally {
            setLoading(false)
        }
        return success;
    }

    const handleWhiteList = async (otp, reason) => {
        let success = false;
        if(otp.length !== 4) {
            setOtpErr('Provide a valid otp');
            return (success = false);
        }

        try {
            setLoading(true);
            await whiteListMasterClient(
                clientData.masterClientId, 
                reason, 
                otp, 
                false,
                user?.emailId,
                axiosPrivate
            );
            success = true;

            clientData.serviceStatus = SERVICESTATUS_TYPES.WHITELISTED;
            setTextPopupHead('Alert');
            setTextPopupDescription(`${clientData.masterClientId} has been WHITELISTED successfully!`)
            setTextPopupDisabled(false);
        } catch (error) {
            success = false;
            if(error.message === 'Invalid OTP' && error.headers[`ratelimit-remaining`] > 0) {
                setOtpErr(`Invalid OTP, You still have ${error.headers[`ratelimit-remaining`]} attempts left`);
                setLoading(false);
                return success;
            }

            setTextPopupHead('Alert');
            setTextPopupDescription(error.message || `Unable to Whitelist ${clientData.masterClientId}`)
            setTextPopupDisabled(false);
            setOtpPopupDisabled(true);
        } finally {
            setLoading(false);
        }

        return success;
    }

    const handleBlackList = async (otp, reason) => {
        let success = false;
        if(otp.length !== 4) {
            setOtpErr('Provide a valid otp');
            return (success = false);
        }

        try {
            setLoading(true);
            await blackListMasterClient(
                clientData.masterClientId, 
                reason, 
                otp, 
                false,
                user?.emailId,
                axiosPrivate
            );
            success = true;

            clientData.serviceStatus = SERVICESTATUS_TYPES.BLACKLISTED;
            setTextPopupHead('Alert');
            setTextPopupDescription(`${clientData.masterClientId} has been BLACKLISTED successfully!`)
            setTextPopupDisabled(false);
        } catch (error) {
            success = false;
            if(error.message === 'Invalid OTP' && error.headers[`ratelimit-remaining`] > 0) {
                setOtpErr(`Invalid OTP, You still have ${error.headers[`ratelimit-remaining`]} attempts left`);
                setLoading(false);
                return success;
            }

            setTextPopupHead('Alert');
            setTextPopupDescription(error.message || `Unable to Blacklist ${clientData.masterClientId}`)
            setTextPopupDisabled(false);
            setOtpPopupDisabled(true);
        } finally {
            setLoading(false);
        }

        return success;
    }

    const handleSubmit = async (values) => {
        const parsedData = parseData(values);
        const parsedFiles = parseFiles(values);

        //creating formData
        const formData = new FormData();
        formData.append('masterClientDetails', JSON.stringify(parsedData));
        Object.keys(parsedFiles).forEach(key => {
            const file = parsedFiles[`${key}`];
            if(file) formData.append(key, file);
        });
        //

        try {
            setLoading(true);
            const res = await saveEditedChanges(clientData?.masterClientId, formData, axiosPrivate);

            setCanSubmit(false);
            setTextPopupHead('Success!');
            setTextPopupDescription('Data has been updated successfully!')
            setTextPopupDisabled(false);
        } catch (error) {
            setTextPopupHead('Alert');
            setTextPopupDescription(error.message || 'Unable to update data')
            setTextPopupDisabled(false);
        } finally {
            setLoading(false);
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: handleSubmit
    });

    useEffect(() => {
        let tempCanSubmit = false;
        Object.keys(formik.values).forEach(key => {
            const formVal = formik.values[`${key}`];
            const srVal = loadedValues[`${key}`];
            if(typeof formVal != typeof srVal) {
                tempCanSubmit = true;
            } else if(typeof formVal === 'object') {
                const formStr = JSON.stringify(Object.keys(formVal).sort().reduce((obj, key) => { 
                        obj[key] = formVal[key]; 
                        return obj;
                    },{}));
                const srStr = JSON.stringify(Object.keys(srVal).sort().reduce((obj, key) => { 
                        obj[key] = srVal[key]; 
                        return obj;
                    },{}));
                if(formStr != srStr) {
                    tempCanSubmit = true;
                }
            } else if(formVal != srVal) {
                tempCanSubmit = true;
            }
        })

        setCanSubmit(tempCanSubmit);
    }, [formik.values, loadedValues]);

    return (
        <>
            {loading && <Loader />}
            <PopUpManager 
                disable={textPopupDisabled}
                control='text'
                heading={textPopupHead}
                description={textPopupDescription}
                onClose={() => setTextPopupDisabled(true)}
            />
            <PopUpManager 
                control={'otp-text'}
                disable={otpPopupDisabled || !textPopupDisabled}
                heading={'Alert'}
                title={otpPopupHead}
                description={`Otp will be sent to ${otpSentTo.slice(0,2)}*******${otpSentTo.slice(otpSentTo.length-10, otpSentTo.length)}`}
                okButtonText={'Submit'}
                errorMessage={otpErr}

                onClose={() => {
                    setOtpPopupDisabled(true)
                }}
                clickedOk={async () => {
                    const success = await otpPopupFun.current(otp, reason)
                    if(success) setOtpPopupDisabled(true);
                }}
                otpSetter={setOtp}
                reasonSetter={setReason}
                resendOtp={handleResendOtp}
            />
            <form 
                className='bg-white rounded-[12px] py-1'
                onSubmit={formik.handleSubmit} 
            >
                
                <CompanyDetails 
                    formik={formik} 
                    isReview={true}
                />

                <hr className='mx-16 my-20' />

                <AuthPersonInfo 
                    formik={formik}
                />

                <hr className='mx-16 my-20' />

                <InvoicePaymentContact 
                        formik={formik}
                />

                <hr className='mx-16 mb-20 mt-10' />

                <p className='text-[#182AB4] text-5xl mb-10 mx-16 font-semibold'> Cost Structure</p>
                <Solutions 
                    formik={formik}
                />
                <Services 
                    formik={formik}
                />

                <hr className='mx-16 my-20' />

                <div className='mx-[5%]'>
                    <Preview 
                        gradAngle={formik.values.gradientAngle}
                        logo={formik.values.clientLogo}
                        favicon={formik.values.clientFavIcon}
                        logoBgColor={formik.values.backgroundColor}
                        primaryColor={formik.values.primaryColor}
                        secondaryColor={formik.values.secondaryColor}
                        includeFooter={formik.values.isfooterIncluded === "" || formik.values.isfooterIncluded === "true" || formik.values.isfooterIncluded === true}
                        organizationName={formik.values.organizationName}
                    />
                </div>

                <hr className='mx-16 my-20' />

                <AppDetails 
                    formik={formik} 
                    isReview={true}
                />

                <hr className='mx-16 my-20' />

                <OtpDetails
                    formik={formik}
                />

                <hr className='mx-16 my-20' />

                <Comment
                    formik={formik}
                />

                <hr className='mx-16 my-20' />

                <BackgroundVerification 
                    formik={formik}
                />

                <hr className='mx-16 my-20' />
                
                <div className='flex justify-between items-center gap-20 mx-16 mb-10'>
                    {
                        clientData?.serviceStatus === SERVICESTATUS_TYPES.WHITELISTED && <div className='w-[20rem]'>
                            <SubmitButtonWide 
                                value={'Blacklist'}
                                type='button'
                                onClick={async () => {
                                    const success = await handleReqOtp();
                                    if(!success) return;

                                    setOtpPopupHead(`Do you want to BLACKLIST ${clientData.masterClientId}`);
                                    otpPopupFun.current = async (otp, reason) => {
                                        const success =  await handleBlackList(otp, reason);
                                        return success;
                                    }
                                    setOtpPopupDisabled(false);
                                }}
                            />
                        </div>
                    }
                    {
                        clientData?.serviceStatus === SERVICESTATUS_TYPES.BLACKLISTED && <div className='w-[20rem]'>
                            <SubmitButtonWide 
                                value={'Whitelist'}
                                type='button'
                                onClick={async () => {
                                    const success = await handleReqOtp();
                                    if(!success) return;

                                    setOtpPopupHead(`Do you want to WHITELIST ${clientData.masterClientId}`);
                                    otpPopupFun.current = async (otp, reason) => {
                                        const success = await handleWhiteList(otp, reason);
                                        return success;
                                    }
                                    setOtpPopupDisabled(false);
                                }}
                            />
                        </div>
                    }  
                    {
                        canSubmit && <div className='w-[15rem]'>
                            <SubmitButtonWide 
                                value='Save'
                                type='submit'
                            />
                        </div> 
                    }
                </div>
                
            </form>
        </>
    );
}

export default FormManager;
