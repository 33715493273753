import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import TableBody from '../../../../../components/table/tableBody';
import { SubmitButtonWide } from '../../../../../components/ui/buttons/submitButton';

import { getSRRequests } from '../services/api';


const tableHeaderOptions = [{label: 'SL'},{label: 'Service Request No.'}, {label: 'Company Name'}, {label: 'Date of Request'}, {label: 'Actions'}]

const widthsHeader = [
    '7%', '25%', '25%', '25%', '20%' 
];

const ViewRequests = () => {

    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [bodyData, setBodyData] = useState([]);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const pageSize = useRef(10);

    useEffect(() => {
        (async () => {
            await getAllRequests(currentPageNo);
        })();
    }, [])

    const getAllRequests = async (currentPageNo) => {
        try {
            const requests = await getSRRequests(currentPageNo, pageSize.current, axiosPrivate);
            if(!requests) return;

            let tempBodyData = [];
            requests && Array.isArray(requests) && requests.forEach((request, ind) => {
                tempBodyData = [
                    ...tempBodyData,
                    {
                        sl: (ind+1 < 10)? `0${ind+1}` : ind+1,
                        srId: request?.srId,
                        company: request?.organizationName,
                        createdAt: new Date(request?.createdAt).toLocaleDateString('en-us', { 
                            year:"numeric", 
                            month:"short", 
                            day:"numeric", 
                            hour: 'numeric', 
                            minute: 'numeric', 
                            second: 'numeric', 
                            timeZoneName: 'longOffset' 
                        })
                    }
                ];
            });
            setBodyData(tempBodyData);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className=''>
            <div className='bg-white rounded-[12px] p-10 mb-10'>
                <p className='text-[#182AB4] font-bold'>Requests</p>
            </div>

            <div className='rounded-[12px] overflow-hidden'>
                <table className='w-[100%]'>
                    <thead className='w-full bg-bgLightBlue text-couponsTableText flex'>
                        <tr className='w-full bg-bgLightBlue text-couponsTableText flex'>
                            {
                                tableHeaderOptions.map((head, ind) => {
                                    return <th style={{width: widthsHeader[ind]}} key={ind} className='flex justify-center items-center font-bold'>
                                        <p className={`text-font16 py-5`}>{head.label}</p>
                                    </th>
                                })
                            }
                        </tr>
                    </thead>
                    <TableBody 
                        details={bodyData}
                        widths={widthsHeader}
                        actionWidth={'20%'}
                        actions={(rowData) => { 
                            return <div className='flex justify-center items-center w-[100%] h-[100%] my-5'>
                                <div className='w-[70%]'>
                                    <SubmitButtonWide value={"View"} 
                                        onClick={() => {
                                            navigate(`/signup-request/${rowData.srId}`)
                                        }}
                                    />
                                </div>
                            </div>
                            }
                        }
                    />
                </table>
            </div>
        </div>
    );
}

export default ViewRequests;
