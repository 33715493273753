import { handleError } from "../../../../utils/handles/errorHandle";

async function getCustomerProfile(data,axiosPrivate){
    console.log(data);
    try{
        const response = await axiosPrivate.get("/api/customer/view-customer-profile", {
            params: data
        });

        return response.data;
    }catch(error){
        handleError(error);
    }
}

export{
    getCustomerProfile
}