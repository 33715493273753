import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import useUserData from '../../../../hooks/useUserData';

import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import FormikControl from '../../../../components/form/formikControl';
import { SubmitButton } from '../../../../components/ui/buttons/submitButton';
import PopUpManager from '../../../../components/popups2/popupManager';
import Loader from '../../../../components/ui/loader/Loader';

import { getDataSummary } from '../services/api';

const typeOptions = [
    {key: '-- Select Type --', value: ''},
    {key: 'Business Insights', value: 'Admin_Business_Insights'},
    {key: 'Tracking Insights', value: 'Admin_Tracking_Insights'},
    {key: 'Finance Insights', value: 'Admin_Finance_Insights'},
];

const periodOptions = [
    {key: '-- Select Type --', value: ''},
    {key: 'Last 15 days', value: '15_days'},
    {key: '1 Month', value: '1_month'},
    {key: '3 months', value: '3_month'},
    {key: '6 months', value: '6_month'},
    {key: '9 months', value: '9_month'},
    {key: '1 year', value: '1_year'},
];

const yearOptions = [

]

const monthOptions = [
    {key: '-- Select Month --', value: ''},
    { key: 'January', value: 'Jan' },
    { key: 'February', value: 'Feb' },
    { key: 'March', value: 'Mar' },
    { key: 'April', value: 'Apr' },
    { key: 'May', value: 'May' },
    { key: 'June', value: 'Jun' },
    { key: 'July', value: 'Jul' },
    { key: 'August', value: 'Aug' },
    { key: 'September', value: 'Sep' },
    { key: 'October', value: 'Oct' },
    { key: 'November', value: 'Nov' },
    { key: 'December', value: 'Dec' }
  ];

const DataSummary = () => {

    const axiosPrivate = useAxiosPrivate();
    const [downloadLink, setDownloadLink] = useState('');

    //Popup
    const [loading, setLoading] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [textPopupDisabled, setTextPopupDisabled] = useState(true);

    const initialValues = {
        clientId: '',
        type: '',
        period: '',
        year: '',
        month: '',
    }

    const validationSchema = Yup.object({
        clientId: Yup.string().length(7, 'Client Id should have 7 characters only.').required('This field is required'),
        type: Yup.string().required('This field is required'),
        year: Yup.string().required('This field is required'),
        month: Yup.string().required('This field is required'),
    })

    const handleSubmit = async (values) => {
        try {
            setLoading(true)
            const downloadUrl = await getDataSummary(values.type, values.period, axiosPrivate);
            setDownloadLink(downloadUrl);
        } catch (error) {
            setAlertText(error.message || 'Unable to fetch Data');
            setTextPopupDisabled(false);
        } finally {
            setLoading(false)
        }
    }

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = downloadLink;
        link.target = '_blank';

        link.click();
    }
 
    return (
        <>
            { loading && <Loader /> }
            <PopUpManager 
                disable={textPopupDisabled}
                control={"text"}
                heading={"Alert"}
                description={alertText}
                onClose={() => setTextPopupDisabled(true)}
            />
            <div className='w-full rounded-[12px] p-10'>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {
                        formik => {
                            return (
                                <Form>
                                    <div className="w-1/2 profile-search flex items-center pr-5 pl-10 mb-10">
                                        <FormikControl 
                                            label={'Client ID: '}
                                            control='input'
                                            name='clientId'
                                            bgcolor={'selectorBg'}
                                            options={typeOptions}
                                        />
                                    </div>
                                    <div className="bg-white rounded-3xl w-full p-10 flex flex-col gap-10">
                                        <div className='w-full flex flex-col gap-10'>
                                            <div className="w-1/2 profile-search pr-5">
                                                    <FormikControl 
                                                        control='select'
                                                        name='type'
                                                        label={'Type: '}
                                                        bgcolor={'selectorBg'}
                                                        options={typeOptions}
                                                        onChange={e => {
                                                            setDownloadLink('');
                                                            formik.setFieldValue('type', e.target.value);
                                                        }}
                                                    />
                                            </div>

                                            <div className='flex gap-10'>
                                                <div className="profile-search w-1/2">
                                                        <FormikControl 
                                                            control='select'
                                                            name='year'
                                                            label='Year: '
                                                            bgcolor={'selectorBg'}
                                                            options={periodOptions}
                                                            onChange={e => {
                                                                setDownloadLink('');
                                                                formik.setFieldValue('year', e.target.value);
                                                            }}
                                                        />
                                                </div>
                                                <div className="profile-search w-1/2">
                                                    <FormikControl 
                                                        control='select'
                                                        name='month'
                                                        label='Month: '
                                                        bgcolor={'selectorBg'}
                                                        options={monthOptions}
                                                        onChange={e => {
                                                            setDownloadLink('');
                                                            formik.setFieldValue('month', e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full flex justify-start">
                                            <div className='w-[25rem]'>
                                                <SubmitButton label={'Search'} btnWidth={'[100%]'} type={'submit'} />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>

            {
                downloadLink && <div className='mt-10 bg-white rounded-[12px] mx-[20%]'>
                    <SubmitButton 
                        label={'Download'} 
                        btnWidth={'[100%]'} 
                        type={'submit'} 
                        onClick={handleDownload}
                        />
                </div>
            }
        </>
    );
}

export default DataSummary;
