import * as Yup from 'yup';

const validationSchema = Yup.object({
    clientId: Yup.string().length(7, 'Invalid client ID').required('This field is required'),
    clientName: Yup.string().required('This field is required'),
    noOfActiveUsers:Yup.string().required('This field is required'),
    type: Yup.string().required("Please select the type of QRs"),
    batchType: Yup.string().required("This Field is required"),
    skuId: Yup.string(),
    
    totalQRGen: Yup.number().typeError("Please enter the number of QRs").integer().test(
        'Is positive?', 
        'This field can not be negative', 
        value => value >= 0
    ),
    trackingQr_tert: Yup.number().typeError("Please enter the number of QRs").integer().test(
        'Is positive?', 
        'This field can not be negative', 
        value => value >= 0
    ),
    trackingQr_quat: Yup.number().typeError("Please enter the number of QRs").integer().test(
        'Is positive?', 
        'This field can not be negative', 
        value => value >= 0
    ),
})

export default validationSchema;
