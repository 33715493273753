import React, { useEffect, useRef, useState } from 'react'
import { TableHeadColSpan } from '../../../components/table/tablehead';
import TableBody from '../../../components/table/tableBody';
import { useNavigate } from 'react-router-dom'

import PopUpManager from '../../../components/popups2/popupManager';
import Loader from '../../../components/ui/loader/Loader';

import deleteIcon from '../../../assets/images/batch_actions_images/batch_actions_delete_icon.svg'
import editIcon from '../../../assets/images/batch_actions_images/batch_actions_edit_icon.svg'
import blockIcon from '../../../assets/images/batch_actions_images/batch_actions_block_icon.svg'
import addIcon from '../../../assets/images/batch_actions_images/batch_actions_add_icon.svg'

import { USER_TYPES, SORT_ORDER } from '../../../config/constants';
import {deleteBatch,statusChange} from "../services/api";
import { requestOtp, resendOtp } from '../../../services/otpService';
import useUserData from '../../../hooks/useUserData';

//importing necessary hooks
import { URL_TYPES } from '../../../config/constants';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'

const tableHeaderOptions = [{label: 'Image'},{label: 'Client ID'}, {label: 'Product Name / Sold by'}, {label: 'Brand '}, {label: 'Batch type'}, {label: 'MRP'},{label: 'Batch No'},{label: 'Overall QR Code Generated'},{label: 'QR Code Generated date'},{label: 'QR Code Generated by'}, {label: 'Overall L1 QR Code Generated'}, {label: 'L1 Tracking Generated Dates'}, {label: 'L1 Tracking Generated by'}, {label: 'Overall L2 QR Code Generated'}, {label: 'L2 Tracking Generated Dates'}, {label: 'L2 Tracking Generated by'}, {label: 'Status'},{label: 'Perform actions'}]

const widthsHeader = [
    '17rem', '20rem', '30rem', '30rem', '20rem', '17rem', '30rem', '20rem', '40rem', '35rem', '20rem', '40rem', '35rem', '20rem', '40rem', '35rem', '20rem', '35rem', 
];

const BATCHSTATUS_TYPES = {
    DELETED: "Deleted",
    BLOCKED: "Blocked",
    UNBLOCKED: "Unblocked"
}

function BatchTable(props) {

    const navigate = useNavigate();
    const { user } = useUserData();
    const axiosPrivate = useAxiosPrivate(URL_TYPES.QR);
    const { data } = props

    const [bodyData, setBodyData] = useState([]);
    const [otpPopupDisabled, setOtpPopupDisabled] = useState(true)
    const [twoButtonPopupHeading, setTwoButtonPopupHeading] = useState('')
    const otpPopupFun = useRef(() => {});
    const [okButtonText, setOkButtonText] = useState('Proceed');
    const [reason, setReason] = useState('');
    const [otp, setOtp] = useState('');
    const [otpErr, setOtpErr] = useState('');

    const [loading, setLoading] = useState(false);
    const [textPopupDiabled, setTextPopupDisabled] = useState(true);
    const [textPopupText, setTextPopupText] = useState('');

    const [otpSentTo, setOtpSentTo] = useState('');

    useEffect(() => {
        setOtpErr('');
    }, [otpPopupDisabled])

    useEffect(() => {
        (async () => {
            let tempBodyData = [];
            data.forEach((rawRowData) => {
                const prodQRDates = rawRowData.prodQRDates && Array.isArray(rawRowData.prodQRDates) && rawRowData.prodQRDates.map(date => {
                    return date.slice(0, 10);
                })
                const prodQRNames = rawRowData.prodQRGenBy && Array.isArray(rawRowData.prodQRGenBy) && rawRowData.prodQRGenBy.map(name => {
                    return name;
                })
                const l1QRDates = rawRowData.quatTrackQRDates && Array.isArray(rawRowData.quatTrackQRDates) && rawRowData.quatTrackQRDates.map(date => {
                    return date.slice(0, 10);
                })
                const l1QRNames = rawRowData.quatTrackQRGenBy && Array.isArray(rawRowData.quatTrackQRGenBy) && rawRowData.quatTrackQRGenBy.map(name => {
                    return name;
                })
                const l2QRDates = rawRowData.tertTrackQRDates && Array.isArray(rawRowData.tertTrackQRDates) && rawRowData.tertTrackQRDates.map(date => {
                    return date.slice(0, 10);
                })
                const l2QRNames = rawRowData.tertTrackQRGenBy && Array.isArray(rawRowData.tertTrackQRGenBy) && rawRowData.tertTrackQRGenBy.map(name => {
                    return name;
                })
    
                tempBodyData = [
                    ...tempBodyData,
                    {
                        image: rawRowData.image || 'N/A',
                        clientId: rawRowData.clientId || 'N/A',
                        productName: rawRowData.productName || 'N/A',
                        brand: rawRowData.brand || 'N/A',
                        batchType: rawRowData.batchType || 'N/A',
                        mrp: rawRowData.mrp || 'N/A',
                        batchNo: rawRowData.batchNo || 'N/A',
                        qrGenerated: rawRowData.totalProductQrs || 'N/A',
                        qrGeneratedDate: rawRowData.prodQRDates && Array.isArray(rawRowData.prodQRDates) && prodQRDates.length ? prodQRDates : 'N/A',
                        qrGeneratedBy: rawRowData.prodQRGenBy && Array.isArray(rawRowData.prodQRGenBy) && prodQRNames.length? prodQRNames : 'N/A',
                        l1Generated: rawRowData.totalQuatTrackingQrs || '0',
                        l1QRGeneratedDates: rawRowData.quatTrackQRDates && Array.isArray(rawRowData.quatTrackQRDates) && l1QRDates.length ? l1QRDates : 'N/A',
                        l1QRGeneratedBy: rawRowData.quatTrackQRGenBy && Array.isArray(rawRowData.quatTrackQRGenBy) && l1QRNames.length? l1QRNames : 'N/A',
                        l2Generated: rawRowData.totalTertTrackingQrs || '0',
                        l2QRGeneratedDates: rawRowData.tertTrackQRDates && Array.isArray(rawRowData.tertTrackQRDates) && l2QRDates.length ? l2QRDates : 'N/A',
                        l2QRGeneratedBy: rawRowData.tertTrackQRGenBy && Array.isArray(rawRowData.tertTrackQRGenBy) && l2QRNames.length? l2QRNames : 'N/A',
                        status: rawRowData.status || 'N/A',
                    }
                ]
            })
    
            setBodyData(tempBodyData);
            console.log(tempBodyData)
        })();
    }, [data]);

    const updateBatchStatus = (batchNo, status) => {
        const tempBodyData = bodyData;

        tempBodyData.forEach((rawRowData) => {
            if(rawRowData.batchNo === batchNo) 
                rawRowData.status = status;
        });

        setBodyData(tempBodyData);
    }

    const handleReqOtp = async () => {
        let success = false;
        try {
            setLoading(true);
            const data = await requestOtp(axiosPrivate);
            setOtpSentTo(data.emailId);

            success = true;
        } catch (err) {
            setTextPopupText(err.message || 'Error sending OTP');
            setTextPopupDisabled(false);

            success = false;
        } finally {
            setLoading(false)
        }
        return success;
    }

    const handleResendOtp = async () => {
        let success = false;
        try {
            setLoading(true)
            await resendOtp(user?.emailId);

            success = true;
        } catch (err) {
            setTextPopupText(err.message || 'Error sending OTP');
            setTextPopupDisabled(false);

            success = false;
        } finally {
            setLoading(false)
        }
        return success;
    }

    const handleEdit = (clientId, batchNo) => {
        navigate(`/batch-actions/edit/${clientId}/${batchNo}`)
    }

    const handleAppend = (clientId, batchNo) => {
        navigate(`/batch-actions/append/${clientId}/${batchNo}`)
    }

    const handleBlock = async(action, clientId, batchNo, reason, otp) => {
        let success = false;
        if(otp.length !== 4) {
            setOtpErr('Provide a valid otp');
            return (success = false);
        }

        try {
            setLoading(true);
            await statusChange({
                otp,
                reason,
                isMobile: false,
                contactType: 'email',
                emailId: user?.emailId,
                clientId: clientId,
                batchNo: batchNo,
            },axiosPrivate);

            updateBatchStatus(batchNo, action === 'BLOCKED'? BATCHSTATUS_TYPES.BLOCKED : BATCHSTATUS_TYPES.UNBLOCKED);
            setTextPopupText(`${batchNo} has been ${action} successfully!`);
            setTextPopupDisabled(false);

            success = true;
        } catch (error) {
            if(error.message === 'Invalid OTP' && error.headers[`ratelimit-remaining`] > 0) {
                setOtpErr(`Invalid OTP, You still have ${error.headers[`ratelimit-remaining`]} attempts left`);
                setLoading(false);
                return (success = false);
            }

            setTextPopupText(error.message || 'Error sending OTP');
            setTextPopupDisabled(false);
            setOtpPopupDisabled(true);
            success = false;
        } finally {
            setLoading(false);
        }

        return success;
    }

    const handleDelete = async (clientId, batchNo, reason, otp) => {
        let success = false;
        if(otp.length !== 4) {
            setOtpErr('Provide a valid otp');
            return (success = false);
        }

        try {
            setLoading(true);
            await deleteBatch({
                otp,
                reason,
                isMobile: false,
                contactType: 'email',
                emailId: user?.emailId,
                clientId: clientId,
                batchNo: batchNo,
            },axiosPrivate);

            updateBatchStatus(batchNo, BATCHSTATUS_TYPES.DELETED)
            setTextPopupText(`${batchNo} has been DELETED successfully!`);
            setTextPopupDisabled(false);

            success = true;
        } catch (error) {
            if(error.message === 'Invalid OTP' && error.headers[`ratelimit-remaining`] > 0) {
                setOtpErr(`Invalid OTP, You still have ${error.headers[`ratelimit-remaining`]} attempts left`);
                setLoading(false);
                return (success = false);
            }

            setTextPopupText(error.message || 'Error sending OTP');
            setTextPopupDisabled(false);
            setOtpPopupDisabled(true);
            success = false;
        } finally {
            setLoading(false);
        }

        return success;
    }

    return (
        <>
        {loading && <Loader />}

        <PopUpManager 
            control={'otp-text'}
            disable={otpPopupDisabled || !textPopupDiabled}
            heading={'Alert'}
            title={twoButtonPopupHeading}
            description={`Otp will be sent to ${otpSentTo.slice(0,2)}*******${otpSentTo.slice(otpSentTo.length-10, otpSentTo.length)}`}
            okButtonText={okButtonText}
            errorMessage={otpErr}

            onClose={() => {
                setOtpPopupDisabled(true)
            }}
            clickedOk={async () => {
                const success = await otpPopupFun.current(reason, otp)
                if(success) setOtpPopupDisabled(true);
            }}
            otpSetter={setOtp}
            reasonSetter={setReason}
            resendOtp={handleResendOtp}
        />

        <PopUpManager 
                control={'text'}
                disable={textPopupDiabled}
                heading={'Alert'}
                description={textPopupText}
                onClose={() => {
                    setTextPopupDisabled(true);
                }}
            />

        <div className='rounded-2xl overflow-x-auto'>
            <table className='batch-table w-[100%]' >
                <TableHeadColSpan options={tableHeaderOptions} height='full' widths={widthsHeader} fontSize={'text-font16'} radius={'rounded-t-[10px]'} />
                <TableBody 
                    details={bodyData}
                    widths={widthsHeader}
                    actionWidth={'35rem'}
                    actions={(rowData) => { 
                        const {clientId, batchNo} = rowData; 
                        return <div className='flex justify-center items-center w=[100%] h-[100%]'>
                                    {
                                       rowData.status === BATCHSTATUS_TYPES.UNBLOCKED && <button className='mx-4'
                                            onClick={() => handleEdit(clientId, batchNo)}
                                        >
                                            <img src={editIcon} alt="edit" />
                                        </button>
                                    }
                                    {
                                        rowData.status === BATCHSTATUS_TYPES.UNBLOCKED && <button className='mx-4'
                                            onClick={() => handleAppend(clientId, batchNo)}
                                        >
                                            <img src={addIcon} alt="add" />
                                        </button>
                                    }
                                    {
                                        rowData.status !== BATCHSTATUS_TYPES.DELETED && <button className='mx-4'
                                            onClick={async () => {
                                                const status = rowData.status;
                                                const toDo = status === 'Unblocked' ? 'BLOCK' : 'UNBLOCK';

                                                const success = await handleReqOtp();
                                                if(!success) return;

                                                setOtpPopupDisabled(false);
                                                setTwoButtonPopupHeading(`Do you want to ${toDo} this Batch?`);
                                                setOkButtonText(`${toDo}`);
                                                otpPopupFun.current = async (reason, otp) => {
                                                    const success = await handleBlock(`${toDo}ED`, clientId, batchNo, reason, otp);
                                                    return success;
                                                }
                                            }}
                                        >
                                            <img src={blockIcon} alt="block" />
                                        </button>
                                    }
                                    {
                                        rowData.status !== BATCHSTATUS_TYPES.DELETED && <button className='mx-4'
                                            onClick={async () => {
                                                const success = await handleReqOtp();
                                                if(!success) return;
                                                
                                                setOtpPopupDisabled(false);
                                                setTwoButtonPopupHeading('Do you want to DELETE this Batch?')
                                                setOkButtonText('Delete')
                                                otpPopupFun.current = async (reason, otp) => {
                                                    const success = await handleDelete(clientId, batchNo, reason, otp);
                                                    return success;
                                                }
                                            }}
                                        >
                                            <img src={deleteIcon} alt="delete" />
                                        </button>
                                    }
                                    {rowData.status === BATCHSTATUS_TYPES.DELETED && <p className='font-semibold text-3xl'>DELETED</p>}
                                </div>
                        }
                    }
                />
            </table>
        </div>
        </>
    )
}

export default BatchTable;