import { handleError } from "../../../../utils/handles/errorHandle";

async function getDataSummary(type, period, axiosPrivate) {
    try {
        const response = await axiosPrivate.get('/api/report/raw-data-summary', {
            params: {   
                type,
                period
            }
        })

        return response.data;
    } catch (error) {
        handleError(error);
    }
}

export {
    getDataSummary
}