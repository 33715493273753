import {handleError} from "../../../../../utils/handles/errorHandle";

async function getSRRequests(pageNo,pageSize,axiosPrivate) {
    try{
        const response = await axiosPrivate.get('/api/client/sr-form/pending-sr-requests',{
            params:{
                pageNo,
                pageSize
            }
        });
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

export{
    getSRRequests
}