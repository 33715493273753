import React, { useEffect, useState, useRef } from "react";
import { motion, AnimatePresence, useInView } from 'framer-motion';

import countriesData from 'i18n-iso-countries/langs/en.json';
import Input, { getCountryCallingCode } from 'react-phone-number-input/input';
import { formatNumber  } from 'libphonenumber-js';

import Info from "./components/Info";

import downArrow from '../../assets/svg/downArrow.svg';

const MobileNumber = ({
        name,
        label,
        message='',
        formik,
        
        //styleProps
        bgColor = '#7F7F7F',

        //tailwind Classes
        marginClass = 'mx-[auto]',
    }) => {
    
    const contriesList = useRef(countriesData.countries);   //unFormatted countries object
    const [allCountries, setAllCountries] = useState([]);   //Formatted countries Array
    const [searchedCountries, setSearchedCountries] = useState([]);    //countries matching the search Param entered by the user
    const [countryCode, setCountryCode] = useState('IN');
    const [countryCallingCode, setCountryCallingCode] = useState(getCountryCallingCode(countryCode));
    const [value, setValue] = useState('');
    const [isSuggestionsOn, setIsSuggestionsOn] = useState(false);
    const [searchparam, setSearchparam] = useState('');

    //DefaultValue change
    useEffect(() => {
        setCountryCode(formik?.values[`${name}`]?.countryCode || 'IN');
        setValue(formik?.values[`${name}`]?.intelMobileNo || '');
    }, [formik?.values[`${name}`]?.countryCode, formik?.values[`${name}`]?.intelMobileNo]);

    //Logic for suggestions to close suggestions when clicked outside
    const suggestionsDivRef = useRef(null);
    const countrySelectionRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if(countrySelectionRef.current && countrySelectionRef.current.contains(event.target)) return;
            if (suggestionsDivRef.current && !suggestionsDivRef.current.contains(event.target)) {
                setIsSuggestionsOn(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    //logic to create list of all countries
    useEffect(() => {
        if(!contriesList.current) return;
        const countryList = contriesList.current && Object.keys(contriesList.current).map((key) => {
            const code = key;
            const name = Array.isArray(contriesList.current[key])? contriesList.current[key][0] : contriesList.current[key]; //Array or a string
            let callingCode = undefined;
            try {
                //@ts-ignore
                callingCode = getCountryCallingCode(code);
            } catch {} //No Country found - No error will be thrown

            if(callingCode)
                return { code, name, callingCode };
            else
                return null
        }).filter(element => element !== null);

        //Sort them according to theier names
        countryList.sort((a, b) => {
            const nameA = a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase(); // ignore accents and case
            const nameB = b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase(); // ignore accents and case

            return nameA.localeCompare(nameB);
        });
        
        setAllCountries(countryList);
    }, [contriesList]);
    
    //Handle change in input
    useEffect(() => {
        (async () => {
            const nationalNumber = value?.replace(`+${countryCallingCode}`, '');
            const formattedIntelMobileNumber = formatNumber({
                country: countryCode,
                phone: nationalNumber
            }, 'INTERNATIONAL');

            formik?.setFieldValue && formik?.setFieldValue(name, nationalNumber? {
                countryCode: countryCode,
                countryCallingCode: `+${countryCallingCode}`,

                intelMobileNo: value,
                mobileNo: nationalNumber,
                formattedMobileNo: formattedIntelMobileNumber
            }: '');
        })();

        //eslint-disable-next-line
    }, [countryCode, countryCallingCode, value]);

    //handle search
    useEffect(() => {
        if(!searchparam) setSearchedCountries(allCountries);
        const matchingCountries = allCountries.map((country) => {
            if((country && country.name && country.code && country.callingCode) && (country.name.toLowerCase().includes(searchparam.toLowerCase()) || ('+' + country.callingCode).includes(searchparam) || country.code.toLowerCase().includes(searchparam.toLowerCase())))
                return country;
            return null;
        }).filter(country => country !== null);
        setSearchedCountries(matchingCountries);
    }, [searchparam, allCountries]);

    return (
        <div className={`w-full ${marginClass}`}>  
            {/* Mobile number */}
            {
                label && <div className='flex items-center'>
                    <label htmlFor={name} className='text-3xl mr-2'>{label}</label>
                    {message && <Info message={message} />}
                </div>
            }
            <div 
                style={{
                    borderColor: bgColor
                }}
                className={`flex w-full mt-2`}
            >
                <div 
                    ref={countrySelectionRef}
                    className={`bg-[#182AB408] mr-[5%] flex items-center justify-center w-[30%] p-[.65rem] rounded-md text-base cursor-pointer border`}
                    onClick={() => {
                        if(isSuggestionsOn)
                            setIsSuggestionsOn(false);
                        else
                            setIsSuggestionsOn(true);
                    }}
                >
                    <p className="mx-2 text-3xl">{countryCode}</p>
                    <p className="mx-1 text-3xl">+{countryCallingCode}</p>
                    <motion.img 
                        src={downArrow} 
                        alt={'Arrow'} 
                        style={{
                            height: '1rem',
                            width: '1rem'
                        }}

                        // Animation
                        variants={{
                            up: {
                                rotate: '180deg'
                            },
                            down: {
                                rotate: '0deg'
                            }
                        }}
                        initial="up"
                        animate={isSuggestionsOn? "down" : "up"}
                    />
                </div>
                <Input
                    name={name}
                    placeholder={`Phone Number`}
                    className={`w-[70%] h-[46px] pl-5 text-3xl bg-[#182AB408] flex items-center justify-center p-[.65rem] rounded-md cursor-pointer border`}
                    country={countryCode}
                    onBlur={formik?.handleBlur}
                    value={value}
                    onChange={setValue}
                />
            </div>
            <p className='text-red-500 text-lg'>{formik?.touched[`${name}`] && formik?.errors[`${name}`]}&nbsp;</p>

            {/* Suggestions */}
            <AnimatePresence>
            {
                isSuggestionsOn && <div 
                        ref={suggestionsDivRef} 
                        className={`w-full ${marginClass} relative`}
                    >
                    <motion.div 
                        style={{
                            borderColor: bgColor
                        }}
                        className={`absolute z-[50] max-h-[200px] flex flex-col bg-white w-[100%] rounded-md border-solid border`}
                        initial={{ height: 0 }}
                        animate={{ height: 'auto' }}
                        exit={{ height: 0 }}
                    >
                        <motion.div 
                            style={{
                                borderBottomWidth: '1px',
                                borderColor: bgColor
                            }}
                            className='border-solid'
                            initial={{ height: 0 }}
                            animate={{ height: 'auto' }}
                            exit={{ height: 0 }}
                        >
                            <motion.input 
                                type="text" 
                                placeholder="Search"
                                onChange={(e) => setSearchparam(e.target.value)}
                                style={{
                                    borderColor: bgColor
                                }}
                                className={`w-[calc(100%-14px)] m-[7px] px-[5px] py-[3px] border-solid border`} 
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            />
                        </motion.div>
                        <div 
                            style={{
                                overflowY: 'auto'
                            }}
                            className="flex-1"
                        >
                            {
                                searchedCountries && searchedCountries.length &&  <Suggestions 
                                    isSuggestionsOn={isSuggestionsOn}
                                    searchedCountries={searchedCountries}

                                    setValue={setValue}
                                    setSearchparam={setSearchparam}
                                    setCountryCode={setCountryCode}
                                    setCountryCallingCode={setCountryCallingCode}
                                    setIsSuggestionsOn={setIsSuggestionsOn}
                                />
                            }
                            {
                                searchedCountries && !searchedCountries.length &&
                                <div className="">
                                    <p className="p-[7px] text-3xl">No Countries found</p>
                                </div>
                            }
                        </div>
                    </motion.div>
                </div>
            }
            </AnimatePresence>
        </div>
    );
}

const Suggestions = ({
    searchedCountries = [],
    isSuggestionsOn = false,

    //setters
    setValue,
    setSearchparam,
    setCountryCode,
    setCountryCallingCode,
    setIsSuggestionsOn,

    }) => {

    /**
     * nunmber countries in view: To decresse the sudden pressure when suggestions turned on
     * @type {[number, (val: number | Function) => void]}
     */
    const [ countryListSize, setCountryListSize  ] = useState(10);

    /**
     * When this is in view we change the number of countries
     */
    const countriesScroller = useRef(null);
    
    //reset to 10 when suggestions revoke
    useEffect(() => {
        setCountryListSize(10);
    }, [isSuggestionsOn])

    //Logic to add countries to list on scroll
    const isCountriesScrollerInView = useInView(countriesScroller);
    useEffect(() => {
        if(!isCountriesScrollerInView) return;
        
        setCountryListSize(currSize => {
            let newSize = currSize + 10;
            if(newSize > searchedCountries.length) newSize = searchedCountries.length;
            return newSize;
        });
    }, [isCountriesScrollerInView, searchedCountries, searchedCountries.length]);

    return (
        <>
            {
                searchedCountries.slice(0, countryListSize).map((country, ind) => {
                    if(!country || !country.callingCode || !country.code || !country.name) return (<div key={ind}></div>);
                    return <div 
                        key={ind}
                        className={`p-[10px] flex hover:bg-slate-100 cursor-pointer rounded-md`}
                        onClick={() => {
                            setValue('');
                            setSearchparam('');
                            //@ts-ignore
                            setCountryCode(country.code);
                            setCountryCallingCode(parseInt(country.callingCode));
                            setIsSuggestionsOn(false);
                        }}
                    >
                        <div className="mx-[2px] aspect-square w-[1.3rem]">
                            
                        </div>
                        <p className="w-[6rem] ml-[5px] text-3xl">+{country.callingCode}</p>
                        <p className="text-3xl">{country.name?.length > 15? country.name?.slice(0, 12) + '...' : country.name}</p>
                    </div>
                })
            }
            <div ref={countriesScroller} className="h-2" />
        </>
    )
}

export default MobileNumber;