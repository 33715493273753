import React from 'react'

function SubmitButton(props) {
    return (
        <div className={`w-[100%] `}>
            {/* <button className={`text-white text-2xl p-5 flex flex-row justify-around items-center w-${props.btnWidth} h-${props.height} rounded-lg bg-gradient-to-r from-gradientPurple  to-gradientBlue to-90% `}> */}
            <button type={props.type} disabled={props.disabled} onClick={props.onClick} className={`h-[50px] text-white text-font16 font-medium p-4 flex flex-row justify-around items-center w-${props.btnWidth} h-${props.height} rounded-lg ${props.disabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-gradient-to-r from-gradientPurple to-gradientBlue to-90%'
                } `}>
                {/* <img className={`w-${props.imgWidth} ${props.imgWidth ? 'pr-10 pl-10' : 'pr-0 pl-0'}`} src={props.imageUrl}/>{props.label} */}
                {props.imageUrl && <img className='w-6' alt={props.label} src={props.imageUrl} />}
                {props.label}
            </button>
        </div>
    )
}



function SubmitButtonWide(props) {
    const { onClick, ...rest } = props;
    return (
        <div className='' >
            <button {...rest} onClick={onClick} type={props.type} disabled={props.disabled} className={`font-['Montserrat'] font-medium text-white text-font16 p-4 flex flex-row justify-center items-center w-[100%] h-[50px] rounded-lg ${props.disabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-gradient-to-r from-gradientPurple to-gradientBlue to-90%'
                }`} >
                {props.value}
            </button>
        </div>
    )
}
export { SubmitButton, SubmitButtonWide }