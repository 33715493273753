import { Formik,Form } from 'formik'
import React, {useState} from 'react'
import * as Yup from "yup";

import FormikControl from '../../../components/form/formikControl';
import DeleteButtonRed from '../../../components/ui/buttons/deleteButtonRed';

import Loader from "../../../components/ui/loader/Loader";
import PopUpManager from '../../../components/popups2/popupManager';

import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import {createSubClient} from "../services/api"

function ClientAddUserForm({ onCreateSuccess, masterClientId}) {

    const axiosPrivate = useAxiosPrivate()
    const [loading, setLoading] = useState(false);
    const [textPopupDisabled, setTextPopupDisabled] = useState(true);
    const [textPopupDescription, setTextPopupDescription] = useState('heading');

    const handleSubmit = async(values) => {
        try{
            setLoading(true)
            const response = await createSubClient(masterClientId, values.emailId, values.userName, axiosPrivate)
            setLoading(false)

            onCreateSuccess({
                userId: response.userId,
                emailId: values.emailId
            })
        } catch(error){
            setTextPopupDescription(error.message);
            setTextPopupDisabled(false);
        }
        finally{
            setLoading(false)
        }
    }

    const initialValues={
        'userName': '',
        'emailId':'',
    }
    const addClinetUserValidations = Yup.object({
        userName: Yup.string().required('Please provide a name'),
        emailId: Yup.string().email("Invalid email address").required("Email is required"),
    })
    
    return (
    <div>
        {loading && <Loader />}
        <PopUpManager 
            control={'text'}
            disable={textPopupDisabled}
            heading={'Alert'}
            description={textPopupDescription}
            onClose={() =>{
                setTextPopupDisabled(true);
            }}
        />

        <div className='w-[100%]'>
            <Formik
                initialValues={initialValues}
                validationSchema={addClinetUserValidations}
                onSubmit={handleSubmit}

                validateOnChange={true}
                validateOnBlur={false}
            >
                {
                    formik => {
                        return(
                            <Form>
                                <div className='flex w-[100%] justify-between items-center bg-popupScreen pl-10 pr-10'>
                                    <div className='w-[40%] mr-5'>
                                        <FormikControl control='input' type='text' label={""} name={'userName'} placeholder={"Name"} bgcolor='popupScreen' width={'full'} border='true' />
                                    </div>

                                    <div className='w-[40%] ml-5'>
                                        <FormikControl control='input' type='text' label={""} name={'emailId'} placeholder={"Email ID"} bgcolor='popupScreen' width={'full'} border='true' />
                                    </div>
                                    
                                    <div className='mx-1 w-[20%] pl-10 -mt-6' >
                                        <DeleteButtonRed value='Create' type='submit' disabled={!(formik.isValid&&formik.dirty)} />
                                    </div>
                                </div>
                                
                            </Form>
                        )
                    }
                }
            </Formik>
        </div>
    </div>
    )
}

export  default ClientAddUserForm