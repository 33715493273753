import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import FormikControl from '../../../components/form/formikControl';

import { SubmitButton } from '../../../components/ui/buttons/submitButton';
import SortingTable from '../../../components/table/sortingTable';
import PopUpManager from '../../../components/popups2/popupManager';

import { SORT_ORDER, USER_TYPES } from '../../../config/constants';
import { getAllAdminsPaginated, getAllMasterClientsPaginated, getAllSubClientsPaginated, getAdminSuggestions, getMasterClientSuggestions, getSubClientSuggestions, getAdminsByQueryString, getMasterClientsByQueryString, getSubClientsByQueryString } from '../services/api';
import { axiosPrivate } from '../../../axios/axios';

const AdminsHeaderData = [{value: 'Admin User ID', space: '20%', key: 'userId', sorting: 'true'}, {value: 'Admin Name', space: '20%', key: 'userName', sorting: 'true'}, {value: 'Created On', space: '20%', key: 'createdAt', sorting: 'true'}, {value: 'Email', space: '30%', key: 'emailId', sorting: 'true'}, {value: 'Created by', space: '20%', key: 'createdBy'}, {value: 'Status', space: '20%', key: 'status'}]
const MasterclientsHeaderData = [{value: 'Client ID', space: '20%', key: 'masterClientId', sorting: 'true'}, {value: 'Organization Name', space: '35%', key: 'organizationName', sorting: 'true'}, {value: 'Created On', space: '20%', key: 'createdAt', sorting: 'true'}, {value: 'Sub Clients', space: '18%', key: 'subClientsCount',sorting: 'true'}, {value: 'Created by', space: '20%', key: 'createdBy'},  {value: 'Service Status', space: '20%', key: 'ServiceStatus'}, {value: 'Status', space: '20%', key: 'status'}];
const SubclientsHeaderData = [{value: 'Client ID', space: '25%', key: 'userId', sorting: 'true'}, {value: 'Master Client ID', space: '20%', key: 'masterClientId', sorting: 'true'}, {value: 'Created On', space: '20%', key: 'createdAt', sorting: 'true'}, {value: 'Email ID', space: '35%', key: 'emailId', sorting: 'true'}, {value: 'Created by', space: '20%', key: 'createdBy'}]

function AdminProfile() {

    const pageCount = 10;

    const [userType, setUserType] = useState();
    const [placeholder, setPlaceholder] = useState('Search');
    const [suggestionArray, setSuggetionArray] = useState([]);
    const [searchParam, setSearchParam] = useState('');

    const [tableLoading, setTableLoading] = useState(false);

    const [textPopupDiabled, setTextPopupDisabled] = useState(true);
    const [textPopupText, setTextPopupText] = useState('');

    //Admin data
    const [admins, setAdmins] = useState([]);
    const [adminsSortedKey, setAdminsSortedKey] = useState(AdminsHeaderData[0].key);
    const [adminsSortedWay, setAdminsSortedWay] = useState(SORT_ORDER.ASC);
    const [adminsCurrentPage, setAdminsCurrentPage] = useState(1);
    const [adminsMaxPage, setAdminsMaxPage] = useState(0);
    const [adminsPrevDisabled, setAdminsPrevDisabled] = useState(true);
    const [adminsNextDisabled, setADminsNextDisabled] = useState(true);

    //master-clients data
    const [masterClients, setMasterClients] = useState([]);
    const [masterSortedKey, setMasterSortedKey] = useState(MasterclientsHeaderData[0].key);
    const [masterSortedWay, setMasterSortedWay] = useState(SORT_ORDER.ASC);
    const [masterCurrentPage, setMasterCurrentPage] = useState(1);
    const [masterMaxPage, setMasterMaxPage] = useState()
    const [masterPrevDisabled, setMasterPrevDisabled] = useState(true);
    const [masterNextDisabled, setMasterNextDisabled] = useState(true);

    //sub-clients data
    const [subClients, setSubClients] = useState([]);
    const [subSortedKey, setSubSortedKey] = useState(SubclientsHeaderData[0].key);
    const [subSortedWay, setSubSortedWay] = useState(SORT_ORDER.ASC);
    const [subCurrentPage, setSubCurrentPage] = useState(1);
    const [subMaxPage, setSubMaxPage] = useState(0)
    const [subPrevDisabled, setSubPrevDisabled] = useState(true);
    const [subNextDisabled, setSubNextDisabled] = useState(true);
    
    //Admin logic
    const loadAdmins = useCallback(async (adminsCurrentPage, adminsSortedKey, adminsSortedWay, searchParam) => {
        try {
            let res;
            setTableLoading(true);
            if(!searchParam)
                res = await getAllAdminsPaginated(adminsCurrentPage, pageCount, adminsSortedKey, adminsSortedWay, axiosPrivate);
            else 
                res = await getAdminsByQueryString(searchParam, adminsSortedKey, adminsSortedWay, adminsCurrentPage, pageCount, axiosPrivate);

            
            const pageData = res?.pageData;
            if(!pageData) throw new Error("No data found");
            pageData?.forEach(adminData => adminData.status = adminData.isBlocked? 'Blocked' : 'UnBlocked')

            pageData.forEach(ele => {
                const date = new Date(ele.createdAt);
                ele.createdAt = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
            });
            setAdmins(res?.pageData);
            setAdminsMaxPage(Math.floor(res?.totalUsers/pageCount) + (res?.totalUsers%pageCount? 1 : 0));
        } catch (error) {
            //Popup
            setTextPopupText(error.message || `Unable to load Admins`);
            setTextPopupDisabled(false);
        } finally {
            setTableLoading(false);
        }
    }, []);

    const setAdminsSorting = (key, way) => {
        setAdminsSortedKey(key);
        setAdminsSortedWay(way);

        loadAdmins(adminsCurrentPage, key, way, searchParam);
    }

    const adminsNext = () => {
        const newPageNumber = adminsCurrentPage + 1;
        setAdminsCurrentPage(newPageNumber);
        loadAdmins(newPageNumber, adminsSortedKey, adminsSortedWay, searchParam);
    }

    const adminsPrevious = () => {
        const newPageNumber = adminsCurrentPage - 1;
        setAdminsCurrentPage(newPageNumber);
        loadAdmins(newPageNumber, adminsSortedKey, adminsSortedWay, searchParam);
    }

    useEffect(() => {
        if(adminsCurrentPage === 1) 
            setAdminsPrevDisabled(true);
        else
            setAdminsPrevDisabled(false);

        if(adminsCurrentPage === adminsMaxPage)
            setADminsNextDisabled(true);
        else
            setADminsNextDisabled(false); 
    }, [adminsCurrentPage, adminsMaxPage]);
    //

    //master logic
    const loadMasterClients = useCallback(async (masterCurrentPage, masterSortedKey, masterSortedWay, searchParam) => {
        try {
            let res;
            setTableLoading(true);
            if(!searchParam)
                res = await getAllMasterClientsPaginated(masterCurrentPage, pageCount, masterSortedKey, masterSortedWay, axiosPrivate);
            else
                res = await getMasterClientsByQueryString(searchParam, masterSortedKey, masterSortedWay, masterCurrentPage, pageCount, axiosPrivate);

            const pageData = res?.pageData;
            if(!pageData) throw new Error("No data found");
            pageData?.forEach(masterData => masterData.status = masterData.isBlocked? 'Blocked' : 'UnBlocked')

            pageData.forEach(ele => {
                const date = new Date(ele.createdAt);
                ele.createdAt = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
            });
            setMasterClients(res?.pageData);
            setMasterMaxPage(Math.floor(res?.totalUsers/pageCount) + (res?.totalUsers%pageCount? 1 : 0));
        } catch (error) {
            //Popup
            setTextPopupText(error.message || `Unable to load Master clients`);
            setTextPopupDisabled(false);
        } finally {
            setTableLoading(false);
        }
    }, []);

    const setMasterSorting = (key, way) => {
        setMasterSortedKey(key);
        setMasterSortedWay(way);

        loadMasterClients(masterCurrentPage, key, way, searchParam);
    }

    const masterNext = () => {
        const newPageNum = masterCurrentPage + 1;
        setMasterCurrentPage(newPageNum);

        loadMasterClients(newPageNum, masterSortedKey, masterSortedWay, searchParam);
    }

    const masterPrevious = () => {
        const newPageNum =  masterCurrentPage - 1;
        setMasterCurrentPage(newPageNum);

        loadMasterClients(newPageNum, masterSortedKey, masterSortedWay, searchParam);
    }

    useEffect(() => {
        if(masterCurrentPage === 1) 
            setMasterPrevDisabled(true);
        else
            setMasterPrevDisabled(false);

        if(masterCurrentPage === masterMaxPage)
            setMasterNextDisabled(true);
        else
            setMasterNextDisabled(false); 
    }, [masterCurrentPage, masterMaxPage]);
    //

    //Sub-clients logic
    const loadSubs = useCallback(async (subCurrentPage, subSortedKey, subSortedWay, searchParam) => {
        try {
            let res;
            setTableLoading(true);
            if(!searchParam)
                res = await getAllSubClientsPaginated(subCurrentPage, pageCount, subSortedKey, subSortedWay, axiosPrivate);
            else
                res = await getSubClientsByQueryString(searchParam, subSortedKey, subSortedWay, subCurrentPage, pageCount, axiosPrivate);
            
            const pageData = res?.pageData;
            if(!pageData) throw new Error("No data found");

            pageData.forEach(ele => {
                const date = new Date(ele.createdAt);
                ele.createdAt = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
            });
            setSubClients(res?.pageData);
            setSubMaxPage(Math.floor(res?.totalUsers/pageCount) + (res?.totalUsers%pageCount? 1 : 0));
        } catch (error) {
            //Popup
            setTextPopupText(error.message || `Unable to load Sub clients`);
            setTextPopupDisabled(false);
        } finally {
            setTableLoading(false);
        }
    }, []);

    const setSubsSorting = (key, way) => {
        setSubSortedKey(key);
        setSubSortedWay(way);

        loadSubs(subCurrentPage, key, way, searchParam);
    }

    const subsNext = () => {
        const newPageNum = subCurrentPage + 1;
        setSubCurrentPage(newPageNum);

        loadSubs(newPageNum, subSortedKey, subSortedWay, searchParam);
    }

    const subsPrevious = () => {
        const newPageNum = subCurrentPage - 1;
        setSubCurrentPage(newPageNum);

        loadSubs(newPageNum, subSortedKey, subSortedWay, searchParam);
    }

    useEffect(() => {
        if(subCurrentPage === 1) 
            setSubPrevDisabled(true);
        else
            setSubPrevDisabled(false);

        if(subCurrentPage === subMaxPage)
            setSubNextDisabled(true);
        else
            setSubNextDisabled(false); 
    }, [subCurrentPage, subMaxPage]);
    //

    const refreshTables = async type => {
        setSearchParam('');
        setSuggetionArray([]);

        if(type === 'admin') {
            setPlaceholder('Admin ID')
            setAdminsCurrentPage(1);
            setAdminsSortedKey(AdminsHeaderData[0].key);
            setAdminsSortedWay(SORT_ORDER.ASC);

            await loadAdmins(1, AdminsHeaderData[0].key, SORT_ORDER.ASC, '');
        } else if(type === 'master-client') {
            setPlaceholder('Master client ID')
            setMasterCurrentPage(1);
            setMasterSortedKey(MasterclientsHeaderData[0].key);
            setMasterSortedWay(SORT_ORDER.ASC);

            await loadMasterClients(1, MasterclientsHeaderData[0].key, SORT_ORDER.ASC, '');
        } else if(type === 'sub-client') {
            setPlaceholder('Sub Client ID')
            setSubCurrentPage(1);
            setSubSortedKey(SubclientsHeaderData[0].key);
            setSubSortedWay(SORT_ORDER.ASC);

            await loadSubs(1, SubclientsHeaderData[0].key, SORT_ORDER.ASC, '');
        }
    }

    const handleUserType = async (e) => {
        const type = e.target.value;
        setUserType(type);

        //reset search
        await refreshTables(type);
    }

    //Manage suggestion Array
    const searchParamChange = async (param) => {
        setSearchParam(param);

        if(param) { 
            try {
                let res;
                if(userType === 'admin')
                    res =  await getAdminSuggestions(param, axiosPrivate);
                else if(userType === 'master-client')
                    res =  await getMasterClientSuggestions(param, axiosPrivate);
                else if(userType === 'sub-client')
                    res =  await getSubClientSuggestions(param, axiosPrivate);
                    
                setSuggetionArray(res);
            } catch (error) {
                setSuggetionArray([])
            }
        } else {
            await refreshTables(userType);
        }
    }

    const initialValues = {
        'param': ''        
    }

    const handleSubmit = async ({param}) => {
        setSearchParam(param);

        if(userType === 'admin') {
            setAdminsSortedKey(AdminsHeaderData[0].key);
            setAdminsSortedWay(SORT_ORDER.ASC);
            setAdminsCurrentPage(1);

            await loadAdmins(1, AdminsHeaderData[0].key, SORT_ORDER.ASC, param);
        }
        if(userType === 'master-client') {
            setMasterSortedKey(MasterclientsHeaderData[0].key);
            setMasterSortedWay(SORT_ORDER.ASC);
            setMasterCurrentPage(1);

            await loadMasterClients(1, MasterclientsHeaderData[0].key, SORT_ORDER.ASC, param);
        }
        if(userType === 'sub-client') {
            setSubSortedKey(SubclientsHeaderData[0].key);
            setSubSortedWay(SORT_ORDER.ASC);
            setMasterCurrentPage(1);

            await loadSubs(1, SubclientsHeaderData[0].key, SORT_ORDER.ASC, param);
        }
    }

    return (
        <>
            <PopUpManager 
                control={'text'}
                disable={textPopupDiabled}
                heading={'Alert'}
                description={textPopupText}
                onClose={() => {
                    setTextPopupDisabled(true);
                }}
            />
            <div className='radio-btn-wrapper flex items-center bg-bgLightBlue py-[15px] pl-10 rounded-tl-[12px] rounded-tr-[12px] border border-b-0 border-bgLightBlue'>
                <div>
                    <h5 className='text-couponsRadioHeading font-medium w-48 text-font20'>Filters: </h5>
                </div>
                <div className='flex items-center gap-5 text-couponsRadioOPtions font-semibold text-3xl mr-[20px]'>
                    <input type='radio' name='addUser' value='admin' className='w-[20px] h-[20px] radio active' onChange={handleUserType}></input>
                    <label htmlFor='addUsers'>Admin</label>
                </div>

                <div className='flex items-center gap-5 text-couponsRadioOPtions font-semibold text-3xl mr-[20px]'>
                    <input type='radio' name='addUser' value='master-client' className='w-[20px] h-[20px] radio' onChange={handleUserType}></input>
                    <label htmlFor='addUsers'>Master Client</label>
                </div>

                <div className='flex items-center gap-5 text-couponsRadioOPtions font-semibold text-3xl mr-[20px]'>
                    <input type='radio' name='addUser' value='sub-client' className='w-[20px] h-[20px] radio' onChange={handleUserType}></input>
                    <label htmlFor='addUsers'>Sub Client</label>
                </div>
            </div>
            {
                userType && 
                <Formik
                    initialValues={initialValues}
                    validateOnChange={true}
                    validateOnBlur={false}
                    onSubmit={handleSubmit}
                >
                    {
                        formik => {
                            return (
                                <Form>
                                    <div className='bg-popupScreen py-10 flex w-[100%] items-center rounded-b-[12px]'>
                                        <div className='pl-10 flex items-center w-[100%]'>
                                            <div className='w-[50%]'>
                                                <FormikControl control='input' value={searchParam} requiredSuggestions={true} formSetter={formik.setFieldValue} formSubmitter={formik.submitForm} suggestions={suggestionArray} onInputChange={searchParamChange} type='search' label={"Profile Details :"} name={'param'} placeholder={placeholder} bgcolor='selectorBg' width={'full'} />
                                            </div>
                                            <div className='w-[20%] pl-20 -mt-6'>
                                                <SubmitButton label='Search' btnWidth='full' disabled={!userType} />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }
                    }
                </Formik>
            }
            {
                userType === 'admin' && 
                <SortingTable loading={tableLoading} headerData={AdminsHeaderData} tableData={admins} onSelction={() => {}} nextPage={adminsNext} previousPage={adminsPrevious} sortTable={setAdminsSorting} nextDisabled={adminsNextDisabled} previousDisabled={adminsPrevDisabled} selectedSortedKey={adminsSortedKey} selectedSortedOrder={adminsSortedWay} />
            }
            {
                userType === 'master-client' && 
                <SortingTable loading={tableLoading} headerData={MasterclientsHeaderData} tableData={masterClients} onSelction={() => {}} nextPage={masterNext} previousPage={masterPrevious} sortTable={setMasterSorting} nextDisabled={masterNextDisabled} previousDisabled={masterPrevDisabled} selectedSortedKey={masterSortedKey} selectedSortedOrder={masterSortedWay} />
            }
            {
                userType === 'sub-client' && 
                <SortingTable loading={tableLoading} headerData={SubclientsHeaderData} tableData={subClients} onSelction={() => {}} nextPage={subsNext} previousPage={subsPrevious} sortTable={setSubsSorting} nextDisabled={subNextDisabled} previousDisabled={subPrevDisabled} selectedSortedKey={subSortedKey} selectedSortedOrder={subSortedWay} />
            }
        </>
    )

}

export default AdminProfile