import axios from "../../../axios/axios"
import { handleError } from "../../../utils/handles/errorHandle";

const loginService = async(login, password) => {
    try{
        const response = await axios.post('/api/auth/admin/login/', {login, password}, {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        });
        return response?.data;
    }catch(error){
        handleError(error);
    }
}
export { loginService };