import React, { useEffect, useState } from 'react';

import Input from '../../../../../../components/form2/Input';
import FileUpload from '../../../../../../components/form2/FileUpload';
import Select from '../../../../../../components/form2/Select';
import MultipleSelect from '../../../../../../components/form2/MultipleSelect';

import useGetCountriesAndStates from '../../services/countriesAndStates';
import { entityOptions, enterPriseTypeOptions, serviceTypeOptions, companyDocTypeOptions, orgCategoryOptions } from './utils/options';

const CompanyDetails = ({ formik, isReview=false }) => {

    const { countries, getStates } = useGetCountriesAndStates();
    
    const [countryOptions, setCOuntryOption] = useState();
    const [stateOptions, setStateOptions] = useState();

    useEffect(() => {
        const options = countries?.map((country) => {
            return {
                key: country,
                value: country
            }
        });
        setCOuntryOption(options);
        // formik.setFieldValue('country', countries && Array.isArray(countries) && countries.length > 0 && countries[0]);  
    }, [countries]);

    useEffect(() => {
        const country = formik?.values?.country;

        if(country) {
            const states = getStates(country);
            let options = states?.map((state) => {
                return {
                    key: state,
                    value: state
                }
            });
            if(options?.length === 0) options = [{key: 'No State', value: 'No State'}];
            setStateOptions(options);
            // formik.setFieldValue('state', Array.isArray(options) && options.length > 0 && options[0].value);
        }

    }, [formik?.values?.country]);

    return (
        <div className='my-10 mx-16'>
            <p className='text-[#182AB4] text-5xl mb-10 font-semibold'>Company Details</p>
            <div className='w-full'>
                <Input 
                    label='Name of Organisation *'
                    formik={formik}
                    name={'organizationName'}
                    placeholder=''
                    marginClass='mb-5'
                    disabled={isReview}
                    />
            </div>

            <div className='flex gap-16'>
                <div className='w-1/2 flex flex-col'>
                    <Input 
                        label='Address Line 1 *'
                        formik={formik}
                        name={'addressLine1'}
                        placeholder=''
                        marginClass='mb-5'
                    />
                    <Select 
                        label='Country *'
                        formik={formik}
                        name={'country'}
                        marginClass='mb-5'
                        options={countryOptions}
                    />
                    <Input 
                        label='City *'
                        formik={formik}
                        name={'city'}
                        placeholder=''
                        marginClass='mb-5'
                    />
                    <Select 
                        label='Entity Type *'
                        formik={formik}
                        name={'entityType'}
                        marginClass='mb-5'
                        options={entityOptions}
                    />
                    <Input 
                        label='GST / VAT Number *'
                        message='GST number in case of India, VAT or any Tax related registration number for other countries.'
                        formik={formik}
                        name={'taxId'}
                        placeholder=''
                        marginClass='mb-5'
                    />
                    <Input 
                        label='Monthly Production Quantity *'
                        formik={formik}
                        name={'monthlyProductionQuantity'}
                        placeholder=''
                        marginClass='mb-5'
                        type='number'
                    />
                    <MultipleSelect 
                        label='Service Type *'
                        message='Select all services you want to opt for.'
                        formik={formik}
                        name={'serviceType'}
                        marginClass='mb-5'
                        options={serviceTypeOptions}
                        // multiple={true}
                    />
                    <Input 
                        label='Company Website *'
                        message='Format should be like: https://www.veots.com'
                        formik={formik}
                        name={'companyWebsite'}
                        placeholder=''
                        marginClass='mb-5'
                    />
                    <Input 
                        label='Billing Date *'
                        formik={formik}
                        name={'billingDate'}
                        placeholder=''
                        marginClass='mb-5'
                        type='number'
                    />
                </div>
                <div className='w-1/2 flex flex-col'>
                    <Input 
                        label='Address Line 2'
                        formik={formik}
                        name={'addressLine2'}
                        placeholder=''
                        marginClass='mb-5'
                    />
                    <Select 
                        label='State *'
                        formik={formik}
                        name={'state'}
                        marginClass='mb-5'
                        options={stateOptions}
                    />
                    <Input 
                        label='Pin / Postal Code *'
                        formik={formik}
                        name={'postalCode'}
                        placeholder=''
                        marginClass='mb-5'
                        type='number'
                    />
                    <Select 
                        label='Category of Organization *'
                        formik={formik}
                        name={'categoryOfOrganization'}
                        marginClass='mb-5'
                        options={orgCategoryOptions}
                    />
                    <Input 
                        label='Company / CIN / TIN / registration no. *'
                        message='Any company registration number to prove company’s existance.'
                        formik={formik}
                        name={'businessId'}
                        placeholder=''
                        marginClass='mb-5'
                    />
                    <Input 
                        label="Number of SKU's *"
                        message='Total SKUs for which you require this service.'
                        formik={formik}
                        name={'numberOfSKUs'}
                        placeholder=''
                        marginClass='mb-5'
                        type='number'
                    />
                    <Input 
                        label='DUNS Number'
                        message='For companies registered outside India.'
                        formik={formik}
                        name={'dunsNumber'}
                        placeholder=''
                        marginClass='mb-5'
                    />
                    <Select 
                        label='Enterprise Type *'
                        formik={formik}
                        name={'enterpriseType'}
                        marginClass='mb-5'
                        options={enterPriseTypeOptions}
                    />
                </div>
            </div>

            <label className='text-3xl mb-2'>Company Document *</label>
            <div className='flex gap-16 py-5 px-5 bg-[#F1F2FA] rounded-md'>
                <div className='w-1/2'>
                    <Select 
                        label=''
                        formik={formik}
                        name={'companyDocOptions'}
                        options={companyDocTypeOptions}
                    />
                </div>
                <div className='w-1/2'>
                    <FileUpload 
                        name={'companyDoc'}
                        formik={formik}
                        defaultFileName={formik?.values?.companyDocFileName}
                        marginClass=''
                    />
                </div>
            </div>
        </div>
    );
}

export default CompanyDetails;
