import { React, useEffect, useState } from 'react'
import { Formik, Form } from 'formik';

import { FormHeadingComponent, FormSubHeadingComponent } from './formHeadingComponent'
import FormikControl from '../../../../components/form/formikControl';
import { SubmitButtonWide } from '../../../../components/ui/buttons/submitButton'
import Loader from "../../../../components/ui/loader/Loader";
import TextPopup from '../../../../components/popups/textPopup';

import { warrantyDropDown } from "../../../../utils/QR/batchDropdowns";
import initialValues from '../utils/retaInitialValues';
import validationSchema from '../validations/retaValidations';

import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'

import {createSKU,getAllSkuIds} from "../../services/api"
import PopUpManager from '../../../../components/popups2/popupManager';

import { URL_TYPES } from '../../../../config/constants';
import removeSpecialCharacters from '../../../../utils/functionalities/removeSpecialCharacters';
import { useNavigate } from 'react-router-dom';

function CreateRetaSku(props) {

    const clientid = props.clientId;
    const batchType = props.batchType;
    const axiosPrivate = useAxiosPrivate(URL_TYPES.QR)
    const navigation = useNavigate()

    const [clickedSkuDetails, setclickedSkuDetails] = useState(true)
    const [clickedSellerDetails, setclickedSellerDetails] = useState(true)
    const [clickedSeller2Details, setclickedSeller2Details] = useState(true)
    const [erorPopupController, setErrorPopupController] = useState(false)
    const [errorPopupText, setErrorPopupText] = useState('');

    const [clickedSkuDetailsSub, setclickedSkuDetailsSub] = useState(true)
    const [clickedSellerDetailsSub, setclickedSellerDetailsSub] = useState(true)
    const [clickedSeller2Detailssub, setClickedSeller2Detailssub] = useState(true)
    const [loading, setLoading] = useState(false);

    const [skuIds, setSkuIds] = useState([])

    const [showTextPopup, setShowTextPopup] = useState(false)
    const [alertText, setAlertText] = useState("");
    const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false);
    const blurredBackgroundStyles = isBackgroundBlurred
        ? {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(100, 100, 100, 0.5)",
            backdropFilter: "blur(1.8px)",
            zIndex: 1,
        }
        : {};

    const [submitPopupController, setSubmitPopupController] = useState(false)
    const [customSkuErr, setCustomSkuErr] = useState('');
    const [newSkuId, setNewSkuId] = useState();

    const handleClickSkudetails = (event) => {
        setclickedSkuDetails(!event)
    }
    const handleClickSellerDetails = (event) => {
        setclickedSellerDetails(!event)
    }
    const handleClickSeller2Details = (event) => {
        setclickedSeller2Details(!event)
    }
    const handleClickSkuDetailsSub = (event) => {
        setclickedSkuDetailsSub(!event)
    }
    const handleClickSellerDetailsSub = (event) => {
        setclickedSellerDetailsSub(!event)
    }
    const handleClickSeller2sub = (event) => {
        setClickedSeller2Detailssub(!event)
    }

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const data = await getAllSkuIds(clientid, axiosPrivate);
                
                setSkuIds(data);
            } catch (error) {
                setErrorPopupText(error.message);
                setErrorPopupController(true);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const handleSkuIdChange = (formik, value) => {
        const parsedValue = removeSpecialCharacters(value);

        setNewSkuId(parsedValue);
        formik.setFieldValue('skuId', parsedValue);

        let valueExists = false;
        skuIds.forEach(val => {
            if(val.value == value) 
                valueExists = true;
        });
        if(valueExists)
            setCustomSkuErr('This SkuId is already in use')
        else 
            setCustomSkuErr('');
    }

    const handleSubmit = async (values) => {
        const formData = new FormData();
        formData.append('batchType', 'Retailer');
        formData.append('action', 'Create');
        formData.append('uploadType','SKU')

        Object.keys(values).map((key) => {
            formData.append(key, values[key]);
        })

        //Make a Api call here to create a new SKU
        try{
            setLoading(true)
            await createSKU(clientid,'Retailer',values.skuId,formData,axiosPrivate)
            
            setSubmitPopupController(true);
        }catch(error){
            setErrorPopupText(error.message);
            setErrorPopupController(true);
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <PopUpManager 
                control = {'text'}
                disable = {!erorPopupController}
                heading = {`Error`}
                description = {errorPopupText}
                onClose = {() => {
                    setErrorPopupController(false)
                }}
            />
            <PopUpManager 
                control = {'one-button'}
                disable = {!submitPopupController}
                heading = {`SkuId Created Succefully`}
                description = {`New SkuID '${newSkuId}' created succefully`}
                onClose = {() => {
                    setSubmitPopupController(false)
                    window.location.reload(false)
                }}
                okButtonText = {'Generate Qrs'}
                clickedOk = {() => {
                    navigation('/new-batch-details')
                }}
            />

            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {
                    formik => {
                        formik.values.clientId = clientid;
                        return (
                            <Form>
                                {isBackgroundBlurred && <div style={blurredBackgroundStyles} />}
                                {showTextPopup && (
                                    <TextPopup
                                        heading="Alert"
                                        description={alertText}
                                        onClose={() => {
                                            setShowTextPopup(false);
                                            setIsBackgroundBlurred(false);
                                        }}
                                    />
                                )}
                                {
                                    loading && <Loader />
                                }
                                <div className='pt-10'>
                                    <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                        <div onClick={() => handleClickSkudetails(clickedSkuDetails)}>
                                            <FormHeadingComponent name={"SKU Name/ID"} clicked={clickedSkuDetails} />
                                        </div>
                                        {clickedSkuDetails ? (
                                            <div className='bg-bgWhite rounded-b-[12px]'>
                                                <div onClick={() => handleClickSkuDetailsSub(clickedSkuDetailsSub)}>
                                                    <FormSubHeadingComponent name={"Provide a name for SKU you are about to create"} clicked={clickedSkuDetailsSub} />
                                                </div>
                                                {clickedSkuDetailsSub ? (
                                                    <div className='bg-bgWhite pb-10 border-solid border-navBorder rounded-b-[12px] w-1/2 pl-14 '>
                                                        <FormikControl
                                                            control='input'
                                                            label='SKU ID'
                                                            name='skuId'
                                                            bgcolor={'selectorBg'}
                                                            customError={customSkuErr}
                                                            
                                                            onInputChange={(val) => {
                                                                handleSkuIdChange(formik, val)
                                                            }}
                                                        />
                                                    </div>
                                                ) :
                                                    <></>}
                                            </div>
                                        ) :
                                            <></>
                                        }
                                    </div>

                                    <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                        <div onClick={() => handleClickSellerDetails(clickedSellerDetails)}>
                                            <FormHeadingComponent name={"Seller Details"} clicked={clickedSellerDetails} />
                                        </div>
                                        {clickedSellerDetails ? (
                                            <div className='bg-bgWhite rounded-b-[12px] '>
                                                <div className='rounded-b-[12px]'>
                                                    <div onClick={() => handleClickSellerDetailsSub(clickedSellerDetailsSub)}>
                                                        <FormSubHeadingComponent name={"Provide Seller details"} clicked={clickedSellerDetailsSub} />
                                                    </div>
                                                </div>
                                                {clickedSellerDetailsSub ? (
                                                    <>
                                                    <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10 img-upload-wrapper'>
                                                        <FormikControl
                                                            control='file'
                                                            type='file'
                                                            label={"Product Image :"}
                                                            name='product-image'
                                                            acceptableFiles='.jpg,.png,.jpeg'
                                                            blobRequired={false}
                                                            onFileSelect={(image) => {
                                                                formik.setFieldValue('pimage', image)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10 img-upload-wrapper'>
                                                        <FormikControl
                                                            control='file'
                                                            type='file'
                                                            label={"Hidden QR on Product :"}
                                                            name={'hiddend-qr-on-prod'}
                                                            acceptableFiles='.jpg,.png,.jpeg'
                                                            blobRequired={false}
                                                            onFileSelect={(image) => {
                                                                formik.setFieldValue('QROnprod', image)
                                                            }}
                                                        />

                                                        <FormikControl
                                                            control='file'
                                                            type='file'
                                                            label={"Hidden QR on Product :"}
                                                            name={'hiddend-qr-on-prod'}
                                                            acceptableFiles='.jpg,.png,.jpeg'
                                                            blobRequired={false}
                                                            onFileSelect={(image) => {
                                                                formik.setFieldValue('preSaleQRLocation', image)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='grid grid-cols-2 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 pb-10 rounded-b-[12px] qr-product-etails'>
                                                        <FormikControl control='input' type='text' label={"Sold by :"} name={"prodName"} bgcolor={'selectorBg'} />
                                                    </div>
                                                    </>
                                                ) :
                                                    <></>}
                                            </div>
                                        ) :
                                            <></>}
                                    </div>

                                    <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                        <div onClick={() => { handleClickSeller2Details(clickedSeller2Details) }}>
                                            <FormHeadingComponent name={"Seller Details"} clicked={clickedSeller2Details} />
                                        </div>
                                        {clickedSeller2Details ? (
                                            <div className='bg-bgWhite rounded-[12px]'>
                                                <div onClick={() => handleClickSeller2sub(clickedSeller2Detailssub)}>
                                                    <FormSubHeadingComponent name={"Provide Seller Details "} clicked={clickedSeller2Detailssub} />
                                                </div>
                                                {clickedSeller2Detailssub ? (
                                                    <div className='px-[30px] pb-20 grid grid-cols-1 gap-y-10 rounded-[12px] qr-manufacturing-details'>
                                                        <FormikControl
                                                            control='input-textarea-small'
                                                            width={'full'}
                                                            type='description'
                                                            label={"Manufacturing license no :"}
                                                            name={'manuLicenseNo'}
                                                            placeholder={"Type your text here"} />
                                                        <FormikControl
                                                            control='input-textarea-large'
                                                            width={'full'}
                                                            type='description'
                                                            label={"Manufacturing Address :"}
                                                            name={'manuAdd'}
                                                            placeholder={"Type your text here"} />
                                                        <FormikControl
                                                            control='input-textarea-large'
                                                            width={'full'}
                                                            type='description'
                                                            label={"Additional Details :"}
                                                            name={'addDetails'}
                                                            placeholder={"Type your text here"} />
                                                        <FormikControl
                                                            control='input-textarea-small'
                                                            width={'full'}
                                                            type='description'
                                                            label={"Image or Video Header :"}
                                                            name={'additionalImageDetails'}
                                                            placeholder={"image header"}
                                                        />
                                                        <div className='flex items-center h-[46px] gap-5'>
                                                            <label htmlFor=""></label>
                                                            <div className='generate-img-upload-wrapper'>
                                                                <div className=' flex justify-end'>
                                                                    <FormikControl
                                                                        control='file'
                                                                        type='file'
                                                                        label={""}
                                                                        name='extra-Image-1'
                                                                        acceptableFiles='.jpg,.png,.jpeg'
                                                                        blobRequired={false}
                                                                        onFileSelect={(image) => {
                                                                            formik.setFieldValue('extraimage1', image)
                                                                        }}
                                                                    />
                                                                    <FormikControl
                                                                        control='file'
                                                                        type='file'
                                                                        label={""}
                                                                        name='extra-Image-2'
                                                                        acceptableFiles='.jpg,.png,.jpeg'
                                                                        blobRequired={false}
                                                                        onFileSelect={(image) => {
                                                                            formik.setFieldValue('extraimage2', image)
                                                                        }}
                                                                    />

                                                                     <FormikControl
                                                                        control='video'
                                                                        type='file'
                                                                        label={""}
                                                                        name='reta-extra-video-1'
                                                                        acceptableFiles='.mp4'
                                                                        blobRequired={false}
                                                                        onFileSelect={(url) => {
                                                                            formik.setFieldValue("prodVedioLink", url);
                                                                        }} 
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <FormikControl
                                                            control='input-textarea-small'
                                                            type='description'
                                                            width={'full'}
                                                            label={"Website Link :"}
                                                            name={'manuWebsiteLink'}
                                                            placeholder={"Type your text here"} />
                                                    </div>
                                                ) : <></>}
                                            </div>
                                        ) : <></>
                                        }

                                    </div>

                                    <div className='h-40 pt-[15px]'>
                                        <SubmitButtonWide disabled={customSkuErr} value={"Create"} type={'submit'} />
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                }

            </Formik>
            {/* <PopUpScreen header = {popupScreenView} title = {""}/> */}
        </>
    )
}

export default CreateRetaSku;