import * as Yup from 'yup';

export const validationSchemma = Yup.object({
    clientId: Yup.string().required("This field is required"),
    clientName: Yup.string().required("This field is required"),
    couponHeader: Yup.string().required("This field is required"),
    expiryDate: Yup.string().required("This field is required"),
    couponText: Yup.string().required("This field is required"),
    websiteLink: Yup.string().url(),
    offerDetails: Yup.string().required("This field is required"),
    termsAndConditions: Yup.string().required("This field is required"),
    distributionRatio: Yup.string().required("This field is required"),
    distributionType: Yup.string().required("This field is required"),
    uploadCoupons: Yup.string(),
})