export const ADMIN_ROLES = {
    SUPER_ADMIN: 73295,
    ADMIN: 53921,
    FINANCE: 10473,
    CLIENT: 34618,
    CUSTOMER:38749,
    QR: 86247,
    DEVELOPER:68856
}

export const SUB_CLIENT_ROLES = {
    ADMIN: 51276,
    USER: 93741,
    CUSTOMER: 24680,
    DASHBOARD: 68539,
}

export const COUPON_DISTRIBUTION_TYPES = {
    ALL: "all",
    SELF: "self",
}

export const COUPON_DISTRIBUTION_RATIOS={
    ONE_TO_ONE:"oneToOne",
    RANDOM:"random",
}
export const USER_TYPES = {
    ADMIN: "admin",
    SUB_CLIENT: "sub_client",
    CUSTOMER: "customer",
    MASTERCLIENT:"masterclient"
}

export const SORT_ORDER = {
    ASC:"asc",
    DESC:"desc"
}

export const QR_SCAN_ACCESS_LEVEL = {
    VEOTS:"veots",
    PARENT_BRAND:"parent_brand",
}

export const URL_TYPES = {
    BASE: 'BASE',
    QR: 'QR'
}