import * as Yup from 'yup';

const validationSchema_form = Yup.object({
    clientId: Yup.string(),
    batchNo: Yup.string(),
    fromDate: Yup.date().nullable(),
    toDate: Yup.date().when('fromDate', (fromDate, schema) => {
        return fromDate && !isNaN(Date.parse(fromDate))
        ? schema.required('This field is required').min(fromDate, 'Please select a valid range of dates')
        : schema;
    }),
});

const validationSchema_update = Yup.object({
    type: Yup.string().required("Please select the type of QRs"),
    Pimage: Yup.mixed().required("Product image is required"),
    QROnprod: Yup.mixed().when('type',
    (type,schema)=>{
        if(type === "3"){
            return schema.required('Hidden QR image is required');
        }else{
            return schema;
        }
    }),
    brand: Yup.string().required('This field is required'),
    prodName: Yup.string().required('This field is required'),
    batchNo: Yup.string().required('This field is required'),
    mfgDate: Yup.date().required('This field is required'),
    mrp: Yup.number().required('This field is required').test(
        'Is positive?', 
        'MRP can not be negative', 
        value => value >= 0
    ),
    serialNo: Yup.string(),
    shelfLife: Yup.number()
            .nullable()
            .typeError("Please select the type of QRs")
            .positive()
            .integer(),
    expiryDate: Yup.date().when('shelfLife', (shelfLife, schema)=>{
        if(shelfLife[0])
            return schema.required("This field is required")
        else
            return schema
        
    }),
    warrantyApp: Yup.boolean().when('type',
    (type,schema)=>{
        if(type !== "3"){
            return schema;
        }else{
            return schema.required('This field is required');
        }
    }),
    warrantyPeriod: Yup.number()
        .positive()
        .min(0)
        .integer()
        .when('warrantyApp', (warrantyApp, schema) => {
            if( warrantyApp[0] === false || !warrantyApp[0] ){
                return schema
            }else{
                return schema.required("This field is required");
            }
        }),
    manuLicenseNo: Yup.string(),
    manuAdd: Yup.string().required('This field is required'),
    addDetails: Yup.string(),
    additionalImageDetails: Yup.string(),
    extraImage1: Yup.string(),
    extraImage2: Yup.string(),
    manuWebsiteLink: Yup.string().url('Please provide a valid url. example: `https://example.com`'),
});

const validationSchema_append = Yup.object({
    totalQRGen: Yup.number().typeError("Please enter the number of QRs").integer().test(
        'Is positive?', 
        'This field can not be negative', 
        value => value >= 0
    ),
    trackingQr_tert: Yup.number().typeError("Please enter the number of QRs").integer().test(
        'Is positive?', 
        'This field can not be negative', 
        value => value >= 0
    ),
    trackingQr_quat: Yup.number().typeError("Please enter the number of QRs").integer().test(
        'Is positive?', 
        'This field can not be negative', 
        value => value >= 0
    ),
});

export {
    validationSchema_form,
    validationSchema_update,
    validationSchema_append
}