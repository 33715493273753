import { handleError } from "../../../utils/handles/errorHandle";

async function getAllSubClientsPaginated(masterClientId, pageNo, pageSize, axiosPrivate){
    try{
        const response = await axiosPrivate.get(`/api/client/${masterClientId}/subclients/paginated`, { params: {pageNo, pageSize} });
        return response?.data;
    }catch(error){  
        handleError(error);
    }
}

async function createSubClient(masterClientId, subClientEmail, subClientName, axiosPrivate){
    try{
        const response = await axiosPrivate.post("/api/client/create-sub-client",{
            'userName': subClientName,
            'emailId': subClientEmail,
            masterClientId
        });
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function createAdminUser(adminName, adminEmail, axiosPrivate){
    try{
        const response = await axiosPrivate.post("/api/admin/create-admin-user", { 'userName': adminName, 'emailId':adminEmail });
        return response?.data;
    }catch(error){
        handleError(error);
    }
}

async function getAllAdminsPaginated(pageNo,pageSize,sortBy,sortOrder,axiosPrivate){
    try{
        const response = await axiosPrivate.get("/api/admin/users/paginated",{ params:{ pageNo, pageSize, sortBy, sortOrder }});
        return response?.data;
    }catch(error){  
        handleError(error);
    }
}

export {
    getAllSubClientsPaginated,
    createSubClient,
    createAdminUser,
    getAllAdminsPaginated
}