import React from 'react'
import homeIcon from '../assets/svg/home_icon.svg'
import { SubmitButton } from '../components/ui/buttons/submitButton'
import Breadcrumbs from './breadcrumbs';

function navbar() {
    return (
        <div className='bg-bgWhite pt-2 pb-2 pr-2 w-full right-0 border-3 border-navBorder rounded-[8px] flex flex-row justify-between items-center'>
            <div className='pl-[20px]'>
                <Breadcrumbs />
            </div>
            <div className='w-[100px]'>
                <SubmitButton imageUrl={homeIcon} label={"Home"} btnWidth={'full'} height={'[46px]'} />
            </div>
        </div>
    )
}

export default navbar