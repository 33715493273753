import { handleError } from "../../../../utils/handles/errorHandle";

async function viewMasterClient(masterClientId,axiosPrivate) {
    try{
        const response = await axiosPrivate.get(`/api/client/master-client/info/${masterClientId}`);
        return response.data;
    }catch(error){
        handleError(error);
    }
}

async function saveEditedChanges(masterClientId,formData,axiosPrivate){
    try{
        const response = await axiosPrivate.post(`/api/client/update/master-client/${masterClientId}/MASTERCLIENT_DOCS`,formData);
        return response.data;
    }catch(error){
        handleError(error);
    }
}

async function blackListMasterClient(masterClientId,reason,otp,isMoibile,emailId,axiosPrivate){
    try{
        const response = await axiosPrivate.post("/api/client/blacklist/master-client", {
            masterClientId,
            reason,
            otp,
            isMoibile,
            emailId
        })
        return response;
    }catch(error){
        handleError(error)
    }
}   

async function whiteListMasterClient(masterClientId,reason,otp,isMoibile,emailId,axiosPrivate){
    try{
        const response = await axiosPrivate.post("/api/client/whitelist/master-client", {
            masterClientId,
            reason,
            otp,
            isMoibile,
            emailId
        })
        return response;
    }catch(error){
        handleError(error)
    }
} 

export{
    viewMasterClient,
    saveEditedChanges,
    blackListMasterClient,
    whiteListMasterClient
}