import React, { useState } from 'react'
import { HeaderPlain } from '../../layouts/header'
import createNewPassword from '../../assets/images/forgot_password.svg'
import EmailVerification from './components/EmailVerification'
import ResetPassword from './components/ResetPassword'

function Index() {

    const [isEmailVerified, setIsEmailVerified] = useState(false);

    return (
        <div className='flex flex-col h-[100vh]'>
            <div className='h-[110px]'>
                <HeaderPlain />
            </div>
            <div className='w-full h-full pb-[20px] flex'>
                <div className='w-[50%] flex justify-center items-center h-full'>
                    <img className='md:w-[70%] h-[80%] md:mx-[0px] mx-auto' src={createNewPassword} alt='forgot password' />
                </div>

                <div className='w-[50%] flex justify-center items-center h-full'>
                    <div className='md:w-[75%] h-full'>
                        {
                            isEmailVerified ? <ResetPassword /> : <EmailVerification setIsEmailVerified={setIsEmailVerified} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index;