export const modeOfServiceOptions = [
    {key: "Mobile and Web App", value: "Web and Mobile" },
    {key: "Web App", value: "Web" },
];

export const subDomainOptions = [
    {key: 'Yes', value: true},
    {key: 'No', value: false},
]

export const footerIncludeOptions = [
    {key: 'Yes', value: true},
    {key: 'No', value: false},
]

export const qrScanLevelOptions = [
    {key: 'Parent Brand', value: '["parent_brand"]'},
    {key: 'Veots', value: '["veots"]'},
    {key: 'Both', value: '["parent_brand","veots"]'},
]