import { React, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik';

import { FormHeadingComponent, FormSubHeadingComponent } from './formHeadingComponent'
import FormikControl from '../../../../components/form/formikControl';
import { SubmitButtonWide } from '../../../../components/ui/buttons/submitButton'
import TextPopup from '../../../../components/popups/textPopup';
import Loader from "../../../../components/ui/loader/Loader";

import { radioOptions, batchTypeOptions } from "../../../../utils/QR/batchDropdowns";
import validationSchema from "../validations/validation";
import initialValues from '../utils/initialValues';
import monthNameToNumber from '../utils/monthNameToNumber';

import ManufacturerForm, { manuForm } from './manufactureForm';
import RetailerForm, { retaForm } from './retailerForm';

//importing necessary hooks
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import { URL_TYPES } from '../../../../config/constants';
import { calculateShelfLifeChange } from '../../../../utils/QR/handleDateChanges';

import {
    getClientDetails,
    getSkuIdsByBatchType,
    getSkuData,
    generateNewBatch
} from "../services/api";


function GenerateQRCodeForm(props) {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate(URL_TYPES.QR);
    const [loading, setLoading] = useState(false)
    const [clickedSolution, setclickedSolution] = useState(true)
    const [clickedSkuDetails, setclickedSkuDetails] = useState(true)
    const [clickedQRDetails, setclickedQRDetails] = useState(true)
    const [clickedSolutionSub, setclickedSolutionContainer] = useState(true)
    const [clickedSkuDetailsSub, setclickedSkuDetailsSub] = useState(true)
    const [clickedQRDetailsSub, setclickedQRDetailsSub] = useState(true)
    const [clickedClientDetails, setClickedClientDetails] = useState(true)
    const [clickedClientDetailsSub, setclickedClientDetailsSub] = useState(true)

    const [skuData, setSkuData] = useState(initialValues);
    const [skuIds, setSkuIds] = useState([{value: 'Loading...', key: 'Loading...'}]);
    const [slectedSku_Id, setSelectedSku_Id] = useState('')

    const [showTextPopup, setShowTextPopup] = useState(false)
    const [alertText, setAlertText] = useState("");
    const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false);
    const blurredBackgroundStyles = isBackgroundBlurred
        ? {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(100, 100, 100, 0.5)",
            backdropFilter: "blur(1.8px)",
            zIndex: 1,
        }
        : {};

    const [isBatchGenerating, setIsBatchGenerating] = useState(false);

    const handleClickSolutionHeading = (event) => {
        setclickedSolution(!event)
    }
    const handleClickSkudetails = (event) => {
        setclickedSkuDetails(!event)
    }
    const handleClickQRDetails = (event) => {
        setclickedQRDetails(!event)
    }
    const handleClickSolutionSub = (event) => {
        setclickedSolutionContainer(!event)
    }
    const handleClickSkuDetailsSub = (event) => {
        setclickedSkuDetailsSub(!event)
    }
    const handleClickQRDetailsSub = (event) => {
        setclickedQRDetailsSub(!event)
    }
    const handleClickClientDetailsSub = (event) => {
        setclickedClientDetailsSub(!event)
    }
    const handleClickClientDetailsHeading = (event) => {
        setClickedClientDetails(!event)
    }

    useEffect(() => {
        if(!isBatchGenerating) return;
        
        const handlePopEvent = (event) => {
            const confirmLeave = window.confirm(`If you navigate away from this screen, you won't be able to view the generated file. However, it will be saved under the 'Download QR' option once processing is complete. Are you sure you want to continue?`);
            if(!confirmLeave) {
                navigate('/new-batch-details');
            }
        }

        const handleBeforeUnLoad = (event) => {
            event.preventDefault();
            return(event.returnValue = '');
        }


        window.addEventListener('beforeunload', handleBeforeUnLoad, { capture: true })
        window.addEventListener('popstate', handlePopEvent, { capture: true });

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnLoad, { capture: true });
            setTimeout(() => {
                window.removeEventListener('popstate', handlePopEvent, { capture: true });
            }, 100);
        };
    }, [isBatchGenerating]);
    

    const handleSubmit = async (values) => {
        let batchData;

        if(values.batchType === "Manufacturer") {
            if(!manuForm.isFormValid()) return;
            batchData = Object.assign({}, {...values, ...manuForm.values});
        }

        if(values.batchType === "Retailer") {
            if(!retaForm.isFormValid) return;
            batchData = Object.assign({}, {...values, ...retaForm.values})
        }

        Object.keys(initialValues).forEach((key) => {
            batchData[key] = values[key];
        })

        if (batchData.type === 1 || batchData.warrantyApp === "false") {
            batchData.warrantyApp = 'false';
            batchData.warrantyPeriod = 0;
        }

        //update
        batchData.preSaleQRLocation = batchData.preSaleQRLocation;
        if (batchData.type !== "3") {
            batchData.QROnprod = '';
            batchData.preSaleQRLocation = '';
        }
        if (batchData.warrantyApp === "true" && !batchData.warrantyPeriod) {
            setAlertText("Please enter the warranty period");
            setShowTextPopup(true);
            setIsBackgroundBlurred(true);
            return
        }
        batchData.verb = "new";
        batchData.batchNo = batchData.batchid;
        if(slectedSku_Id) batchData.skuId = slectedSku_Id;

        //parse dates
        if(values.batchType === "Manufacturer") {
            if(batchData.mfgDate.year && batchData.mfgDate.month) {
                if(batchData.shelfLife && batchData.expiryDate === 'notAvailable') {
                    const mfgDate = new Date(`${batchData.mfgDate.year} ${batchData.mfgDate.month} ${batchData.mfgDate.day && batchData.mfgDate.day !== '' && batchData.mfgDate.day !== 'DD' ? batchData.mfgDate.day : '01'}`);
                    const expiryDate = await  calculateShelfLifeChange({
                        shelfLife: batchData.shelfLife, mfgDate
                    });
    
                    batchData.expiryDate = {
                        year: expiryDate.getFullYear(), 
                        month: expiryDate.toLocaleString('default', { month: 'long' }), 
                        day: expiryDate.getDate()
                    };
                }

                batchData.mfgDate = `${batchData.mfgDate.year}-${monthNameToNumber(batchData.mfgDate.month)}-${batchData.mfgDate.day && batchData.mfgDate.day !== 'DD'? batchData.mfgDate.day : '01'}`
            }
            if(batchData.expiryDate.year && batchData.expiryDate.month) {
                const lastDay = new Date(batchData.expiryDate.year, monthNameToNumber(batchData.expiryDate.month), 0);
                batchData.expiryDate = `${batchData.expiryDate.year}-${monthNameToNumber(batchData.expiryDate.month)}-${batchData.expiryDate.day && batchData.expiryDate.day !== 'DD'? batchData.expiryDate.day : lastDay.getDate()}`
            }
            // if()
        }

        try {
            setLoading(true);
            setIsBatchGenerating(true);

            const qrCodeLinks = await generateNewBatch(batchData, axiosPrivate);
            navigate(`/generated-qr-codes`, { state: { qrCodeLinks, batchData } });
        } catch (error) {
            setAlertText(error.message || 'Unable to genreate QR codes');
            setShowTextPopup(true);
            setIsBackgroundBlurred(true);
        } finally {
            setLoading(false);
            setIsBatchGenerating(false);
        }
    }

    const reqSkuData = async (values, skuid, formik) => {
        if (!values.clientId) {
            setAlertText(`Please enter the client Id`);
            setShowTextPopup(true);
            setIsBackgroundBlurred(true);
            return;
        }
        if (skuid) {
            try {
                setLoading(true);
                const data = await getSkuData(values.clientId, skuid, axiosPrivate);

                formik?.resetForm && formik.resetForm();
                setSkuData({ ...values, ...data[0], "warrantyApp": data[0].warrantyApp ? "true" : "false" });

                setAlertText(`${skuid} data autofilled`);
                setShowTextPopup(true);
                setIsBackgroundBlurred(true);
            } catch (error) {
                setAlertText(error.message);
                setShowTextPopup(true);
                setIsBackgroundBlurred(true);
            } finally {
                setLoading(false);
            }
        }
    }

    const validateManufacturerAndRetailerForms = (values) => {
        if(values.batchType === "Manufacturer") {
            manuForm.valideForm && manuForm.valideForm(); //This will active all manuform errors
        }

        if(values.batchType === "Retailer") {
            retaForm.valideForm && retaForm.valideForm(); //This will active all retaForm errors
        }
    }

    const validateForm = (values) => {
        const { totalQRGen, trackingQr_tert, trackingQr_quat } = values;
        if (!totalQRGen && !trackingQr_tert && !trackingQr_quat) {
            return {
                totalQRGen: 'At least one field is required',
                trackingQr_tert: 'At least one field is required',
                trackingQr_quat: 'At least one field is required',
            };
        }
        return {};
    };

    const setClientDetails = async (formik, clientId) => {
        await formik.setFieldValue('clientId', clientId)
        if (clientId.length !== 7) {
            await formik.setFieldValue('clientName', '')
            await formik.setFieldValue('noOfActiveUsers', '')
            return;
        }

        try {
            setLoading(true);
            const clientData = await getClientDetails(clientId,axiosPrivate);
            
            if(!clientData.isCloneApproved) {
                setAlertText(`Can not create QrCodes while App creation request is still at pending!!`);
                setShowTextPopup(true);
                setIsBackgroundBlurred(true);

                await formik.setFieldValue('clientId', '')
                await formik.setFieldValue('clientName', '')
                await formik.setFieldValue('noOfActiveUsers', '')
                return;
            }

            await formik.setFieldValue('clientName', clientData.organizationName);
            await formik.setFieldValue('noOfActiveUsers', clientData.totalActiveSubClients);


            const ids = await getSkuIdsByBatchType(clientId,formik.values.batchType ,axiosPrivate);
            setSkuIds(ids);
        } catch (error) {
            setAlertText(error.message);
            setShowTextPopup(true);
            setIsBackgroundBlurred(true);

            await formik.setFieldValue('clientId', '')
            await formik.setFieldValue('clientName', '')
            await formik.setFieldValue('noOfActiveUsers', '')
        } finally {
            setLoading(false);
        }
    }

    const handleBatchChange = async (formik, batchtype) => {
        if(!formik.values.clientName) return;
        try {
            setLoading(true);
            const ids = await getSkuIdsByBatchType(formik.values.clientId,batchtype ,axiosPrivate);
            setSkuIds(ids);
        } catch (error) {
            setAlertText(error.message);
            setShowTextPopup(true);
            setIsBackgroundBlurred(true);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Formik
                initialValues={skuData || initialValues}
                enableReinitialize={true}
                validate={validateForm}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {
                    formik => {
                        return (
                            <Form>
                                {
                                    loading && <Loader />
                                }
                                {isBackgroundBlurred && <div style={blurredBackgroundStyles} />}
                                {showTextPopup && (
                                    <TextPopup
                                        heading="Alert"
                                        description={alertText}
                                        onClose={() => {
                                            setShowTextPopup(false);
                                            setIsBackgroundBlurred(false);
                                        }}
                                    />
                                )}
                                <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                    <div onClick={() => handleClickClientDetailsHeading(clickedClientDetails)}>
                                        <FormHeadingComponent name={"Client Details"} clicked={clickedClientDetails} />
                                    </div>

                                    <div className='pt-7 bg-white pb-5'>
                                        <div className='flex items-center gap-10 text-couponsRadioOPtions font-semibold text-3xl radio-btn-custom bg-white pb-5 rounded-b-[12px]'>
                                            <h5 className='text-sideBarBtnText font-medium pl-5 inline mb-8'> Mode: </h5>
                                            <div className='-ml-14'>
                                                <FormikControl control='radio' name="batchType" options={batchTypeOptions}
                                                    onChange={async (value) => {
                                                        await formik.setFieldValue('batchType', value)
                                                        await handleBatchChange(formik, value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {clickedClientDetails ? (
                                        <div className='flex flex-col rounded-b-[12px]' >
                                            <div onClick={() => handleClickClientDetailsSub(clickedClientDetailsSub)}>
                                                <FormSubHeadingComponent name={"Enter Client ID"} clicked={clickedClientDetailsSub} />
                                            </div>
                                            {clickedClientDetailsSub ? (
                                                <div className='flex flex-wrap md:flex-nowrap gap-10 bg-bgWhite justify-between items-center border-solid border-navBorder rounded-b-xl py-5 pl-10 pr-10'>
                                                    <FormikControl control='input' type='text' label={"Client ID:"} name={'clientId'} bgcolor='selectorBg' width={'[100%]'} textcolor='black' onChange={(e) => setClientDetails(formik, e.target.value)} />
                                                    <FormikControl control='input' type='text' label={"Client Name:"} name={'clientName'} bgcolor='selectorBg' width={'[100%]'} disabled={true} />
                                                    <FormikControl control='input' type='text' label={"No of Users Active:"} name={'noOfActiveUsers'} bgcolor='selectorBg' width={'[100%]'} disabled={true} />
                                                </div>
                                            ) :
                                                <></>}
                                        </div>
                                    ) : <></>}
                                </div>

                                <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                    <div onClick={() => handleClickSolutionHeading(clickedSolution)}>
                                        <FormHeadingComponent name={"Solution"} clicked={clickedSolution} />
                                    </div>
                                    {clickedSolution ? (
                                        <div className='flex flex-col' >

                                            {/*  */}
                                            <div onClick={() => handleClickSolutionSub(clickedSolutionSub)}>
                                                <FormSubHeadingComponent name={"Choose Solution"} clicked={clickedSolutionSub} />
                                            </div>
                                            {clickedSolutionSub ? (
                                                <div className='flex items-center gap-10 text-couponsRadioOPtions font-semibold text-3xl radio-btn-custom bg-white pb-5 rounded-b-[12px]'>
                                                    <FormikControl control='radio' name="type" options={radioOptions}
                                                        onChange={async (value) => {
                                                            await formik.setFieldValue('type', value)
                                                        }}
                                                    />
                                                </div>
                                            ) :
                                                <></>}
                                        </div>
                                    ) : <></>}
                                </div>
                                {
                                    formik.values.clientId?.length === 7 && skuIds && skuIds.length > 1 && formik.values.batchType && 
                                        <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                            <div onClick={() => handleClickSkudetails(clickedSkuDetails)}>
                                                <FormHeadingComponent name={"SKU Details"} clicked={clickedSkuDetails} />
                                            </div>
                                            {clickedSkuDetails ? (
                                                <div className='bg-bgWhite rounded-b-[12px]'>
                                                    <div onClick={() => handleClickSkuDetailsSub(clickedSkuDetailsSub)}>
                                                        <FormSubHeadingComponent name={"Select the sku id from drop down"} clicked={clickedSkuDetailsSub} />
                                                    </div>
                                                    {clickedSkuDetailsSub ? (
                                                        <div className='bg-bgWhite border-solid border-navBorder rounded-b-[12px] w-1/2 pl-14 pb-6 dropdown-icon'>
                                                            <FormikControl
                                                                control='select'
                                                                label='SKU ID'
                                                                name='skuId'
                                                                options={skuIds}
                                                                bgcolor={'selectorBg'}
                                                                onChange={async (e) => {
                                                                    const _skuId = e.target.value;
                                                                    await formik.setFieldValue('skuId', _skuId);
                                                                    await reqSkuData(formik.values, _skuId, formik);

                                                                    skuIds.forEach(sku => {
                                                                        if(sku.value === _skuId) {
                                                                            setSelectedSku_Id(sku._id);
                                                                        }
                                                                    });
                                                                }}
                                                                height={'[46px]'}
                                                                radius={'[5px]'}
                                                            />
                                                        </div>
                                                    ) :
                                                        <></>}
                                                </div>
                                            ) :
                                                <></>
                                            }
                                        </div>
                                } 

                                {
                                    formik.values.clientName && formik.values.batchType === "Manufacturer" && <div className='w-full'>
                                        <ManufacturerForm type={formik.values.type} skuData={skuData} />
                                    </div>
                                }                                                    

                                {
                                    formik.values.clientName && formik.values.batchType === "Retailer" && <div className="w-full">
                                        <RetailerForm type={formik.values.type} skuData={skuData} />
                                    </div>
                                }

                                {
                                    formik.values.batchType && 
                                    <div className='border border-navBorderLight rounded-[12px] mb-10'>
                                        <div onClick={() => { handleClickQRDetails(clickedQRDetails) }}>
                                            <FormHeadingComponent name={"QR Details"} clicked={clickedQRDetails} />
                                        </div>
                                        {clickedQRDetails ? (
                                            <div className='bg-bgWhite rounded-b-[12px]'>
                                                <div onClick={() => handleClickQRDetailsSub(clickedQRDetailsSub)}>
                                                    <FormSubHeadingComponent name={"Provide Manufacturing Details "} clicked={clickedQRDetailsSub} extraText='No of tracking QR code:' />
                                                </div>
                                                {clickedQRDetailsSub ? (
                                                    <div className='w-[100%] grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-10 bg-bgWhite pl-10 pr-10 rounded-b-[12px] py-10 provide-manufacturing-details'>
                                                        <FormikControl control='input' type='number' label={"No of Product QR's:"} name={'totalQRGen'} bgcolor={'selectorBg'} />
                                                        <FormikControl control='input' type='number' label={"level 1 :"} name={'trackingQr_tert'} bgcolor={'selectorBg'} width='1/2' />
                                                        <div></div>
                                                        <FormikControl control='input' type='number' label={"level 2  :"} name={'trackingQr_quat'} bgcolor={'selectorBg'} width='1/2' />
                                                    </div>
                                                ) :
                                                    <></>}
                                            </div>
                                        ) :
                                            <></>}
                                    </div>
                                }

                                <div className='h-40'>
                                    <SubmitButtonWide value={"Process"} type={'submit'} onClick={() => validateManufacturerAndRetailerForms(formik.values)} />
                                </div>
                            </Form>
                        )
                    }
                }

            </Formik>
        </>
    )
}

// 
// 

// 
// 

export default GenerateQRCodeForm