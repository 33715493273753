import React from 'react'
import { useNavigate } from 'react-router-dom'
import HomeBanner from '../../../assets/images/home_images/home_banner.svg'
import AdminImage from '../../../assets/images/home_images/admin.svg'
import CustomerImage from '../../../assets/images/home_images/customer.svg'
import ClientImage from '../../../assets/images/home_images/client.svg'
import QRCodeImage from '../../../assets/images/home_images/qrcode.svg'
import FinanceImage from '../../../assets/images/home_images/finance.svg'
import DeveloperImage from '../../../assets/images/home_images/developer.svg'


import useAuth from "../../../hooks/useAuth"

import { ADMIN_ROLES } from "../../../config/constants"

function HomeContent() {

    const { auth } = useAuth();
    const navigate = useNavigate();

    const handleAdminClick = async () => {
        navigate("/admin-view-profile")
    }

    const handleClientClick = async () => {
        navigate('/view-client-profile')

    }
    const handleCustomerClick = async () => {
        navigate('/customer-profile')
    }

    const handleQRCodeClick = async () => {
        navigate("/new-batch-details")
    }

    const handleFinanceClick = async () => {
        navigate("/finance")
    }

    // const handleDeveloperClick = async () => {
    //     navigate("/create-developer-request")
    // }

    return (
        <div className='flex flex-col'>
            <div className='flex flex-col w-full'>
                <h4 className='text-popupText italic font-semibold'>howdy,</h4>
                <h3 className='md:text-[48px] text-[35px] text-popupText italic font-extrabold'>Welcome back ! ! !</h3>
            </div>
            <div className='pt-5  flex flex-col h-[100%] w-[100%]'>
                <div className='pb-5 w-[100%]  '>
                    <img className='w-full' src={HomeBanner} alt='home_banner' />
                </div>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-[25px] w-[100%] justify-between pb-5 pt-5'>
                    {auth?.roles?.find((role => [ADMIN_ROLES.SUPER_ADMIN, ADMIN_ROLES.ADMIN]?.includes(role)))
                        && <div onClick={handleAdminClick} className='cursor-pointer'>
                            <img className='w-full' src={AdminImage} alt='admin panel button' />
                        </div>
                    }
                    {auth?.roles?.find((role => [ADMIN_ROLES.SUPER_ADMIN, ADMIN_ROLES.ADMIN, ADMIN_ROLES.CLIENT]?.includes(role)))
                        && <div onClick={handleClientClick} className='cursor-pointer'>
                            <img className='w-full' src={ClientImage} alt='clinet panel button' />
                        </div>
                    }
                    {auth?.roles?.find((role => [ADMIN_ROLES.SUPER_ADMIN, ADMIN_ROLES.ADMIN, ADMIN_ROLES.CUSTOMER]?.includes(role)))
                        && <div onClick={handleCustomerClick} className='cursor-pointer'>
                            <img className='w-full' src={CustomerImage} alt='customer panel button' />
                        </div>
                    }
                    {auth?.roles?.find((role => [ADMIN_ROLES.SUPER_ADMIN, ADMIN_ROLES.ADMIN, ADMIN_ROLES.QR]?.includes(role)))
                        && <div onClick={handleQRCodeClick} className='cursor-pointer'>
                            <img className='w-full' src={QRCodeImage} alt='QR Code generation panel button' />
                        </div>
                    }
                    {auth?.roles?.find((role => [ADMIN_ROLES.SUPER_ADMIN, ADMIN_ROLES.ADMIN, ADMIN_ROLES.FINANCE]?.includes(role)))
                        && <div onClick={handleFinanceClick} className='cursor-pointer'>
                            <img className='w-full' src={FinanceImage} alt='Finance Panel button' />
                        </div>
                    }
                </div>
            </div>

        </div>
    )
}

export default HomeContent