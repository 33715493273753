import * as Yup from 'yup';

const manValidationSchema = Yup.object({
    skuId: Yup.string().required('This Field is Required'),
    Pimage: Yup.mixed(),
    QROnprod: Yup.mixed(),
    preSaleQRLocation: Yup.mixed(),
    expiryImage: Yup.mixed(),
    brand: Yup.string().required('This Field is Required'),
    prodName: Yup.string().required('This Field is Required'),
    mrp: Yup.number().test(
        'Is positive?', 
        'MRP can not be negative', 
        value => value >= 0
    ).integer(),
    serialNo: Yup.string(),
    shelfLife: Yup.number().positive().integer(),
    warrantyApp: Yup.boolean().required('This Field is Required'),
    warrantyPeriod: Yup.number().positive().integer(),
    warrantyTC: Yup.string(),
    manuLicenseNo: Yup.string(),
    manuAdd: Yup.string(),
    addDetails: Yup.string(),
    additionalImageDetails: Yup.string(),
    extraImage1: Yup.string(),
    extraImage2: Yup.string(),
    extraVideo1: Yup.string(),
    manuWebsiteLink: Yup.string().url('Please provide a valid url. example: `https://example.com`'),
})

export default manValidationSchema;
