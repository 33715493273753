const initialValues = {
    'clientId':'',
    'clientName':'',
    'noOfActiveUsers':'',
    'type':'',
    'batchType': '',
    'skuId': '',
    'totalQRGen': '',
    'trackingQr_tert': '',
    'trackingQr_quat': '',
}

export default initialValues;