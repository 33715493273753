import React, { useEffect, useState } from 'react';
import Info from './components/Info';

const Select = ({
    name, 
    formik,
    marginClass='mx-auto',
    tailwindClasses='',  
    label,
    message='',
    options=[],
    disabled=false
    }) => {

    const [optionsList, setOptionsList] = useState([{key: 'Please selct an option', value: ''}, ...options]);
    useEffect(() => {
        setOptionsList([{key: 'Please selct an option', value: ''}, ...options]);
    }, [options])
        
    return (
        <div 
            className={`w-full flex flex-col ${marginClass}`}
        >
            {
                label && <div className='flex pb-2 items-center'>
                    <label htmlFor={name} className='text-3xl mr-2'>{label}</label>
                    {message && <Info message={message} />}
                </div>
            }
            <select 
                name={name} 
                id={name}
                value={formik?.values[`${name}`]} 
                onBlur={formik?.handleBlur}
                onChange={formik?.handleChange} 
                className={`${tailwindClasses} bg-[#182AB408] border p-[.65rem] rounded-md text-3xl h-[46px]`}
                disabled={disabled}
            >
                {
                    optionsList && optionsList.map((option, ind) => {
                        return <option key={ind} value={option?.value} >{option?.key}</option>
                    })
                }
            </select>
            <p className='text-red-500 text-xl'>{formik?.touched[`${name}`] && formik?.errors[`${name}`]}&nbsp;</p>
        </div>
    );
}

export default Select;