import { React, useState } from 'react'
import downArrowIcon from '../../assets/svg/form_icons/down_arrow_icon.svg'
import rightArrowIcon from '../../assets/svg/form_icons/right_arrow_icon.svg'

function Tablehead(props) {
    return (
        <thead className={`rounded-t-[12px] py-7 bg-bgLightBlue w-[100%]  flex`}>
            <tr className='w-[100%] flex'>
            {props.options.map((key, val) => {
                return (
                    <th className={`text-center font-semibold text-3xl text-gray-600 flex items-center justify-center ${val === 0 && 'pl-10'}`} style={{ width: key.width }} key={val} >
                        {key.heading.includes('*') ? (
                            <>
                                {key.heading.split('*')[0]}
                                <span className="text-red-600">*</span>
                                {key.heading.split('*')[1]}
                            </>
                        ) : (
                            key.heading
                        )}
                    </th>
                )
            })}
            </tr>
        </thead>
    )

}


function TableMainHeading(props) {
    const [isMainHeadingOpen, setisMainHeadingOpen] = useState(false)
    const handleClickMainHeading = () => {
        setisMainHeadingOpen(!isMainHeadingOpen)
    }
    const { details, widths, ...rest } = props
    return (
        <thead className={`bg-popupScreen text-font16 font-medium w-[100%] h-28 flex cursor-pointer`} onClick={handleClickMainHeading}>
            {/* {console.log(data)} */}
            {Object.keys(details).map((key, index) => {
                // {console.log(widths)}
                return (
                    <th className={`flex items-center justify-start ${index === 0 && 'pl-5'} ${index === 4 ? 'text-redButtonColor' : 'text-financeSideBoxText'}`} style={{ width: widths[index] }} key={key} >
                        {index === 0 && (details[key] != 'Customer Support' && details[key] != 'Outstanding Amount ( Previous Months )') && <img className='pr-5' src={isMainHeadingOpen ? `${downArrowIcon}` : `${rightArrowIcon}`} alt='arrowicons' />}
                        {details[key]}
                    </th>
                )
            })}
        </thead>
    )
}


function TableSubHeading(props) {
    const [isSubHeadingOpen, setisSubHeadingOpen] = useState(false)
    const handleClickSubHeading = () => {
        setisSubHeadingOpen(!isSubHeadingOpen)
    }
    const { details, widths, ...rest } = props
    return (
        <tbody className='bg-bgLightBlue text-financeSideBoxText text-font16 font-normal w-[100%] py-5 flex cursor-pointer' onClick={handleClickSubHeading}>
            {Object.keys(details).map((key, index) => {
                return (
                    <td className={`flex items-center justify-start ${index === 0 && 'pl-5'}`} style={{ width: widths[index] }} key={key} >
                        {index === 0 && <img className='pr-5' src={isSubHeadingOpen ? `${downArrowIcon}` : `${rightArrowIcon}`} alt='arrowicons' />}
                        <p className='mx-auto text-2xl fontb-bold'> {details[key]  }</p>
                    </td>
                )
            })}
        </tbody>
    )
}


function TableHeadRowSpan(props) {
    const { fontSize, radius, widths, options } = props;
    const subHeaderWidth = `${100 / options.length}%`; // Calculate subheader width

    return (
        <thead className={`bg-bgLightBlue text-couponsTableText w-[100%] ${props.fontSize} ${props.radius}`}>
            <tr className={`${props.radius}`}>
                {options.map((item, index) => (
                    <th className={`p-5 ${fontSize} w-[${widths[index]}]`} key={index} rowSpan={item.rowspan} colSpan={item.colSpan}>
                        {item.label}
                    </th>
                ))}
            </tr>
            <tr>
                {options.map((item, index) => (
                    item.subHeaders.map((subHeader, subIndex) => (
                        <th className={`p-5 ${fontSize}`} style={{ width: subHeaderWidth }} key={`${index}-${subIndex}`}>
                            {subHeader}
                        </th>
                    ))
                ))}
            </tr>
        </thead>
    );
}


function TableHeadColSpan(props) {
    const { fontSize, radius, widths, options } = props;

    return (
        <thead className={`bg-bgLightBlue text-couponsTableText ${fontSize} ${radius}`}>
            <tr className={`${radius}`}>
                {options.map((item, index) => (
                    <th className={`${fontSize}`} style={{ width: widths[index]}} key={index} colSpan={item.colSpan}>
                        <p className={`${fontSize} py-5`}>{item.label}</p>
                    </th>
                ))}
            </tr>
        </thead>
    );
}



export { Tablehead, TableMainHeading, TableSubHeading, TableHeadRowSpan, TableHeadColSpan }