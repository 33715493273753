import React from 'react';

import Input from '../../../../../../components/form2/Input';
import FileUpload from '../../../../../../components/form2/FileUpload';
import Select from '../../../../../../components/form2/Select';
import MobileNumber from '../../../../../../components/form2/MobileNumber';

import { identityDocOptions } from './utils/options';

const InvoicePaymentContact = ({ formik }) => {
    return (
        <div className='my-10 mx-16'>
            <p className='text-[#182AB4] text-5xl mb-10 font-semibold'>Invoice Payment contact</p>
            <div className='flex gap-16'>
                <div className='w-1/2 flex flex-col'>
                    <Input 
                        label='First name *'
                        formik={formik}
                        name={'inv_firstName'}
                        placeholder=''
                        marginClass='mb-5'
                    />
                    <Input 
                        label='Email Address *'
                        formik={formik}
                        name={'inv_emailId'}
                        placeholder=''
                        marginClass='mb-5'
                    />
                    <Input 
                        label='Designation *'
                        formik={formik}
                        name={'inv_designation'}
                        placeholder=''
                        marginClass='mb-5'
                    />
                </div>
                <div className='w-1/2 flex flex-col'>
                    <Input 
                        label='Last Name *'
                        formik={formik}
                        name={'inv_lastName'}
                        placeholder=''
                        marginClass='mb-5'
                    />
                    <MobileNumber 
                        label='Phone Number *'
                        name={'inv_mobileNoDetails'}
                        formik={formik}
                        marginClass='mb-5'
                    />
                    <FileUpload 
                        label='Authorisation Document *'
                        name={'invAuthorizationDoc'}
                        formik={formik}
                        defaultFileName={formik?.values?.payment_Authorization_Doc_Filename}
                        marginClass=''
                    />
                </div>
            </div>

            <label className='text-3xl mb-2'>Proof of Identity *</label>
            <div className='flex gap-16 py-5 px-5 bg-[#F1F2FA] rounded-md'>
                <div className="w-1/2">
                    <Select 
                        label=''
                        formik={formik}
                        name={'inv_auth_Proof_Of_Identity_Doc_Options'}
                        options={identityDocOptions}
                    />
                </div>
                <div className="w-1/2">
                    <FileUpload 
                        name={'invProofDoc'}
                        formik={formik}
                        defaultFileName={formik?.values?.payment_Proof_Of_Identity_Doc_Filename} 
                        marginClass=''
                    />
                </div>
            </div>
        </div>
    );
}

export default InvoicePaymentContact;