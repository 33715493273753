import React, { useState,useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { SubmitButton } from '../../../components/ui/buttons/submitButton'
import AdminAddUserForm from './adminAddUserForm'
import addIcon from '../../../assets/svg/add_icon_white.svg'
import PopupManager from '../../../components/popups2/popupManager';
import SortingTable from '../../../components/table/sortingTable'

import { getAllAdminsPaginated } from "../services/api"
import { SORT_ORDER } from '../../../config/constants'
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"

const AdminsHeaderData = [{value: 'Created At', space: '20%', key: 'createdAt'}, {value: 'Admin User ID', space: '20%', key: 'userId'}, {value: 'Name', space: '25%', key: 'userName'}, {value: 'Email', space: '40%', key: 'emailId'}, {value: 'Status', space: '20%', key: 'status'}]

function AdminAddUser() {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const pageCount = 10;
    const [canCreateAdmin, setCanCreateAdmin] = useState(true);
    const [canAdd, setCanAdd] = useState(false);

    //Admin
    const [admins, setAdmins] = useState([]);
    const [adminsCurrentPage, setAdminsCurrentPage] = useState(1);
    const [adminsMaxPage, setAdminsMaxPage] = useState(0);
    const [adminsPrevDisabled, setAdminsPrevDisabled] = useState(true);
    const [adminsNextDisabled, setADminsNextDisabled] = useState(true);

    const [teableLoading, setTableLoading] = useState(false);
    const [textPopupDisabled, setTextPopupDisabled] = useState(true);
    const [textPopupDescription, setTextPopupDescription] = useState('heading');


     //ADmin logic
     const loadAdmins = useCallback(async (adminsCurrentPage) => {
        try {
            setTableLoading(true);
            const res = await getAllAdminsPaginated(adminsCurrentPage, pageCount, 'createdAt', SORT_ORDER.DESC, axiosPrivate);

            const pageData = res?.pageData;
            pageData?.forEach(ele => {
                const date = new Date(ele.createdAt);
                ele.createdAt = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
            });
            
            res?.pageData?.forEach(adminData => adminData.status = adminData.isBlocked? 'Blocked' : 'UnBlocked')
            setAdmins(res?.pageData);
            setAdminsMaxPage(Math.floor(res?.totalUsers/pageCount) + (res?.totalUsers%pageCount? 1 : 0));
        } catch (error) {
            //Show error
            setTextPopupDescription(error.message);
            setTextPopupDisabled(false);
        } finally {
            setTableLoading(false);
        }
    }, [axiosPrivate])

    const adminsNext = () => {
        setAdminsCurrentPage(adminsCurrentPage + 1);
    }

    const adminsPrevious = () => {
        setAdminsCurrentPage(adminsCurrentPage - 1);
    }

    useEffect(() => {
        if(adminsCurrentPage === 1) 
            setAdminsPrevDisabled(true);
        else
            setAdminsPrevDisabled(false);

        if(adminsCurrentPage === adminsMaxPage)
            setADminsNextDisabled(true);
        else
            setADminsNextDisabled(false); 
    }, [adminsCurrentPage, adminsMaxPage]);

    useEffect(() => {
        (async () => {
            await loadAdmins(adminsCurrentPage);
        })();
    }, [adminsCurrentPage, loadAdmins]);

    const onCreateSuccess = async (newUser) => {
        setCanCreateAdmin(false);
        setCanAdd(true);

        setTextPopupDescription(`New Admin has been created with Id: ${newUser.userId}`);
        setTextPopupDisabled(false);

        //Load the updated users list
        setAdminsCurrentPage(1);
        loadAdmins(1);
    }

    // const handleDone = async()=>{
    //     navigate("/home")
    // }

    return (
        <>
            <PopupManager 
                control={'text'}
                disable={textPopupDisabled}
                heading={'Success'}
                description={textPopupDescription}
                onClose={() =>{
                    setTextPopupDisabled(true);
                }}
            />

            <div className='h-full'>
                <div className='bg-popupScreen -mt-2 py-5 rounded-b-[12px]'>
                    {canCreateAdmin && <AdminAddUserForm onCreateSuccess={onCreateSuccess} />}
                    
                    <div className='w-[20%] pl-10 flex items-center pt-10' >
                        <SubmitButton label='Add More Users' imageUrl={addIcon} btnWidth='full' disabled={!canAdd} onClick={() => {
                            setCanCreateAdmin(true)
                            setCanAdd(false)
                        }} />
                    </div>
                    {/* <div className='pl-10 pr-10 my-5 flex items-center'>
                        <SubmitButton label='Done' btnWidth='full' onClick={handleDone} />
                    </div> */}
                </div>

                <SortingTable loading={teableLoading} headerData={AdminsHeaderData} tableData={admins} nextPage={adminsNext} previousPage={adminsPrevious} nextDisabled={adminsNextDisabled} previousDisabled={adminsPrevDisabled} />
            </div>
        </>
    )
}

export default AdminAddUser